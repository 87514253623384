import React, { useState, useContext } from "react";

//framework
import Events from "../core/Events";
import Web3Connection from "../core/Web3Connection";

//hooks
import { useEventSubscription } from "../hooks";

export const Web3ConnectionContext = React.createContext(null);
export const useWeb3Connection = () =>
{
    return useContext(Web3ConnectionContext);
};

export const Web3ConnectionProvider = ({ children }) =>
{
    //handlers
    const handleUpdate = () =>
    {
        //event
        document.dispatchEvent(new CustomEvent(Events.web3.update));
        setConnection(connection);
    };

    //state & effect
    const [connection, setConnection] = useState(() => Web3Connection.getOrCreateInstance());
    useEventSubscription(
        [
            Events.web3.connect,
            Events.web3.connectWallet,
            Events.web3.getAccount
        ],
        handleUpdate,
        [connection]);

    return (
        <Web3ConnectionContext.Provider value={connection}>
            {children}
        </Web3ConnectionContext.Provider>
    );
};