import { useState, useEffect } from "react";

//components
import { Modal, Text, Button, Grid } from "../../";
import ModalSelectToken from "../Modals/ModalSelectToken";
import ModalSelectNFT from "../Modals/ModalSelectNFT";

//framework
import t from "../../../utils/MLTranslate";

//classes
import { NFTType } from "../../../classes/NFT";

//hooks
import { useDApp } from "../../../hooks";

//styles
import styles from "./Modals.module.css";

const ModalSelectTokenOrNFT = (props) =>
{
    //hooks
    const dApp = useDApp();

    //state
    const [show, setShow] = useState(() => props.show);
    const [showToken, setShowToken] = useState(() => false);
    const [showNFT, setShowNFT] = useState(() => false);
    const [showLPTokens, setShowLPTokens] = useState(() => false);

    //handler
    const handleClose = () =>
    {
        if (props.onClose)
        {
            props.onClose();
        }
        else
        {
            setShow(false);
        }
    };
    const handleSelect = (_value) =>
    {
        if (props.onSelect)
        {
            props.onSelect(_value);
        }
        else
        {
            handleClose();
        }
    };
    const handleSelectToken = (token) =>
    {
        setShowToken(false);
        handleSelect(
        {
            type: "ERC20",
            contract: token.address,
            nftID: 0
        });
    };
    const handleSelectNFT = (nft, nftID) =>
    {
        setShowNFT(false);
        handleSelect(
        {
            type: (nft.type === NFTType.ERC721 ? "ERC721" : "ERC1155"),
            contract: nft.address,
            nftID: nftID
        });
    };

    //effect
    useEffect(() =>
    {
        setShow(props.show);
    }, [props.show]);

    return (
        <>
            <Modal
                show={show}
                header=
                {
                    <Text size="1">
                        Select
                    </Text>
                }
                footer=
                {
                    <Button onClick={() => handleClose()}>
                        {t("cancel")}
                    </Button>
                }
                onClose={() => handleClose()}
            >
                <Grid>
                    <Button
                        className={styles.selectTokenOrNFT_button}
                        onClick={() => setShowToken(true)}
                    >
                        Select Token
                    </Button>
                    <Button
                        className={styles.selectTokenOrNFT_button}
                        onClick={() => setShowNFT(true)}
                    >
                        Select NFT
                    </Button>
                </Grid>
            </Modal>
            <ModalSelectToken
                show={showToken}
                tokenList={dApp.getTokenList(showLPTokens)}
                onSelect={(token) => handleSelectToken(token)}
                onClose={() => setShowToken(false)}
            />
            <ModalSelectNFT
                show={showNFT}
                nftList={dApp.nfts}
                onSelect={(nft, nftID) => handleSelectNFT(nft, nftID)}
                onClose={() => setShowNFT(false)}
            />
        </>
    );
};

export default ModalSelectTokenOrNFT;