import { useState, useEffect } from "react";

//components
import { Grid, Button, Modal } from "../../";

//framework
import t from "../../../utils/MLTranslate";
import { MLUI, MLWeb3 } from "../../../utils";
import { useWeb3Connection, useDApp } from "../../../hooks";

//styles
import styles from "./Modals.module.css";

const ModalSelectChain = (props) =>
{
    //context
	const dApp = useDApp();
    const web3 = useWeb3Connection();

    //state
    const [show, setShow] = useState(() => props.show);

    //handler
    const handleClose = () =>
    {
        if (props.onClose)
        {
            props.onClose();
        }
        else
        {
            setShow(false);
        }
    };
    const selectChain = (_chainID) =>
    {
        web3.selectChain(_chainID);
        handleClose();
    };

    //effect
    useEffect(() =>
    {
        setShow(props.show);
    }, [props.show]);

    return (
        <Modal
            show={show}
            header={t("modal_selectChain_title")}
            footer=
            {
                <Button onClick={() => handleClose()}>
                    {t("cancel")}
                </Button>
            }
            onClose={() => handleClose()}
        >
            <Grid>
                {dApp.config.chains.chains.map((c) =>
                    {
                        const chain = MLWeb3.findChainConfig(c.id);
                        if (chain === null)
                        {
                            return <></>;
                        }
                        return (
                            <Button
                                key={`chain_${dApp.config.chains.chains.indexOf(c)}`}
                                className={styles.selectChain_button}
                                onClick={() => selectChain(chain.id)}
                            >
                                {MLUI.imageOrSymbol(chain.networkIcon, styles.selectChain_icon)}
                                {chain.name}
                            </Button>
                        );
                    }
                )}
            </Grid>
        </Modal>
    );
};

export default ModalSelectChain;