import { useState, useEffect } from "react";

//components
import { Modal, Text, Button, Grid, Flex } from "../../";

//framework
import t from "../../../utils/MLTranslate";
import { MLUI, MLSymbols } from "../../../utils";
import { useDApp } from "../../../hooks";

//styles
import styles from "./Modals.module.css";

const ModalTransactionMightFail = (props) =>
{
    //context
    const dApp = useDApp();

	//functions
	const getErrorMessage = () =>
	{
		const prefixRemove = "execution reverted: ";
		let err = dApp.showTxMightFail?.error;
		if (typeof err === "string"
			&& err.indexOf(prefixRemove) === 0)
		{
			err = err.substring(prefixRemove.length);
		}
		return err;
	};

    //state
    const [show, setShow] = useState(() => props.show);

    //handler
    const handleClose = () =>
    {
        if (props.onClose)
        {
            props.onClose();
        }
        else
        {
            setShow(false);
        }
    };
	const handleTryAnyway = () =>
    {
		const tx = dApp.showTxMightFail.tx;
        handleClose();
		tx.send();
    };

    //effect
    useEffect(() =>
    {
        setShow(props.show);
    }, [props.show]);

    return (
        <Modal
            show={show}
			allowBig={true}
            header=
            {
                <Text size="1">
                    {t("modal_transactionMightFail_title")}
                </Text>
            }
            footer=
            {
				<Flex>
					<Button onClick={() => handleTryAnyway()}>
						{t("tryAnyway")}
					</Button>
					<Button onClick={() => handleClose()}>
						{t("cancel")}
					</Button>
				</Flex>
            }
            classNameOverlay={styles.priorityOverlay}
            onClose={() => handleClose()}
        >
            <Grid className={styles.transaction_content}>
                {MLUI.imageOrSymbol(MLSymbols.transaction_failed(),
                    [
                        styles.transaction_icon,
                        styles.transaction_iconFailed
                    ].join(" "))
				}
                <Text color={1}>
                    Transaction might fail with reason:
                </Text>
                <Text
                    color={2}
                    size={-1}
                >
                    {getErrorMessage()}
                </Text>
            </Grid>
        </Modal>
    );
};

export default ModalTransactionMightFail;