import { useState, useEffect } from "react";

//components
import
{
    Modal,
    Button,
    Grid,
    Flex,
    Text,
    Input
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLTranslate as t } from "@MoonLabsDev/dapp-core-lib";
import { MLWeb3 } from "@MoonLabsDev/dapp-core-lib";


//modules
import { useEscrow } from "../../../../modules";

const ModalTradeSettings = (props) =>
{
    //hooks
    const escrow = useEscrow();

    //state
    const [show, setShow] = useState(() => props.show);
    const [tradeID, setTradeID] = useState(() => props.tradeID);
    const [offer, setOffer] = useState(() => props.offer || null);
    const [receiver, setReceiver] = useState(() => (MLWeb3.isZeroAddress(offer?.receiver) ? "" : offer.receiver));

    //handler
    const handleClose = () =>
    {
        if (props.onClose)
        {
            props.onClose();
        }
        else
        {
            setShow(false);
        }
    };
    const handleSave = () =>
    {
        escrow.setTradeReceiver(tradeID, receiver).send();
        handleClose();
    };

    //effect
    useEffect(() =>
    {
        setShow(props.show);
    }, [props.show]);
    useEffect(() =>
    {
        setTradeID(props.tradeID);
    }, [props.tradeID]);
    useEffect(() =>
    {
        setOffer(props.offer);
    }, [props.offer]);
    useEffect(() =>
    {
        setReceiver((MLWeb3.isZeroAddress(offer?.receiver) ? "" : offer.receiver));
    }, [offer?.receiver]);

    return (
        <Modal
            show={show}
            header={t("modal_escrowTradeSettings_title")}
            footer=
            {
                <Flex>
                    <Button onClick={() => handleClose()}>
                        {t("cancel")}
                    </Button>
                    <Button onClick={() => handleSave()}>
                        {t("OK")}
                    </Button>
                </Flex>
            }
            onClose={() => handleClose()}
        >
            <Grid>
                <Text>
                    Receiver Wallet:
                </Text>
                <Input
                    value={receiver}
                    placeholder={"Wallet address"}
                    onChange={(e, v) => setReceiver(v)}
                />
            </Grid>
        </Modal>
    );
};

export default ModalTradeSettings;