import { useState } from "react";

//components
import
{
    PageHeader,
    PageContent,
    Grid,
    Flex,
    Button,
    Emblem,
    EmblemColor,
    CheckBox,
    Input,
    Toggle,
    TextToggle,
    SelectBox,
    ApprovalButton,
    InputTokenAmount_Balance,
    SelectTokenOrNFT,
	CheckBalanceApproveButton,
	TokenPriceButton,
	SocialButton,
	SocialPlatform
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { Events } from "@MoonLabsDev/dapp-core-lib";
import { useEventSubscription, useDApp } from "@MoonLabsDev/dapp-core-lib";

const testInputs = () =>
{
    //hooks
    const dApp = useDApp();

    //state
    const [revision, setRevision] = useState(0);
	const [numberValue, setNumberValue] = useState(() => "");
	const [stringValue, setStringValue] = useState(() => "");

    //handler
    const handleUpdate = () =>
    {
        setRevision(revision + 1);
    };

    //values
    const values =
    [
        { value: 0, text: "black"},
        { value: 1, text: "red"},
        {
            value: 2,
            text: "green",
            children:
            [
                { value: 4, text: "cyan"},
                { value: 5, text: "orange"},
                { value: 6, text: "violet"}
            ]
        },
        { value: 3, text: "blue"}
    ];

    //effects
    useEventSubscription(Events.dApp.reload, handleUpdate, [revision]);

    return (
        <>
            <Flex>
                <CheckBox />
                <Toggle />
                <TextToggle values={values} value={values[1]} />
                <Input
					placeholder="Text Input"
					value={stringValue}
					onChange={(e, v) => setStringValue(v)}
				/>
                <Input
					type="number"
					placeholder="Number Input"
					value={numberValue}
					onChange={(e, v) => setNumberValue(v)}
				/>
                <SelectBox options={values} placeholder="Select Box" />
            </Flex>
            <Flex>
                <InputTokenAmount_Balance
					token={dApp.wrappedCoin}
					allowTokenSelection={true}
					showInWallet={true}
					value={numberValue}
					onChange={(e, v) => setNumberValue(v)}
				/>
                <SelectTokenOrNFT />
                <ApprovalButton
                    token={dApp.wrappedCoin}
                    contract={dApp.wrappedCoin?.address}
                />
				<CheckBalanceApproveButton
					token={dApp.wrappedCoin}
					amount={dApp.wrappedCoin?.one}
					approveFor={dApp.wrappedCoin?.address}
				>
					All OK
				</CheckBalanceApproveButton>
            </Flex>
			<Flex>
				<TokenPriceButton />
				<TokenPriceButton
					token={dApp.stableCoin}
				/>
				<SocialButton
					link="https://google.com"
					social={SocialPlatform.Telegram}
				/>
            </Flex>
        </>
    );
};

const testEmblems = () =>
{
    return (
        <Flex>
            {Object.values(EmblemColor).map((value, index) => (
                <Emblem
                    key={index}
                    color={value}
                >
                    Test {index}
                </Emblem>
            ))}
        </Flex>
    );
};

const testButtons = () =>
{
    return (
        <Flex>
            <Button buttonStyle={1}>
                Primary
            </Button>

            <Button buttonStyle={0}>
                Normal
            </Button>
        </Flex>
    );
};

export default () =>
{
    return (
        <>
            <PageHeader title="General" />

            <PageContent>
                <Grid>
                    {testEmblems()}
                    {testButtons()}
                    {testInputs()}
                </Grid>
            </PageContent>
        </>
    );
};