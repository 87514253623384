//components
import
{
	LineChart,
	AreaChart,
	PieChart,
	PageHeader,
	PageContent,
	Grid,
	Flex
} from "@MoonLabsDev/dapp-core-lib";

//tests
export default () =>
{
    const width = 600;
    const height = 250;

    return (
        <>
            <PageHeader title="Charts" />

            <PageContent>
				<Flex>
					<Grid>
						<LineChart
							width={width}
							height={height}
							showAxis={false}
							gridType={-1}
						/>
						<AreaChart
							width={width}
							height={height}
							showAxis={false}
							gridType={-1}
						/>
						<PieChart
							width={width}
							height={height}
						/>
					</Grid>
				</Flex>
            </PageContent>
        </>
    );
};