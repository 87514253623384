import { useState } from "react";

//components
import { Button } from "../../";
import ModalConnectWallet from "../Modals/ModalConnectWallet";

//framework
import t from "../../../utils/MLTranslate";
import { MLFormat } from "../../../utils";
import { useWeb3Connection } from "../../../hooks";
import Events from "../../../core/Events";

//hooks
import { useEventSubscription } from "../../../hooks";

//styles
import styles from "./Buttons.module.css";

const ConnectWalletButton = () =>
{
    //context
    const web3 = useWeb3Connection();

    //state
    const [show, setShow] = useState(() => false);
    const [account, setAccount] = useState(() => web3.account);

    //effect
    useEventSubscription(Events.web3.update, () => setAccount(web3.account));

    return (
        <>
            <Button
                className={styles.connectWallet_button}
                onClick={() => setShow(true)}
            >
                {account === null
                    ? "Connect Wallet"
                    : <>{MLFormat.formatAddress(account, true)}</>
                }
            </Button>
            <ModalConnectWallet
                show={show}
                header={t("modal_connectWallet_title")}
                onClose={() => setShow(false)}
            />
        </>
    );
};

export default ConnectWalletButton;