import Icon_ethereum from "../../framework/images/chain_ethereum.svg";
import Icon_cronos from "../../framework/images/chain_cronos.png";
import Icon_bnb from "../../framework/images/chain_bnb.png";
import Icon_polygon from "../../framework/images/chain_polygon.png";
import Icon_fantom from "../../framework/images/chain_fantom.png";
import Icon_harmony from "../../framework/images/chain_harmony.png";

const makeEthereumTestChain = (_id, _name) =>
{
	return {
		id: _id,
		name: _name,
		networkName: `Ethereum Testnet (${_name})`,
		networkIcon: Icon_ethereum,
		currency:
		{
			name: `${_name}ETH`,
			symbol: "ETH",
			decimals: 18
		},
		multiCall: "0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696",
		blockExplorerUrl: `https://${_name.toLowerCase()}.etherscan.io`,
		nodes:
		[
			`https://${_name.toLowerCase()}.infura.io/v3/`
		],
		onlySwitchDontAdd: true
	}
};

const configWeb3 =
{
    ipfsGateway: "https://ipfs.io/ipfs/",
    alwaysUseUserWeb3: true,
    chains:
    [
        {
            id: 1,
            name: "Ethereum",
            networkName: "Ethereum Mainnet",
            networkIcon: Icon_ethereum,
            currency:
            {
                name: "Ethereum",
                symbol: "ETH",
                decimals: 18
            },
            multiCall: "0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696",
            blockExplorerUrl: "https://etherscan.io",
            nodes:
            [
                "https://cloudflare-eth.com/"
            ],
			onlySwitchDontAdd: true
        },
		makeEthereumTestChain(2, "Ropsten"),
		makeEthereumTestChain(3, "Kovan"),
		makeEthereumTestChain(4, "Rinkeby"),
		makeEthereumTestChain(5, "Goerli"),
        {
            id: 25,
            name: "Cronos",
            networkName: "Cronos Mainnet",
            networkIcon: Icon_cronos,
            currency:
            {
                name: "Cronos Coin",
                symbol: "CRO",
                decimals: 18
            },
            multiCall: "0x5e954f5972EC6BFc7dECd75779F10d848230345F",
            blockExplorerUrl: "https://cronoscan.com",
            nodes:
            [
                "https://evm-cronos.crypto.org"
            ]
        },
        {
            id: 56,
            name: "BNB Chain",
            networkName: "BNB Chain Mainnet",
            networkIcon: Icon_bnb,
            currency:
            {
                name: "Binance Coin",
                symbol: "BNB",
                decimals: 18
            },
            multiCall: "0xC50F4c1E81c873B2204D7eFf7069Ffec6Fbe136D",
            blockExplorerUrl: "https://bscscan.com",
            nodes:
            [
                "https://bsc-dataseed.binance.org/",
                "https://bsc-dataseed1.defibit.io/",
                "https://bsc-dataseed1.ninicoin.io/"
            ]
        },
        {
            id: 97,
            name: "BNB Chain (Test)",
            networkName: "BNB Chain Testnet",
            networkIcon: Icon_bnb,
            currency:
            {
                name: "Binance Coin",
                symbol: "BNB",
                decimals: 18
            },
            multiCall: "0x6e5BB1a5Ad6F68A8D7D6A5e47750eC15773d6042",
            blockExplorerUrl: "https://testnet.bscscan.com",
            nodes:
            [
                "https://data-seed-prebsc-1-s1.binance.org:8545/"
            ]
        },
        {
            id: 137,
            name: "Polygon",
            networkName: "Polygon Mainnet",
            networkIcon: Icon_polygon,
            currency:
            {
                name: "Polygon",
                symbol: "MATIC",
                decimals: 18,
            },
            multiCall: "0x275617327c958bD06b5D6b871E7f491D76113dd8",
            blockExplorerUrl: "https://polygonscan.com",
            nodes:
            [
                "https://polygon-rpc.com/",
                "https://matic-mainnet.chainstacklabs.com/",
                "https://rpc-mainnet.matic.network/",
                "https://rpc-mainnet.maticvigil.com/"
            ]
        },
        {
            id: 250,
            name: "Fantom",
            networkName: "Fantom Mainnet",
            networkIcon: Icon_fantom,
            currency:
            {
                name: "Fantom",
                symbol: "FTM",
                decimals: 18,
            },
            multiCall: "0xD98e3dBE5950Ca8Ce5a4b59630a5652110403E5c",
            blockExplorerUrl: "https://ftmscan.com",
            nodes:
            [
                "https://rpc.ftm.tools/"
            ]
        },
        {
            id: 1285,
            name: "MoonRiver Mainnet",
            currency:
            {
                name: "Moonriver",
                symbol: "MVR",
                decimals: 18
            },
            multiCall: "0x55f46144bC62e9Af4bAdB71842B62162e2194E90",
            blockExplorerUrl: "https://moonriver.moonscan.io/",
            nodes:
            [
                "https://rpc.moonriver.moonbeam.network/"
            ]
        },
        {
            id: 80001,
            name: "Polygon (Test)",
            networkName: "Polygon Testnet",
            networkIcon: Icon_polygon,
            currency:
            {
                name: "Polygon",
                symbol: "MATIC",
                decimals: 18,
            },
            multiCall: "0x5c41f6817feeb65d7b2178b0b9cebfc8fad97969",
            blockExplorerUrl: "https://polygonscan.com",
            nodes:
            [
                "https://matic-mumbai.chainstacklabs.com",
                "https://rpc-mumbai.maticvigil.com"
            ]
        },
        {
            id: 1666600000,
            name: "Harmony",
            networkName: "Harmony Mainnet",
            networkIcon: Icon_harmony,
            currency:
            {
                name: "Harmony",
                symbol: "ONE",
                decimals: 18
            },
            multiCall: "0xBa5041B1c06e8c9cFb5dDB4b82BdC52E41EA5FC5",
            blockExplorerUrl: "https://explorer.harmony.one",
            nodes:
            [
                "https://api.harmony.one/",
                "https://api.s0.t.hmny.io/"
            ]
        }
    ]
};

export default configWeb3;