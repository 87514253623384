import { useState, useEffect } from "react";

//components
import { Grid, Flex, Button, Modal } from "../../";

//framework
import t from "../../../utils/MLTranslate";
import { MLUI, MLWeb3 } from "../../../utils";
import { useWeb3Connection, useDApp } from "../../../hooks";

//styles
import styles from "./Modals.module.css";

const ModalConnectWallet = (props) =>
{
    //context
	const dApp = useDApp();
    const web3 = useWeb3Connection();

    //functions
    const findSupportedWallets = () =>
    {
        return MLWeb3.config.wallets.filter((w) =>
        {
            if (w.supportedChains === undefined)
            {
                return true;
            }
            for (let n = 0; n < dApp.config.chains.chains.length; n++)
            {
                const c = dApp.config.chains.chains[n];
                if (w.supportedChains.includes(c.id))
                {
                    return true;
                }
            }
            return false;
        });
    };

    //state
    const [show, setShow] = useState(() => props.show);
    const [supportedWallets, setSupportedWallets] = useState(() => findSupportedWallets());

    //handler
    const handleClose = () =>
    {
        if (props.onClose)
        {
            props.onClose();
        }
        else
        {
            setShow(false);
        }
    };
    const connectWallet = (_providerID) =>
    {
        web3.selectProvider(_providerID);
        handleClose();
    };
    const logout = () =>
    {
        web3.disconnectWallet();
        handleClose();
    };

    //effect
    useEffect(() =>
    {
        setShow(props.show);
    }, [props.show]);

    return (
        <Modal
            show={show}
            header={t("modal_connectWallet_title")}
            footer=
            {
                <Flex>
                    <Button onClick={() => handleClose()}>
                        {t("cancel")}
                    </Button>
                    {web3.web3_user &&
                        <Button onClick={() => logout()}>
                            {t("logout")}
                        </Button>
                    }
                </Flex>
            }
            onClose={() => handleClose()}
        >
            <Grid>
                {supportedWallets.map((w) =>
                    {
                        return (
                            <Button
                                key={`wallet_${supportedWallets.indexOf(w)}`}
                                className={styles.connectWallet_button}
                                onClick={() => connectWallet(w.providerID)}
                            >
                                {MLUI.imageOrSymbol(w.icon, styles.connectWallet_icon)}
                                {w.name}
                            </Button>
                        );
                    }
                )}
            </Grid>
        </Modal>
    );
};

export default ModalConnectWallet;