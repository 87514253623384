import React, { useState } from "react";

//framework
import { getEnabledProviders } from "../../framework/core";
import
{
	DAppProvider,
	MediaInfoProvider,
	useDApp,
	Web3ConnectionProvider,
	ThemeProvider
} from "../hooks";

//config
import { config as configDefault } from "../../dApp/config";

export const EnabledModulesProviders = ({children}) =>
{
	//context
    const dApp = useDApp();

	try
	{
		const ep = getEnabledProviders(dApp);
		const ecp = ep.filter(p => p.availableOnChain);
		const elm = ecp.reduce(
			(acc, e) =>
			{
				if (!!dApp.config.debug.moduleProvider)
				{
					dApp.log(false, `Setting provider for [${e.moduleName}]`);
				}
				return React.createElement(e.provider, {children: acc})
			},
			children
		);
		return (ecp.length === 0
			? children
			: elm
		);
	}
	catch (_e)
	{
		console.error(_e);
		return children;
	}
}

export const WidgetProvider = ({config, modules, web3User, web3Data, wallet, children, toastCallback}) =>
{
	//state
	const [cfg] = useState(() => config || configDefault)

    return (
		<div className={cfg.ui.theme.root}>
			<Web3ConnectionProvider>
				<DAppProvider
					config={cfg}
					modules={modules}
					web3User={web3User}
					web3Data={web3Data}
					wallet={wallet}
					toastCallback={toastCallback}
				>
					<EnabledModulesProviders>
						<MediaInfoProvider>
							<ThemeProvider>
								{children}
							</ThemeProvider>
						</MediaInfoProvider>
					</EnabledModulesProviders>
				</DAppProvider>
			</Web3ConnectionProvider>
		</div>
	);
}