import MLUtils from "./MLUtils";
import MLWeb3 from "./MLWeb3";

const MLFormat =
{
    /////////////////////////
    // Config
    /////////////////////////

    config:
    {
        smartFloatFormat:
        {
            numberSymbols:
            [
                { value: 1000, symbol: "k" },
                { value: 1000000, symbol: "M" },
                { value: 1000000000, symbol: "B" },
                { value: 1000000000000, symbol: "T" },
            ]
        },
		thousandsSeperator: ","
    },

    /////////////////////////
    // Wallet / Contract address
    /////////////////////////

    formatAddress(_address, _shorten)
	{
		if (!_address)
		{
			return "";
		}

        let addr = _address;
        if (_shorten)
        {
            addr = addr.substring(0, 6) + " ... " + addr.substring(addr.length - 4);
        }

		return addr;
	},

    /////////////////////////
    // FloatString
    /////////////////////////

    formatFloatString(_floatString, _decimals, _shorten = true, _thousandsSeperator = undefined)
    {
		if (_floatString === "NaN")
		{
			return _floatString;
		}

        //get parts
        const parts = _floatString.replace(",", ".").split(".");
        if (parts.length === 1)
        {
            parts.push("0");
        }

        //ensure decimal part correct length
        if (parts[1].length < _decimals)
        {
            parts[1] = parts[1].padEnd(_decimals, "0");
        }
        else if (parts[1].length > _decimals)
        {
            parts[1] = parts[1].substring(0, _decimals);
        }

		//thousands seperator
		const sep = (_thousandsSeperator !== undefined
			? _thousandsSeperator
			: this.config.thousandsSeperator);
		if (!!sep
			&& sep !== "")
		{
			let t = "";
			for (let n = 0; n < parts[0].length; n++)
			{
				const p = parts[0].length - n;
				if (n !== 0
					&& p % 3 === 0)
				{
					t += sep;
				}
				t += parts[0][n];
			}
			parts[0] = t;
		}

        //make formatted str
		let formatStr = parts.join(".");
        if (_shorten)
        {
            formatStr = MLUtils.removeTrailEnd(formatStr, "0");
        }
        formatStr = MLUtils.removeTrailEnd(formatStr, ".");

        return formatStr;
    },

    /////////////////////////
    // Float
    /////////////////////////

    formatFloat(_float, _decimals, _shorten = true, _thousandsSeperator = undefined)
    {
        return this.formatFloatString(
            _float.toFixed(20),
            _decimals,
            _shorten,
			_thousandsSeperator);
    },

    smartFormatFloat(_float, _precision = 2)
    {
        //find number symbol
        let ns = null;
        if (_float > 1)
        {
            const cfg = this.config.smartFloatFormat;
            for (let n = 0; n < cfg.numberSymbols.length; n++)
            {
                const cur = cfg.numberSymbols[cfg.numberSymbols.length - 1 - n];
                if (_float >= cur.value)
                {
                    ns = cur;
                    break;
                }
            }
        }

        //reduce
        if (ns !== null)
        {
            _float /= ns.value;
        }
        const formatStr = this.formatFloat(_float, _precision, true) + (ns?.symbol ?? "");

        return formatStr;
    },

    /////////////////////////
    // Tokens
    /////////////////////////

    formatToken(_amount, _token, _precision = 4, _shorten = true)
    {
        const floatStr = MLWeb3.convertTokenBN_FloatString(_amount, _token);
        return this.formatFloatString(floatStr, _precision, _shorten);
    },

    smartFormatToken(_amount, _token)
    {
        const float = MLWeb3.convertTokenBN_Float(_amount, _token);
        return this.smartFormatFloat(float, 4);
    },

    /////////////////////////
    // NFTs
    /////////////////////////

    formatNFT(_amount, _nftItem, _precision = 4, _shorten = true)
    {
        const floatStr = MLWeb3.convertBN_FloatString(_amount, (_nftItem?.data?.decimals || 0));
        return this.formatFloatString(floatStr, _precision, _shorten);
    },

    smartFormatNFT(_amount, _nftItem)
    {
        const float = MLWeb3.convertBN_Float(_amount, (_nftItem?.data?.decimals || 0));
        return this.smartFormatFloat(float, 4);
    },

    /////////////////////////
    // Fiat
    /////////////////////////

    formatFiat(_value, _decimals = 2, _shorten = true)
    {
        return `$${this.formatFloat(_value, _decimals, _shorten)}`;
    },

    smartFormatFiat(_value)
    {
        return `$${this.smartFormatFloat(_value, 2)}`;
    },

    /////////////////////////
    // Multiplicator
    /////////////////////////

    formatMultiplicator(_value)
    {
        return `x ${this.formatFloat(_value, 2, true)}`;
    },

    /////////////////////////
    // Percent
    /////////////////////////

    formatPercent(_value, _showSign = false, _precision = 2)
    {
        const sign = (_value < 0 ? "" : "+");
        return `${(_showSign ? sign : "")}${this.formatFloat(_value * 100, _precision, true)}%`;
    },

    /////////////////////////
    // Date/Time
    /////////////////////////

    formatDate(_date)
    {
        return _date.toLocaleDateString();
    },

    formatTime(_timeInSeconds, _showSeconds = true)
    {
        return (new Date(_timeInSeconds * 1000)).toLocaleTimeString();
    },

    formatDateTime(_date, _showSeconds = true)
    {
        return `${this.formatDate(_date)} ${this.formatTime(_date.getTime() / 1000, _showSeconds)}`;
    },

    formatHMS(_timeInSeconds, _showHMS = false)
    {
		let ret = "";
		const neg = (_timeInSeconds < 0);

		//seconds
		const s = parseInt(_timeInSeconds) % 60
		_timeInSeconds /= 60

		//minutes
		const m = parseInt(_timeInSeconds) % 60
		_timeInSeconds /= 60

		//hours
		const h = parseInt(_timeInSeconds)

		//build string
		if (h > 0)
		{
			ret += h.toString() + (!!_showHMS ? "h" : "")
		}
		if (m > 0
			|| ret !== "")
		{
			if (ret !== "")
			{
				ret += (!!_showHMS ? " " : ":")
			}
			ret += m.toString().padStart((ret !== "" && !_showHMS ? 2 : 1), "0") + (!!_showHMS ? "m" : "")
		}
		if (s >= 0
			|| ret !== "")
		{
			if (ret !== "")
			{
				ret += (!!_showHMS ? " " : ":")
			}
			ret += s.toString().padStart((ret !== "" && !_showHMS ? 2 : 1), "0") + (!!_showHMS ? "s" : "")
		}

		return `${neg ? "- " : ""}${ret}`;
    }
};

export default MLFormat;