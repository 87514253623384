import { useState, useEffect } from "react";

//components
import { Text, Grid, Button, Modal, List, NFTIcon, ConditionalContainer, Input } from "../../";
import { ValueNFTName, ValueNFTBalance } from "../../";

//framework
import t from "../../../utils/MLTranslate";
import { MLWeb3 } from "../../../utils";
import Events from "../../../core/Events";

//hooks
import { useEventSubscription } from "../../../hooks";

//styles
import styles from "./Modals.module.css";

const ModalSelectNFT = (props) =>
{
    //state
    const [show, setShow] = useState(() => props.show);
    const [selectID, setSelectID] = useState(() => false);
    const [nftList, setNFTList] = useState(() => props.nftList || []);
    const [itemList, setNFTItemList] = useState(() => []);
    const [nft, setNFT] = useState(() => null);
    const [nftID, setNFTID] = useState(() => null);
    const [showBalance, setShowBalance] = useState(() => (props.showBalance !== undefined ? props.showBalance : true));
    const [filterString, setFilterString] = useState(() => "");
    const [filterNFTList, setFilterNFTList] = useState(() => props.nftList || []);
    const [filterIDString, setFilterIDString] = useState(() => "");
    const [filterItemList, setFilterItemList] = useState(() => []);

    //functions
    const filterNFTs = (list, filter) =>
    {
        filter = filter.toUpperCase();
        return list.filter((t) =>
        {
            return (filter === ""
                || t.symbol.toUpperCase().indexOf(filter) !== -1
                || t.address.toUpperCase() === filter);
        });
    };
    const applyFilter = () =>
    {
        setFilterNFTList(filterNFTs(nftList, filterString));
    };
    const filterItems = (list, filter) =>
    {
        filter = filter.toUpperCase();
        return list.filter((t) =>
        {
            return (filter === ""
                || t.symbol.toUpperCase().indexOf(filter) !== -1
                || t.id.ToString().indexOf(filter) === 0);
        });
    };
    const applyItemFilter = () =>
    {
        setFilterItemList(filterItems(nft?.items ?? [], filterIDString));
    };

    //handler
    const handleFilterChanged = (data) =>
    {
        setFilterString(data.target.value);
    };
    const handleFilterIDChanged = (data) =>
    {
        setFilterIDString(data.target.value);
    };
    const handleSelectNFTID = (nft, id) =>
    {
        setNFTID(id);
        if (props.onSelect)
        {
            props.onSelect(nft, id);
        }
        else
        {
            handleClose();
        }
    };
    const handleSelectNFT = (nft) =>
    {
        //load
        nft.batch_metaData(undefined, false)
        .then(() => nft.batch_userData());

        setSelectID(true);
        setNFT(nft);
        setNFTItemList(nft.items);
        setFilterIDString("");
        setFilterItemList(filterNFTs(nft.items, ""));
    };
    const handleClose = () =>
    {
        if (props.onClose)
        {
            props.onClose();
        }
        else
        {
            setShow(false);
        }
    };
    const handleItemUpdate = (_data) =>
    {
        if (!MLWeb3.checkEqualAddress(_data.detail.address, nft?.address))
        {
            return;
        }
        setNFTItemList(nft.items);
    };

    //effect
    useEffect(() =>
    {
        applyFilter();
    }, [filterString]);
    useEffect(() =>
    {
        applyItemFilter();
    }, [filterIDString]);
    useEffect(() =>
    {
        setShow(props.show);
        setSelectID(false);
        setNFTItemList([]);
        setFilterString("");
        setFilterNFTList(filterNFTs(nftList, ""));
    }, [props.show]);
    useEffect(() =>
    {
        setNFTList(props.nftList);
        setFilterNFTList(filterNFTs(props.nftList, filterString));
    }, [JSON.stringify(props.nftList)]);
    useEventSubscription(Events.nft.metaData, handleItemUpdate, [nft]);

    return (
        <Modal
            show={show}
            allowBig={true}
            header={t("modal_selectNFT_title")}
            footer=
            {
                <Button onClick={() => handleClose()}>
                    {t("cancel")}
                </Button>
            }
            onClose={() => handleClose()}
        >
            <ConditionalContainer visible={!selectID}>
                <Input
                    className={styles.selectNFT_filterString}
                    nft={filterString}
                    onChange={handleFilterChanged}
                    placeholder={t("modal_selectNFT_filterPlaceholder")}
                />
                <List
                    className={styles.selectNFT_list}
                    itemClassName={styles.selectNFT_row}
                    onSelect={(v) => handleSelectNFT(v)}
                    items=
                    {filterNFTList.map((n) =>
                        {
                            return {
                                value: n,
                                content: (
                                    <>
                                        <NFTIcon
                                            className={styles.selectNFT_icon}
                                            nft={n}
                                        />
                                        <Grid gap="0px">
                                            <Text>
                                                {n.symbol}
                                            </Text>
                                            <Text size={-1}>
                                                {n.name}
                                            </Text>
                                        </Grid>
                                    </>
                                )
                            };
                        }
                    )}
                />
            </ConditionalContainer>

            <ConditionalContainer visible={selectID}>
                <Input
                    className={styles.selectNFT_filterString}
                    nft={filterString}
                    onChange={handleFilterIDChanged}
                    placeholder={t("modal_selectNFT_filterIDPlaceholder")}
                />
                <List
                    className={styles.selectNFT_list}
                    itemClassName={styles.selectNFT_row}
                    onSelect={(v) => handleSelectNFTID(nft, v)}
                    items=
                    {(nft === null
                        ? []
                        : filterItemList.map((i) =>
                            {
                                return {
                                    value: i.id,
                                    content: (
                                        <>
                                            <NFTIcon
                                                className={styles.selectNFT_icon}
                                                nft={nft}
                                                nftID={i.id}
                                            />
                                            <Grid gap="0px">
                                                <Text>
                                                    <ValueNFTName
                                                        nft={nft}
                                                        nftID={i.id}
                                                    />
                                                </Text>
                                                <Text size={-1}>
                                                    #{i.id}
                                                </Text>
                                            </Grid>
                                            {showBalance &&
                                                <Text
                                                    size={-1}
                                                    style=
                                                    {
                                                        {
                                                            marginLeft: "auto"
                                                        }
                                                    }
                                                >
                                                    <ValueNFTBalance
                                                        nft={nft}
                                                        nftID={i.id}
                                                        shorten={true}
                                                        precision={4}
                                                    />
                                                </Text>
                                            }
                                                </>
                                    )
                                };
                            })
                        )
                    }
                />
            </ConditionalContainer>
        </Modal>
    );
};

export default ModalSelectNFT;