import configPage from "./page";

//components
import { ToolMenu } from "../components/ToolMenu";

const configMenu =
{
	toolComponent: ToolMenu,
    desktopMode:
    {
        header:
        {
            menu: "normal",
			tool: "normal",
            user: "normal"
        },
        footer:
        {
            menu: "none",
			tool: "none",
            user: "none"
        }
    },
    tabletMode:
    {
        header:
        {
            menu: "minimal",
			tool: "none",
            user: "normal"
        },
        footer:
        {
            menu: "none",
			tool: "none",
            user: "none"
        }
    },
    mobileMode:
    {
        header:
        {
            menu: "none",
			tool: "none",
            user: "app"
        },
        footer:
        {
            menu: "app",
			tool: "none",
            user: "none"
        }
    },

	...(!configPage.devMode &&
		{
			desktopMenu:
			[
				{
					label: "Home",
					link: "/"
				},
				{
					text: "Swap",
					link: "/swap",
				},
				{
					text: "Escrow",
					link: "/tools/escrow",
				},
				{
					text: "SFL Trader",
					link: "/tools/sflTrader"
				}
			]
		}
	),

	...(!!configPage.devMode &&
		{
			desktopMenu:
			[
				{
					label: "Home",
					link: "/"
				},
				{
					text: "Swap",
					link: "/swap",
				},
				{
					text: "Earn",
					link: "/earn/vaults",
					children:
					[
						{
							text: "Vaults",
							link: "/earn/vaults"
						},
						{
							text: "Token",
							link: "/earn/token"
						},
						{
							text: "Pool",
							link: "/earn/Pool"
						},
						{
							text: "Bank",
							link: "/earn/Bank"
						}
					]
				},
				{
					text: "Tools",
					link: "/tools/escrow",
					children:
					[
						{
							text: "Escrow",
							link: "/tools/escrow"
						},
						{
							text: "SFL Trader",
							link: "/tools/sflTrader"
						}
					]
				},
				{
					text: "Analytics",
					link: "/analytics/protocol",
					children:
					[
						{
							text: "Protocol",
							link: "/analytics/protocol"
						},
						{
							text: "User",
							link: "/analytics/user"
						}
					]
				},
				{
					text: "Learn",
					link: "/learn"
				},
				{
					text: "Test",
					link: "/tests/general",
					children:
					[
						{
							text: "General",
							link: "/tests/general"
						},
						{
							text: "Cards",
							link: "/tests/cards"
						},
						{
							text: "Charts",
							link: "/tests/charts"
						},
						{
							text: "Modals",
							link: "/tests/modals"
						}
					]
				}
			],

			tabletMenu: undefined, //will use desktop instead

			mobileMenu:
			[
				{
					text: "Swap",
					link: "/swap",
				},
				{
					text: "Earn",
					children:
					[
						{
							text: "Vaults",
							link: "/earn/vaults"
						},
						{
							text: "Token",
							link: "/earn/token"
						},
						{
							text: "Pool",
							link: "/earn/Pool"
						}
					]
				},
				{
					text: "Tools",
					children:
					[
						{
							text: "Escrow",
							link: "/tools/escrow"
						}
					]
				},
				{
					text: "Test",
					link: "/tests/general",
					children:
					[
						{
							text: "General",
							link: "/tests/general"
						},
						{
							text: "Cards",
							link: "/tests/cards"
						},
						{
							text: "Charts",
							link: "/tests/charts"
						},
						{
							text: "Modals",
							link: "/tests/modals"
						}
					]
				}
			]
		}
	)
};

export default configMenu;