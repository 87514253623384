//framework
import { DApp, MLWeb3, MLMultiCall } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "..//modules/Module_SunflowerTrader";

//contracts
import ABI_SunflowerTrader from "../abi/SunflowerTrader";

export const ListingStatus =
{
	ACTIVE: 1,
	CANCELLED: 2,
	PURCHASED: 3
}

export const SFLResourceIDs =
[
	//crops
	201,
	202,
	203,
	204,
	205,
	206,
	207,
	208,
	209,
	210,

	//wood / ores / eggs
	601,
	602,
	603,
	604,
	605
];

export class SunflowerTrader
{
	////////////////////////////////////

	constructor(_address)
	{
		//values
		this.address = _address;
		this.stepSize = 1000;
		this.step = 0;
		this.token = DApp.instance.findToken("SFL");
		this.nft = DApp.instance.findNFT("0x22d5f9B75c524Fec1D6619787e582644CD4D7422");

		//data
		this.listings = [];
		this.listingGroups = [];
		this.excludeListings = [];
	}

	////////////////////////////////////

	debugErrorString(_text)
	{
		return `SunflowerTrader failed at: ${_text}`;
	}

    getContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(ABI_SunflowerTrader, this.address).methods;
    }

    makeMultiCall(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.address,
            ABI_SunflowerTrader,
            _calls
        );
    }

    dispatchEvent(_name, _listingId)
    {
        document.dispatchEvent(new CustomEvent(_name,
		{
			detail:
            {
				...(_listingId !== undefined && { id: _listingId })
			}
		}));
    }

	/////////////////////////
    // Data
    /////////////////////////

    async batch_data()
    {
        await DApp.instance.batchCall(
            [this],
            (o) => o.makeRequest_data(),
            (o, r) => o.processRequest_data(r),
            false,
            "[SunflowerTrader] data",
            "SunflowerTrader: data"
        );
    }

    makeRequest_data()
    {
        return this.makeMultiCall(
        {
            count: { function: "listingsCount" },
        });
    }

    async processRequest_data(_data)
    {
        const count = parseInt(_data.count);

		//process
		for (let n = this.listings.length + 1; n <= count; n++)
		{
			this.addListing(n);
		}

        //event
        this.dispatchEvent(ModuleEvents.data);
    }

	/////////////////////////
    // Listing
    /////////////////////////

    async batch_listing()
    {
		const filtered = this.listings
			.filter(f => !this.excludeListings.includes(f.id))
			.filter(f => f.id >= this.step && f.id <= this.step + this.stepSize);
        await DApp.instance.batchCall(
            filtered,
            (o) => this.makeRequest_listing(o),
            (o, r) => this.processRequest_listing(o, r),
            false,
            "[SunflowerTrader] listing",
            "SunflowerTrader: listing"
        );

		//process
		this.step = this.step + this.stepSize;
		if (this.step >= this.listings.length)
		{
			this.step = 0;
		}
		SFLResourceIDs.forEach(r => this.refreshStats(r));

		//event
        this.dispatchEvent(ModuleEvents.stats);
    }

    makeRequest_listing(_listing)
    {
        return this.makeMultiCall(
        {
            listing: { function: "listings", parameters: [_listing.id] },
        });
    }

    async processRequest_listing(_listing, _data)
    {
		_listing.status 			= parseInt(_data.listing.status);
        _listing.farmID 			= parseInt(_data.listing.farmId);
		_listing.farmAddress 		= _data.listing.farmAddress;
		_listing.resource			= parseInt(_data.listing.resourceId);
		_listing.amount				= MLWeb3.toBN(_data.listing.resourceAmount);
		_listing.sfl				= MLWeb3.toBN(_data.listing.sfl);
		_listing.tax				= MLWeb3.toBN(_data.listing.tax);
		_listing.listedAt			= new Date(parseInt(_data.listing.listedAt) * 1000);
		_listing.cancelledAt		= (_data.listing.cancelledAt === "0" ? null : new Date(parseInt(_data.listing.cancelledAt) * 1000));
		_listing.purchasedAt		= (_data.listing.purchasedAt === "0" ? null : new Date(parseInt(_data.listing.purchasedAt) * 1000));
		_listing.purchasedByFarmID	= parseInt(_data.listing.purchasedById);
		_listing.nftItem			= this.nft.findItem(_listing.resource);

		//process
		_listing.priceWithTax = _listing.sfl.mul(_listing.tax.add(MLWeb3.toBN(1000))).div(MLWeb3.toBN(1000));
		_listing.pricePerUnit = _listing.sfl.mul(this.nft.findItem(_listing.resource).data.one).div(_listing.amount);
		_listing.pricePerUnitWithTax = _listing.priceWithTax.mul(this.nft.findItem(_listing.resource).data.one).div(_listing.amount);
		if (!_listing.itemWasLoaded)
		{
			_listing.itemWasLoaded = _listing.nftItem.initialized;
		}
		if (_listing.status !== ListingStatus.ACTIVE
			&& !this.excludeListings.includes(_listing.id))
		{
			this.excludeListings.push(_listing.id);
		}

        //event
        this.dispatchEvent(ModuleEvents.listing, _listing.id);
    }

	/////////////////////////
    // Helper
    /////////////////////////

	refreshStats(_resource)
	{
		const group = this.addListingGroup(_resource);
		const filterActive = this.listings.filter(l =>
		{
			return (l.status === ListingStatus.ACTIVE
				&& l.resource === _resource);
		});
		const filterPurchased = this.listings.filter(l =>
		{
			return (l.status === ListingStatus.PURCHASED
				&& l.resource === _resource);
		});
		const filterPurchased24h = filterPurchased.filter(l =>
		{
			return (l.purchasedAt.getTime() >= ((new Date()).getTime() - (1000 * 60 * 60 * 24)));
		});

		//stats	active
		if (filterActive.length > 0)
		{
			let priceMin = filterActive[0].pricePerUnit;
			filterActive.forEach(l =>
			{
				if (l.itemWasLoaded
					&& !l.pricePerUnit.isZero())
				{
					if (l.pricePerUnit.cmp(priceMin) === -1)
					{
						priceMin = l.pricePerUnit;
					}
				}
			});

			group.priceMin = priceMin;
			group.count_active = filterActive.length;
		}

		//stats purchased 24h
		if (filterPurchased24h.length > 0)
		{
			let loaded = 0;
			let priceSum = MLWeb3.toBN(0);
			filterPurchased24h.forEach(l =>
			{
				if (l.itemWasLoaded
					&& !l.pricePerUnit.isZero())
				{
					priceSum = priceSum.add(l.pricePerUnit);
					loaded += 1;
				}
			});
			group.count_purchased24h = filterPurchased24h.length;
			group.priceAvg24h = (loaded === 0 ? MLWeb3.toBN(0) : priceSum.div(MLWeb3.toBN(loaded)));
		}

		//stats purchased
		group.count_purchased = filterPurchased.length;
		if (group.count_purchased > 0)
		{
			filterPurchased.sort((a, b) => b.purchasedAt.getTime() - a.purchasedAt.getTime());
			group.priceLast = filterPurchased[0].pricePerUnit;
		}
	}

	findListing(_id)
	{
		return (this.listings.find(l => l.id === _id) || null);
	}

	addListing(_id)
	{
		if (this.findListing(_id) !== null)
		{
			return;
		}

		this.listings.push(
		{
			id: _id,
			status: 0,
			farmID: 0,
			farmAddress: "",
			resource: 0,
			amount: MLWeb3.toBN(0),
			sfl: 0,
			priceWithTax: MLWeb3.toBN(0),
			pricePerUnit: MLWeb3.toBN(0),
			pricePerUnitWithTax: MLWeb3.toBN(0),
			tax: 0,
			listedAt: null,
			cancelledAt: null,
			purchasedAt: null,
			purchasedByFarmID: 0,
			itemWasLoaded: false,
			nftItem: null
		});
	}

	findListingGroup(_resource)
	{
		return (this.listingGroups.find(g => g.resource === _resource) || null);
	}

	addListingGroup(_resource)
	{
		let ret = this.findListingGroup(_resource);
		if (ret === null)
		{
			ret =
			{
				resource: _resource,
				count_active: 0,
				count_purchased: 0,
				count_purchased24h: 0,
				priceMin: MLWeb3.toBN(0),
				priceLast: MLWeb3.toBN(0),
				priceAvg24h: MLWeb3.toBN(0)
			};
			this.listingGroups.push(ret);
		}

		return ret;
	}
}