import { useState, useEffect } from "react";

//components
import { ValueLoad } from "../../";

//framework
import { MLFormat, MLUtils } from "../../../utils";

const ValueCountdown = (props) =>
{
    //state
	const [text, setText] = useState(() => "???");
    const [additionalLoad] = useState(MLUtils.defaultBoolean(props.additionalLoad, true));

    //handler
    const handleTimer = () =>
    {
		setText((props.target === null
			? "???"
			: MLFormat.formatHMS((props.target.getTime() - (new Date()).getTime()) / 1000)
		));
    };

    //effect
    useEffect(() =>
    {
        const t = setInterval(() => handleTimer(), 1000);
		return () => clearInterval(t);
    }, [props.target]);

	return (
		<ValueLoad
			loaded={!props.load || (token?.initialized && additionalLoad)}
		>
			{text}
		</ValueLoad>
	);
};

export default ValueCountdown;