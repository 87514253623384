import { useState, useEffect } from "react";

//components
import
{
    Text,
    Link,
    Button,
    Flex,
    Grid,
    TokenIcon,
    InputBNAmount,
} from "../../";
import ModalSelectToken from "../Modals/ModalSelectToken";

//framework
import { MLFormat, MLUI, MLWeb3, MLUtils } from "../../../utils";

//hooks
import { useDApp } from "../../../hooks/useDApp";

//symbols
import { wallet } from "../../../symbols";

//styles
import styles from "./Inputs.module.css";
import { useEventSubscription } from "../../../hooks";
import Events from "../../../core/Events";

const InputTokenAmount = (props) =>
{
    //hooks
    const dApp = useDApp();

	//functions
    const getUSD = () =>
    {
        const t = (props.token ?? dApp.wrappedCoin);
        if (!t?.initializedPrice
            || MLUtils.isEmpty(props.value))
        {
            return null;
        }
        else
        {
            return t.getPriceUSDForAmount(
				MLWeb3.convertFloatString_TokenBN(props.value, t)
			);
        }
    };

    //state
    const [usdValue, setUSDValue] = useState(() => getUSD());
	const [showTokenSelection, setShowTokenSelection] = useState(() => false);

	//const state
	const [availableText] = useState(props.availableText || "");
    const [showAvailable] = useState(MLUtils.defaultBoolean(props.showAvailable, false));
    const [showInWallet] = useState(MLUtils.defaultBoolean(props.showInWallet, true));
    const [showMax] = useState(MLUtils.defaultBoolean(props.showMax, true));
    const [showIcon] = useState(MLUtils.defaultBoolean(props.showIcon, true));
    const [showUSD] = useState(MLUtils.defaultBoolean(props.showUSD, true));
    const [allowTokenSelection] = useState(MLUtils.defaultBoolean(props.allowTokenSelection, false));

    //handler
    const handleClickAvailable = () =>
    {
        if (props.onChange)
        {
            props.onChange(
				null,
				MLWeb3.convertTokenBN_FloatString(
					props.available,
					(props.token ?? dApp.wrappedCoin),
					true
				)
			);
        }
    };
    const handleClickTokenSelect = () =>
    {
        if (!allowTokenSelection)
        {
            return;
        }
        setShowTokenSelection(true);
    };
    const handleTokenSelect = (_value) =>
    {
        setShowTokenSelection(false);
        if (props.onSelect)
        {
            props.onSelect(_value);
        }
    };
    const handlePriceUpdate = (_data) =>
    {
        if (_data.detail.address !== (props.token ?? dApp.wrappedCoin).address)
        {
            return;
        }
        setUSDValue(getUSD());
    };

    //effects
    useEffect(() =>
    {
        setUSDValue(getUSD());
    }, [props.value, props.token])
    useEventSubscription(Events.token.priceInfo, handlePriceUpdate, [props.value, props.token]);

    return (
        <div
            className=
            {
                [
                    styles.inputAmount,
                    styles.inputTokenAmount,
                    props.className
                ].join(" ")
            }
        >
            {showTokenSelection &&
                <ModalSelectToken
                    show={showTokenSelection}
                    tokenList={dApp.getTokenList(false)}
                    onClose={() => setShowTokenSelection(false)}
                    onSelect={(t) => handleTokenSelect(t)}
                />
            }

            <Grid
                gap="0px"
                className={styles.inputAmountContainer}
            >
                {showAvailable &&
                    <Flex className={styles.inputAmount_header}>
                        {showIcon &&
                            <Flex className={styles.inputAmount_headerIconWrapper}>
                                <TokenIcon
                                    className={styles.inputAmount_headerIcon}
                                    token={(props.token ?? dApp.wrappedCoin)}
                                    style=
                                    {
                                        {
                                            ...(allowTokenSelection && { cursor: "pointer" })
                                        }
                                    }
                                    onClick={() => handleClickTokenSelect()}
                                />
                                <Text
									color={1}
									weight={0}
									size={-1}
									lineHeight={1}
								>
                                    {" "}{(props.token?.symbol ?? dApp.coinSymbol)}
                                </Text>
                            </Flex>
                        }
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClickAvailable()}
                        >
                            <Flex>
                                <Text
									color={1}
									weight={0}
									size={-1}
									lineHeight={1}
								>
                                    {availableText !== "" ? `${availableText}: ` : ""}
                                    {(!props.token ? dApp.wrappedCoin?.initialized : props.token.initialized) &&
                                        <>
                                            {MLFormat.formatToken(
                                                props.available,
                                                (props.token ?? dApp.wrappedCoin)
                                            )}
                                        </>
                                    }
                                </Text>
                                {MLUI.imageOrSymbol(wallet(), styles.inputAmount_iconBalance)}
                            </Flex>
                        </div>
                    </Flex>
                }

                <Flex
                    gap="0px"
                    className=
                    {
                        [
                            styles.inputAmount_content,
                            (showInWallet ? "": styles.noFooter),
                            (showAvailable ? "": styles.noHeader)
                        ].join(" ")
                    }
                >
                    <InputBNAmount
                        id={props.id}
                        name={props.name}
                        rawStyle={true}
                        value={props.value}
                        decimals={(!props.token ? dApp.wrappedCoin?.decimals : props.token.decimals) || 0}
                        placeholder={props.placeholder || "Input token amount"}
                        max={props.max}
                        step={props.step}
                        readOnly={props.readOnly}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                    />

                    {showMax &&
                        <div className={styles.inputAmount_buttons}>
                            <Button
                                onClick={() => handleClickAvailable()}
                            >
                                max
                            </Button>
                        </div>
                    }
                </Flex>

                {(showUSD || (showInWallet && !!props.token)) &&
                    <Flex className={styles.inputAmount_footer}>
                        {(showInWallet && !!props.token) &&
                            <Link onClick={() => props.token.addToWallet()}>
                                <Text
                                    className={styles.inputAmount_showWallet}
									color={2}
									weight={0}
									size={-1}
									lineHeight={1}
                                >
                                    Show in wallet
                                </Text>
                            </Link>
                        }

                        {showUSD &&
                            <Text
                                className={styles.inputAmount_usdValue}
                                color={2}
								weight={0}
								size={-1}
								lineHeight={1}
                            >
                                {usdValue === null
                                    ? <>???</>
                                    : <>~ {dApp.formatFiat(usdValue, true)}</>
                                }
                            </Text>
                        }
                    </Flex>
                }
            </Grid>
        </div>
    );
};

export default InputTokenAmount;