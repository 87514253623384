import { configUI as config } from "@MoonLabsDev/dapp-core-lib";

//template & theme
import Theme from "../../themes/MoonVault/Theme.module.css";

const configUI =
{
	...config,

    theme: Theme
};

export default configUI;