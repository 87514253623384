import { useState, useEffect } from "react";

//components
import { ValueLoad, FormatTokenAmount } from "../../../";

//framework
import { MLUtils, MLWeb3 } from "../../../../utils";
import Events from "../../../../core/Events";

//hooks
import { useEventSubscription } from "../../../../hooks";

const ValueTokenTotalSupply = (props) =>
{
    //state
    const [token, setToken] = useState(() => props.token || null);
    const [smart, setSmart] = useState(() => props.smart || false);
    const [value, setValue] = useState(() => token?.totalSupply || MLWeb3.toBN(0));
    const [shorten, setShorter] = useState(() => props.shorten || false);
    const [precision, setPrecision] = useState(() => (isNaN(props.precision) ? 8 : props.precision));
    const [load, setLoad] = useState(() => MLUtils.defaultBoolean(props.load, true));

    //handler
    const handleTokenUpdate = (data) =>
    {
        if (!MLWeb3.checkEqualAddress(data.detail.address, token.address))
        {
            return;
        }
        setValue(token?.totalSupply || MLWeb3.toBN(0));
    };

    //effect
    useEffect(() =>
    {
        setToken(props.token || null);
        setValue(props.token?.totalSupply || MLWeb3.toBN(0));
    }, [props.token, props.value]);
    useEventSubscription([Events.token.init, Events.token.data], handleTokenUpdate, [token]);

    return (
		<ValueLoad
			loaded={!load || token?.initializedData}
		>
			<FormatTokenAmount
				token={token}
				value={value}
				smart={smart}
				shorten={shorten}
				precision={precision}
			/>
		</ValueLoad>
	);
};

export default ValueTokenTotalSupply;