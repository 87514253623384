import { useState, useEffect } from "react";

//components
import
{
	Grid,
	Flex,
	Text
} from "../..";

//framework
import
{
	getModule,
	whenModuleLoaded,
	whenModuleContextCreated
} from "../../../core";

export const InfoModuleLoading = ({module, loadContent, children}) =>
{
	//functions
	const getMod = () =>
	{
		if (typeof module === "string")
		{
			return getModule(module);
		}
		return module;
	};

	//state
	const [mod] = useState(() => getMod());
	const [loaded, setLoaded] = useState(() => mod.isLoaded);
	const [created, setCreated] = useState(() => mod.isContextCreated);

	//effects
	useEffect(() =>
	{
		whenModuleLoaded(mod, () =>
		{
			setLoaded(true);
		});
		whenModuleContextCreated(mod, () =>
		{
			setCreated(true);
		});
	}, [mod]);

	const ready = loaded && created;
	return (
		<>
			{ready && children}
			{(!ready && !!loadContent) && loadContent}
			{(!ready && !loadContent) &&
				<Grid>
					<Flex>
						<Text size={1}>
							Please wait a moment...
						</Text>
					</Flex>
					<Flex>
						<Text size={0} align="center">
							The website is still loading
						</Text>
					</Flex>
				</Grid>
			}
		</>
	);
};