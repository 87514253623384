
import React, { useState, useEffect, useContext } from "react";

import { DApp } from "./";
import { useDApp } from "../hooks";

export class DAppModule
{
    static moduleName;
    constructor(extractor)
    {
        this.loaded = new Promise((res, rej) =>
        {
            this.setLoaded = () => res(true);
            this.failedLoaded = () => rej();
        });
		this.contextCreated = new Promise((res, rej) =>
        {
            this.setContextCreated = () => res(true);
            this.failedContextCreation = () => rej();
        });
        const [hook, provider] = createModuleContext(this, extractor);
        this.hook = hook;
        this.provider = provider;
        this.entry = [this.constructor.moduleName, provider];
		this.isLoaded = false;
		this.isContextCreated = false;
    }

    /* abstract */
    async init(dApp) {}
    async onLoad(dApp) {}
    async onRefresh(dApp) {}
    async onRefreshChainData(dApp) {}
    async onLoad(dApp) {}
    async onDestroy(dApp) {}
    static availableOnChain(chain) {}
}

export const whenModuleLoaded = (module, cb) =>
{
    module.loaded.then(() => cb(module));
};

export const whenModuleContextCreated = (module, cb) =>
{
    module.contextCreated.then(() => cb(module));
};

export const createModuleContext = (module, retrieveObject) =>
{
    //context
    const context = React.createContext();

    const hook = () => useContext(context);
    const component = ({children}) =>
    {
        //state
        const [mod, setMod] = useState();
        const dapp = useDApp();

		//effect
        useEffect(() =>
        {
            whenModuleLoaded(module, m =>
            {
                const loadedModule = (typeof retrieveObject === "function"
					? retrieveObject(m)
					: m
				);
                setMod(loadedModule);
				loadedModule.isLoaded = true;

				if (!!DApp.instance.config.debug.dApp.moduleLoaded)
				{
					DApp.instance.log(false, `Module [${loadedModule.constructor.moduleName}] loaded`);
				}
            });
        }, [module]);
		useEffect(() =>
		{
			if (!!mod)
			{
				module.isContextCreated = true;
				module.setContextCreated();
			}
		}, [mod]);

        if (!dapp.isModuleRegistered(module.constructor.moduleName))
        {
            return <>Module not registered: {module.constructor.moduleName}</>;
        }
        return <context.Provider value={mod}>{children}</context.Provider>;
    };

    return [hook, component];
}

export const useModule = (module) =>
{
    const getName = () =>
	{
        if (typeof module === "string")
		{
            return module;
		}
        else if (!!module.moduleName)
		{
            return module.moduleName;
        }
        return "";
    }

    const m = getModule(getName());
	return m?.hook() || null;
}

export const getModule = (key) =>
{
    return DApp.instance.modules.find(m => m.constructor.moduleName === key) || null;
}

export const getModules = (keys) => keys.map(getModule).filter(m => !!m);

export const overrideModule = (module) =>
{
    if (!module instanceof DAppModule)
	{
        throw "Not a module";
    }
	else
	{
        const moduleName = module.constructor.moduleName;
        if (getModule(moduleName) === null)
		{
            throw `Can't override unknown module [${moduleName}]`;
        }
		else
		{
			DApp.instance.registeredModules =
			[
				...DApp.instance.registeredModules.filter(m => m.constructor.moduleName !== moduleName),
				module
			];
			DApp.instance.refreshModules();
        }
    }
}

export const getEnabledProviders = (dApp) =>
{
	return dApp.modules.reduce((acc, m) =>
	{
		return dApp.isModuleRegistered(m.entry[0])
			? [
				...acc,
				{
					module: m,
					moduleName: m.constructor.moduleName,
					availableOnChain: m.constructor.availableOnChain(dApp.currentChain),
					provider: m.entry[1]
				}
			]
			: acc;
	}, []);
};