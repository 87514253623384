import { useState, useEffect } from "react";

//components
import InputTokenAmount from "../InputTokenAmount";

//framework
import { MLWeb3 } from "../../../../utils";
import Events from "../../../../core/Events";
import { useEventSubscription, useDApp } from "../../../../hooks";

const InputTokenAmount_Balance = (props) =>
{
    //context
    const dApp = useDApp();

	//functions
	const getAvailable = () =>
	{
		return (props.token?.userBalance ?? dApp.userCoinBalance);
	};

	//state
    const [available, setAvailable] = useState(() => getAvailable());

    //handler
    const handleBalanceUpdate = (data) =>
    {
        if (!!props.token
            && !MLWeb3.checkEqualAddress(data.detail.address, props.token.address))
        {
            return;
        }

        setAvailable(getAvailable());
    };

    //effects
    useEffect(() =>
    {
        setAvailable(getAvailable());
    }, [props.value, props.token]);
    useEventSubscription((!props.token ? Events.dApp.reload : Events.token.userData), handleBalanceUpdate, [props.token]);

    return (
        <InputTokenAmount
            token={props.token}
            value={props.value}
            showAvailable={true}
            available={available}
            availableText={"Balance"}
            showMax={props.showMax}
            showInWallet={props.showInWallet}
            allowTokenSelection={props.allowTokenSelection}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onSelect={props.onSelect}
            onBlur={props.onBlur}
        />
    );
};

export default InputTokenAmount_Balance;