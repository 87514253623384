//components
import
{
    PageHeader,
    PageContent,
    Flex,
    ErrorServiceNotAtChain,
} from "@MoonLabsDev/dapp-core-lib";
import { MoonReferralInfo } from "@MoonVault/dapp-widgets-lib";

//modules
import { useMoonReferral } from "@MoonVault/dapp-widgets-lib";

export default () =>
{
	//context
	const referral = useMoonReferral();

    return (
        <>
            <PageHeader
                title=
                {
                    <Flex>
                        Referral
                    </Flex>
                }
            >
                Help us grow and earn a part of our profits!
            </PageHeader>

            <PageContent>
                <ErrorServiceNotAtChain checkSupportChainCallback={referral.availableOnChain}>
                    <Flex style={{ width: "100%" }}>
                        <MoonReferralInfo />
                    </Flex>
                </ErrorServiceNotAtChain>
            </PageContent>
        </>
    );
}