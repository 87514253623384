//dApp
import { DApp, configPage as config } from "@MoonLabsDev/dapp-core-lib";

//pages
import
{
	Page_debugInfo,
	Page_test_general,
	Page_test_cards,
	Page_test_charts,
	Page_test_modals
} from "../../pages";

const configPage =
{
	...config,

    title: "MoonLabs dApp",
    description: "This is a MoonLabs dApp",
    themeColor: "#F5F5F5",
	backgroundImage: "",
    logo: "",
	socials:
	{
		discord: "",
		telegram: "",
		github: "",
		gitbook: "",
		twitter: ""
	},
    dApp: DApp,
    modules: [],
	format:
	{
		thousandsSeperator: ","
	},
    preconnect:
    [
        { link: "https://fonts.googleapis.com" },
        { link: "https://fonts.gstatic.com", crossOrigin: true }
    ],
    fonts:
    [
        "https://fonts.googleapis.com/css2?family=Montserrat&display=swap"
    ],
    pages:
    [
        { path: "home", page: Page_debugInfo },
        { path: "debugInfo", page: Page_debugInfo },
        { path: "tests/general", page: Page_test_general },
        { path: "tests/cards", page: Page_test_cards },
        { path: "tests/charts", page: Page_test_charts },
        { path: "tests/modals", page: Page_test_modals },
    ]
};

export default configPage;