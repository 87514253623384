import { useState, useEffect } from "react";

//components
import { ValueTokenAmount } from "@MoonLabsDev/dapp-core-lib";

//hooks
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "../../../..//modules/Module_Bank";
import { useBank } from "../../../../modules";

const ValueTokenBankBalance = (props) =>
{
	//context
	const dApp = useDApp();
	const bank = useBank();
    //state
    const [token, setToken] = useState(() => props.token || null);
    const [smart, setSmart] = useState(() => props.smart || false);
    const [value, setValue] = useState(() => bank && bank.getBalance(token));
    const [shorten, setShorter] = useState(() => props.shorten || false);
    const [precision, setPrecision] = useState(() => (isNaN(props.precision) ? 8 : props.precision));
    const [load, setLoad] = useState(() => props.load);

    //handler
    const handleUpdate = () =>
    {
        setValue(bank.getBalance(token));
    };

    //effect
    useEffect(() =>
    {
        setToken(props.token || null);
        setValue(bank?.getBalance(props.token || null));
    }, [props.token]);
    useEventSubscription(ModuleEvents.userData, handleUpdate, [token]);

    return (
        <ValueTokenAmount
            token={token ?? dApp.wrappedCoin}
            value={value}
            smart={smart}
            shorten={shorten}
            load={load}
            additionalLoad={bank?.initializedUser}
        />
    );
};

export default ValueTokenBankBalance;