import { useState, useEffect } from "react";

//hooks
import { useMediaInfo } from "../../../hooks";

//framework
import { MLUI } from "../../../utils";

const ConditionalContainer = (props) =>
{
    //context
    const curMediaInfo = useMediaInfo();

    //select prop
    const responsiveProps = props.responsive;
    const selectProp = (_propID) =>
    {
        return MLUI.selectPropertyFromMediaQueryList(
            curMediaInfo.types,
            curMediaInfo.matches,
            responsiveProps,
            _propID
        );
    };

    //style props
    const [visible, setVisible] = useState(() => props.visible);

    //media query
    useEffect(() =>
    {
        setVisible(selectProp("visible") || props.visible);
    }, [props.visible, JSON.stringify(curMediaInfo)]);

    return (
        <div
            className={props.className}
            style=
            {
                {
                    ...props.style,
                    display: (visible ? "block" : "none"),
                    visibility: (visible ? "visible" : "hidden")
                }
            }
        >
            {props.children}
        </div>
    );
};

export default ConditionalContainer;