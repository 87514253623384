//framework
import { DApp, MLWeb3, MLMultiCall, MLFormat, Web3Transaction } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "..//modules/Module_Escrow";

//contracts
import ABI_Escrow from "../abi/Escrow";

export const OfferItemType =
{
    ERC20: 0,
    ERC721: 1,
    ERC1155: 2
};

export const TradeStatus =
{
    ONGOING: 0,
    CANCELLED: 1,
    COMPLETE: 2
};

export class Escrow
{
    ////////////////////////////////////

	constructor(_address)
	{
        this.initialized = false;
        this.initializedUser = false;

		//base values
		this.address = _address;
        this.feeInEth = MLWeb3.toBN(0);
        this.tradeCount = 0;
        this.trades = [];
        this.userInfo = [];

        //add user
        if (DApp.instance.account !== null)
        {
            this.createUserInfo(DApp.instance.account).load = true;
        }
	}

    ////////////////////////////////////

	debugErrorString(_text)
	{
		return `Escrow failed at: ${_text}`;
	}

    getContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(ABI_Escrow, this.address).methods;
    }

    makeMultiCall(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.address,
            ABI_Escrow,
            _calls
        );
    }

    dispatchEvent(_name, _id, _userAddress)
    {
        document.dispatchEvent(new CustomEvent(_name,
        {
            detail:
            {
                ...(_id !== undefined && { id: _id }),
                ...(_userAddress !== undefined && { user: _userAddress })
            }
        }));
    }

    lazyLoadTrade(_id)
    {
        const trade = this.findOrCreateTrade(_id);
        if (trade)
        {
            trade.load = true;
        }
        return _id;
    }

    /////////////////////////
    // Data
    /////////////////////////

    async reload_data()
    {
        await DApp.instance.batchCall(
            [this],
            (o) => o.makeRequest_data(),
            (o, r) => o.processRequest_data(r),
            false,
            "[Escrow] batch data",
            "Escrow: data"
        );
    }

    makeRequest_data()
    {
        return this.makeMultiCall(
        {
            feeInEth: { function: "getFeeAmountInETH" },
            ...(DApp.instance.account !== null &&
                {
                    activeCount: { function: "getUserTradeListLength", parameters: [DApp.instance.account, TradeStatus.ONGOING] },
                    completedCount: { function: "getUserTradeListLength", parameters: [DApp.instance.account, TradeStatus.COMPLETE] },
                    cancelledCount: { function: "getUserTradeListLength", parameters: [DApp.instance.account, TradeStatus.CANCELLED] }
                }
            )
        });
    }

    async processRequest_data(_data)
    {
        this.feeInEth = MLWeb3.toBN(_data.feeInEth);
        if (DApp.instance.account !== null)
        {
            const user = this.findUserInfo(DApp.instance.account);
            user.activeCount = parseInt(_data.activeCount);
            user.completedCount = parseInt(_data.completedCount);
            user.cancelledCount = parseInt(_data.cancelledCount);
        }

        //comlete
        this.initialized = true;

        //event
        this.dispatchEvent(ModuleEvents.data);
    }

    /////////////////////////
    // Trade Info
    /////////////////////////

    async batch_tradeInfo(_trades)
    {
        if (_trades === undefined)
        {
            _trades = this.trades;
        }

        const filtered = _trades.filter((t) => t.load);
        await DApp.instance.batchCall(
            filtered,
            (o) => this.makeRequest_tradeInfo(o),
            (o, r) => this.processRequest_tradeInfo(o, r),
            false,
            "[Escrow] batch tradeInfo",
            "Escrow: tradeInfo"
        );
    }

    makeRequest_tradeInfo(_trade)
    {
        return this.makeMultiCall(
        {
            tradeInfo: { function: "trades", parameters: [_trade.id] }
        });
    }

    async processRequest_tradeInfo(_trade, _data)
    {
        _trade.status = parseInt(_data.tradeInfo.status);
        _trade.created = new Date(parseInt(_data.tradeInfo.created) * 1000);
        _trade.updated = new Date(parseInt(_data.tradeInfo.lastUpdated) * 1000);

        //offer 1
        const rev1Changed = (parseInt(_data.tradeInfo.offer1.revision) !== _trade.offer1.revision);
        _trade.offer1.owner = _data.tradeInfo.offer1.owner;
        _trade.offer1.receiver = _data.tradeInfo.offer1.receiver;
        _trade.offer1.revision = parseInt(_data.tradeInfo.offer1.revision);
        _trade.offer1.updated = new Date(parseInt(_data.tradeInfo.offer1.lastUpdated) * 1000);
        _trade.offer1.accepted = _data.tradeInfo.offer1.accepted;
        _trade.offer1.balance = MLWeb3.toBN(_data.tradeInfo.offer1.balance);
        if (rev1Changed)
        {
            _trade.offer1.items = _data.tradeInfo.offer1.items.map((i) =>
            {
                return {
                    type: parseInt(i.itemType),
                    contract: i.contractAddress,
                    nftID: parseInt(i.nftId),
                    amount: MLWeb3.toBN(i.amount)
                }
            });
        }

        //offer 2
        const rev2Changed = (parseInt(_data.tradeInfo.offer2.revision) !== _trade.offer2.revision);
        _trade.offer2.owner = _data.tradeInfo.offer2.owner;
        _trade.offer2.receiver = _data.tradeInfo.offer2.receiver;
        _trade.offer2.revision = parseInt(_data.tradeInfo.offer2.revision);
        _trade.offer2.updated = new Date(parseInt(_data.tradeInfo.offer2.lastUpdated) * 1000);
        _trade.offer2.accepted = _data.tradeInfo.offer2.accepted;
        _trade.offer2.balance = MLWeb3.toBN(_data.tradeInfo.offer2.balance);
        if (rev2Changed)
        {
            _trade.offer2.items = _data.tradeInfo.offer2.items.map((i) =>
            {
                return {
                    type: parseInt(i.itemType),
                    contract: i.contractAddress,
                    nftID: parseInt(i.nftId),
                    amount: MLWeb3.toBN(i.amount)
                }
            });
        }

        //comlete
        _trade.initialized = true;

        //event
        this.dispatchEvent(ModuleEvents.tradeInfo, _trade.id);
    }

    /////////////////////////
    // User Info
    /////////////////////////

    async batch_userInfo(_users)
    {
        if (_users === undefined)
        {
            _users = this.userInfo;
        }

        const filtered = _users.filter((u) => u.load);
        await DApp.instance.batchCall(
            filtered,
            (o) => this.makeRequest_userInfo(o),
            (o, r) => this.processRequest_userInfo(o, r),
            false,
            "[Escrow] batch userInfo",
            "Escrow: userInfo"
        );
    }

    makeRequest_userInfo(_user)
    {
        return this.makeMultiCall(
        {
            ...(_user.activeCount > 0 &&
                {
                    activeTrades: { function: "getUserTradesInRange", parameters: [DApp.instance.account, TradeStatus.ONGOING, 0, _user.activeCount] }
                }
            ),
            ...(_user.completedCount > 0 &&
                {
                    completedTrades: { function: "getUserTradesInRange", parameters: [DApp.instance.account, TradeStatus.COMPLETE, 0, _user.completedCount] }
                }
            ),
            ...(_user.cancelledCount > 0 &&
                {
                    cancelledTrades: { function: "getUserTradesInRange", parameters: [DApp.instance.account, TradeStatus.CANCELLED, 0, _user.cancelledCount] }
                }
            )
        });
    }

    async processRequest_userInfo(_user, _data)
    {
        if (_data)
        {
            if (_data.activeTrades)
            {
                _user.activeTrades = _data.activeTrades.map((t) => parseInt(t));
                _user.activeTrades.sort((a, b) => a - b);
                _user.activeTrades.forEach((t) =>
                {
                    const trade = this.findOrCreateTrade(t);
                    if (MLWeb3.checkEqualAddress(_user.address, DApp.instance.account))
                    {
                        trade.load = true;
                    }
                });
            }
            if (_data.completedTrades)
            {
                _user.completedTrades = _data.completedTrades.map((t) => parseInt(t));
                _user.completedTrades.sort((a, b) => a - b);
                _user.completedTrades.forEach((t) =>
                {
                    this.findOrCreateTrade(t);
                });
            }
            if (_data.cancelledTrades)
            {
                _user.cancelledTrades = _data.cancelledTrades.map((t) => parseInt(t));
                _user.cancelledTrades.sort((a, b) => a - b);
                _user.cancelledTrades.forEach((t) =>
                {
                    this.findOrCreateTrade(t);
                });
            }
        }

        //comlete
        _user.initialized = true;

        //event
        this.dispatchEvent(ModuleEvents.userInfo, undefined, _user.address);
    }

    /////////////////////////
    // Helper
    /////////////////////////

    findTrade(_id)
    {
        return (this.trades.find(t => t.id === parseInt(_id)) || null);
    }

    createTrade(_id)
    {
        const trade =
        {
            id: parseInt(_id),
            status: null,
            created: null,
            updated: null,
            offer1:
            {
                owner: "",
                revision: -1,
                updated: null,
                accepted: false,
                balance: MLWeb3.toBN(0),
                items: []
            },
            offer2:
            {
                owner: "",
                revision: -1,
                updated: null,
                accepted: false,
                balance: MLWeb3.toBN(0),
                items: []
            },

            load: false,
            initialized: false
        };
        this.trades.push(trade);
        return trade;
    }

    findOrCreateTrade(_id)
    {
        let trade = this.findTrade(_id);
        if (trade === null)
        {
            trade = this.createTrade(_id);
        }
        return trade;
    }

    getTradeOffer(_trade, _offer)
    {
        if (_offer === 1)
        {
            return _trade?.offer1;
        }
        else if (_offer === 2)
        {
            return _trade?.offer2;
        }
        return null;
    }

    getTradeOfferByPartner(_trade, _address, _other)
    {
        if (MLWeb3.checkEqualAddress(_trade.offer1.owner, _address) === !_other)
        {
            return _trade.offer1;
        }
        else
        {
            return _trade.offer2;
        }
    }

    checkIsUserTrade(_trade)
    {
        return (MLWeb3.checkEqualAddress(_trade?.offer1?.owner, DApp.instance.account)
            || MLWeb3.checkEqualAddress(_trade?.offer2?.owner, DApp.instance.account));
    }

    getUserInfoTradeList(_address, _status)
    {
        const user = this.findUserInfo(_address);
        if (user !== null)
        {
            switch (_status)
            {
                case TradeStatus.ONGOING:
                    return user.activeTrades;

                case TradeStatus.CANCELLED:
                    return user.cancelledTrades;

                case TradeStatus.COMPLETE:
                    return user.completedTrades;
            }
        }

        return [];
    }

    findUserInfo(_address)
    {
        return (this.userInfo.find(u => MLWeb3.checkEqualAddress(u.address, _address)) || null);
    }

    createUserInfo(_address)
    {
        const info =
        {
            address: _address,
            activeCount: 0,
            activeTrades: [],
            completedCount: 0,
            completedTrades: [],
            cancelledCount: 0,
            cancelledTrades: [],

            load: false,
            initialized: false
        };
        this.userInfo.push(info);
        return info;
    }

    findOrCreateUserInfo(_address)
    {
        let info = this.findUserInfo(_address);
        if (info === null)
        {
            info = this.createUserInfo(_address);
        }
        return info;
    }

    /////////////////////////
    // Transactions
    /////////////////////////

    newTrade(_partner)
    {
        const con = this.getContract(true);
        return new Web3Transaction(
            con.createTrade(_partner),
            this.debugErrorString("create trade"),
            `Create trade ${MLFormat.formatAddress(_partner, true)}`
		);
    }

    cancelTrade(_id)
    {
        const con = this.getContract(true);
        return new Web3Transaction(
            con.cancelTrade(_id),
            this.debugErrorString("cancel trade"),
            `Cancel trade ${_id}`
		);
    }

    executeTrade(_id, _referralID = 2)
    {
        const con = this.getContract(true);
        return new Web3Transaction(
            con.executeTrade(_id, _referralID),
            this.debugErrorString("execute trade"),
            `Execute trade ${_id}`,
            this.feeInEth
		);
    }

    acceptOffer(_id, _revision)
    {
        const con = this.getContract(true);
        return new Web3Transaction(
            con.acceptOffer(
                _id,
                _revision
            ),
            this.debugErrorString("accept offer"),
            `Accept offer ${_id}_${_revision}`
		);
    }

    setTradeReceiver(_id, _wallet)
    {
        if (_wallet === "")
        {
            _wallet = MLWeb3.getEmptyAddress();
        }

        const con = this.getContract(true);
        return new Web3Transaction(
            con.setReceiver(
                _id,
                _wallet
            ),
            this.debugErrorString("set receiver"),
            `Set Receiver #${_id}`
		);
    }

    setOffer(_id, _offer)
    {
        //get data
        const coinValue = MLWeb3.toBN(_offer.items.find(o => o.type === "Coin")?.amount || 0);
        const balanceAdd = (coinValue.cmp(_offer.balance) === 1 ? coinValue.sub(_offer.balance) : MLWeb3.toBN(0));
        let items = _offer.items.filter(o => o.type !== "Coin").map(o =>
        {
            return {
                itemType: o.type,
                contractAddress: o.contract,
                nftId: o.nftID,
                amount: o.amount.toString(10)
            }
        });

        const con = this.getContract(true);
        return new Web3Transaction(
            con.setOffer(
                _id,
                coinValue,
                items
            ),
            this.debugErrorString("make offer"),
            `Make offer ${_offer.revision + 1}`,
            balanceAdd
		);
    }
}