import { useState } from "react";

//components
import
{
    Grid,
    Flex,
    Text,
    ButtonGuide
} from "../../";
import ModalYoutube from "../Modals/ModalYoutube";

const ButtonVideoGuide = (props) =>
{
    //state
    const [show, setShow] = useState(() => false);

    return (
        <>
            <ButtonGuide
                onClick={() => setShow(true)}
            >
                <Grid>
                    <Flex>
                        <Text color={3}>
                            Video Guide:
                        </Text>
                    </Flex>
                    <Flex>
                        <Text
                            color={1}
                            size={-1}
                        >
                            Click on the Icon,
                            <br />
                            to see a Video guide!
                        </Text>
                    </Flex>
                </Grid>
            </ButtonGuide>
            <ModalYoutube
                title={"Video Guide"}
                show={show}
                onClose={() => setShow(false)}
                videoID={props.videoID}
            />
        </>
    );
};

export default ButtonVideoGuide;