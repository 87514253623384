import { useState, useEffect } from "react";

//components
import { Button, Modal } from "../../../components"

//framework
import t from "../../../utils/MLTranslate";

//styles
import styles from "./Modals.module.css";

const ModalYoutube = (props) =>
{
    //state
    const [show, setShow] = useState(() => props.show);
    const [videoID, setVideoID] = useState(() => props.videoID);

    //handler
    const handleClose = () =>
    {
        if (props.onClose)
        {
            props.onClose();
        }
        else
        {
            setShow(false);
        }
    };

    //effect
    useEffect(() =>
    {
        setShow(props.show);
    }, [props.show]);

    return (
        <Modal
            show={show}
            allowBig={true}
            header={props.title}
            onClose={() => handleClose()}
        >
            <div className={styles.video}>
                <div>
                    <iframe
                        frameBorder="0"
                        scrolling="no"
                        marginHeight="0"
                        marginWidth="0"
                        type="text/html"
                        src={`https://www.youtube.com/embed/${videoID}?autoplay=1&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0`}
                    />
                </div>
            </div>

        </Modal>
    );
};

export default ModalYoutube;