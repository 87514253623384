import { useState, useEffect } from "react";

//framework
import { MLFormat, MLUtils, MLWeb3 } from "../../../utils";
import Events from "../../../core/Events";

//hooks
import { useEventSubscription } from "../../../hooks";

const FormatNFTAmount = ({value, nft, nftID, precision, shorten, smart}) =>
{
	//functions
	const getNFTItem = () =>
	{
		return {
			...(nft?.findItem(nftID))
		}
	}

    //state
    const [nftItem, setNFTItem] = useState(() => getNFTItem());

    //handler
    const handleNFTItemUpdate = (data) =>
    {
        if (!MLWeb3.checkEqualAddress(data.detail.address, nft?.address)
            || data.detail.id !== nftID)
        {
            return;
        }
        setNFTItem(getNFTItem());
    };

    //effect
    useEffect(() =>
    {
        setNFTItem(getNFTItem());
    }, [nft, nftID]);
    useEventSubscription(Events.nft.metaData, handleNFTItemUpdate, [nft, nftID]);

    return (
        <>
            {nftItem &&
                (MLUtils.defaultBoolean(smart, false)
                    ? MLFormat.smartFormatNFT(
						value,
						nftItem
					)
                    : MLFormat.formatNFT(
						value,
						nftItem,
						precision,
						shorten
					)
                )
            }
        </>
    );
};

export default FormatNFTAmount;