import React, { useState, useContext } from "react";

//framework
import {DApp} from "../core";

class Theme
{
	getClassName(_className)
	{
		return `${DApp.instance?.config?.ui?.classPrefix ?? ""}${_className}`;
	}
};

const ThemeContext = React.createContext();
export const useTheme = () =>
{
    return useContext(ThemeContext);
};

export const ThemeProvider = ({ children }) =>
{
    //state
    const [theme] = useState(() => new Theme());

    return (
        <ThemeContext.Provider value={theme}>
            {children}
        </ThemeContext.Provider>
    );
};