import { useEffect, useState } from "react";

//components
import
{
    Button
} from "../../";

//framework
import { MLWeb3 } from "../../../utils";
import { useDApp, useEventSubscription } from "../../../hooks";
import Events from "../../../core/Events";

const CheckBalanceApproveButton = (props) =>
{
    //context
    const dApp = useDApp();

    //functions
    const getBalance = (_token) =>
    {
        return (_token === null ? dApp.userCoinBalance : _token.userBalance);
    };
    const checkApproved = (_token, _for) =>
    {
        if (_token === null
            || _for === null)
        {
            return true;
        }
        return _token.checkApproved(_for);
    };

    //state
    const [token, setToken] = useState(() => props.token || null);
    const [amount, setAmount] = useState(() => props.amount || MLWeb3.toBN(0));
    const [approveFor, setApproveFor] = useState(() => props.approveFor || null);
    const [approved, setApproved] = useState(() => checkApproved(token, approveFor));
    const [balance, setBalance] = useState(() => getBalance(token));

    //handler
    const handleUpdateBalance = (_data) =>
    {
        if (_data.detail?.address !== token?.address)
        {
            return;
        }

        setBalance(getBalance(token));
    };
    const handleUpdateApproval = (_data) =>
    {
        if (token !== null
            && _data.detail.address !== token.address)
        {
            return;
        }
        if (approveFor !== null)
        {
            setApproved(checkApproved(token, approveFor));
        }
    };
    const handleClick = () =>
    {
        //check approval
        if (token !== null
            && !!approveFor
            && !token.checkApproved(approveFor))
        {
            token.approve(approveFor)?.send();
            return;
        }

        //check balance
        if (balance.cmp(amount) === -1)
        {
            return;
        }

        //all ok, so handle onclick
        if (props.onClick)
        {
            props.onClick();
        }
    };

    //effects
    useEffect(() =>
    {
        const t = props.token || null;
        setToken(t);
        setBalance(getBalance(t));
        setAmount(props.amount || MLWeb3.toBN(0));
        setApproveFor(props.approveFor || null);
        if (approveFor !== null
            && token !== null)
        {
            setApproved(checkApproved(t, props.approveFor || null));
        }
        else
        {
            setApproved(true);
        }
    }, [props.token, props.amount, props.approveFor]);
    useEventSubscription(Events.token.approval, handleUpdateApproval, [token, approveFor]);
    useEventSubscription([Events.token.userData, Events.dApp.reload], handleUpdateBalance, [token]);

    return (
        <Button
            style={props.style}
            onClick={() => handleClick()}
        >
            {!approved &&
                <>
                    {`Approve ${token.symbol}`}
                </>
            }

            {(approved && balance.cmp(amount) === -1) &&
                <>
                    Insufficient Balance
                </>
            }

            {(approved && balance.cmp(amount) !== -1) &&
                <>
                    {props.children}
                </>
            }
        </Button>
    );
};

export default CheckBalanceApproveButton;
