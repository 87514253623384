//framework
import { DApp, MLWeb3, MLMultiCall, MLFormat, Web3Transaction } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "../modules/Module_Bank";

//contracts
import ABI_Bank from "../abi/Bank";

export class Bank
{
	////////////////////////////////////

	constructor(_address)
	{
		//init
		this.initialized = false;
		this.initializedUser = false;

		//values
		this.address = _address;

		//data
		this.tokens = [];
	}

	////////////////////////////////////

	debugErrorString(_text)
	{
		return `Bank failed at: ${_text}`;
	}

    getContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(ABI_Bank, this.address).methods;
    }

    makeMultiCall(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.address,
            ABI_Bank,
            _calls
        );
    }

    dispatchEvent(_name)
    {
        document.dispatchEvent(new CustomEvent(_name));
    }

	/////////////////////////
    // User
    /////////////////////////

    async batch_userInfo()
    {
        await DApp.instance.batchCall(
            [this],
            (o) => o.makeRequest_userInfo(),
            (o, r) => o.processRequest_userInfo(r),
            false,
            "[Bank] userInfo",
            "Bank: userInfo"
        );
    }

    makeRequest_userInfo()
    {
        return this.makeMultiCall(
        {
            userInfo: { function: "getUserInfo", parameters: [DApp.instance.account] },
        });
    }

    async processRequest_userInfo(_data)
    {
        this.tokens = _data.userInfo;

		//process
		this.tokens.forEach(t =>
		{
			t.tokenAddress = t.token;
			t.token = DApp.instance.findToken(t.tokenAddress);
			t.allowances = parseInt(t.allowances);
			t.balance = MLWeb3.toBN(t.balance);
		});
		this.tokens = this.tokens.filter(t => t.token !== null || t.tokenAddress === MLWeb3.getZeroAddress());

		this.initializedUser = true;

        //event
        this.dispatchEvent(ModuleEvents.userData);
    }

	/////////////////////////
    // Helper
    /////////////////////////

	getBalance(_token)
	{
		const asset = this.tokens.find(t => t.token === _token);
		if (!!asset)
		{
			return asset.balance;
		}
		return MLWeb3.toBN(0);
	}

	/////////////////////////
    // Transactions
    /////////////////////////

	depositFor(_token, _amount, _for)
    {
		//default
		_for = _for ?? DApp.instance.account;

        const con = this.getContract(true);
        return new Web3Transaction(
            con.depositFor(
				_token.address,
				_for,
				_amount
			),
            this.debugErrorString("depositFor"),
            `Deposit ${MLFormat.smartFormatToken(_amount, _token)} ${_token.symbol}`
		);
    }

	depositCoinFor(_amount, _for)
    {
		//default
		_for = _for ?? DApp.instance.account;

        const con = this.getContract(true);
        return new Web3Transaction(
            con.depositCoinFor(_for),
            this.debugErrorString("depositCoinFor"),
            `Deposit ${MLFormat.smartFormatToken(_amount, DApp.instance.wrappedCoin)} ${DApp.instance.coinSymbol}`,
			_amount
		);
    }

	transfer(_token, _from, _to, _amount)
    {
		//default
		_from = _from ?? DApp.instance.account;
		_to = _to ?? DApp.instance.account;

        const con = this.getContract(true);
        return new Web3Transaction(
            con.transfer(
				(_token?.address ?? MLWeb3.getZeroAddress()),
				_from,
				_to,
				_amount
			),
            this.debugErrorString("transfer"),
            `Transfer ${MLFormat.smartFormatToken(_amount, _token ?? DApp.instance.wrappedCoin)} ${_token?.symbol ?? DApp.instance.coinSymbol}`
		);
    }

	transferToAccount(_token, _from, _to, _amount)
    {
		//default
		_from = _from ?? DApp.instance.account;
		_to = _to ?? DApp.instance.account;

        const con = this.getContract(true);
        return new Web3Transaction(
            con.transfer(
				(_token?.address ?? MLWeb3.getZeroAddress()),
				_from,
				_to,
				_amount
			),
            this.debugErrorString("transferToAccount"),
            `Transfer ${MLFormat.smartFormatToken(_amount, _token ?? DApp.instance.wrappedCoin)} ${_token?.symbol ?? DApp.instance.coinSymbol}`
		);
    }
}