//framework
import { DAppModule, MLUtils, useModule } from "@MoonLabsDev/dapp-core-lib";

//classes
import { MoonToken } from "../classes/MoonToken";

export const ModuleEvents =
{
    init: "moonToken_init",
    data: "moonToken_data"
};

export class Module_MoonToken extends DAppModule
{
    static moduleName = "moon_token";
    constructor()
    {
        super(m => m.moonToken);
        this.moonToken = null;
    }

    async onRefreshChainData(dApp)
    {
        if (this.moonToken === null)
        {
            return;
        }

        //data
        await MLUtils.measureTime(`TM: MoonToken => Data`, async () =>
        {
            await this.moonToken.reload_data();
        });
    }

    async onLoad(dApp)
    {
        if (!Module_MoonToken.availableOnChain(dApp.currentChain))
        {
            return;
        }

        const addr = dApp.currentChain?.modules?.moonToken;
        dApp.log(false, `Loading MoonToken [${addr}]`);
        this.moonToken = new MoonToken(addr);
        this.setLoaded();
    }

    static availableOnChain(chain)
    {
        const addr = chain?.modules?.moonToken;
        return (addr !== undefined
            && addr !== "");
    }
}

export const useMoonToken = () => useModule(Module_MoonToken);

const currentModule = new Module_MoonToken();
export default currentModule;