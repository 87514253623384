import { useState, useEffect } from "react";

//components
import
{
    Text,
    Flex,
    Grid,
    Card,
    CardHeader,
    CardBody
} from "@MoonLabsDev/dapp-core-lib";
import { MoonSwapPathInfo } from "./";

//framework
import { MLUtils, MLWeb3 } from "@MoonLabsDev/dapp-core-lib";

//hooks
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { ModuleEvents, useMoonSwap } from "../../../modules/Module_MoonSwap";

//styles
import styles from "./MoonSwap.module.css";


const MoonSwapTradeInfo = (props) =>
{
    //context
    const dApp = useDApp();
    const swap = useMoonSwap();

    //functions
    const filterSwapInfo = (groupByRouter) =>
    {
        return (groupByRouter ? swap.swapInfoGrouped : swap.swapInfo);
    };
    const getSwapFeeInUSD = () =>
    {
        const from = dApp.findToken(swap.pollSwapInfo.from);
        const fee = MLWeb3.toBN(swap.percentFactor * swap.fee);
        const share = swap.pollSwapInfo.amount.mul(fee).div(MLWeb3.toBN(swap.percentFactor));
        return from.getPriceUSDForAmount(share);
    };
    const getSavedUSD = (_min, _tokenTo) =>
    {
        const to = dApp.findToken(swap.pollSwapInfo.to);
        const best = swap.swapInfoGrouped[0].amountOut;
        const compareTo = swap.swapInfoGrouped[(_min ? 1 : swap.swapInfoGrouped.length - 1)].amountOut;
        const diff = best.sub(compareTo);
        return to.getPriceUSDForAmount(diff);
    }

    //state
    const [groupByRouter, setGroupByRouter] = useState(() => MLUtils.defaultBoolean(props.groupByRouter, true));
    const [swapInfo, setSwapInfo] = useState(() => filterSwapInfo(groupByRouter));

    //effect
    useEffect(() =>
    {
        setGroupByRouter(MLUtils.defaultBoolean(props.groupByRouter, false));
    }, [props.groupByRouter]);
    useEffect(() =>
    {
        setSwapInfo(filterSwapInfo(groupByRouter));
    }, [groupByRouter]);
    useEventSubscription(ModuleEvents.swapInfo, () => setSwapInfo(filterSwapInfo(groupByRouter)));

    return (
        <Grid>
            {(!swapInfo || swapInfo.length === 0) &&
                <Card>
                    <CardHeader>
                        <Flex>
                            <Text color={0} size={-1}>
                                No swap found!
                            </Text>
                        </Flex>
                    </CardHeader>
                </Card>
            }

            {(!!swapInfo && swapInfo.length >= 1) &&
                <Card>
                    <CardHeader>
                        <Grid
                            className={styles.tradeInfo_header}
                            gap="0px"
                        >
                            <Flex gap="0px">
                                <Text size={-1}>
                                    Our swap fee would be ~{" "}
                                </Text>
                                <Text
                                    color={3}
                                    size={-1}
                                >
                                    {dApp.smartFormatFiat(getSwapFeeInUSD())},
                                </Text>
                            </Flex>
                            <Flex gap="0px">
                                <Text size={-1}>
                                    but we would save you ~{" "}
                                </Text>
                                <Text
                                    color={3}
                                    size={-1}
                                >
                                    {dApp.smartFormatFiat(getSavedUSD(true))}
                                    {" "}-{" "}
                                    {dApp.smartFormatFiat(getSavedUSD(false))}
                                </Text>
                            </Flex>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                    <Grid>
                        {swapInfo.map(i =>
                            <MoonSwapPathInfo
                                key={`item_${swapInfo.indexOf(i)}`}
                                item={i}
                            />
                        )}
                    </Grid>
                    </CardBody>
                </Card>
            }
        </Grid>
    );
};

export default MoonSwapTradeInfo;