import { useState, useEffect } from "react";
import ReactModal from "react-modal";

//hooks
import { useMediaInfo, useTheme } from "../../../hooks";
import { MLUtils } from "../../../utils";

//styles
import styles from "./Modals.module.css";

const Modal = (props) =>
{
    //context
    const curMediaInfo = useMediaInfo();
	const theme = useTheme();

    //style props
    const [big, setBig] = useState(() => curMediaInfo.matches.sm);

    //media query
    useEffect(() =>
    {
    	setBig(curMediaInfo.matches.sm);
    }, [JSON.stringify(curMediaInfo)]);

    return (
        <ReactModal
            isOpen={props.show}
            contentLabel={(props.label === undefined ? "Modal" : props.label)}
            className=
            {
                [
					theme?.getClassName("Modal") ?? "",
                    styles.modal,
                    (big && MLUtils.defaultBoolean(props.allowBig, false)
						? styles.modal_big
						: styles.modal_small
					),
                    styles.modalStyled,
                    props.className
                ].join(" ")
            }
            overlayClassName=
			{
				[
					theme?.getClassName("ModalOverlay") ?? "",
					styles.modalOverlay,
					props.classNameOverlay
				].join(" ")
			}
			ariaHideApp={false}
        >
            {(props.raw
                ? props.children
                : (
                    <div className={styles.modalContainer}>
                        <div className={styles.header}>
                            {props.header}
                            {props.onClose &&
                                <button
                                    className={styles.closeButton}
                                    onClick={() => props.onClose()}
                                >
                                    X
                                </button>
                            }
                        </div>
                        <div className={styles.content}>
                            {props.children}
                        </div>
                        {!!props.footer &&
                            <div className={styles.footer}>
                                {props.footer}
                            </div>
                        }
                    </div>
                )
            )}
        </ReactModal>
    )
};

export default Modal;