import { useState, useEffect } from "react";

//styles
import styles from "./Containers.module.css";

const TemplateGridArea = (props) =>
{
    //style props
    const [area, setArea] = useState(() => "");

    //media query
    useEffect(() =>
    {
        setArea(props.area);
    }, [props.area]);

    return (
        <div
            className={styles.templateGridArea}
            style=
            {
                {
                    gridArea: area
                }
            }
        >
            {props.children}
        </div>
    );
};

export default TemplateGridArea;