import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";

//framework
import MLFormat from "./MLFormat";
import MLUtils from "./MLUtils";
import
{
    wallet_metaMask,
    wallet_walletConnect,
    wallet_trustWallet,
    wallet_binanceWallet
} from "../symbols";
import {DApp} from "../core/DApp";

const MLWeb3 =
{
    /////////////////////////
    // Config
    /////////////////////////

    config:
    {
        wallets:
        [
            {
                name: "MetaMask",
                providerID: "MetaMask",
                icon: wallet_metaMask(),
                detect: () => (window.ethereum?.isMetaMask || false)
            },
            {
                name: "WalletConnect",
                providerID: "WalletConnect",
                icon: wallet_walletConnect()
            },
            {
                name: "Trust Wallet",
                providerID: "MetaMask",
                icon: wallet_trustWallet(),
                detect: () => (window.ethereum?.isTrust || false)
            },
            {
                name: "Binance Wallet",
                providerID: "MetaMask",
                icon: wallet_binanceWallet(),
                detect: () => (window.BinanceChain || false),
                supportedChains:
                [
                    56,
                    97
                ]
            }
        ]
    },

    /////////////////////////
    // Data
    /////////////////////////

    data:
    {
        web3:
        {
            connections: []
        }
    },

    /////////////////////////
    // General
    /////////////////////////

    getZeroAddress()
    {
        return "0x0000000000000000000000000000000000000000";
    },

    isZeroAddress(_address)
    {
        return (!_address
            || _address === "0x0"
            || _address === this.getZeroAddress());
    },

	getDeadAddress()
    {
        return "0x000000000000000000000000000000000000dead";
    },

    isDeadAddress(_address)
    {
        return (!_address
            || _address.toLowerCase() === "0xdead"
            || this.checkEqualAddress(_address, this.getDeadAddress()));
    },

    /////////////////////////
    // Convert
    /////////////////////////

    convertBN_FloatString(_bn, _decimals, _shorten = false)
    {
        let bnStr = _bn.toString();

        //enough 0
        if (bnStr.length < _decimals + 1)
        {
            bnStr = bnStr.padStart(_decimals + 1, "0");
        }

        //insert "."
        const dotPos = bnStr.length - _decimals;
        let floatStr = [bnStr.slice(0, dotPos), ".", bnStr.slice(dotPos)].join("");
        if (_shorten)
        {
            floatStr = MLUtils.removeTrailEnd(floatStr, "0");
        }
        floatStr = MLUtils.removeTrailEnd(floatStr, ".");

        return floatStr;
    },

    convertBN_Float(_bn, _decimals)
    {
        return parseFloat(this.convertBN_FloatString(_bn, _decimals));
    },

    convertTokenBN_Float(_bn, _token)
    {
        return this.convertBN_Float(_bn, _token.decimals);
    },

    convertTokenBN_FloatString(_bn, _token, _shorten = false)
    {
        return this.convertBN_FloatString(_bn, _token.decimals, _shorten);
    },

    convertNFTBN_Float(_bn, _nft, _nftID)
    {
        return this.convertBN_Float(_bn, _nft.findItem(_nftID)?.data?.decimals || 0);
    },

    convertNFTBN_FloatString(_bn, _nft, _nftID, _shorten = false)
    {
        return this.convertBN_FloatString(_bn, _nft.findItem(_nftID)?.data?.decimals || 0, _shorten);
    },

    convertFloat_BN(_float, _decimals)
    {
        return this.toBN(
            MLFormat.formatFloat(
                _float,
                _decimals,
                false,
				"").replace(".", ""));
    },

    convertFloat_TokenBN(_float, _token)
    {
        return this.convertFloat_BN(_float, _token.decimals);
    },

    convertFloatString_BN(_floatString, _decimals)
    {
        return this.toBN(
            MLFormat.formatFloatString(
                _floatString,
                _decimals,
                false,
				"").replace(".", ""));
    },

    convertFloatString_TokenBN(_floatString, _token)
    {
        return this.convertFloatString_BN(_floatString, _token.decimals);
    },

    convertFloatString_NFTBN(_floatString, _nft, _nftID)
    {
        return this.convertFloatString_BN(_floatString, _nft.findItem(_nftID)?.data?.decimals || 0);
    },

    /////////////////////////
    // compare
    /////////////////////////

    compareAddress(_a, _b)
	{
		_a = (typeof(_a) !== "string" ? "" : _a.toLowerCase());
		_b = (typeof(_b) !== "string" ? "" : _b.toLowerCase());
		if (_a === ""
			&& _b === "")
		{
			return -1;
		}

		if (_a > _b)
		{
			return 1;
		}
		else if (_a < _b)
		{
			return -1;
		}

		return 0;
	},

	checkEqualAddress(_a, _b)
	{
		return (this.compareAddress(_a, _b) === 0);
	},

    /////////////////////////
    // Helpers
    /////////////////////////

	getPercent(_value, _max, _precision = 4)
	{
		return MLWeb3.convertBN_Float(
			_value
				.mul(MLWeb3.toBN(Math.pow(10, _precision + 2)))
				.div(_max),
			_precision + 2
		);
	},

	getBNShare(_value, _share, _total)
	{
		return _value.mul(_share).div(_total);
	},

	getBNPercentShare(_value, _percent)
	{
		return this.getBNShare(
			_value,
			this.toBN(parseInt(_percent * 1000000)),
			1000000
		);
	},

    toBN(_value)
    {
        return Web3.utils.toBN(_value);
    },

    encodeABIParameter(_web3, _type, _value)
    {
        _web3.eth.abi.encodeParameter(_type, _value);
    },

    getBNMax(_bit = 256)
    {
        const bytes = parseInt(_bit / 16);
        let hex = "";
        for (let n = 0; n < bytes; n++)
        {
            hex += "FF";
        }
        return this.toBN(`0x${hex}`);
    },

    /////////////////////////
    // Web3 Call/Send/ScanEvent
    /////////////////////////

    async tryCall(_callPromise, _errorMsg, _default)
	{
		if (_callPromise === undefined
			&& _errorMsg !== undefined)
		{
			console.log(_errorMsg);
		}

		return await _callPromise.call().catch((e) =>
		{
			if (_errorMsg !== undefined)
			{
				console.log(_errorMsg);
			}
			if (_default !== undefined)
			{
				return _default;
			}
			throw e;
		})
	},

	async trySend(_callPromise, _from, _errorMsg, _default, _onTransactionHash, _onReceipt, _onConfirmation, _onError, _gasPrice = undefined, _coinValue = undefined)
	{
		if (_callPromise === undefined
			&& _errorMsg !== undefined)
		{
			console.log(_errorMsg);
		}

		const data =
		{
			from: _from,
			...(_coinValue !== undefined && { value: _coinValue }),		//payable
			...(_gasPrice !== undefined && { gasPrice: _gasPrice })		//gas
		};

		return await _callPromise.send(data)
			.once('transactionHash', (txHash) => { if (_onTransactionHash) _onTransactionHash(txHash) })
			.once('receipt', (receipt) => { if (_onReceipt) _onReceipt(receipt) })
			.once('confirmation', (confNumber, receipt) => { if (_onConfirmation) _onConfirmation(confNumber, receipt) })
			.on('error', (error) => { if (_onError) _onError(error) })
			.catch((e) =>
		{
			if (_errorMsg !== undefined)
			{
				console.error(_errorMsg);
			}
			if (_default !== undefined)
			{
				return _default;
			}
			throw e;
		});
	},

	async scanEvent(_contract, _name, _fromBlock, _toBlock)
	{
		//defaulting
		if (_fromBlock === undefined)
		{
			_fromBlock = window.chef.currentBlock - 5000;
		}
		if (_toBlock === undefined)
		{
			_toBlock = "latest";
		}

		//get logs
		let result = null;
		let options =
		{
			fromBlock: _fromBlock,
			toBlock: _toBlock
		};
		try
		{
			result = await _contract.getPastEvents(_name, options);
		}
        catch (e) { console.error(e); }

		return result;
	},

	/////////////////////////
    // Web3
    /////////////////////////

	getWeb3ErrorObject(_error)
	{
		const eObjStr = _error.message.substring(_error.message.indexOf("{"));
		try
		{
			const eObj = JSON.parse(eObjStr);
			return eObj;
		}
		catch (e)
		{
			console.error(e);
			return null;
		}
	},

    findConnection(_id)
    {
        return this.data.web3.connections.find((c) => c.id === _id) || null;
    },

    createConnection(_id)
    {
		let con = this.findConnection(_id);
        if (con === null)
        {
            con =
			{
				id: _id,
				web3: null,
				rpc: "",
				chain: -1
			};
			this.data.web3.connections.push(con);
		}
        return con;
    },

    async createAndConnect(_id, _chainID)
    {
        this.createConnection(_id);
        return await this.connect(_id, _chainID);
    },

    findConnection(_id)
    {
        return this.data.web3.connections.find(c => c.id === _id) || null;
    },

    findRPCs(_chainID)
    {
        const c = this.findChainConfig(_chainID);
        return (c ? c.nodes : []);
    },

    async closeProvider()
    {
        if (Web3.currentProvider?.close)
		{
			await Web3.currentProvider.close();
		}
    },

    async selectProvider(_providerName)
    {
        //select provider
		if (_providerName === "MetaMask")
		{
			Web3.currentProvider = new Web3(window.ethereum);
		}
		else if (_providerName === "WalletConnect")
		{
			const rpc = {};
			DApp.instance.config.web3.chains.forEach(c => rpc[c.id] = c.nodes[0]);
			Web3.currentProvider = new WalletConnectProvider({ rpc: rpc });
			await Web3.currentProvider.enable();
		}

		//reconnect all wallet based connections
        for (let n = 0; n < this.data.web3.connections.length; n++)
        {
            const c = this.data.web3.connections[n];
            if (c.rpc === "")
            {
                await this.connect(c.id);
            }
        };
    },

	async connect(_id, _chainID)
	{
		if (_chainID === undefined)
		{
			_chainID = -1;
		}

		const c = this.findConnection(_id);
		if (c === null)
		{
			return null;
		}

		//make new web3
		if (_chainID === -1)
		{
			if (Web3.currentProvider
				|| Web3.givenProvider)
			{
				c.web3 = new Web3(Web3.currentProvider || Web3.givenProvider);
				c.chain = await c.web3.eth.net.getId();
			}
		}
		else
		{
			const nodes = this.findRPCs(_chainID);
			c.rpc = (nodes.length === 0 ? null : nodes[0]);
			c.web3 = new Web3(new Web3.providers.HttpProvider(c.rpc));
			c.chain = await c.web3.eth.net.getId();
		}

		return c.web3;
	},

    findChainConfig(_chainID)
    {
        return DApp.instance.config.web3.chains.find((c) => c.id === _chainID) || null;
    },

    async switchChain(_chainID)
    {
        const c = this.findChainConfig(_chainID);
        if (c === null)
        {
            return;
        }

        //switch / suggest
        if (c.onlySwitchDontAdd)
        {
            await ethereum.request(
            {
                method: 'wallet_switchEthereumChain',
                params:
                [
                    {
                        chainId: "0x".concat(parseInt(c.id, 10).toString(16))
                    }
                ],
            });
        }
        else
        {
            await window.ethereum.request(
            {
                method: "wallet_addEthereumChain",
                params:
                [
                    {
                        chainId: "0x".concat(parseInt(c.id, 10).toString(16)),
                        chainName: c.networkName,
                        nativeCurrency: c.currency,
                        rpcUrls: c.nodes,
                        blockExplorerUrls: [c.blockExplorerUrl]
                    }
                ]
            });
        }
    }
};

export default MLWeb3;