import { useState } from "react";

//components
import { Button } from "../../";
import ModalSelectChain from "../Modals/ModalSelectChain";

//framework
import t from "../../../utils/MLTranslate";
import { MLUI, MLWeb3 } from "../../../utils";
import { useWeb3Connection } from "../../../hooks/useWeb3Connection";
import Events from "../../../core/Events";

//hooks
import { useEventSubscription } from "../../../hooks";

//styles
import styles from "./Buttons.module.css";

const SelectChainButton = () =>
{
    //context
    const web3 = useWeb3Connection();

    //state
    const [show, setShow] = useState(() => false);
    const [connectedChain, setConnectedChain] = useState(() => web3.chainID);

    //effect
    useEventSubscription(Events.web3.update, () => setConnectedChain(web3.chainID));

	const selChain = MLWeb3.findChainConfig(connectedChain);
    return (
        <>
            <Button
                className={styles.selectChain_button}
                onClick={() => setShow(true)}
            >
                {(connectedChain === -1 || !selChain?.networkIcon)
                    ? "Select Network"
                    : MLUI.imageOrSymbol(MLWeb3.findChainConfig(connectedChain).networkIcon, styles.selectChain_icon)
                }
            </Button>
            <ModalSelectChain
                show={show}
                onClose={() => setShow(false)}
            />
        </>
    );
};

export default SelectChainButton;