//components
import { Tooltip } from "../../";

//framework
import { MLUI } from "../../../utils";
import
{
    question
} from "../../../symbols";

//styles
import styles from "./Buttons.module.css";

const ButtonGuide = (props) =>
{
    return (
        <Tooltip
            content={props.children}
        >
            {MLUI.imageOrSymbol(
				question(),
				styles.guideButton,
				props.onClick
			)}
        </Tooltip>
    );
};

export default ButtonGuide;