import { useState, useRef } from "react";

//styles
import styles from "./Inputs.module.css";

const CheckBox = (props) =>
{
    //state
    const inputRef = useRef();
    const [checked, setChecked] = useState(() => props.checked);

    //handler
    const handleChange = (e) =>
    {
        setChecked(inputRef.current.checked);
        if (props.onChange)
        {
            props.onChange(e);
        }
    };

    return (
        <input
            id={props.id}
            ref={inputRef}
            name={props.name}
            className={[styles.checkBox, props.className].join(" ")}
            type="checkbox"
            checked={checked}
            onChange={(e) => handleChange(e)}
		/>
    );
};

export default CheckBox;