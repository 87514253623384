

export const Chart_testData_Pie =
[
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
    { name: "Group E", value: 200 },
    { name: "Group F", value: 100 },
    { name: "Group G", value: 200 },
    { name: "Group H", value: 200 },
    { name: "Group I", value: 300 },
];

export const Chart_testDataKeys_Area =
[
    "uv",
    "pv",
    "amt"
];

export const Chart_testGradients_Area =
[
    {
        id: "uv",
        stops:
        [
            { offset: 0.1, color: "#6ac286", opacity: 0.1 },
            { offset: 0.95, color: "#0b172e", opacity: 0.1 }
        ]
    },
    {
        id: "pv",
        stops:
        [
            { offset: 0.1, color: "#e75145", opacity: 0.1 },
            { offset: 1, color: "#0b172e", opacity: 0.1 }
        ]
    },
    {
        id: "amt",
        stops:
        [
            { offset: 0.1, color: "#ffffff", opacity: 0.1 },
            { offset: 1, color: "#0b172e", opacity: 0.1 }
        ]
    }
];

export const Chart_testData_Area =
[
    {
        x: "Page A",
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        x: "Page B",
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        x: "Page C",
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        x: "Page D",
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        x: "Page E",
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        x: "Page F",
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        x: "Page G",
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

export const Chart_testDataKeys_Line = Chart_testDataKeys_Area;

export const Chart_testData_Line = Chart_testData_Area;