import { useEffect, useState } from "react";
import { LineChart as RechartLineChart, Line, YAxis, CartesianGrid, Tooltip } from "recharts";

//hooks
import { useMediaInfo } from "../../../hooks";

//framework
import { MLUI, MLUtils } from "../../../utils";

//misc
import CustomTooltip from "./CustomTooltip";
import { Chart_defaultColors } from "./config";
import { generateXAxis, fillData } from "./utils";
import { Chart_testData_Line, Chart_testDataKeys_Line } from "./tests";

const LineChart = (props) =>
{
    //context
    const curMediaInfo = useMediaInfo();

    //select prop
    const responsiveProps = props.responsive;
    const selectProp = (_propID) =>
    {
        return MLUI.selectPropertyFromMediaQueryList(
            curMediaInfo.types,
            curMediaInfo.matches,
            responsiveProps,
            _propID
        );
    };

    //default style props & cur values
    const cur_width = props.width || 300;
    const cur_height = props.height || 300;
    const cur_showTooltip = MLUtils.defaultBoolean(props.showTooltip, true);
    const cur_gridType = props.gridType || 0;
    const cur_axisType = (props.axisType !== undefined ? props.axisType : "number");
    const cur_showAxis = MLUtils.defaultBoolean(props.showAxis, true);
    const cur_colors = props.colors || Chart_defaultColors;
    const cur_data = props.data || Chart_testData_Line;
    const cur_dataKeys = props.dataKeys || Chart_testDataKeys_Line;
    const cur_stroke = props.stroke || 2;
    const cur_dot = MLUtils.defaultBoolean(props.dot, true);

    //style props
    const [width, setWidth] = useState(() => cur_width);
    const [height, setHeight] = useState(() => cur_height);
    const [showTooltip, setShowTooltip] = useState(() => cur_showTooltip);
    const [colors, setColors] = useState(() => cur_colors);
    const [data, setData] = useState(() => fillData(cur_axisType, cur_data));
    const [dataKeys, setDataKeys] = useState(() => cur_dataKeys);
    const [axisType, setAxisType] = useState(() => cur_axisType);
    const [showAxis, setShowAxis] = useState(() => cur_showAxis);
    const [stroke, setStroke] = useState(() => cur_stroke);
    const [dot, setDot] = useState(() => cur_dot);
    const [domain, setDomain] = useState(() => props.domain);
    const [domainPerKey, setDomainPerKey] = useState(() => props.domainPerKey);

    //media query
    useEffect(() =>
    {
        setWidth(selectProp("width") || cur_width);
        setHeight(selectProp("height") || cur_height);
    }, [JSON.stringify(curMediaInfo)]);

    //color & data
    useEffect(() =>
    {
        setColors(cur_colors);
    }, [JSON.stringify(cur_colors)]);
    useEffect(() =>
    {
        setData(fillData(cur_axisType, cur_data));
        setAxisType(cur_axisType);
    }, [JSON.stringify(cur_data), JSON.stringify(cur_axisType)]);
    useEffect(() =>
    {
        setDataKeys(cur_dataKeys);
    }, [JSON.stringify(cur_dataKeys)]);
    useEffect(() =>
    {
        setDomain(props.domain);
    }, [props.domain]);
    useEffect(() =>
    {
        setDomain(props.domain);
    }, [props.domain]);
    useEffect(() =>
    {
        setDomainPerKey(props.domainPerKey);
    }, [props.domainPerKey]);

    return (
        <RechartLineChart
        id="test"
            width={width}
            height={height}
            data={data}
        >
            {cur_gridType >= 0 &&
                (cur_gridType === 0
                    ? <CartesianGrid />
                    : <CartesianGrid strokeDasharray={cur_gridType} />
                )
            }
            {showTooltip && <Tooltip />}
            {showAxis &&
                <>
                    {generateXAxis(axisType)}
                </>
            }
            {(domainPerKey && domainPerKey.length > 0) &&
                <>
                    {domainPerKey.map(d =>
                        <YAxis
                            key={d.key}
                            dataKey={d.key}
                            domain={d.domain}
                            hide={!showAxis}
                            allowDataOverflow
                        />
                    )}
                </>
            }
            {!domainPerKey &&
                <YAxis domain={domain} hide={!showAxis} />
            }

            {dataKeys.map((key, index) => (
                <Line
                    key={`line-${index}`}
                    dataKey={key}
                    type="monotone"
                    strokeWidth={stroke}
                    stroke={colors[index % colors.length]}
                    dot={dot}
                />
            ))}
        </RechartLineChart>
    );
};
export default LineChart;