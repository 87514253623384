import { useState, useEffect } from "react";

//components
import
{
    Text,
    Grid,
    Flex,
    TokenIcon,
    NFTIcon
} from "../../";
import ModalSelectTokenOrNFT from "../Modals/ModalSelectTokenOrNFT";

//framework
import t from "../../../utils/MLTranslate";

//hooks
import { useDApp } from "../../../hooks";

//styles
import styles from "./Inputs.module.css";
import { MLUtils } from "../../../utils";

const SelectTokenOrNFT = (props) =>
{
    //hooks
    const dApp = useDApp();

    //state
    const [value, setValue] = useState(() => props.value || null);
    const [readOnly] = useState(MLUtils.defaultBoolean(props.readOnly, false));
    const [show, setShow] = useState(() => false);

    //handler
    const handleOpenSelect = () =>
    {
		if (readOnly)
		{
			return;
		}
        setShow(true);
    };
    const handleSelect = (_value) =>
    {
        setShow(false)
        if (props.onSelect)
        {
            props.onSelect(_value);
        }
        else
        {
            setValue(_value);
        }
    };

    //functions
    const getTypeName = () =>
    {
        switch (value.type)
        {
            case "Coin":
                return "Coin";

            case "ERC20":
                return "Token [ERC20]";

            case "ERC721":
                return "NFT [ERC721]";

            case "ERC1155":
                return "NFT [ERC1155]";
        }
        return "";
    };
    const getSymbol = () =>
    {
        const empty = "???";
        switch (value.type)
        {
            case "Coin":
                return dApp.coinSymbol;

            case "ERC20":
                return dApp.findToken(value.contract).symbol || empty;

            case "ERC721":
            case "ERC1155":
                return `${dApp.findNFT(value.contract).symbol || empty}: #${value.nftID}`;
        }
        return empty;
    };

    //effects
    useEffect(() =>
    {
        setValue(props.value || (props.onSelect ? null : value));
    }, [JSON.stringify(props.value)]);

    return (
        <>
            <ModalSelectTokenOrNFT
                show={show}
                onClose={() => setShowSelect(false)}
                onSelect={(v) => handleSelect(v)}
            />
            <div
                onClick={() => handleOpenSelect()}
            >
                <Flex
                    className=
                    {
                        [
                            styles.selectTokenOrNFT,
                            (readOnly ? "" : styles.selectTokenOrNFT_nonReadOnly)
                        ].join(" ")
                    }
                >
                    {value?.type === "Coin" &&
                        <TokenIcon
                            className={styles.selectTokenOrNFT_icon}
                            token={dApp.wrappedCoin}
                        />
                    }
                    {value?.type === "ERC20" &&
                        <TokenIcon
                            className={styles.selectTokenOrNFT_icon}
                            token={dApp.findToken(value.contract)}
                        />
                    }
                    {(value?.type === "ERC721" || value?.type === "ERC1155") &&
                        <NFTIcon
                            className={styles.selectTokenOrNFT_icon}
                            nft={dApp.findNFT(value.contract)}
                            nftID={value.nftID}
                        />
                    }

                    {value === null &&
                        <Text
							color={1}
							weight={0}
							size={0}
							lineHeight={1}
						>
                            Select Token or NFT
                        </Text>
                    }
                    {value !== null &&
                        <Grid
                            gap="0"
                        >
                            <Text
								color={1}
								weight={0}
								size={0}
								lineHeight={1}
                            >
                                {getSymbol()}
                            </Text>
                            <Text
								color={2}
								weight={0}
								size={-1}
								lineHeight={1}
                            >
                                {getTypeName()}
                            </Text>
                        </Grid>
                    }
                </Flex>
            </div>
        </>
    );
};

export default SelectTokenOrNFT;