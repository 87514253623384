import { configUI as config } from "@MoonLabsDev/dapp-core-lib";

//template
import Template from "../../templates/MoonLabs";

import NavLink from "../../framework/components/base/Link/NavLink";

const configUI =
{
	...config,

	navLinkComponent: NavLink,

    template: Template,
};

export default configUI;