import { useEffect, useState } from "react";
import { PieChart as RechartPieChart, Pie, Cell, Tooltip } from "recharts";

//hooks
import { useMediaInfo } from "../../../hooks";

//framework
import { MLUI } from "../../../utils";

//misc
import CustomTooltip from "./CustomTooltip";
import { Chart_defaultColors } from "./config";
import { Chart_testData_Pie } from "./tests";

const PieChart = (props) =>
{
    //context
    const curMediaInfo = useMediaInfo();

    //select prop
    const responsiveProps = props.responsive;
    const selectProp = (_propID) =>
    {
        return MLUI.selectPropertyFromMediaQueryList(
            curMediaInfo.types,
            curMediaInfo.matches,
            responsiveProps,
            _propID
        );
    };

    //default style props & cur values
    const cur_width = props.width || 300;
    const cur_height = props.height || 300;
    const cur_showTooltip = (props.showTooltip !== undefined ? props.showTooltip : true);
    const cur_innerRadius = props.innerRadius || 0;
    const cur_outerRadius = props.outerRadius || 80;
    const cur_colors = props.colors || Chart_defaultColors;
    const cur_data = props.data || Chart_testData_Pie;

    //style props
    const [width, setWidth] = useState(() => cur_width);
    const [height, setHeight] = useState(() => cur_height);
    const [showTooltip, setShowTooltip] = useState(() => cur_showTooltip);
    const [innerRadius, setInnerRadius] = useState(() => cur_innerRadius);
    const [outerRadius, setOuterRadius] = useState(() => cur_outerRadius);
    const [colors, setColors] = useState(() => cur_colors);
    const [data, setData] = useState(() => cur_data);

    //media query
    useEffect(() =>
    {
        setWidth(selectProp("width") || cur_width);
        setHeight(selectProp("height") || cur_height);
        setInnerRadius(selectProp("innerRadius") || cur_innerRadius);
        setOuterRadius(selectProp("outerRadius") || cur_outerRadius);
    }, [JSON.stringify(curMediaInfo)]);

    //color & data
    useEffect(() =>
    {
        setColors(cur_colors);
    }, [JSON.stringify(cur_colors)]);
    useEffect(() =>
    {
        setData(cur_data);
    }, [JSON.stringify(cur_data)]);

    return (
        <RechartPieChart
            width={width}
            height={height}
        >
            {showTooltip && <Tooltip />}
            <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                fill="#000000"
                dataKey="value"
            >
                {data.map((value, index) => (
                    <Cell
                        key={`cell-${index}`}
                        fill={colors[index % colors.length]}
                    />
                ))}
            </Pie>
        </RechartPieChart>
    );
};
export default PieChart;