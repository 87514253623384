//framework
import { Image } from "../components";
import {DApp} from "../core/DApp";

const MLUI =
{
    /////////////////////////
    // Config
    /////////////////////////

    config:
    {
        mediaQuery:
        {
            order:
            [
                "xs",
                "sm",
                "md",
                "lg",
                "xl",
                "xxl"
            ]
        }
    },

    /////////////////////////
    // Sound
    /////////////////////////

    tryPlaySound(_sound)
    {
        try
        {
            (new Audio(notification)).play();
        }
        catch (e) { }
    },

    /////////////////////////
    // image / symbol wrapper
    /////////////////////////

    imageOrSymbol(_imgOrSym, _className = "", _onClick = undefined)
    {
        if (_imgOrSym === undefined)
        {
            return <></>;
        }
        else if (typeof _imgOrSym === "string")
        {
            return (
                <Image
                    src={_imgOrSym}
                    className={_className}
                    onClick={_onClick}
                />
            );
        }
        else
        {
            return (
                <div
                    className={_className}
                    onClick={_onClick}
                >
                    {_imgOrSym}
                </div>
            );
        }
    },

    /////////////////////////
    // check device capabilities
    /////////////////////////

    isTouchDevice()
    {
        if (typeof window === "undefined")
        {
            return false;
        }
        return ("ontouchstart" in window
            || navigator.maxTouchPoints > 0
            || navigator.msMaxTouchPoints > 0);
    },

	/////////////////////////
    // class by id
    /////////////////////////

	findClassByID(_list, _id)
	{
		return _list.find(i => i.id === _id)?.class || "";
	},

    /////////////////////////
    // mediaQuery
    /////////////////////////

    getMediaQueryList()
    {
        return this.config.mediaQuery.order;
    },

    getCurrentMediaQuery()
    {
        const cfg = this.config.mediaQuery.order;
        const width = (window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth);

        //get matching size
        let mqIdx = 0;
        for (let n = 0; n < cfg.length; n++)
        {
            if (width < DApp.instance.config.ui.mediaQuery[cfg[n]])
            {
                break;
            }
            mqIdx = n;
        }

        return DApp.instance.config.ui.mediaQuery[mqIdx];
    },

    useMediaQuery(_type)
    {
        //get requested index
        const current = this.getCurrentMediaQuery();
        const currentIdx = DApp.instance.config.ui.mediaQuery.indexOf(current);
        const requestedIdx = DApp.instance.config.ui.mediaQuery.indexOf(_type);
        return (currentIdx >= requestedIdx);
    },

    /////////////////////////
    // selectPropertyFromMediaQuery
    /////////////////////////

    checkMediaQueryAbove(_mediaMatches, _minimum)
    {
        return !!_mediaMatches[_minimum];
    },

    selectPropertyFromMediaQueryList(_mediaTypes, _mediaMatches, _resposiveProps, _propID)
    {
        let prop = undefined;
        for (let n = 0; n < _mediaTypes.length; n++)
        {
            const rPropName = _mediaTypes[n];
            if (!_mediaMatches[rPropName])
            {
                break;
            }

            const rProp = (_resposiveProps ? _resposiveProps[rPropName] : undefined);
            const rSelProp = (rProp ? rProp[_propID] : undefined);
            prop = rSelProp || prop;
        }
        return prop;
    },

    mergePropertiesFromMediaQueryList(_mediaTypes, _mediaMatches, _resposiveProps)
    {
        let prop = {};
        for (let n = 0; n < _mediaTypes.length; n++)
        {
            const rPropName = _mediaTypes[n];
            if (!_mediaMatches[rPropName])
            {
                break;
            }

            if (_resposiveProps[rPropName] !== undefined)
            {
                //merge
                prop =
                {
                    ...prop,
                    ..._resposiveProps[rPropName]
                };
            }
        }
        return prop;
    },
};

export default MLUI;