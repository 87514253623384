import { useState, useEffect } from "react";

//components
import
{
    Button,
    Flex,
    Grid,
    Text,
    ValueTokenFiatAmount
} from "@MoonLabsDev/dapp-core-lib";
import EscrowOffer from "./EscrowOffer";
import ModalEscrowEditItem from "../Modals/ModalEscrowEditItem";

//framework
import { MLFormat, MLWeb3 } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useEscrow } from "../../../../modules";
import { ModuleEvents } from "../../../..//modules/Module_Escrow";

//classes
import { TradeStatus } from "../../../../../dApp/classes/Escrow";

//hooks
import { useDApp, useEventSubscription, } from "@MoonLabsDev/dapp-core-lib";


const EscrowTrade = (props) =>
{
    //hooks
    const dApp = useDApp();
    const escrow = useEscrow();

    //vars
    const propsTrade = escrow.findTrade(props.tradeID);

    //state
    const [tradeID, setTradeID] = useState(() => escrow.lazyLoadTrade(props.tradeID));
    const [editModalInfo, setEditModalInfo] = useState(() => null);
    const [feeInETH, setFeeInETH] = useState(() => escrow.feeInEth);
    const [trade, setTrade] = useState(() => propsTrade);
    const [tradeStatus, setTradeStatus] = useState(() => propsTrade?.status);
    const [isUserTrade, setIsUserTrade] = useState(() => escrow.checkIsUserTrade(propsTrade));
    const [offer2IsUser, setOffer2IsUser] = useState(() => MLWeb3.checkEqualAddress(propsTrade?.offer2?.owner, dApp.account));

    //functions
    const openEditModal = (_item, _saveCallback, _deleteCallback) =>
    {
        setEditModalInfo(
        {
            item: (!_item
                ? null
                : {..._item}),
            saveCallback: _saveCallback,
            deleteCallback: _deleteCallback
        });
    };

    //handler
    const handleEditModalClosed = () =>
    {
        setEditModalInfo(null);
    };
    const handleEditModalSave = (_item) =>
    {
        if (!!editModalInfo.saveCallback)
        {
            editModalInfo.saveCallback(_item);
        }
        handleEditModalClosed();
    };
    const handleEditModalDelete = (_item) =>
    {
        if (!!editModalInfo.deleteCallback)
        {
            editModalInfo.deleteCallback(_item);
        }
        handleEditModalClosed();
    };
    const handleTrade = () =>
    {
        if (trade?.status === TradeStatus.ONGOING)
        {
            escrow.executeTrade(
				tradeID,
				dApp.currentChain.referralID || 0
			)?.trySend();
        }
    };
    const handleEscrowUpdate = () =>
    {
        setFeeInETH(escrow.feeInEth);
    };
    const handleTradeUpdate = () =>
    {
        setTradeStatus(trade?.status);
        setIsUserTrade(escrow.checkIsUserTrade(trade));
        setOffer2IsUser(MLWeb3.checkEqualAddress(trade?.offer2?.owner, dApp.account));
    };

    //effect
    useEffect(() =>
    {
        setTradeID(escrow.lazyLoadTrade(props.tradeID));

        const t = escrow.findTrade(props.tradeID);
        setTrade(t);
        setTradeStatus(t?.status);
        setIsUserTrade(escrow.checkIsUserTrade(t));
        setOffer2IsUser(MLWeb3.checkEqualAddress(t?.offer2?.owner, dApp.account));
    }, [props.tradeID]);
    useEventSubscription(ModuleEvents.data, handleEscrowUpdate);
    useEventSubscription(ModuleEvents.tradeInfo, handleTradeUpdate, [trade?.status]);

    return (
        <>
            <Grid>
                <Flex>
                    <Text size={1}>
                        Trade #{tradeID}
                    </Text>
                </Flex>
                <Flex style={{ minWidth: "300px" }}>
                    <ModalEscrowEditItem
                        item={editModalInfo?.item}
                        show={editModalInfo !== null}
                        editMode={!!editModalInfo?.item}
                        onClose={() => handleEditModalClosed()}
                        onSave={(i) => handleEditModalSave(i)}
                        onDelete={(i) => handleEditModalDelete(i)}
                    />

                    <EscrowOffer
                        tradeID={tradeID}
                        offerID={(offer2IsUser ? 2 : 1)}
                        isUserTrade={isUserTrade}
                        tradeStatus={tradeStatus}
                        openEditModal={openEditModal}
                    />
                    <EscrowOffer
                        tradeID={tradeID}
                        offerID={(offer2IsUser ? 1 : 2)}
                        isUserTrade={isUserTrade}
                        tradeStatus={tradeStatus}
                        openEditModal={openEditModal}
                    />
                </Flex>
                {(escrow.checkIsUserTrade(trade) && tradeStatus === TradeStatus.ONGOING) &&
                    <Button
                        onClick={() => handleTrade()}
                    >
                        Execute Trade (
                        <ValueTokenFiatAmount
                            token={null}
                            value={feeInETH}
                            smart={true}
                        />
                        {" "}in {dApp.coinSymbol})
                    </Button>
                }
                {(tradeStatus !== TradeStatus.ONGOING && !!trade?.updated) &&
                    <Button>
                        {trade?.status === TradeStatus.COMPLETE
                            ? "Trade completed"
                            : "Trade cancelled"
                        }
                        {" "}at {MLFormat.formatDateTime(trade.updated, false)}
                    </Button>
                }

                <Button onClick={() => props?.onBack()}>
                    Back to overview
                </Button>
            </Grid>
        </>
    );
};

export default EscrowTrade;