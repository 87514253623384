import { XAxis } from "recharts";
import { clamp } from "lodash";

export const fillData = (_axisType, _data) =>
{
    if (_axisType === undefined
        || _axisType === "number")
    {
        return _data;
    }
    
    return _data;
};

const generateTicks = (_axisType) =>
{
    const ticks = [];
    for (let n = _axisType.from; n <= _axisType.to; n += _axisType.precisionw)
    {
        ticks.push(n);        
    }
    if (ticks[ticks.length - 1] < _axisType.to)
    {
        ticks.push(_axisType.to);
    }
    return ticks;
};

export const generateXAxis = (_axisType) =>
{
    if (_axisType === undefined)
    {
        return <XAxis dataKey="x" />;
    }
    else if (_axisType === "number")
    {
        <XAxis dataKey="x"  type="number" />
    }
    else
    {
        const ticks = generateTicks(_axisType);
        return (
            <XAxis
                dataKey="x"
                hasTick
                scale="time"
                tickFormatter={_axisType.formatter}
                type="number"
                domain={[_axisType.from, _axisType.to]}
                ticks={ticks}
              />
        );
    }
};

export const getFillColor = (_key, _index, _gradients, _colors) =>
{
    const grad = _gradients.find((g) => g.id === _key);
    if (grad === undefined)
    {
        return _colors[_index % _colors.length];
    }

    return `url(#gradient_${_key})`;
};

export const generateChartGradients = (_gradients) =>
{
    if (_gradients === undefined
        || _gradients.length === 0)
    {
        return <></>;
    }

    return (
        <defs>
            {_gradients.map((g) => (
                <linearGradient
                    id={`gradient_${g.id}`}
                    key={`gradient_${g.id}`}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                >
                    {g.stops.map((s) => (
                        <stop
                            key={`stop_${g.stops.indexOf(s)}`}
                            offset={`${clamp(Math.round(s.offset) * 100, 0, 100)}%`}
                            stopColor={s.color}
                            stopOpacity={clamp(s.opacity, 0, 1)}/>
                    ))}
                </linearGradient>
            ))}        
        </defs>
    );
};