import { useState, useEffect, useRef } from "react";
import { usePopper } from "react-popper";

//framework
import { MLSymbols, MLUI } from "../../../utils";

//hooks
import { useEventSubscription } from "../../../hooks";

//styles
import styles from "./Inputs.module.css";

const findOption = (_options, _value) =>
{
    let ret = null;
    for (let n = 0; n < _options?.length; n++)
    {
        const o = _options[n];
        if (o.value === _value)
        {
            return o;
        }
        else if (o.children)
        {
            ret = findOption(o.children, _value);
            if (ret !== null)
            {
                return ret;
            }
        }
    }

    return null;
};

const Option = (props) =>
{
    //current values
    const cur_options = props.options || [];

    //state
    const selectOption = props.selectOption;
    const [value, setValue] = useState(() => props.value);
    const [text, setText] = useState(() => props.text);
    const [options, setOptions] = useState(() => cur_options);
    const [showSubMenu, setShowSubMenu] = useState(() => false);

    //popper
    const [popperOwnerRef, setPopperOwnerRef] = useState(null);
	const [popperRef, setPopperRef] = useState(null);
    const popperInfo = usePopper(popperOwnerRef, popperRef,
    {
        placement: "right-start"
    });

    //effects
    useEffect(() =>
    {
        setOptions(cur_options);
    }, [JSON.stringify(cur_options)]);
    const handleMouseEnter = () =>
    {
        setShowSubMenu(true);
    };
    const handleMouseLeave = () =>
    {
        setShowSubMenu(false);
    };
    const handleClickItem = () =>
    {
        selectOption(value);
    };

    return (
        <div ref={setPopperOwnerRef}>
            <div
                className={styles.selectBox_option}
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={() => handleMouseLeave()}
            >
                <a onClick={() => handleClickItem()}>
                    {(text === undefined ? value : text)}
                </a>
                {options && options.length > 0 && showSubMenu &&
                    <div
                        ref={setPopperRef}
                        className={styles.selectBox_subMenu}
                        style={popperInfo.styles.popper}
                        {...popperInfo.attributes.popper}
                    >
                        {options.map((o) => (
                                <Option
                                    selectOption={props.selectOption}
                                    key={`opt_${options.indexOf(o)}`}
                                    value={o.value}
                                    text={o.text}
                                    options={o.children}
                                />
                        ))}
                    </div>
                }
            </div>
        </div>
    );
};

const SelectBox = (props) =>
{
    //current values
    const cur_options = props.options || [];

    //state
    const [placeholder, setPlaceholder] = useState(() => props.placeholder);
    const [options, setOptions] = useState(() => cur_options);
    const [value, setValue] = useState(() => props.value);
    const [text, setText] = useState(() => findOption(cur_options, props.value)?.text);
    const [showSubMenu, setShowSubMenu] = useState(() => false);

    //popper
    const popperOwnerRef = useRef(null);
	const [popperRef, setPopperRef] = useState(null);
    const popperInfo = usePopper(popperOwnerRef.current, popperRef,
    {
        placement: "bottom-end"
    });

    //functions
    const selectOption = (_value) =>
    {
        const opt = findOption(options, _value);
        if (opt === null)
        {
            return;
        }

        setShowSubMenu(false);
        if (props.onSelect)
        {
            props.onSelect(opt.value);
        }
        else
        {
            setText(opt.text);
            setValue(opt.value);
        }
    };
    const onEmptyString = (_value, _default) =>
    {
        if (_value === undefined
            || _value === "")
        {
            return _default;
        }
        return _value;
    };

    //handlers
    const handleDocumentClick = (event) =>
    {
        if (popperOwnerRef.current.contains(event.target))
        {
            return;
        }
        setShowSubMenu(false);
    };
    const handleSelectBoxClick = () =>
    {
        setShowSubMenu(!showSubMenu);
    };

    //effects
    useEffect(() =>
    {
        setOptions(cur_options);
        setValue(props.value);
        setText(findOption(cur_options, props.value)?.text);
    }, [JSON.stringify(cur_options), props.value]);
    useEventSubscription("mousedown", handleDocumentClick);

    return (
        <div
            ref={popperOwnerRef}
            className={[styles.selectBox, (value === undefined ? styles.selectBoxPlaceholder : "")].join(" ")}
            onClick={() => handleSelectBoxClick()}
        >
            {(value === undefined ? onEmptyString(placeholder, <>&nbsp;</>) : onEmptyString(text, <>&nbsp;</>))}
            <button>
                {MLUI.imageOrSymbol(MLSymbols.arrowDownShort(), styles.selectBox_icon)}
            </button>
            {options && options.length > 0 && showSubMenu &&
                <div
                    ref={setPopperRef}
                    className={styles.selectBox_subMenu}
                    style={popperInfo.styles.popper}
                    {...popperInfo.attributes.popper}
                >
                    {
                        options.map((o) => (
                            <Option
                                selectOption={selectOption}
                                key={`opt_${options.indexOf(o)}`}
                                value={o.value}
                                text={o.text}
                                options={o.children}
                            />
                    ))}
                </div>
            }
        </div>
    );
};

export default SelectBox;