//components
import
{
	Text,
	SocialButton,
	SocialPlatform,
	Flex,
	Grid,
	Image
} from "@MoonLabsDev/dapp-core-lib"

//config
import { configPage } from "../config";

//styles
import styles from "./Components.module.css";

export const PageFooter = () =>
{
	return (
		<>
			{configPage.devMode &&
				<Grid
					className={styles.footer_background}
					gap="20px"
				>
					<Grid gap="0px">
						<Text
							color={1}
							size={2}
							align="center"
						>
							Follow us!
						</Text>
						<Text
							color={3}
							size={-1}
							align="center"
						>
							To receive the latest updates and find out about future releases.
						</Text>
					</Grid>
					<Flex gap="20px">
						<SocialButton social={SocialPlatform.Discord} />
						<SocialButton social={SocialPlatform.Telegram} />
						<SocialButton social={SocialPlatform.Twitter} />
						<SocialButton social={SocialPlatform.Gitbook} />
						<SocialButton social={SocialPlatform.Github} />
					</Flex>
					<Flex>
						<Image
							className={styles.footer_image}
							src="/logo.png"
						/>
					</Flex>
				</Grid>
			}
		</>
	);
};