import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Helmet, HelmetProvider } from "react-helmet-async";

//config
import { config as configDefault } from "../../dApp/config"

//framework
import { Text } from "@MoonLabsDev/dapp-core-lib";
import { DAppModals } from "@MoonLabsDev/dapp-core-lib";
import
{
	MediaInfoProvider,
	Web3ConnectionProvider,
	DAppProvider,
	EnabledModulesProviders,
	ThemeProvider
} from "@MoonLabsDev/dapp-core-lib";

//styles
import "react-toastify/dist/ReactToastify.css";
import "../styles/globals.css";

const makeToast = (_content, _success) =>
{
	if (_success === undefined)
	{
		_success = true;
	}

	const opts =
	{
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: false,
		progress: undefined,
		theme: "dark"
	};
	if (_success)
	{
		toast.success(_content, opts);
	}
	else
	{
		toast.error(_content, opts);
	}
}

const Head = ({configUI, configPage}) =>
{
    return (
        <Helmet>
            <html className={configUI.theme.root} />

            <meta name="description" content={configPage.description} />
            <meta name="theme-color" content={configPage.themeColor} />

            <title>
                {configPage.title}
            </title>

            {configPage.preconnect.map((p) => (
                <link
                    key={`preconnect_${configPage.preconnect.indexOf(p)}`}
                    rel="preconnect"
                    href={p.link}
                    crossOrigin={p.crossOrigin ? "crossorigin" : undefined}
                />
            ))}
            {configPage.fonts.map((f) => (
                <link
                    key={`preconnect_${configPage.fonts.indexOf(f)}`}
                    rel="stylesheet"
                    href={f}
                />
            ))}
        </Helmet>
    );
};

const Document = ({config, wallet, children}) =>
{
    return (
        <HelmetProvider>
            <Head
				configUI={config.ui}
				configPage={config.page}
			/>
            <div id="react_modal_root" />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable={false}
				pauseOnHover
				toastClassName="dark-toast"
			/>
            <BrowserRouter>
                <Web3ConnectionProvider>
                    <DAppProvider
						config={config}
						wallet={wallet}
						toastCallback={makeToast}
					>
						<EnabledModulesProviders>
							<MediaInfoProvider>
								<ThemeProvider>
									<Text
										color={1}
										weight={0}
										size={0}
										lineHeight={1}
										style={{ width: "100%" }}
									>
										<config.ui.template>

											<DAppModals />
											{children}

										</config.ui.template>
									</Text>
								</ThemeProvider>
							</MediaInfoProvider>
						</EnabledModulesProviders>
                    </DAppProvider>
                </Web3ConnectionProvider>
            </BrowserRouter>
        </HelmetProvider>
    );
};

const PageManager = ({configPage}) =>
{
    //functions
    const makePageAlias = (_pages, _id, _alias) =>
    {
        if (!_pages.find(p => p.path === _alias))
        {
            //add base path
            const page = _pages.find(p => p.path === _id);
            if (!!page)
            {
                _pages.push({ path: _alias, page: page.page })
            }
        }

        return _pages;
    };

    //get pages and make "home" & "/" alias
    let pages = configPage.pages;
    pages = makePageAlias(pages, "home", "/");
    pages = makePageAlias(pages, "/", "home");

    return (
        <Routes>
            {pages.map((p) => (
                <Route
                    key={`route_${pages.indexOf(p)}`}
                    path={p.path}
                    element={<p.page />}
                />
            ))}
        </Routes>
    );
};

const App = ({config, wallet}) =>
{
	//state
	const [cfg] = useState(() => config || configDefault);

    //effects
    useEffect(() =>
    {
        //init react modal
        if (document.getElementById("react_modal_root"))
        {
            ReactModal.setAppElement("#react_modal_root");
        }

		//body
		const root = document.getElementById("root");
		if (!!cfg.page.backgroundImage
			&& cfg.page.backgroundImage !== ""
			&& root)
		{
			root.style.backgroundImage = `url("${cfg.page.backgroundImage}")`;
		}
    }, []);

	return (
        <Document
			config={cfg}
			wallet={wallet}
		>
			<PageManager configPage={cfg.page} />
        </Document>
    );
}

export default App;