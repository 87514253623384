import { useState, useEffect } from "react";

//components
import { ValueNFTAmount } from "../../../";

//framework
import { MLWeb3 } from "../../../../utils";
import Events from "../../../../core/Events";

//hooks
import { useEventSubscription } from "../../../../hooks";

const ValueNFTBalance = (props) =>
{
    //state
    const [nft, setNFT] = useState(() => props.nft || null);
    const [nftID, setNFTID] = useState(() => props.nftID);
    const [smart, setSmart] = useState(() => props.smart || false);
    const [value, setValue] = useState(() => props.nft?.findItem(props.nftID)?.userBalance);
    const [shorten, setShorter] = useState(() => props.shorten || false);
    const [precision, setPrecision] = useState(() => (isNaN(props.precision) ? 8 : props.precision));
    const [load, setLoad] = useState(() => props.load);
    const [item, setItem] = useState(() => nft?.findItem(nftID) ?? null);

    //handler
    const handleNFTItemUpdate = (data) =>
    {
        if (!MLWeb3.checkEqualAddress(data.detail.address, nft.address)
            || data.detail.id !== nftID)
        {
            return;
        }
        const item = nft?.findItem(nftID) ?? null;
        setValue(item?.userBalance);
    };

    //effect
    useEffect(() =>
    {
        setNFT(props.nft?.lazyLoad() || null);
        setNFTID(props.nft.lazyLoadItem(props.nftID));
        setValue(nft?.findItem(nftID)?.userBalance);
    }, [props.nft, props.nftID, props.value]);
    useEventSubscription(Events.nft.userData, handleNFTItemUpdate, [nft, nftID]);

    return (
        <ValueNFTAmount
            nft={nft}
            nftID={nftID}
            value={value}
            smart={smart}
            shorten={shorten}
            load={load}
            additionalLoad={item?.initialized && nft.initializedUser}
        />
    );
};

export default ValueNFTBalance;