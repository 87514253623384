//framework
import { DApp, MLUtils, MLWeb3 } from "@MoonLabsDev/dapp-core-lib";

//classes
import Cache from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "../modules/Module_MoonAPI";

export const BetaUnlockTier =
{
    WHITELIST: -1,
    PUBLIC: 0,
    MOON_LOW: 100,
    MOON_HIGH: 1000,
};

export class MoonAPI
{
    /////////////////////////
    // Singleton
    /////////////////////////

    static instance;
    static getInstance()
    {
        if (!this.instance)
        {
            this.instance = new MoonAPI();
        }
        return this.instance;
    }

    /////////////////////////
    // Create
    /////////////////////////

    constructor()
    {
        this.url = "https://app.moon-vault.com/api.php";

        //cached
        //this.cache_moonToken_liquidityData = new Cache(async (_p) => await MoonAPI.instance.moonToken_getLiquidityData(_p, true), 60);
        this.cache_token_HistoricPrice = {};
        this.cache_token_LPFeeHistory = {};

        //data
        this.userGovernance = null;
    }

    /////////////////////////
    // Beta Access
    /////////////////////////

    hasBetaAccess(_tier)
    {
        //get data
        const moon = (this.userGovernance === null
            ? 0
            : this.userGovernance.breakdown.moon);
        const whitelist = (this.userGovernance === null
            ? false
            : (this.userGovernance.breakdown.events.find(e => e.name === "test_BETA").value !== 0
                || this.userGovernance.breakdown.events.find(e => e.name === "developer").value !== 0));

        //decide
        if (DApp.instance.account === null)
        {
            return true;
        }
        switch (_tier)
        {
            case BetaUnlockTier.PUBLIC:
                return true;

            case BetaUnlockTier.MOON_LOW:
                return (whitelist || moon >= BetaUnlockTier.MOON_LOW);

            case BetaUnlockTier.MOON_HIGH:
                return (whitelist || moon >= BetaUnlockTier.MOON_HIGH);

            case BetaUnlockTier.WHITELIST:
                return whitelist;
        }

        return false;
    }

    /////////////////////////
    // Governance
    /////////////////////////

    async governance_getUserData(_user)
    {
        //try get voting power
        if (_user !== null)
        {
            try
            {
                const result = await MLUtils.fetchJSON(this.url + `?module=governance&addresses=${_user}&reload=true&breakdown=true`);
                const user = result.score.find(s => MLWeb3.checkEqualAddress(s.address, _user))
                this.userGovernance =
                {
                    power: parseFloat(user.score) / 1000000,
                    breakdown: user.scoreBreakdown
                };
                document.dispatchEvent(new CustomEvent(ModuleEvents.governance));
            }
            catch (e) { }
        }

        return this.userGovernance;
    }

    /////////////////////////
    // Protocol
    /////////////////////////

    async protocol_getMetrics(_days = 180)
    {
        return await MLUtils.fetchJSON(this.url + "?module=protocol&action=getProtocolMetrics&days=" + _days);
    }

    /////////////////////////
    // MOON Token
    /////////////////////////

    async moonToken_getLiquidityData(_filter, _forceReload)
	{
		_filter = _filter || {};
		if (_forceReload)
		{
			let data = undefined;
			try
			{
				let apiURL = this.url + "?module=token&action=getTokenLiquidity";
				apiURL += "&days=" + (_filter.days || 30);
				data = await MLUtils.fetchJSON(apiURL);
			}
			catch (e) { }
			return data;
		}

		return await this.cache_moonToken_liquidityData.getData(_filter);
	}

    /////////////////////////
    // Tokens
    /////////////////////////

    async token_getHistoricPrice(_token, _filter, _forceReload)
	{
		_filter = _filter || {};
		if (_forceReload)
		{
			let data = undefined;
			try
			{
				let apiURL = this.url + "?module=token&action=getTokenPriceHistory";
                apiURL += "&chain=" + DApp.instance.currentChain.id;
                apiURL += "&token=" + _token.address;
                apiURL += "&days=" + (_filter.days || 30);
                data = await MLUtils.fetchJSON(apiURL);
			}
			catch (e) { }
			return data;
		}

		return await this.findOrCreateCache(
            this.cache_token_HistoricPrice,
            _token.address,
            async (_p) => await this.db_getHistoricPrice(_token, _p, true),
            60 * 5).getData(_filter);
	}

    async db_getHistoricLPFee(_token, _filter, _forceReload)
	{
		_filter = _filter || {};
		if (_forceReload)
		{
			let data = undefined;
			try
			{
				let apiURL = this.url + "?module=token&action=getTokenLPFeeHistory";
                apiURL += "&chain=" + DApp.instance.currentChain.id;
                apiURL += "&token=" + _token.address;
                apiURL += "&days=" + (_filter.days || 30)
                data = await MLUtils.fetchJSON(apiURL);
			}
			catch (e) { }
			return data;
		}

        return await this.findOrCreateCache(
            this.cache_token_LPFeeHistory,
            _token.address,
            async (_p) => await this.db_getHistoricPrice(_token, _p, true),
            60 * 5).getData(_filter);
	}

    /////////////////////////
    // Helper
    /////////////////////////

    findOrCreateCache(_cacheList, _id, _reloadCallback, _cacheTime)
    {
        if (!_cacheList[_id])
        {
            _cacheList[_id] = new Cache(_reloadCallback, _cacheTime);
        }
        return _cacheList[_id];
    }
}