import { useState, useEffect } from "react";

//components
import { Menu, MenuStyle, UserMenu, UserMenuStyle } from "../../";

//framework
import { MLUI } from "@MoonLabsDev/dapp-core-lib";
import { useMediaInfo, useDApp } from "@MoonLabsDev/dapp-core-lib";

//styles
import styles from "./ToolBar.module.css";

const ToolBarMode =
{
    Mobile: "mobile",
    Tablet: "tablet",
    Desktop: "desktop"
};

const ToolBar = ({ header }) =>
{
    //context
	const dApp = useDApp();
    const curMediaInfo = useMediaInfo();

    //functions
    const checkToolbarMode = () =>
    {
        if (!MLUI.checkMediaQueryAbove(curMediaInfo.matches, "sm"))
        {
            return ToolBarMode.Mobile;
        }
        else if (!MLUI.checkMediaQueryAbove(curMediaInfo.matches, "xl"))
        {
            return ToolBarMode.Tablet;
        }
        return ToolBarMode.Desktop;
    };

    //state
    const [toolbarMode, setToolbarMode] = useState(() => checkToolbarMode());

    //media query
    useEffect(() =>
    {
        setToolbarMode(checkToolbarMode());
    }, [JSON.stringify(curMediaInfo)]);

    //functions
    const selectModeDependand = (_mode, _selectMobile, _selectTablet, _selectDesktop) =>
    {
        switch (_mode)
        {
            case ToolBarMode.Mobile:
                return (_selectMobile
                    ?? selectModeDependand(
                        ToolBarMode.Tablet,
                        _selectMobile,
                        _selectTablet,
                        _selectDesktop));

            case ToolBarMode.Tablet:
                return (_selectTablet
                    ?? selectModeDependand(
                        ToolBarMode.Desktop,
                        _selectMobile,
                        _selectTablet,
                        _selectDesktop));

            default:
                return _selectDesktop;
        }
    };

    //select correct menu & mode
    const mode = selectModeDependand(toolbarMode, dApp.config.menu.mobileMode, dApp.config.menu.tabletMode, dApp.config.menu.desktopMode);
    const menu = selectModeDependand(toolbarMode, dApp.config.menu.mobileMenu, dApp.config.menu.tabletMenu, dApp.config.menu.desktopMenu);
    const menuStyle = (header ? mode.header.menu : mode.footer.menu);
    const userStyle = (header ? mode.header.user : mode.footer.user);

    //build menu bar
    let menuContent = <></>;
    let userContent = <></>;
    if (menuStyle === MenuStyle.None
        && userStyle === UserMenuStyle.None)
    {
        //empty bar
        return <></>;
    }
    if (menuStyle !== MenuStyle.None)
    {
        //menu bar
        menuContent = (
            <Menu
                menu={menu}
                menuStyle={menuStyle}
            />
        );
    }
    if (userStyle !== UserMenuStyle.None)
    {
        //user bar
        userContent = (
            <UserMenu
                menuStyle={userStyle}
            />
        );
    }

    return (
        <div
            className=
            {
                [
                    styles.toolBar,
                    (header ? styles.header : styles.footer)
                ].join(" ")
            }
        >
            {menuContent}
            {userContent}
        </div>
    );
};

export default ToolBar;