//components
import
{
    PageHeader,
    PageContent,
    Grid,
    Flex,
    ButtonVideoGuide,
} from "@MoonLabsDev/dapp-core-lib";

export default () =>
{
    return (
        <>
            <PageHeader
                title=
                {
                    <Flex>
                        Vaults
                        <Grid>
                            <ButtonVideoGuide
                                videoID="mIAbBivCpm8"
                            />
                        </Grid>
                    </Flex>
                }
            >
                Vaults help to increase your yield, by automatizing compounding for higher returns!
            </PageHeader>

            <PageContent>

            </PageContent>
        </>
    );
}