//framework
import { MLWeb3, MLUtils, MLFormat, MLMultiCall } from "../utils";
import DApp from "../core/DApp";
import Web3Transaction from "../core/Web3Transaction";
import Events from "../core/Events";

//contracts
import ABI_ERC721 from "../abi/ERC721";
import ABI_ERC1155 from "../abi/ERC1155";

export const NFTType =
{
    ERC721: "ERC721",
    ERC1155: "ERC1155"
};

export class NFT
{
    ////////////////////////////////////

	constructor(_data)
	{
        this.initialized = false;
        this.initializedUser = false;

		//config
		this.maxTries_metaData = 5;

		//base values
		this.address = _data.contract;
        this.type = _data.type;
        this.symbol = _data.symbol || "???";
        this.name = _data.name || "???";
        this.image = _data.image;
        this.items = [];
        this.ids = [];
        this.userItems = [];
        this.totalSupply = 0;
        this.load = false;
        this.userBalance = MLWeb3.toBN(0);
        this.approvalList = [];
		this.data = _data.data || null;

        //options
        this.localRedirect = _data.localRedirect || false;
		this.preventIPFSReplace = _data.preventIPFSReplace || false;

        //load ids
        if (_data.items !== undefined)
        {
            this.ids = NFT.flattenIDs(_data.items);
            this.ids.forEach((i) => this.createItem(i));
        }
	}

    ////////////////////////////////////

	debugErrorString(_text)
	{
		return `NFT failed at: ${_text}`;
	}

    getContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(
            (this.type === NFTType.ERC1155
                ? ABI_ERC1155
                : ABI_ERC721),
            this.address).methods;
    }

    makeMultiCall(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.address,
            (this.type === NFTType.ERC1155
                ? ABI_ERC1155
                : ABI_ERC721),
            _calls
        );
    }

    dispatchEvent(_name, _id)
    {
        document.dispatchEvent(new CustomEvent(_name,
        {
            detail:
            {
                address: this.address,
                ...(_id !== undefined && { id: _id })
            }
        }));
    }

    lazyLoad()
    {
        this.load = true;
        return this;
    }

    lazyLoadItem(_id)
    {
        const item = this.findItem(_id);
        if (item)
        {
            item.load = true;
        }
        return _id;
    }

    /////////////////////////
    // Init
    /////////////////////////

	async init()
	{
        await NFT.batch_init([this]);
	}

    static async batch_init(_nfts, _onlyLazy = true)
    {
        const filtered = _nfts.filter(t => !t.initialized && t.address !== "" && (!_onlyLazy || t.load));
        /*
        await DApp.instance.batchCall(
            filtered,
            (o) => o.makeRequest_init(),
            (o, r) => o.processRequest_init(r),
            false,
            "[NFT] batch init",
            "NFT: init"
        );
        */
       filtered.forEach(f => f.processRequest_init());

        return _nfts.filter(t => t.initialized);
    }

    makeRequest_init()
    {
        return null;
    }

    async processRequest_init(_data)
    {
        //this.totalSupply = MLWeb3.toBN(_data.totalSupply);

        //complete
        this.initialized = true;
    }

    /////////////////////////
    // TokenURI
    /////////////////////////

    async batch_tokenURI(_ids)
    {
        await this.init();
        if (!this.initialized)
        {
            return;
        }

        const filtered = this.items.filter((i) => i.uri === "" && _ids.includes(i.id)).map((i) => i.id);
        await DApp.instance.batchCall(
            filtered,
            (o) => this.makeRequest_tokenURI(o),
            (o, r) => this.processRequest_tokenURI(o, r),
            false,
            "[NFT] batch tokenURI",
            "NFT: tokenURI"
        );

        return this.items.filter((i) => i.uri !== "");
    }

    makeRequest_tokenURI(_id)
    {
        return this.makeMultiCall(
        {
            ...(this.type === NFTType.ERC721 &&
                {
                    tokenURI: { function: "tokenURI", parameters: [_id] }
                }
            ),
            ...(this.type === NFTType.ERC1155 &&
                {
                    tokenURI: { function: "uri", parameters: [_id] }
                }
            )
        });
    }

    async processRequest_tokenURI(_id, _data)
    {
        const item = this.findOrCreateItem(_id);
        item.uri = this.modifyIPFS(
            _data.tokenURI,
            _id,
            this.localRedirect,
			this.preventIPFSReplace);

        //event
        this.dispatchEvent(Events.nft.tokenURI, _id);
    }

    /////////////////////////
    // MetaData
    /////////////////////////

    async batch_metaData(_ids, _onlyLazy = true)
    {
        if (_ids === undefined)
        {
            if (_onlyLazy)
            {
                _ids = this.items.filter((i) => !_onlyLazy || i.load).map((i) => i.id);
            }
            else
            {
                _ids = this.ids;
            }
        }
        const filtered = (await this.batch_tokenURI(_ids)).filter((i) => !_onlyLazy || i.load);
        for (let n = 0; n < filtered.length; n++)
        {
            const i = filtered[n];
            if (i.uri === ""
                && i.uri === "\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x00\x02"
                && i.json !== null
                && i.load !== true
				&& i.tries_metaData <= this.maxTries_metaData)
            {
                return;
            }

            try
            {
                i.json = await MLUtils.fetchJSON(i.uri, false);
                i.data =
                {
                    ...i.data,
                    name: i.json.name,
                    description: i.json.description,
                    image: this.modifyIPFS(i.json.image, i.id),
                    externalURL: i.json.external_url,
                    attributes: i.json.attributes,
                    decimals: i.json.decimals || 0,
                    one: MLWeb3.toBN(10).pow(MLWeb3.toBN(i.json.decimals || 0)),
                },
                i.initialized = true;
                this.dispatchEvent(Events.nft.metaData, i.id);
            }
            catch (e)
			{
				i.tries_metaData += 1;
				console.error(e);
			}
        }

        //event
        this.dispatchEvent(Events.nft.metaData);
    }

    /////////////////////////
    // User Data
    /////////////////////////

    async reload_userData(_id)
    {
        await this.init();
        if (!this.initialized)
        {
            return;
        }

		//handle result
        await this.batch_userData([_id]);
    }

    static async batch_userDataNFTs(_nfts)
    {
        if (DApp.instance.account === null)
        {
            return;
        }

        const filtered = _nfts.filter((n) => n.initialized);
        const mapped = [];
        for (let n = 0; n < filtered.length; n++)
        {
            const nft = filtered[n];
            for (let m = 0; m < nft.items.length; m++)
            {
                const item = nft.items[m];
                if (item.initialized)
                {
                    mapped.push(
                    {
                        nft: nft,
                        item: item
                    });
                }
            }
        }
        await DApp.instance.batchCall(
            mapped,
            (o) => o.nft.makeRequest_userData(o.item),
            (o, r) => o.nft.processRequest_userData(o.item, r),
            false,
            "[NFT] batch userData",
            "NFT: userData"
        );

        //process
        filtered.forEach(n =>
        {
            n.userItems = n.items.filter((i) => !i.userBalance.isZero()).map((i) => i.id);
            if (n.type === NFTType.ERC721)
            {
                n.userBalance = MLWeb3.toBN(n.userItems.length);
            }
        });
    }

    async batch_userData(_items)
    {
        if (DApp.instance.account === null)
        {
            return;
        }
        if (_items === undefined)
        {
            _items = this.items;
        }

        const filtered = _items.filter((i) => i.initialized);
        await DApp.instance.batchCall(
            filtered,
            (o) => this.makeRequest_userData(o),
            (o, r) => this.processRequest_userData(o, r),
            false,
            "[NFT] batch userData",
            "NFT: userData"
        );

        //process
        this.userItems = this.items.filter((i) => !i.userBalance.isZero()).map((i) => i.id);
        if (this.type === NFTType.ERC721)
        {
            this.userBalance = MLWeb3.toBN(this.userItems.length);
        }
    }

    makeRequest_userData(_item)
    {
        return this.makeMultiCall(
        {
            ...(this.type === NFTType.ERC721 &&
                {
                    ownerOf: { function: "ownerOf", parameters: [_item.id] }
                }
            ),
            ...(this.type === NFTType.ERC1155 &&
                {
                    userBalance: { function: "balanceOf", parameters: [DApp.instance.account, _item.id] }
                }
            )
        });
    }

    async processRequest_userData(_item, _data)
    {
        if (this.type === NFTType.ERC721)
        {
            _item.userBalance = (MLWeb3.checkEqualAddress(_data.ownerOf, DApp.instance.account) ? _item.data.one : MLWeb3.toBN(0));
        }
        else if (this.type === NFTType.ERC1155)
        {
            _item.userBalance = MLWeb3.toBN(_data.userBalance);
        }

        //comlete
        this.initializedUser = true;

        //event
        this.dispatchEvent(Events.nft.userData, _item.id);
    }

    /////////////////////////
    // Approval
    /////////////////////////

    static async batch_approval(_nfts)
    {
        if (DApp.instance.account === null)
        {
            return;
        }

        //make special approval list
        const filtered = [];
        for (let n = 0; n < _nfts.length; n++)
        {
            const nft = _nfts[n];
            for (let m = 0; m < nft.approvalList.length; m++)
            {
                const approvalAll = nft.approvalList[m];
                if (!approvalAll.load
                    || approvalAll.approved)
                {
                    continue;
                }
                filtered.push(
                {
                    ...approvalAll,
                    nft: nft
                });
            }
        }

        //batch call
        await DApp.instance.batchCall(
            filtered,
            (o) => o.nft.makeRequest_approval(o),
            (o, r) => o.nft.processRequest_approval(o, r),
            false,
            "[NFT] batch approval",
            "NFT: approval"
        );
    }

    makeRequest_approval(_approval)
    {
        return this.makeMultiCall(
        {
            ...(this.type === NFTType.ERC721 &&
                {
                    approvedForAll: { function: "isApprovedForAll", parameters: [DApp.instance.account, _approval.for] }
                }
            ),
            ...(this.type === NFTType.ERC1155 &&
                {
                    approvedForAll: { function: "isApprovedForAll", parameters: [DApp.instance.account, _approval.for] }
                }
            )
        });
    }

    async processRequest_approval(_approval, _data)
    {
        const approval = this.findAppovedForAll(_approval.for);
        if (this.type === NFTType.ERC721)
        {
            approval.approved = _data.approvedForAll;
        }
        else if (this.type === NFTType.ERC1155)
        {
            approval.approved = _data.approvedForAll;
        }

        //event
        this.dispatchEvent(Events.nft.approval);
    }

    /////////////////////////
    // Helper
    /////////////////////////

    static flattenIDs(_ids)
    {
        const ret = [];
        _ids.forEach((i) =>
        {
            if (!isNaN(i))
            {
                ret.push(i);
            }
            else if (i.from !== undefined
                && i.to !== undefined
                && i.from <= i.to)
            {
                for (let n = i.from; n <= i.to; n++)
                {
                    ret.push(n);
                }
            }
        });

        return ret;
    }

    findItem(_id)
    {
        return (this.items.find(i => i.id === parseInt(_id)) || null);
    }

    createItem(_id)
    {
        const item =
        {
            id: parseInt(_id),
            uri: "",
            json: null,
            data:
            {
                name: "???",
                description: "",
                image: "",
                externalURL: "",
                attributes: [],
                decimals: 0,
                one: MLWeb3.toBN(1),
                supply: MLWeb3.toBN(0),
            },
            userBalance: MLWeb3.toBN(0),

            load: false,
            exists: false,
            initialized: false,
			tries_metaData: 0
        };
        this.items.push(item);
        return item;
    }

    findOrCreateItem(_id)
    {
        let item = this.findItem(_id);
        if (item === null)
        {
            item = this.createItem(_id);
        }
        return item;
    }

    modifyIPFS(_path, _id, _localRedirect, _preventIPFSReplace)
    {
		if (_preventIPFSReplace)
		{
			return _path;
		}
		else if (_localRedirect)
        {
            return `${location.origin}/assets/nfts/${this.address}/${_id}.json`;
        }

        let ret = _path.replace("{id}", _id);
        if (_path.indexOf("ipfs/") !== -1)
        {
            //ipfs gateway
            const uriParts = _path.split('ipfs/');
            ret = DApp.instance.config.web3.ipfsGateway + uriParts[uriParts.length - 1];
        }
        return ret;
    }

    findAppovedForAll(_approveFor)
    {
        return this.approvalList.find((a) => MLWeb3.checkEqualAddress(a.for, _approveFor)) || null;
    }

    findOrCreateAppovedForAll(_approveFor)
    {
        if (!_approveFor
            || _approveFor === "")
        {
            return null;
        }
        let approval = this.findAppovedForAll(_approveFor);
        if (!approval)
        {
            approval =
            {
                for: _approveFor,
                load: true,
                approved: false
            };
            this.approvalList.push(approval);
        }
        return approval;
    }

    checkApprovedForAll(_approveFor)
    {
        return !!this.findOrCreateAppovedForAll(_approveFor)?.approved;
    }

    /////////////////////////
    // Transactions
    /////////////////////////

    approveAll(_approveFor, _approve = true)
    {
        //make send call
        const con = this.getContract(true);
        let send = null;
        switch (this.type)
        {
            case NFTType.ERC721:
                send =  con.setApprovalForAll(
                    _approveFor,
                    _approve);
                break;

            case NFTType.ERC1155:
                send =  con.setApprovalForAll(
                    _approveFor,
                    _approve);
                break;
        }

        //send
        return new Web3Transaction(
            send,
            this.debugErrorString("approveAll"),
            `ApproveAll [${this.symbol}] = ${_approve}`
		);
    }

    transferFrom(_from, _to, _tokenId, _amount)
    {
        //make send call
        const data = "";
        const con = this.getContract(true);
        let amountStr = "";
        let send = null;
        switch (this.type)
        {
            case NFTType.ERC721:
                send =  con.safeTransferFrom(
                    _from,
                    _to,
                    _tokenId,
                    data);
                break;

            case NFTType.ERC1155:
                amountStr = `${MLFormat.formatNFT(_amount, this.findItem(_tokenId))}x `;
                send =  con.safeTransferFrom(
                    _from,
                    _to,
                    _tokenId,
                    _amount,
                    data);
                break;
        }

        //send
        return new Web3Transaction(
            send,
            this.debugErrorString("transferFrom"),
            `TransferFrom ${amountStr}[${this.symbol}]`
		);
    }

    ////////////////////////////////////
}