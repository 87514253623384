export const DataSeriesFillType =
{
	INGORE: -1,
	ZERO: 0,
	FILL: 1,
	LINEAR: 2
};

const MLUtils =
{
	/////////////////////////
    // events
    /////////////////////////

	subscribeEvents(_events, _callback, _target)
	{
		_events = (Array.isArray(_events) ? _events : [_events]);
		_events.forEach((e) => (_target === undefined ? document : _target).addEventListener(e, _callback));
	},

	unsubscribeEvents(_events, _callback, _target)
	{
		_events = (Array.isArray(_events) ? _events : [_events]);
		_events.forEach((e) => (_target === undefined ? document : _target).removeEventListener(e, _callback));
	},

	dispatchEvent(_name, _data = null)
    {
        document.dispatchEvent(new CustomEvent(_name,
        {
            detail: _data
        }));
    },

	/////////////////////////
    // default / check empty
    /////////////////////////

	isEmpty(_value)
	{
		return (_value === undefined
			|| _value === null);
	},

	defaultBoolean(_value, _default)
	{
		if (typeof _value !== "boolean")
		{
			return _default;
		}
		return _value;
	},

	defaultNumber(_value, _default)
	{
		if (_value === undefined)
		{
			return _default;
		}
		return _value;
	},

	/////////////////////////
    // Data
    /////////////////////////

	dataSeries_fillRange(_list, _key, _startValue, _endValue, _fillType)
	{
		if (_fillType === DataSeriesFillType.ZERO)
		{
			_list.forEach(i => i[_key] = 0);
		}
		else if (_fillType === DataSeriesFillType.FILL)
		{
			_list.forEach(i => i[_key] = _startValue);
		}
		else if (_fillType === DataSeriesFillType.LINEAR)
		{
			const range = _endValue - _startValue;
			const step = range / (_list.length + 2);
			for (let n = 0; n < _list.length; n++)
			{
				_list[n][_key] = _startValue + ((n + 1) * step);
			}
		}

		return _list;
	},

	dataSeries_normalize(_list, _valueNames, _callback)
	{
		//make it per series
		if (Array.isArray(_valueNames))
		{
			_valueNames.forEach(n => this.dataSeries_normalize(_list, n, _callback));
			return _list;
		}

		_list.forEach(i =>
		{
			if (i[_valueNames] !== undefined)
			{
				i[_valueNames] = _callback(i[_valueNames]);
			}
		});
		return _list;
	},

	dataSeries_insertSteps(_list, _key, _step)
	{
		if (_list.length < 2)
		{
			return _list;
		}

		const valueStart = _list[0][_key];
		const valueEnd = _list[_list.length - 1][_key];
		for (let n = valueStart; n < valueEnd; n += _step)
		{
			this.dataSeries_findOrInsert(_list, _key, n);
		}
	},

	dataSeries_fillMissing(_list, _valueNames, _fillBetween, _fillStart = DataSeriesFillType.INGORE, _fillEnd = DataSeriesFillType.INGORE)
	{
		//make it per series
		if (Array.isArray(_valueNames))
		{
			_valueNames.forEach(n => this.dataSeries_fillMissing(_list, n, _fillBetween, _fillStart, _fillEnd));
			return _list;
		}

		//fill start
		if (_fillStart !== DataSeriesFillType.INGORE)
		{
			//gather
			const fillPoints = [];
			let valueEnd = undefined;
			for (let n = 0; n < _list.length; n++)
			{
				const i = _list[n];
				if (i[_valueNames] !== undefined)
				{
					valueEnd = i[_valueNames];
					break;
				}
				fillPoints.push(i);
			}
			if (fillPoints.length > 0
				&& valueEnd !== undefined)
			{
				this.dataSeries_fillRange(
					fillPoints,
					_valueNames,
					0,
					valueEnd,
					_fillStart
				);
			}
		}

		//fill end
		if (_fillEnd !== DataSeriesFillType.INGORE)
		{
			//gather
			const fillPoints = [];
			let valueStart = undefined;
			for (let n = 0; n < _list.length; n++)
			{
				const i = _list[_list.length - 1 - n];
				if (i[_valueNames] !== undefined)
				{
					valueStart = i[_valueNames];
					break;
				}
				fillPoints.push(i);
			}
			if (fillPoints.length > 0
				&& valueStart !== undefined)
			{
				this.dataSeries_fillRange(
					fillPoints,
					_valueNames,
					valueStart,
					valueStart,
					_fillEnd
				);
			}
		}

		//fill in between
		if (_fillBetween !== DataSeriesFillType.INGORE)
		{
			let fillPoints = [];
			let valueStart = undefined;
			let valueEnd = undefined;
			for (let n = 0; n < _list.length; n++)
			{
				const i = _list[n];
				if (i[_valueNames] !== undefined)
				{
					if (valueStart === undefined
						|| fillPoints.length === 0)
					{
						valueStart = i[_valueNames];
					}
					else
					{
						valueEnd = i[_valueNames];

						//process
						this.dataSeries_fillRange(
							fillPoints,
							_valueNames,
							valueStart,
							valueEnd,
							_fillBetween
						);

						//reset
						valueStart = valueEnd;
						valueEnd = undefined;
						fillPoints = [];
					}
				}
				else if (valueStart !== undefined)
				{
					fillPoints.push(i);
				}
			}
		}

		return _list;
	},

	dataSeries_findOrInsert(_list, _key, _value)
    {
		//prepare item
		const newItem = { };
		newItem[_key] = _value;

		//check if insert at 0
		if (_list.length > 0
			&& _list[0][_key] > _value)
		{
			_list.splice(0, 0, newItem);
			return newItem;
		}

		//find position
		for (let n = 0; n < _list.length; n++)
		{
			const i = _list[n];
			if (i[_key] === _value)
			{
				//found
				return i;
			}
			else if (i[_key] > _value)
			{
				//insert before
				_list.splice(n, 0, newItem);
				return newItem;
			}
		}

		//not found, so add to end
        _list.push(newItem);
        return newItem;
    },

	roundDate(_date)
	{
		return new Date(
			_date.getFullYear(),
			_date.getMonth(),
			_date.getDate()
		);
	},

	getDateTimeFromDB(_string)
	{
		if (isNaN(_string))
		{
			const dts = _string.split(" ");
			const dtStr = dts[0] + "T" + (dts.length > 1 ? dts[1] : "23:59:59") + ".000";
			const dt = new Date(Date.parse(dtStr));
			return dt;
		}
		else
		{
			return new Date(parseInt(_string) * 1000);
		}
	},

    /////////////////////////
    // remove Trail
    /////////////////////////

    removeTrailStart(_string, _remove)
	{
		return this.removeTrail(_string, _remove, true)
	},

	removeTrailEnd(_string, _remove)
	{
		return this.removeTrail(_string, _remove, false)
	},

	removeTrail(_string, _remove, _start)
	{
		let o = 0;
		if (!!_start)
		{
			//find first different from start
			for (let n = 0; n < _string.length; n++)
			{
				if (_string[n] !== _remove)
				{
					break
				}
				o += 1;
			}
		}
		else
		{
			//find first different from end
			for (let n = _string.length - 1; n >= 0; n--)
			{
				if (_string[n] !== _remove)
				{
					break
				}
				o += 1;
			}
		}

		//substring
		if (o >= _string.length)
		{
			return ""
		}
		return (_start ? _string.substring(o) : _string.substring(0, _string.length - o))
	},

	/////////////////////////
    // fetch
    /////////////////////////

	async fetch(_url, _ignoreCORS = false)
	{
		const options =
		{
			//method: "GET",
			//mode: "no-cors"
			mode: "cors",
  			headers:
			{
    			"Access-Control-Allow-Origin": "*"
  			}
		};

		return await fetch(
			_url,
			(_ignoreCORS ? options : undefined));
	},

	async fetchJSON(_url, _ignoreCORS = false)
	{
		const ret = await this.fetch(_url, _ignoreCORS);
		const ret_text = await ret.text();
		try
		{
			return JSON.parse(ret_text.split("/*")[0]);
		}
		catch (e)
		{
			console.error(`Can't parse JSON [${_url}]`);
			throw e;
		}
	},

	async fetchFromForm(_url, _formData)
	{
		return await fetch(_url,
		{
			method: "POST",
			body: _formData
		});
	},

	async fetchJSONFromForm(_url, _formData)
	{
		const ret = await this.fetchFromForm(_url, _formData);
		const ret_text = await ret.text();
		try
		{
			return JSON.parse(ret_text.split("/*")[0]);
		}
		catch (e)
		{
			console.log(`Can't parse JSON [${_url}]`);
			throw e;
		}
	},

	/////////////////////////
    // performance
    /////////////////////////

	async measureTime(_topic, _action)
	{
		const tS = new Date();
		await _action();
		const tE = new Date();
		const tD = (tE.getTime() - tS.getTime());
		console.log(`${_topic}: ${tD}ms`);
	},

	/////////////////////////
    // cookies & storage
    /////////////////////////

	getCookie(_name, _defaultValue)
	{
		const cookies = document.cookie.split(";");
		const cookiePairs = [];
		cookies.forEach(c =>
		{
			const pair = c.split("=");
			cookiePairs.push(
			{
				name: pair[0].replace(" ", ""),
				value: pair[1]
			});
		});

		const c = cookiePairs.find(cp => cp.name === _name);
		if (!!c)
		{
			return c.value;
		}
		return _defaultValue;
	},

	setCookie(_name, _value, _days)
	{
		let expires = "";
    	if (_days)
		{
        	let date = new Date();
        	date.setTime(date.getTime() + (_days * 24 * 60 * 60 * 1000));
        	expires = "; expires=" + date.toUTCString();
		}
		document.cookie = _name + "=" + (_value || "")  + expires + "; path=/";
	},

	setStorage(_sessionOnly, _name, _value)
	{
		if (_sessionOnly)
		{
			sessionStorage.setItem(_name, _value);
		}
		else
		{
			localStorage.setItem(_name, _value);
		}
	},

	getStorage(_sessionOnly, _name, _defaultValue)
	{
		let value = undefined;
		if (_sessionOnly)
		{
			value = sessionStorage.getItem(_name);
		}
		else
		{
			value = localStorage.getItem(_name);
		}

		return (value === null || value === undefined ? _defaultValue : value);
	},

	getStorage_bool: function(_sessionOnly, _name, _defaultValue)
	{
		const val = this.getStorage(_sessionOnly, _name, _defaultValue);
		return (val === _defaultValue ? val : val === "true");
	},

	getStorage_int: function(_sessionOnly, _name, _defaultValue)
	{
		const val = this.getStorage(_sessionOnly, _name, _defaultValue);
		return (val === _defaultValue ? val : parseInt(val));
	},

	getStorage_float: function(_sessionOnly, _name, _defaultValue)
	{
		const val = this.getStorage(_sessionOnly, _name, _defaultValue);
		return (val === _defaultValue ? val : parseFloat(val));
	}
};

export default MLUtils;