import { useState, useEffect } from "react";

//components
import
{
    Text,
    Flex,
    TokenIcon,
    FormatPercent
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLFormat, MLUtils, MLWeb3 } from "@MoonLabsDev/dapp-core-lib";

//hooks
import { useDApp } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useMoonSwap } from "../../../modules/Module_MoonSwap";

//styles
import styles from "./MoonSwap.module.css";


const MoonSwapPathInfo = (props) =>
{
    //context
    const dApp = useDApp();
    const swap = useMoonSwap();

    const getInfo = (item) =>
    {
        const router = dApp.findRouter(item.router);
        const tokens = item.path.map(t => dApp.findToken(t));
        const outputToken = tokens[tokens.length - 1];
        const amount = MLFormat.formatToken(item.amountOut, outputToken, undefined, true);
        const currentSwapAmountFloat = MLWeb3.convertTokenBN_Float(item.amountOut, outputToken);
        const bestSwapAmountFloat = MLWeb3.convertTokenBN_Float(swap.swapInfo[0].amountOut, outputToken);
        const loss = (currentSwapAmountFloat / bestSwapAmountFloat) - 1;

        return {
            router,
            tokens,
            outputToken,
            amount,
            currentSwapAmountFloat,
            bestSwapAmountFloat,
            loss
        };
    };

    //state
    const [item, setItem] = useState(() => props.item);
    const [hideAmountOnLoss] = useState(() => MLUtils.defaultBoolean(props.hideAmountOnLoss, true));

    //effect
    useEffect(() =>
    {
        setItem(props.item);
    }, [props.item]);

    const info = getInfo(item);
    return (
        <Flex className={styles.pathItem}>
            <Text
                className={styles.pathItem_router}
                size={-1}
            >
                {info.router.id}
            </Text>
            <Flex className={styles.pathItem_path}>
                {info.tokens.map(t =>
                    <TokenIcon
                        key={`token_${info.tokens.indexOf(t)}`}
                        className={styles.pathItem_pathIcon}
                        token={t}
                    />
                )}
            </Flex>
            <Text
                className={styles.pathItem_amount}
                size={-1}
            >
                {(!hideAmountOnLoss || info.loss === 0) &&
                    <>
                        {info.amount}{" "}
                    </>
                }
                {info.loss !== 0 &&
                    <>
                        {!hideAmountOnLoss &&
                            <>(</>
                        }
                        <FormatPercent
                            value={info.loss}
                            showSign={true}
                            colorize={true}
                        />
                        {!hideAmountOnLoss &&
                            <>)</>
                        }
                    </>
                }
            </Text>
        </Flex>
    );
};

export default MoonSwapPathInfo;