import { useState, useEffect } from "react";

//components
import { ValueLoad } from "../../../";

//framework
import { MLWeb3 } from "../../../../utils";
import Events from "../../../../core/Events";

//hooks
import { useEventSubscription } from "../../../../hooks";

const ValueNFTName = (props) =>
{
    //state
    const [nft, setNFT] = useState(() => props.nft || null);
    const [nftID, setNFTID] = useState(() => props.nftID);
    const [item, setItem] = useState(() => nft?.findItem(nftID) ?? null);
    const [value, setValue] = useState(() => nft?.findItem(nftID)?.data?.name)

    //handler
    const handleItemUpdate = (data) =>
    {
        if (!MLWeb3.checkEqualAddress(data.detail.address, nft.address)
            || data.detail.id !== nftID)
        {
            return;
        }
        const item = nft?.findItem(nftID) ?? null;
        setItem(item);
        setValue(item?.data?.name);
    };

    //effect
    useEffect(() =>
    {
        setNFT(props.nft?.lazyLoad() || null);
        setNFTID(props.nft.lazyLoadItem(props.nftID));
        setItem(props.nft?.findItem(props.nftID) ?? null);
    }, [props.nft, props.nftID])
    useEventSubscription(Events.nft.metaData, handleItemUpdate, [nft, nftID]);

    return (
        <ValueLoad loaded={item?.initialized}>
            {value}
        </ValueLoad>
    );
};

export default ValueNFTName;