import { useState } from "react";

//components
import
{
    PageHeader,
    PageContent,
    Text,
    Button,
    Grid,
    Flex,
    Card,
    CardHeader,
    CardBody,
    ButtonVideoGuide,
    ErrorServiceNotAtChain,
    InputTokenAmount
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLFormat } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useMoonToken, ModuleEvents_MoonToken } from "@MoonVault/dapp-widgets-lib";

//hooks
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//styles
import styles from "src/pages/Pages.module.css";

const UseCase = (props) =>
{
    return null;
};

const Stat = (props) =>
{
    return (
        <Flex className={styles.earnToken_stats}>
            <Text size={-1}>
                {props.name}
            </Text>
            <Text size={-1}>
                {props.value}
            </Text>
        </Flex>
    );
};

const BuySell = (props) =>
{
    //hooks
    const dApp = useDApp();
    const moon = useMoonToken();

    //function
    const getMooonTokenValues = () =>
    {
        return {
            buyPrice: moon.tokenPrice,
            sellPrice: moon.tokenValue,
            availableSupply: moon.availableSupply,
            circulatingSupply: moon.circulatingSupply
        };
    }

    //state
    const [data, setData] = useState(() => getMooonTokenValues());

    //effects
    useEventSubscription(ModuleEvents_MoonToken.data, () => setData(getMooonTokenValues()));

    return (
        <Flex>
            <Card className={styles.earnToken_buySell}>
                <CardHeader>
                    <Grid gap="0">
                        <Text size={1}>
                            Buy
                        </Text>
                        <Text size={-1} color={2}>
                            {moon.peggedToken?.symbol} &gt; {moon.token?.symbol}
                        </Text>
                    </Grid>
                </CardHeader>
                <CardBody>
                    <InputTokenAmount
                        token={moon.token}
                        showMax={false}
                    />

                    <Button
                        className={styles.earnToken_button}
                    >
                        Buy
                    </Button>

                    <Grid>
                        <Stat
                            name={"Buy Price"}
                            value={dApp.formatFiat(data.buyPrice, 8)}
                        />
                        <Stat
                            name={"Circulating Supply"}
                            value={MLFormat.formatToken(data.circulatingSupply, moon.token, 0)}
                        />
                    </Grid>
                </CardBody>
            </Card>

            <Card className={styles.earnToken_buySell}>
                <CardHeader>
                    <Grid gap="0">
                        <Text size={1}>
                            Redeem
                        </Text>
                        <Text size={-1} color={2}>
                            {moon.token?.symbol}  &gt; {moon.peggedToken?.symbol}
                        </Text>
                    </Grid>
                </CardHeader>
                <CardBody>
                    <InputTokenAmount
                        token={moon.token}
                        showMax={false}
                    />

                    <Button
                        className={styles.earnToken_button}
                    >
                        Redeem
                    </Button>

                    <Grid>
                        <Stat
                            name={"Sell Price"}
                            value={dApp.formatFiat(data.sellPrice, 8)}
                        />
                        <Stat
                            name={"Available Supply"}
                            value={MLFormat.formatToken(data.availableSupply, moon.token, 0)}
                        />
                    </Grid>
                </CardBody>
            </Card>
        </Flex>
    );
};

const Warning = (props) =>
{
    return (
        <Grid>
            <Flex>
                <Text size={1}>
                    Warning
                </Text>
            </Flex>
            <Flex>
                <Text>
                    Blabla initial loss disclaimer blabla
                </Text>
            </Flex>
        </Grid>
    );
};

const MoonTokenContent = () =>
{
    //context
    const moon = useMoonToken();

    //state
    const [init, setInit] = useState(() => !!moon?.initialized);

    //effects
    useEventSubscription(ModuleEvents.init, () => setInit(true));

    return (
        <>
            {init &&
                <Grid>
                    <Warning />
                    <BuySell />
                    <UseCase />
                </Grid>
            }
        </>
    );
};

export default () =>
{
	//context
    const moon = useMoonToken();

    return (
        <>
            <PageHeader
                title=
                {
                    <Flex>
                        MOON Token
                        <Grid>
                            <ButtonVideoGuide
                                videoID="jJB8zM3KHhg"
                            />
                        </Grid>
                    </Flex>
                }
            >
                Unique tokenomics and a token that can't loose value!
            </PageHeader>

            <PageContent>
                <ErrorServiceNotAtChain checkSupportChainCallback={moon.availableOnChain}>
                    <MoonTokenContent />
				</ErrorServiceNotAtChain>
            </PageContent>
        </>
    );
}