//components
import { ToolBar } from "../../framework/components";

//styles
import stylesTemplate from "./Template.module.css";

const Template = (props) =>
{
    return (
        <div>
            <header className={stylesTemplate.header}>
                <ToolBar header={true} />
            </header>
            <main className={stylesTemplate.main}>
                {props.children}
            </main>
            <footer className={stylesTemplate.footer}>
                <ToolBar header={false} />
            </footer>
        </div>
    );
};

export default Template;