import { useState } from "react";

//components
import
{
	Card,
	CardHeader,
	CardBody,
	PageHeader,
	PageContent,
	Text,
	Grid,
	Flex,
	Button,
	Modal
} from "@MoonLabsDev/dapp-core-lib";
import { ModalSelectToken, ModalSelectNFT } from "@MoonLabsDev/dapp-core-lib";

//framework
import { useDApp } from "@MoonLabsDev/dapp-core-lib";

const ModalCard = ({title, children}) =>
{
    return (
        <Card
            style=
            {
                {
                    minWidth: "300px"
                }
            }
        >
            <CardHeader>
                <Text size={1}>
                    {title}
                </Text>
            </CardHeader>
            <CardBody>
                <Grid>
                    {children}
                </Grid>
            </CardBody>
        </Card>
    );
};

const generateModalButton = (_title, _setShow, _modal) =>
{
    return (
        <>
            <Button onClick={() => _setShow(true)}>
                {_title}
            </Button>
            {_modal}
        </>
    );
};

const generateModal = (_show, _setShow, _raw, _onClose, _title, _content, _big) =>
{
    //generate props
    if (_content === undefined)
    {
        _content = _title;
    }
    const modalProps =
    {
        show: _show,
        header: _title,
        footer: <Button onClick={() => _setShow(false)}>close</Button>,
        allowBig: _big
    };
    if (_raw)
    {
        modalProps.raw = true;
    }
    if (_onClose)
    {
        modalProps.onClose = () => _setShow(false);
    }

    return generateModalButton(
        _title,
        _setShow,
        <Modal {...modalProps}>
            {_content}
            {_raw &&
                modalProps.footer
            }
        </Modal>);
};

const BaseModals = () =>
{
    const [showModal_raw, setShowModal_raw] = useState(() => false);
    const [showModal_normalNoClose, setShowModal_normalNoClose] = useState(() => false);
    const [showModal_normal, setShowModal_normal] = useState(() => false);
    const [showModal_big, setShowModal_big] = useState(() => false);

    return (
        <ModalCard
            title="Base"
        >
            {generateModal(
                showModal_raw,
                setShowModal_raw,
                true,
                false,
                "Raw"
            )}
            {generateModal(
                showModal_normalNoClose,
                setShowModal_normalNoClose,
                false,
                false,
                "Normal (no close)"
            )}
            {generateModal(
                showModal_normal,
                setShowModal_normal,
                false,
                true,
                "Normal"
            )}
            {generateModal(
                showModal_big,
                setShowModal_big,
                false,
                true,
                "Big",
                undefined,
                true
            )}
        </ModalCard>
    );
};

const ExtendedModals = () =>
{
	const dApp = useDApp();

    const [showModal_selectToken, setShowModal_selectToken] = useState(() => false);
    const [showModal_selectNFT, setShowModal_selectNFT] = useState(() => false);

    return (
        <ModalCard
            title="Extended"
        >
            {generateModalButton(
                "SelectToken",
                setShowModal_selectToken,
                <ModalSelectToken
                    show={showModal_selectToken}
                    tokenList={dApp.getTokenList(undefined)}
                    onClose={() => setShowModal_selectToken(false)}
                />
            )}
            {generateModalButton(
                "SelectNFT",
                setShowModal_selectNFT,
                <ModalSelectNFT
                    show={showModal_selectNFT}
                    nftList={dApp.nfts}
                    onClose={() => setShowModal_selectNFT(false)}
                />
            )}
        </ModalCard>
    );
};

export default () =>
{
    return (
        <>
            <PageHeader title="Modals" />

            <PageContent>
                <Flex
                    style=
                    {
                        {
                            minWidth: "300px"
                        }
                    }
                >
                    <BaseModals />
                    <ExtendedModals />
                </Flex>
            </PageContent>
        </>
    );
};