//framework
import { DAppModule, MLUtils } from "@MoonLabsDev/dapp-core-lib";

//classes
import { MoonAPI } from "../classes/MoonAPI";

export const ModuleEvents =
{
    governance: "api_governance"
};

export class Module_MoonAPI extends DAppModule
{
    static moduleName = "moon_api";
    constructor()
    {
        super(m => m.api);
        this.api = null;
    }

    async onRefreshChainData(dApp)
    {
        if (this.api === null)
        {
            return;
        }

        //governance
        if (dApp.account !== null
            && this.api.userGovernance === null)
        {
            await MLUtils.measureTime(`TM: API => Governance`, async () =>
            {
                await this.api.governance_getUserData(dApp.account);
            });
        }
    }

    async onLoad(dApp)
    {
        if (!Module_MoonAPI.availableOnChain(dApp.currentChain))
        {
            return;
        }

        dApp.log(false, `Loading API`);
        this.api = MoonAPI.getInstance();
        this.setLoaded();
    }

    static availableOnChain(chain)
    {
        return true;
    }
}

export const useMoonAPI = () => MoonAPI.getInstance();

const currentModule = new Module_MoonAPI();
export default currentModule;