//framework
import { MLFormat, MLWeb3 } from "../utils";
import DApp from "../core/DApp";

//classes
import { Token } from "./Token";
import { Router } from "./Router";

export class Oracle
{
	constructor(config)
	{
		this.debug = config.showPrices;
		this.pairs = [];
	}

	debugErrorString(_text)
	{
		return `Oracle failed at: ${_text}`;
	}

	/////////////////////////
    // Load price pairs
    /////////////////////////

	static async batch_loadPricePairs(_tokens)
	{
        return await Oracle.getPricePairsToLoad(_tokens)
        .then((pairsToLoad) => Router.batch_findPair(pairsToLoad))
        .then((foundPairs) => Token.batch_init(foundPairs));
	}

	static async getPricePairsToLoad(_tokens)
	{
		//init routers
		await Router.batch_init(DApp.instance.routers);

		let tokenPairs = [];
		for (let n = 0; n < _tokens.length; n++)
		{
			const t = _tokens[n];

			//get router
			const router = DApp.instance.findRouter(t.router);
			if (router === null
				|| !router.initialized)
			{
				continue;
			}

			//get partner
			let pairPartner = null;
			if (t.oracleType === "LPCoin")
			{
				pairPartner = DApp.instance.wrappedCoin;
			}
			else if (t.oracleType === "LPStable")
			{
				pairPartner = DApp.instance.stableCoin;
				if (t.oracleParameter !== "")
				{
					pairPartner = DApp.instance.findToken(t.oracleParameter);
				}
			}
			else if (t.oracleType === "LPMirror")
			{
				pairPartner = DApp.instance.findToken(t.oracleParameter);
			}
			else
			{
				continue;
			}

			//check pair
			const routerPair = router.lookupPair(t, pairPartner);
			if (routerPair !== null)
			{
				continue;
			}

			//add to list
			tokenPairs.push(
			{
				router: router,
				token0: t,
				token1: pairPartner
			});
		}

		return tokenPairs;
	}

	/////////////////////////
    // reload price data
    /////////////////////////

	async reloadPriceData(_token)
	{
		if (_token.lastPriceUpdate !== null
			&& ((new Date()).getTime() - _token.lastPriceUpdate) / 1000 < 60)
		{
			return;
		}

		switch (_token.oracleType)
		{
			case "Stable":
				//get unit of stable
				return await this.reloadPriceData_Stable(_token);

			case "LP":
				//token is LP token, so get price by value of child tokens and amount (no param)
				return await this.reloadPriceData_LP(_token);

			case "Alias":
				//user price of parameter token (token to get price from)
				return await this.reloadPriceData_Alias(_token);

			case "LPStable":
				//search token-stable to make price (optional parameter: LP)
				return await this.reloadPriceData_LPStable(_token);

			case "LPCoin":
				//search token-coin to make price (optional parameter LP)
				return await this.reloadPriceData_LPCoin(_token);

			case "LPMirror":
				//use parameter LP and other token value to get current token value (LP to use)
				return await this.reloadPriceData_LPMirror(_token);

			case "Special":
				//this token price will be calculated elsewhere
				return;

			default:
				console.error(this.debugErrorString("getPrice [" + _token.getFullName() + "]"))
				return;
		}
	}

	async reloadPriceData_Stable(_token)
	{
		//set value
		_token.unitPriceUSD = DApp.instance.stableCoin.one;
		_token.liquidityValue = DApp.instance.stableCoin.one;
		_token.liquidityAmount = _token.one;
		_token.lastPriceUpdate = new Date();
		_token.initializedPrice = true;
		_token.priceUpdated();
		this.logUnitPrice(_token);

		return _token.unitPriceUSD;
	}

	async reloadPriceData_LP(_token)
	{
		//check
		const token0 = DApp.instance.findToken(_token.token0);
		const token1 = DApp.instance.findToken(_token.token1);
		if (!token0.initializedPrice
			|| !token1.initializedPrice)
		{
			return MLWeb3.toBN(0);
		}

		//tokens
		const token0USD = token0.unitPriceUSD.mul(_token.token0Reserve).div(token0.one);
		const token1USD = token1.unitPriceUSD.mul(_token.token1Reserve).div(token1.one);

		//total amount & unit price
		const totalPrice = token0USD.add(token1USD);
		const unitPrice = totalPrice.mul(_token.one).div(_token.totalSupply);
		if (unitPrice.isZero())
        {
            return MLWeb3.toBN(0);
        }

		//set value
		_token.unitPriceUSD = unitPrice;
		_token.liquidityValue = totalPrice;
		_token.liquidityAmount = _token.totalSupply;
		_token.lastPriceUpdate = new Date();
		_token.initializedPrice = true;
		_token.priceUpdated();
		this.logUnitPrice(_token);

		return _token.unitPriceUSD;
	}

	async reloadPriceData_Alias(_token)
	{
		//find alias
		const tokenAlias = DApp.instance.findToken(_token.oracleParameter);
		if (tokenAlias === null
			|| !tokenAlias.initialized)
		{
			console.error(this.debugErrorString(`getPrice_Alias [${_token.getFullName()}]`));
			return MLWeb3.toBN(0);
		}
		if (tokenAlias.unitPriceUSD.isZero())
        {
            return MLWeb3.toBN(0);
        }

		//set value
		_token.unitPriceUSD = tokenAlias.unitPriceUSD;
		_token.liquidityValue = tokenAlias.liquidityValue;
		_token.liquidityAmount = tokenAlias.liquidityAmount;
		_token.lastPriceUpdate = new Date();
		_token.initializedPrice = true;
		_token.priceUpdated();
		this.logUnitPrice(_token);

		return _token.unitPriceUSD;
	}

	async reloadPriceData_LPStable(_token)
	{
		let stable = DApp.instance.stableCoin;
		if (_token.oracleParameter !== "")
		{
			stable = DApp.instance.findToken(_token.oracleParameter);
		}
		const pair = await this.findPair(_token, stable);
		if (pair === null
			|| !pair.initialized)
		{
			console.error(this.debugErrorString(`getPrice_LPStable [${_token.getFullName()}]`));
			return MLWeb3.toBN(0);
		}

		//get exchange rate
		const res = this.getTokenLPReserves(pair, _token);
		if (res.tokenReserve.isZero())
        {
            return MLWeb3.toBN(0);
        }
		if (stable.decimals !== DApp.instance.stableCoin.decimals)
		{
			if (stable.decimals > DApp.instance.stableCoin.decimals)
			{
				res.partnerReserve = res.partnerReserve.mul(
					MLWeb3.toBN(10).pow(
						MLWeb3.toBN(stable.decimals - DApp.instance.stableCoin.decimals)));
			}
			else
			{
				res.partnerReserve = res.partnerReserve.div(
					MLWeb3.toBN(10).pow(
						MLWeb3.toBN(DApp.instance.stableCoin.decimals - stable.decimals)));
			}
		}
		const unitPrice = res.partnerReserve.mul(_token.one).div(res.tokenReserve);
		if (unitPrice.isZero())
        {
            return MLWeb3.toBN(0);
        }

		//set value
		_token.unitPriceUSD = unitPrice;
		_token.liquidityValue = res.partnerReserve;
		_token.liquidityAmount = res.tokenReserve;
		_token.lastPriceUpdate = new Date();
		_token.initializedPrice = true;
		_token.priceUpdated();
		this.logUnitPrice(_token);

		return _token.unitPriceUSD;
	}

	async reloadPriceData_LPCoin(_token)
	{
		const wrapped = DApp.instance.wrappedCoin;
		const pair = await this.findPair(_token, wrapped);
		if (pair === null
			|| !pair.initialized)
		{
			console.error(this.debugErrorString(`getPrice_LPCoin [${_token.getFullName()}]`));
			return MLWeb3.toBN(0);
		}

		//get exchange rate
		const res = this.getTokenLPReserves(pair, _token);
		if (res.tokenReserve.isZero())
        {
            return MLWeb3.toBN(0);
        }
		const stableReserve = wrapped.getPriceUSDForAmount(res.partnerReserve);
		const unitPrice = stableReserve.mul(_token.one).div(res.tokenReserve);
		if (unitPrice.isZero())
        {
            return MLWeb3.toBN(0);
        }

		//set value
		_token.unitPriceUSD = unitPrice;
		_token.liquidityValue = stableReserve;
		_token.liquidityAmount = res.tokenReserve;
		_token.lastPriceUpdate = new Date();
		_token.initializedPrice = true;
		_token.priceUpdated();
		this.logUnitPrice(_token);

		return _token.unitPriceUSD;
	}

	async reloadPriceData_LPMirror(_token)
	{
		const mirror = DApp.instance.findToken(_token.oracleParameter);
		const pair = await this.findPair(_token, mirror);
		if (pair === null
			|| !pair.initialized)
		{
			console.error(this.debugErrorString(`getPrice_LPMirror [${_token.getFullName()}]`));
			return MLWeb3.toBN(0);
		}

		//get exchange rate
		const res = this.getTokenLPReserves(pair, _token);
		if (res.tokenReserve.isZero())
        {
            return MLWeb3.toBN(0);
        }
		const stableReserve = mirror.getPriceUSDForAmount(res.partnerReserve);
		const unitPrice = stableReserve.mul(_token.one).div(res.tokenReserve);
		if (unitPrice.isZero())
        {
            return MLWeb3.toBN(0);
        }

		//set value
		_token.unitPriceUSD = unitPrice;
		_token.liquidityValue = stableReserve;
		_token.liquidityAmount = res.tokenReserve;
		_token.lastPriceUpdate = new Date();
		_token.initializedPrice = true;
		_token.priceUpdated();
		this.logUnitPrice(_token);

		return _token.unitPriceUSD;
	}

	/////////////////////////
    // helper
    /////////////////////////

	static sortTokensByOracleType(_tokens)
    {
        _tokens.sort((_a, _b) => //ensure correct order for oracle
        {
            const oracleTypeOrder =
            [
                "Stable",
                "LPStable",
                "LPCoin",
                "LPMirror",
                "Alias",
                "LP"
            ];

            const idxA = oracleTypeOrder.indexOf(_a.oracleType);
            const idxB = oracleTypeOrder.indexOf(_b.oracleType);
            if (idxA === idxB)
            {
                return (_a.address > _b.address ? 1 : -1);
            }
            return (idxA > idxB ? 1 : -1);
        });

        return _tokens;
    }

	getTokenLPReserves(_pair, _token)
	{
		//get price
		if (MLWeb3.checkEqualAddress(_token.address, _pair.token0))
		{
			return {
				tokenReserve: _pair.token0Reserve,
				partnerReserve: _pair.token1Reserve
			};
		}
		else
		{
			return {
				tokenReserve: _pair.token1Reserve,
				partnerReserve: _pair.token0Reserve
			};
		}
	}

	async findPair(_token, _tokenPartner)
	{
		const router = DApp.instance.findRouter(_token.router);
		if (router === null)
		{
			return null;
		}

		return await router.lookupPair(_token, _tokenPartner);
	}

	logUnitPrice(_token, _forceOutput)
	{
		if (!this.debug
			&& !_forceOutput)
		{
			return;
		}
		const v3 = (_token.v3PoolFee === 0 ? "" : `: ${MLFormat.formatPercent(_token.v3PoolFee)}`);
		console.log(`Oracle Unit Price [${_token.getFullName()} @ ${_token.oracleType}${v3}] = ${DApp.instance.smartFormatFiat(_token.unitPriceUSD)}`);
	}
}

export default Oracle;