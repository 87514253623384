//framework
import { DAppModule, MLUtils, useModule } from "@MoonLabsDev/dapp-core-lib";

//classes
import { Escrow } from "../classes/Escrow";

export const ModuleEvents =
{
    data: "escrow_data",
    tradeInfo: "escrow_tradeInfo",
    userInfo: "escrow_userInfo"
};

export class Module_Escrow extends DAppModule
{
    static moduleName = "escrow";
    constructor()
    {
        super(m => m.escrow);
        this.escrow = null;
    }

    async onRefreshChainData(dApp)
    {
        if (this.escrow === null)
        {
            return;
        }

        //data
        await MLUtils.measureTime(`TM: Escrow => Data`, async () =>
        {
            await this.escrow.reload_data();
        });

        //userInfo
        if (dApp.account !== null)
        {
            await MLUtils.measureTime(`TM: Escrow => UserInfo ${this.escrow.userInfo.length}`, async () =>
            {
                await this.escrow.batch_userInfo(this.escrow.userInfo);
            });
        }

        //tradeInfo
        await MLUtils.measureTime(`TM: Escrow => Trades ${this.escrow.trades.length}`, async () =>
        {
            await this.escrow.batch_tradeInfo(this.escrow.trades);
        });
    }

    async onLoad(dApp)
    {
        if (!Module_Escrow.availableOnChain(dApp.currentChain))
        {
            return;
        }

        const addr = dApp.currentChain?.modules?.escrow;
        dApp.log(false, `Loading Escrow [${addr}]`);
        this.escrow = new Escrow(addr);
        this.setLoaded();
    }

    static availableOnChain(chain)
    {
        const addr = chain?.modules?.escrow;
        return (addr !== undefined
            && addr !== "");
    }
}

export const useEscrow = () => useModule(Module_Escrow);

const currentModule = new Module_Escrow();
export default currentModule;