import { useState, useEffect } from "react";

//components
import
{
    Text,
    Flex,
    Grid,
    List,
    ValueLoad,
    ToolButton,
    ToolButtonType,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardRibbon,
    CardRibbonType
} from "@MoonLabsDev/dapp-core-lib";
import EscrowOfferItem from "./EscrowOfferItem";
import ModalTradeSettings from "../Modals/ModalTradeSettings";

//framework
import { MLTranslate as t } from "@MoonLabsDev/dapp-core-lib";
import { MLWeb3, MLFormat } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useEscrow } from "../../../../modules";
import { ModuleEvents } from "../../../..//modules/Module_Escrow";

//classes
import { TradeStatus } from "../../../../../dApp/classes/Escrow";

//hooks
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//style
import styles from "../Escrow.module.css";


const EscrowToolBar = (props) =>
{
    //hooks
    const escrow = useEscrow();

    //vars
    const trade = escrow.findTrade(props.tradeID);
    const offer = escrow.getTradeOffer(trade, props.offerID);

    //state
    const [tradeID, setTradeID] = useState(props.tradeID);
    const [offerID, setOfferID] = useState(props.offerID);
    const [revision, setRevision] = useState(props.revision);
    const [isOwner, setIsOwner] = useState(props.isOwner);
    const [showSettings, setShowSettings] = useState(() => false);

    //handler
    const handleAcceptOffer = () =>
    {
        escrow.acceptOffer(tradeID, revision).send();
    };
    const handleSaveOffer = () =>
    {
        escrow.setOffer(tradeID, offer).send();
    };
    const handleSaveItem = (_item) =>
    {
        if (!_item.amount.isZero())
        {
            const itemFound = offer?.items?.find(i => i.contract === _item.contract && i.nftID === _item.nftID) || null;
            if (itemFound)
            {
                itemFound.amount = _item.amount;
            }
            else
            {
                offer.items.push(_item);
            }
        }
    };
    const handleAddItem = () =>
    {
        if (props.openEditModal)
        {
            props.openEditModal(null, handleSaveItem)
        }
    };

    //effect
    useEffect(() =>
    {
        setTradeID(props.tradeID);
        setRevision(props.revision);
        setOfferID(props.offerID);
        setIsOwner(props.isOwner);
    }, [props.tradeID, props.revision, props.offerID, props.isOwner]);

    return (
        <Flex>
            {isOwner &&
                <>
                    <ToolButton
                        type={ToolButtonType.Add}
                        onClick={() => handleAddItem()}
                    />
                    <ToolButton
                        type={ToolButtonType.ConfirmOffer}
                        onClick={() => handleSaveOffer()}
                    />
                    <ToolButton
                        type={ToolButtonType.Settings}
                        onClick={() => setShowSettings(true)}
                    />
                    <ModalTradeSettings
                        show={showSettings}
                        tradeID={tradeID}
                        offer={offer}
                        onClose={() => setShowSettings(false)}
                    />
                </>
            }
            {!isOwner &&
                <>
                    <ToolButton
                        type={ToolButtonType.Confirm}
                        onClick={() => handleAcceptOffer()}
                    />
                </>
            }
        </Flex>
    );
};

const EscrowOffer = (props) =>
{
    //hooks
    const dApp = useDApp();
    const escrow = useEscrow();

    //vars
    const trade = escrow.findTrade(props.tradeID);
    const offer = escrow.getTradeOffer(trade, props.offerID);

    //state
    const [tradeID, setTradeID] = useState(() => props.tradeID);
    const [tradeInitialized, setTradeInitialized] = useState(trade?.initialized);
    const [offerID, setOfferID] = useState(() => props.offerID);
    const [revision, setRevision] = useState(() => offer?.revision);
    const [isOwner, setIsOwner] = useState(() => MLWeb3.checkEqualAddress(offer?.owner, dApp.account));
    const [isUserTrade, setIsUserTrade] = useState(() => escrow.checkIsUserTrade(trade));
    const [tradeStatus, setTradeStatus] = useState(() => trade?.status);
    const [receiver, setReceiver] = useState(() => (MLWeb3.isZeroAddress(offer?.receiver) ? "" : offer.receiver));
    const [accepted, setAccepted] = useState(() => trade?.accepted);

    //handler
    const handleTradeUpdate = () =>
    {
        setRevision(offer?.revision);
        setTradeStatus(trade?.status);
    };

    //effect
    useEffect(() =>
    {
        setTradeID(props.tradeID);
        setOfferID(props.offerID);
    }, [props.tradeID, props.offerID]);
    useEffect(() =>
    {
        setRevision(offer?.revision);
        setAccepted(offer?.accepted);
        setReceiver((MLWeb3.isZeroAddress(offer?.receiver) ? "" : offer.receiver));
    }, [offer?.revision, offer?.accepted, offer?.receiver]);
    useEffect(() =>
    {
        setTradeInitialized(trade?.initialized);
        setIsUserTrade(escrow.checkIsUserTrade(trade));
        setIsOwner(MLWeb3.checkEqualAddress(offer?.owner, dApp.account));
    }, [trade?.initialized, tradeID, props.offerID]);
    useEventSubscription(ModuleEvents.tradeInfo, handleTradeUpdate, [offer]);

    //maybe add Coin to first position
    if (!offer?.items?.find((i) => i.type === "Coin"))
    {
        offer?.items?.unshift(
        {
            type: "Coin",
            contract: "",
            amount: offer.balance
        });
    }
    return (
        <Card
            className={styles.offer}
            ribbon=
            {
                <CardRibbon
                    type={CardRibbonType.Up}
                    color={accepted ? "#00FF00" : "#FF0000"}
                >
                    {" "}
                </CardRibbon>
            }
        >
            <CardHeader>
                <Grid
                    gap="0px"
                >
                    <Text>
                        {isUserTrade &&
                            (MLWeb3.checkEqualAddress(dApp.account, offer?.owner)
                                ? t("escrow_yourOffer")
                                : t("escrow_partnerOffer")
                            )
                        }
                        {!isUserTrade
                            && <>{MLFormat.formatAddress(offer?.owner, true)}</>
                        }
                    </Text>
                    {(offer && revision !== -1) &&
                        <Text
                            size={-1}
                            color={2}
                        >
                            #{revision}
                            {receiver !== "" &&
                                <>
                                    {" "}=&gt; {MLFormat.formatAddress(receiver, true)}
                                </>
                            }
                        </Text>
                    }
                </Grid>
            </CardHeader>

            <ValueLoad
                loaded={tradeInitialized}
            >
                {tradeInitialized &&
                    <>
                        <CardBody>
                            <List
                                className={styles.offer_list}
                                itemClassName={styles.offerItem_row}
                                items=
                                {offer?.items?.map((i) =>
                                    {
                                        return {
                                            value: i.contract,
                                            content: (
                                                <EscrowOfferItem
                                                    item={i}
                                                    offer={offer}
                                                    offerID={offerID}
                                                    isOwner={isOwner}
                                                    tradeStatus={tradeStatus}
                                                    openEditModal={props.openEditModal}
                                                />
                                            )
                                        }
                                    }
                                )}
                            />
                        </CardBody>

                        {(isUserTrade && tradeStatus === TradeStatus.ONGOING) &&
                            <CardFooter>
                                <EscrowToolBar
                                    tradeID={tradeID}
                                    offerID={offerID}
                                    revision={revision}
                                    isOwner={isOwner}
                                    openEditModal={props.openEditModal}
                                />
                            </CardFooter>
                        }
                    </>
                }
            </ValueLoad>
        </Card>
    );
};

export default EscrowOffer;