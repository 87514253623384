//components
import { SelectChainButton, ConnectWalletButton } from "@MoonLabsDev/dapp-core-lib";
import { UserMenuStyle } from "./types"

//styles
import styles from "./UserMenu.module.css";

const UserMenu = ({ menuStyle }) =>
{
    //generate menu
    let menuContent = <></>;
    let menuClass = "";
    switch (menuStyle)
    {
        default:
            //normal menu
            menuClass = styles.menuMode_normal;
            menuContent = (
                <>
                    <SelectChainButton />
                    <ConnectWalletButton />
                </>
            );
            break;
    }

    //normal menu
    return (
        <div
            className=
            {
                [
                    styles.menuContainer,
                    (menuStyle === UserMenuStyle.App ? styles.menuMode_app : styles.menuMode_desktop)
                ].join(" ")
            }
        >
            {menuContent}
        </div>
    );
};

export default UserMenu;