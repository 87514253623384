import { useState, useEffect } from "react";

//components
import
{
    Text,
    Grid,
    Flex,
    ToolButton,
    ToolButtonType,
    ValueTokenAmount,
    ValueNFTName,
    ValueNFTAmount,
    TokenIcon,
    NFTIcon
} from "@MoonLabsDev/dapp-core-lib";
import EscrowOfferItemState from "./EscrowOfferItemState";

//framework
import { MLWeb3 } from "@MoonLabsDev/dapp-core-lib";
import { OfferItemType } from "../../../../../dApp/classes/Escrow";
import Events from "@MoonLabsDev/dapp-core-lib";

//modules
import { useEscrow } from "../../../../modules";

//classes
import { TradeStatus } from "../../../../../dApp/classes/Escrow";

//hooks
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//style
import styles from "../Escrow.module.css";


const generateEscrowTokenItem = (_item, _isOwner, _editItem) =>
{
    //hooks
    const dApp = useDApp();

    const isCoin = (_item.type === "Coin");
    const token = (isCoin
        ? dApp.wrappedCoin
        : dApp.findToken(_item.contract));
    const symbol = token?.symbol?.substring((isCoin ? 1 : 0)) || "";
    return (
        <>
            <TokenIcon
                className={styles.offerItem_icon}
                token={token}
            />
            <Grid gap="0px">
                <Text>
                    {symbol}
                </Text>
                <Text size={-1}>
                    <ValueTokenAmount
                        token={token}
                        value={_item.amount}
                        shorten={true}
                        load={true}
                    />
                </Text>
            </Grid>
        </>
    );
};

const generateEscrowTokenNFT = (_item, _isOwner, _editItem) =>
{
    //hooks
    const dApp = useDApp();

    const nft = dApp.findNFT(_item.contract);
    return (
        <>
            <NFTIcon
                className={styles.offerItem_icon}
                nft={nft}
                nftID={_item.nftID}
            />
            <Grid gap="0px">
                <ValueNFTName
                    nft={nft}
                    nftID={_item.nftID}
                />
                <Text size={-1}>
                    <ValueNFTAmount
                        nft={nft}
                        nftID={_item.nftID}
                        value={_item.amount}
                        shorten={true}
                        load={true}
                    />
                </Text>
            </Grid>
        </>
    );
};

const EscrowOfferItem = (props) =>
{
    //hooks
    const dApp = useDApp();
    const escrow = useEscrow();

    //functions
    const checkApproved = (_item) =>
    {
        if (!!_item)
        {
            if (_item.type === OfferItemType.ERC20)
            {
                const t = dApp.findToken(_item.contract);
                t?.checkApproved(escrow.address);
            }
            else if (_item.type === OfferItemType.ERC721
                || _item.type === OfferItemType.ERC1155)
            {
                const n = dApp.findNFT(_item.contract);
                n?.checkApprovedForAll(escrow.address);
            }
        }

        return _item;
    };

    //state
    const [isOwner, setIsOwner] = useState(() => props.isOwner);
    const [item, setItem] = useState(() => checkApproved(props.item || null));
    const [offer, setOffer] = useState(() => props.offer || null);
    const [tradeStatus, setTradeStatus] = useState(() => props.tradeStatus);

    //handler
    const handleSave = (_item) =>
    {
        if (_item.amount.isZero()
            && _item.type !== "Coin")
        {
            handleDelete(_item);
            return;
        }
        const itemFound = offer?.items?.find(i => i.contract === _item.contract && i.nftID === _item.nftID) || null;
        if (itemFound)
        {
            itemFound.amount = _item.amount;
        }
    };
    const handleDelete = (_item) =>
    {
        const itemFound = offer?.items?.find(i => i.contract === _item.contract) || null;
        if (itemFound)
        {
            const idx = offer?.items?.indexOf(itemFound);
            offer.items.splice(idx, 1);
        }
    };
    const handleEditItem = () =>
    {
        if (props.openEditModal)
        {
            props.openEditModal(item, handleSave, handleDelete)
        }
    };
    const handleTokenUpdate = (data) =>
    {
        if (!MLWeb3.checkEqualAddress(data.detail.address, item.contract))
        {
            return;
        }
        setItem(item);
    };
    const handleNFTUpdate = (data) =>
    {
        if (!MLWeb3.checkEqualAddress(data.detail.address, item.contract))
        {
            return;
        }
        setItem(item);
    };

    //effect
    useEffect(() =>
    {
        setItem(checkApproved(props.item));
        setIsOwner(props.isOwner);
        setTradeStatus(props.tradeStatus);
    }, [JSON.stringify(props.item), props.isOwner, props.tradeStatus]);
    useEffect(() =>
    {
        setOffer(props.offer);
    }, [JSON.stringify(props.offer)]);
    useEventSubscription(Events.token.init, handleTokenUpdate, [item]);
    useEventSubscription([Events.nft.init, Events.nft.metaData], handleNFTUpdate, [item]);

    //values
    return (
        <>
            {(item.type === "Coin" || item.type === OfferItemType.ERC20
                ? generateEscrowTokenItem(item, isOwner, handleEditItem)
                : generateEscrowTokenNFT(item, isOwner, handleEditItem)
            )}
            {(isOwner && tradeStatus === TradeStatus.ONGOING) &&
                <Flex
                    style={{ marginLeft: "auto" }}
                >
                    <EscrowOfferItemState
                        item={item}
                    />
                    <ToolButton
                        type={ToolButtonType.Edit}
                        onClick={() => handleEditItem()}
                    />
                </Flex>
            }
        </>
    );
};

export default EscrowOfferItem;