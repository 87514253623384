import { configLabels } from "@MoonLabsDev/dapp-core-lib";

const translation =
{
	...configLabels.en_US,

    //escrow
    escrow_yourOffer: "Your Offer:",
    escrow_partnerOffer: "Partner Offer:",
    modal_escrowEditItem_title: "Make Offer",
    modal_escrowTradeSettings_title: "Settings",

    //swap
    modal_swapSettings_title: "Settings",

	//bank
	modal_bankDeposit_title: "Deposit",
	modal_bankTransfer_title: "Transfer"
};

export default translation;