import { useState } from "react";

//framework
import { MLUI } from "../../../utils";
import
{
    tool_confirm,
    tool_confirmOffer,
    tool_edit,
    tool_trash,
    tool_refresh,
    tool_add,
    tool_wallet,
    tool_settings
} from "../../../symbols";

//styles
import styles from "./Buttons.module.css";

export const ToolButtonType =
{
    Delete: "delete",
    Confirm: "confirm",
    ConfirmOffer: "confirmOffer",
    Edit: "edit",
    Refresh: "refresh",
    Add: "add",
    Wallet: "wallet",
    Settings: "settings"
};

const ToolButton = (props) =>
{
    //functions
    const selectIcon = () =>
    {
        switch (props.type)
        {
            case ToolButtonType.Add:
                return MLUI.imageOrSymbol(tool_add(), styles.toolIcon);

            case ToolButtonType.Settings:
                return MLUI.imageOrSymbol(tool_settings(), styles.toolIcon);

            case ToolButtonType.Edit:
                return MLUI.imageOrSymbol(tool_edit(), styles.toolIcon);

            case ToolButtonType.Confirm:
                return MLUI.imageOrSymbol(tool_confirm(), styles.toolIcon);

            case ToolButtonType.ConfirmOffer:
                return MLUI.imageOrSymbol(tool_confirmOffer(), styles.toolIcon);

            case ToolButtonType.Delete:
                return MLUI.imageOrSymbol(tool_trash(), styles.toolIcon);

            case ToolButtonType.Refresh:
                return MLUI.imageOrSymbol(tool_refresh(), styles.toolIcon);

            case ToolButtonType.Wallet:
                return MLUI.imageOrSymbol(tool_wallet(), styles.toolIcon);
        }
        return <></>;
    }
    const selectClass = () =>
    {
        switch (props.type)
        {
            case ToolButtonType.Edit:
                return styles.tool_yellow;

            case ToolButtonType.Confirm:
                return styles.tool_green;

            case ToolButtonType.Delete:
                return styles.tool_red;

            case ToolButtonType.Refresh:
                return styles.tool_red;
        }
        return "";
    };

    if (props.href === undefined)
    {
        //BUTTON
        return (
            <button
                className=
                {
                    [
                        styles.toolButton,
                        selectClass(),
                        props.className
                    ].join(" ")
                }
                onClick={props.onClick}
                title={props.title}
                style={props.style}
            >
                {selectIcon()}
            </button>
        );
    }
    else
    {
        //A
        return (
            <a
                className=
                {
                    [
                        styles.toolButton,
                        selectClass(),
                        props.className
                    ].join(" ")
                }
                href={props.href}
                target={props.target}
                title={props.title}
                style={props.style}
            >
                {selectIcon()}
            </a>
        );
    }
};

export default ToolButton;