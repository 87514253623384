import { useTheme } from "../../../hooks";

//styles
import styles from "./Card.module.css";

const CardFooter = (props) =>
{
	const theme = useTheme();

    return (
        <div
			className=
			{
				[
					theme?.getClassName("CardFooter") ?? "",
					styles.cardFooter,
					props.className
				].join(" ")
			}
			style={props.style}
		>
            {props.children}
        </div>
    );
};

export default CardFooter;