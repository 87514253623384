//framework
import { DAppModule, MLUtils, useModule } from "@MoonLabsDev/dapp-core-lib";

//classes
import { FeeDistributor } from "../classes/FeeDistributor";

export const ModuleEvents =
{
    init: "feeDistributor_init",
};

export class Module_FeeDistributor extends DAppModule
{
    static moduleName = "fee_distributor";
    constructor()
    {
        super();
        this.feeDistributor = null;
    }

    async onRefreshChainData(dApp)
    {
        if (this.feeDistributor === null)
        {
            return;
        }

        //data
        if (!this.feeDistributor.initialized)
        {
            await MLUtils.measureTime(`TM: FeeDistributor => Init`, async () =>
            {
                await this.feeDistributor.reload_init();
            });
        }
    }

    async onLoad(dApp)
    {
        if (!Module_FeeDistributor.availableOnChain(dApp.currentChain))
        {
            return;
        }

        const addr = dApp.currentChain?.modules?.feeDistributor;
        dApp.log(false, `Loading FeeDistributor [${addr}]`);
        this.feeDistributor = new FeeDistributor(addr);
        this.setLoaded();
    }

    static availableOnChain(chain)
    {
        const addr = chain?.modules?.feeDistributor;
        return (addr !== undefined
            && addr !== "");
    }
}

export const useFeeDistributor = () => useModule(Module_FeeDistributor);

const currentModule = new Module_FeeDistributor();
export default currentModule;