import Theme from "../../themes/MoonLabs/Theme.module.css";

const configUI =
{
	navLinkComponent: (props) =>
	{
		return (
			<a
				style={props.style}
				className={props.className}
				onClick={props.className}
			>
				{props.children}
			</a>
		);
	},
	theme: Theme,
	classPrefix: "",
    mediaQuery:
    {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400
    }
};

export default configUI;