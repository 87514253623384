//framework
import { DApp, MLWeb3, MLMultiCall, Web3Transaction } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "..//modules/Module_MoonSwap";

//contracts
import ABI_MoonSwap from "../abi/MoonSwap";

export class MoonSwap
{
	////////////////////////////////////

	static txCallbacks =
	{
		swap: null
	};

	constructor(_address)
	{
        this.initialized = false;

		//values
		this.address = _address;
		this.percentFactor = 1;
        this.fee = 0;

		//data
		this.swapInfo = null;
        this.swapInfoGrouped = null;
        this.pollSwapInfo = null;

        //load additional tokens
		if (!!DApp.instance)
		{
			this.additionalTokens = [];
			this.additionalTokens.push(DApp.instance.wrappedCoin);
			DApp.instance?.tokens
				.filter(t => t.oracleType === "Stable")
				.forEach(t => this.additionalTokens.push(t));

			//load routers
			this.routers = DApp.instance.routers
				.filter(r => !r.data?.notUniswap);
		}
	}

	////////////////////////////////////

	debugErrorString(_text)
	{
		return `MoonSwap failed at: ${_text}`;
	}

	static setTxCallback(_name, _cb)
	{
		MoonSwap.txCallbacks[_name] = _cb;
	}

    getContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(ABI_MoonSwap, this.address).methods;
    }

    makeMultiCall(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.address,
            ABI_MoonSwap,
            _calls
        );
    }

    dispatchEvent(_name)
    {
        document.dispatchEvent(new CustomEvent(_name));
    }

    /////////////////////////
    // Init
    /////////////////////////

    async reload_init()
    {
        if (this.initialized)
        {
            return;
        }

        await DApp.instance.batchCall(
            [this],
            (o) => o.makeRequest_init(),
            (o, r) => o.processRequest_init(r),
            false,
            "[MoonSwap] init",
            "MoonSwap: init"
        );
    }

    makeRequest_init()
    {
        return this.makeMultiCall(
        {
            percentFactor: { function: "PERCENT_FACTOR" },
            swapFee: { function: "swapFee" }
        });
    }

    async processRequest_init(_data)
    {
		this.percentFactor 	= parseInt(_data.percentFactor);
        this.fee			= parseFloat(_data.swapFee) / this.percentFactor;

        //process
        this.initialized = true;

        //event
        this.dispatchEvent(ModuleEvents.initialized);
    }

    /////////////////////////
    // Data
    /////////////////////////

    async reload_swapInfo()
    {
        if (!this.initialized)
        {
            await this.reload_init();
        }
        if (this.pollSwapInfo === null
            || this.pollSwapInfo.from === this.pollSwapInfo.to)
        {
            return;
        }

        await DApp.instance.batchCall(
            [this],
            (o) => o.makeRequest_swapInfo(),
            (o, r) => o.processRequest_swapInfo(r),
            false,
            "[MoonSwap] swapInfo",
            "MoonSwap: swapInfo"
        );
    }

    makeRequest_swapInfo()
    {
        const tokenList = this.additionalTokens.map(t => t.address);
        const routerList = this.routers.map(r => r.address);

        return this.makeMultiCall(
        {
            swapInfo:
            {
                function: "findSwapPathes",
                parameters:
                [
                    this.pollSwapInfo.from,
                    this.pollSwapInfo.to,
                    this.pollSwapInfo.amount.toString(10),
                    routerList,
                    tokenList
                ]
            }
        });
    }

    async processRequest_swapInfo(_data)
    {
        this.swapInfo		= _data.swapInfo;

		//process
		this.swapInfo.forEach(i => i.amountOut = MLWeb3.toBN(i.amountOut));
        this.swapInfo = this.swapInfo.filter(i => !i.amountOut.isZero());
        this.swapInfo.sort((a, b) => b.amountOut.cmp(a.amountOut)); //high to low
        this.swapInfoGrouped = this.swapInfoGroupByRouter(this.swapInfo);

        //event
        this.dispatchEvent(ModuleEvents.swapInfo);
    }

    /////////////////////////
    // Helper
    /////////////////////////

    getSwapInfo(_from, _to, _amount)
    {
        if (this.pollSwapInfo !== null
            && (this.pollSwapInfo.from !== _from
                || this.pollSwapInfo.to !== _to
                || this.pollSwapInfo.amount.cmp(_amount) !== 0))
        {
            this.swapInfo = [];
            this.swapInfoGrouped = [];

            //event
            this.dispatchEvent(ModuleEvents.swapInfo);
        }
        this.pollSwapInfo =
        {
            from: _from,
            to: _to,
            amount: _amount
        };
    }

    swapInfoGroupByRouter(_swapInfo)
    {
        if (!_swapInfo
            || _swapInfo.length === 0)
        {
            return _swapInfo;
        }

        const groups = [];
        const filtered = [];
        for (let n = 0; n < _swapInfo.length; n++)
        {
            const s = _swapInfo[n];
            if (groups.includes(s.router))
            {
                continue;
            }
            filtered.push(s);
            groups.push(s.router);
        }
        return filtered;
    }

	manuallySetSwapInfo(_router, _path, _out)
	{
		this.swapInfo =
		[
			{
				router: _router,
				path: _path,
				amountOut: _out
			}
		];
	}

	/////////////////////////
    // Transactions
    /////////////////////////

    swap(_from, _to, _amount, _slippage = 0.001, _referralID = 0)
    {
		if (!!MoonSwap.txCallbacks.swap)
		{
			MoonSwap.txCallbacks.swap(
				_from?.address || null,
				_to?.address || null,
				_amount,
				_slippage,
				_referralID,
				{
					router: this.swapInfo[0].router,
					path: this.swapInfo[0].path,
					out: this.swapInfo[0].amountOut
				}
			);
			return;
		}

        const router = this.swapInfo[0].router;
        const path = this.swapInfo[0].path;
        const out = this.swapInfo[0].amountOut;
        const minOut = out.mul(MLWeb3.toBN((1 - _slippage) * this.percentFactor)).div(MLWeb3.toBN(this.percentFactor));
        const description = `Swap ${(!!_from ? _from?.symbol : DApp.instance?.coinSymbol)}`;

        const con = this.getContract(true);
        if (_from === null)
        {
            //coin to token
            return new Web3Transaction(
                con.swapCoinToToken(
                    router,
                    path,
                    minOut,
					_referralID
                ),
                this.debugErrorString("swap"),
                description,
                _amount
			);
        }
        else if (_to === null)
        {
            //token to coin
            return new Web3Transaction(
                con.swapTokenToCoin(
                    router,
                    path,
                    _amount,
                    minOut,
					_referralID
                ),
                this.debugErrorString("swap"),
                description
			);
        }
        else
        {
            //token to token
            return new Web3Transaction(
                con.swapTokenToToken(
                    router,
                    path,
                    _amount,
                    minOut,
					_referralID
                ),
                this.debugErrorString("swap"),
                description
			);
        }
    }
}