import { useState, useEffect } from "react";

//components
import
{
    Text,
    Grid,
    Tooltip,
    TooltipPlacement,
    Flex
} from "@MoonLabsDev/dapp-core-lib";

//modules
import { useEscrow } from "../../../../modules";
import { ModuleEvents } from "../../../..//modules/Module_Escrow";

//hooks
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//style
import styles from "../Escrow.module.css";


const EscrowTradeState = (props) =>
{
    //hooks
    const dApp = useDApp();
    const escrow = useEscrow();

    //trade
    const [trade, setTrade] = useState(() => props.trade);
    useEffect(() =>
    {
        setTrade(props.trade);
    }, [props.trade]);

    //errors
    const checkErrorTime = () =>
    {
        if (!trade?.updated)
        {
            return false;
        }

        const diff = ((new Date()).getTime() - trade?.updated.getTime()) / 1000;
        return (diff >= 24 * 60 * 60);
    };
    const checkHasOffer = (_other) =>
    {
        if (!trade)
        {
            return false;
        }

        return (escrow.getTradeOfferByPartner(trade, dApp.account, _other).revision > 0);
    }
    const [errorTime, setErrorTime] = useState(checkErrorTime());
    const [hasYourOffer, setHasYourOffer] = useState(checkHasOffer(false));
    const [hasPartnerOffer, setHasPartnerOffer] = useState(checkHasOffer(true));
    const [approvalYou, setApprovalYou] = useState(escrow.getTradeOfferByPartner(trade, dApp.account, false)?.approved);
    const [approvalPartner, setApprovalPartner] = useState(escrow.getTradeOfferByPartner(trade, dApp.account, true)?.approved);
    const handleTradeUpdated = () =>
    {
        setErrorTime(checkErrorTime());
        setHasYourOffer(checkHasOffer(false));
        setHasPartnerOffer(checkHasOffer(true));
        setApprovalYou(escrow.getTradeOfferByPartner(trade, dApp.account, false)?.approved);
        setApprovalPartner(escrow.getTradeOfferByPartner(trade, dApp.account, true)?.approved);
    };
    useEventSubscription(ModuleEvents.tradeInfo, handleTradeUpdated, [trade]);

    return (
        <>
            {(!errorTime && hasYourOffer && (approvalYou || !hasPartnerOffer)) &&
                <div className={[styles.itemState, styles.itemState_ok].join(" ")} />
            }
            {(errorTime || !hasYourOffer || (!approvalYou && hasPartnerOffer) || (approvalYou && approvalPartner)) &&
                <Tooltip
                    placement={TooltipPlacement.Top}
                    content=
                    {
                        <Grid>
                            <Flex>
                                <Text color={3}>
                                    Problem detected:
                                </Text>
                            </Flex>
                            <Flex>
                                <Text
                                    color={1}
                                    size={-1}
                                >
                                    <ul
                                        style={{ listStyle: "none" }}
                                    >
                                        {errorTime &&
                                            <li>
                                                - Trade inactive &gt;= 24h
                                            </li>
                                        }
                                        {!hasYourOffer  &&
                                            <li>
                                                - Make an offer
                                            </li>
                                        }
                                        {(!approvalYou && hasPartnerOffer)&&
                                            <li>
                                                - Approve partner offer
                                            </li>
                                        }
                                        {(approvalYou && approvalPartner) &&
                                            <li>
                                                - Execute trade
                                            </li>
                                        }
                                    </ul>
                                </Text>
                            </Flex>
                        </Grid>
                    }
                >
                    <div
                        className=
                        {
                            [
                                styles.itemState,
                                (errorTime ? styles.itemState_error : styles.itemState_warning)
                            ].join(" ")
                        }
                    />
                </Tooltip>
            }
        </>
    );
}

export default EscrowTradeState;