import { Multicall } from "ethereum-multicall";

//framework
import MLWeb3 from "./MLWeb3";

const MLMultiCall =
{
    /////////////////////////
    // Config
    /////////////////////////

    config:
    {
        decode:
        {
            alwaysOutputPropertyWithIndex: true
        }
    },

    /////////////////////////
    // Execute Multicall
    /////////////////////////

	async call(_multiCall, _calls, _errorMsg, _topic, _default)
	{
		//performance debugging
		//console.log(`[MC] ${_topic} #${_calls.length}`)

		if (_multiCall === undefined
			&& _errorMsg !== undefined)
		{
			console.log(_errorMsg);
		}

		//prepare call references
		let refId = 0;
		let refMapId = 0;
		const referenceMap = [];
		for (let n = 0; n < _calls.length; n++)
		{
			const c = _calls[n];
			if (Array.isArray(c))
			{
				//replace with array elements
				const arr = c;
				const refMId = refMapId++;
				_calls.splice(n, 1);
				for (let m = 0; m < arr.length; m++)
				{
					arr[m].reference = refId++;
					referenceMap[arr[m].reference] = refMId;
					_calls.splice(n + m, 0, arr[m]);
				}
				n += c.length - 1;
			}
			else
			{
				//give reference
				c.reference = refId++;
				referenceMap[c.reference] = refMapId++;
			}
		}

		//execute call
		let ret = [];
		try
		{
			ret = await _multiCall.call(_calls).catch((e) =>
			{
				throw e;
			});
		}
		catch (e)
		{
			if (_errorMsg !== undefined)
			{
				console.log(_errorMsg);
			}
			if (_default !== undefined)
			{
				return _default;
			}
			throw e;
		}

		//flatten
		const flattenRet = this.flattenMultiCallResults(ret, _calls, referenceMap);

		//check for failed results
		for (let n = 0; n < flattenRet.length; n++)
		{
			const retChild = flattenRet[n];
			for (const [, value] of Object.entries(retChild))
			{
				if (value === undefined)
				{
					if (_errorMsg !== undefined)
					{
						console.error(_errorMsg);
					}
					if (_default !== undefined)
					{
						return _default;
					}
					throw new Error("MultiCall failed");
				}
			}
		}

		//return result
		return flattenRet;
	},

    /////////////////////////
    // Decode Result
    /////////////////////////

	findOutputTypesFromAbi(_abi, _functionName)
	{
        return _abi.find((a) => a.name?.trim() === _functionName.trim())?.outputs || null;
	},

	decodeMultiCallResultValue(_value)
	{
		if (Array.isArray(_value))
		{
			const ret = [];
			_value.forEach((v) => ret.push(this.decodeMultiCallResultValue(v)));
			return ret;
		}
		else if (_value.type === "BigNumber")
		{
			return MLWeb3.toBN(_value.hex).toString(10);
		}

		return _value;
	},

    decodeStructWithABI(_value, _abi)
    {
        const structObj = {};
        for (let n = 0; n < _abi.length; n++)
        {
            const val = _value[n];
            const type = _abi[n];
            const decoded = this.decodeValueWithABI(val, type);

            //add to struct
            if (type.name !== undefined
                && type.name !== "")
            {
                structObj[type.name] = decoded;
                if (this.config.decode.alwaysOutputPropertyWithIndex)
                {
                    structObj[n] = decoded;
                }
            }
            else
            {
                structObj[n] = decoded;
            }
        }
        return structObj;
    },

    decodeArrayWithABI(_value, _abi)
    {
        const arrayObj = [];
        if (!Array.isArray(_value))
        {
            _value = [_value];
        }
        for (let n = 0; n < _value.length; n++)
        {
            const val = _value[n];
            const decoded = this.decodeValueWithABI(val, _abi, true);

            //add to array
            arrayObj.push(decoded);
        }
        return arrayObj;
    },

    decodeValueWithABI(_value, _abi, _ignoreArray)
    {
        if (_abi.type.indexOf("[") !== -1
            && !_ignoreArray)
        {
            //handle array
            return this.decodeArrayWithABI(_value, _abi);
        }
        else if (_abi.components !== undefined)
        {
            //handle struct
            return this.decodeStructWithABI(_value, _abi.components);
        }

        if (typeof _value === "string"
            || !isNaN(_value))
        {
            return _value;
        }
        return (Array.isArray(_value) && _value.length === 1 ? _value : _value[0]); //array to flat
    },

	decodeMultiCallResultWithABI(_value, _abi)
	{
        if (_abi.length === 1)
        {
			if (_abi[0].type.indexOf("[") !== -1)
			{
				//handle array
				return this.decodeArrayWithABI(_value, _abi[0]);
			}
			else
			{
				//handle value
            	return this.decodeValueWithABI(_value[0], _abi[0]);
			}
        }
        else
        {
            return this.decodeStructWithABI(_value, _abi);
        }
	},

	flattenMultiCallResults(_results, _calls, _referenceMap)
	{
        //flatten & format
		const ret = [];
		for (const [key, value] of Object.entries(_results.results))
		{
			const resultObj = {};
            const origCall = value.originalContractCallContext;
			const realRef = _referenceMap[origCall.reference];
			for (let n = 0; n < value.callsReturnContext.length; n++)
			{
                //flatten
				const callResult = value.callsReturnContext[n];
				const flat = callResult.returnValues.map((v) => this.decodeMultiCallResultValue(v));

                //format
                const outputType = this.findOutputTypesFromAbi(origCall.abi, origCall.calls[n].methodName);
				const decoded = this.decodeMultiCallResultWithABI(flat, outputType);
                resultObj[callResult.reference] = decoded;
			}

			//merge reference results
			ret[realRef] =
			{
				...ret[realRef],
				...resultObj
			};
		}
		return ret;
	},

    /////////////////////////
    // Prepare calls
    /////////////////////////

	makeMultiCallContextCall(_reference, _function, _parameters)
	{
		return {
			reference: _reference,
			methodName: _function,
			methodParameters: _parameters
		};
	},

	makeMultiCallContext(_contract, _abi, _calls)
	{
		const calls = [];
		for (const [key, value] of Object.entries(_calls))
		{
			calls.push(this.makeMultiCallContextCall(
				key,
				value.function,
				value.parameters || []
			));
		}

		return {
			contractAddress: _contract,
			abi: _abi,
			calls: calls
		};
	},

    /////////////////////////
    // Find & make Multicall
    /////////////////////////

    findMultiCall(_chainID)
    {
        const c = MLWeb3.findChainConfig(_chainID);
        return (c ? c.multiCall : null);
    },

    makeMultiCall(_id)
	{
		//find connection
		const c = MLWeb3.findConnection(_id);
		if (c === null)
		{
			return null;
		}

		//find multicall
		const mc = MLMultiCall.findMultiCall(c.chain);
		if (mc === null)
		{
			return null;
		}

		//make multicall
		const multicall = new Multicall(
		{
			web3Instance: c.web3,
			tryAggregate: true,
			multicallCustomContractAddress: mc
		});
		return multicall;
	}
};


export default MLMultiCall;