//components
import
{
	Link
} from "../../";

//framework
import
{
	social_discord,
	social_telegram,
	social_gitbook,
	social_twitter,
	social_github
} from "../../../symbols";
import { MLUI } from "../../../utils";

//styles
import styles from "./Buttons.module.css";
import { useDApp } from "../../../hooks";

export const SocialPlatform =
{
	Twitter: "twitter",
	Telegram: "telegram",
	Discord: "discord",
	Gitbook: "gitbook",
	Github: "github"
};

const SocialIcons =
{
	twitter: social_twitter(),
	telegram: social_telegram(),
	discord: social_discord(),
	gitbook: social_gitbook(),
	github: social_github()
};

const SocialButton = (props) =>
{
	//context
	const dApp = useDApp();

	//functions
	const getSocialLink = (_type, _link) =>
	{
		if (!!_link
			&& _link !== "")
		{
			return _link;
		}

		const cfgLink = dApp.config.page.socials[_type];
		return (cfgLink || "");
	};

	const social = props.social || SocialPlatform.Telegram;
	return (
		<Link
			href={getSocialLink(social, props.link)}
			target="_blank"
		>
			{MLUI.imageOrSymbol(
				SocialIcons[social],
				[
					styles.social_icon,
					props.className
				].join(" ")
			)}
		</Link>
	);
};

export default SocialButton;