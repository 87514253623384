//framework
import { DAppModule, MLUtils, useModule } from "@MoonLabsDev/dapp-core-lib";

//classes
import { Bank } from "../classes/Bank";

export const ModuleEvents =
{
    userData: "bank_userInfo",
};

export class Module_Bank extends DAppModule
{
    static moduleName = "bank";
    constructor()
    {
        super(m => m.bank);
        this.bank = null;
    }

    async onRefreshChainData(dApp)
    {
        if (this.bank === null)
        {
            return;
        }

        //userInfo
        if (dApp.account !== null)
        {
            await MLUtils.measureTime(`TM: Bank => UserInfo`, async () =>
            {
                await this.bank.batch_userInfo();
            });
        }
    }

    async onLoad(dApp)
    {
        if (!Module_Bank.availableOnChain(dApp.currentChain))
        {
            return;
        }
        const addr = dApp.currentChain?.modules?.bank;
        dApp.log(false, `Loading Bank [${addr}]`);
        this.bank = new Bank(addr);
        this.setLoaded();
    }

    static availableOnChain(chain)
    {
        const addr = chain?.modules?.bank;
        const result = (addr !== undefined
            && addr !== "");

        return result;
    }
}

export const useBank = () => useModule(Module_Bank);

const currentModule = new Module_Bank();
export default currentModule;