import { configUI as configUI_sdk } from "@MoonLabsDev/dapp-sdk-lib";
import { configUI as configUI_widget } from "@MoonVault/dapp-widgets-lib";

import configPage from "./page";

//template & theme
import Template from "../../templates/MoonVault";

const configUI =
{
	...configUI_sdk,

    template: Template,
	...(configPage.devMode && { theme: configUI_widget.theme })
};

export default configUI;