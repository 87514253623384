import { useState, useEffect } from "react";

//hooks
import { useMediaInfo } from "../../../hooks";

//framework
import { MLUI } from "../../../utils";

//styles
import styles from "./Containers.module.css";

const TemplateGrid = (props) =>
{
    //context
    const curMediaInfo = useMediaInfo();

    //select prop
    const responsiveProps = props.responsive;
    const selectProp = (_propID) =>
    {
        return MLUI.selectPropertyFromMediaQueryList(
            curMediaInfo.types,
            curMediaInfo.matches,
            responsiveProps,
            _propID
        );
    };

    //default style props
    const cur_gap = props.gap || "10px";
    const cur_templateColumns = props.templateColumns;
    const cur_templateAreas = props.templateAreas;

    //style props
    const [gap, setGap] = useState(() => cur_gap);
    const [cols, setCols] = useState(() => cur_templateColumns);
    const [areas, setAreas] = useState(() => cur_templateAreas);

    //media query
    useEffect(() =>
    {
        setGap(selectProp("gap") || cur_gap);
        setCols(selectProp("templateColumns") || cur_templateColumns);
        setAreas(selectProp("templateAreas") || cur_templateAreas);
    }, [JSON.stringify(curMediaInfo)]);

    return (
        <div
            className={styles.templateGrid}
            style=
            {
                {
                    ...props.style,
                    gap: gap,
                    ...(cols && { gridTemplateColumns: cols }),
                    ...(areas && { gridTemplateAreas: areas })
                }
            }
        >
            {props.children}
        </div>
    );
};

export default TemplateGrid;