import { useState, useEffect } from "react";

//components
import { ValueLoad, FormatFiatAmount } from "../../../";

//framework
import { MLUtils, MLWeb3 } from "../../../../utils";
import Events from "../../../../core/Events";
import { useDApp, useEventSubscription } from "../../../../hooks";

const ValueTokenPrice = (props) =>
{
	//context
	const dApp = useDApp();

    //state
    const [token, setToken] = useState(() => props.token || null);
    const [smart, setSmart] = useState(() => props.smart || false);
    const [value, setValue] = useState(() => (token ? token : dApp.wrappedCoin)?.unitPriceUSD || MLWeb3.toBN(0));
    const [shorten, setShorter] = useState(() => props.shorten || false);
    const [precision, setPrecision] = useState(() => (isNaN(props.precision) ? 2 : props.precision));
    const [load, setLoad] = useState(() => MLUtils.defaultBoolean(props.load, true));

    //handler
    const handleTokenUpdate = (data) =>
    {
        if (token !== null
            && !MLWeb3.checkEqualAddress(data.detail?.address, token.address))
        {
            return;
        }
        setValue((token ? token : dApp.wrappedCoin)?.unitPriceUSD || MLWeb3.toBN(0));
    };

    //effect
    useEffect(() =>
    {
        setToken(props.token || null);
        setValue((props.token ? props.token : dApp.wrappedCoin)?.unitPriceUSD || MLWeb3.toBN(0));
    }, [props.token, props.value]);
    useEventSubscription([Events.dApp.reload, Events.token.priceInfo], handleTokenUpdate, [token]);

    return (
		<ValueLoad
			loaded={!load || (token ? token : dApp.wrappedCoin)?.initializedPrice}
		>
			<FormatFiatAmount
				value={value}
				smart={smart}
				shorten={shorten}
				precision={precision}
			/>
		</ValueLoad>
	);
};

export default ValueTokenPrice;