import { useState, useEffect } from "react";

//components
import { Image } from "../../";

//framework
import { MLUtils, MLWeb3 } from "../../../utils";
import Events from "../../../core/Events";

//hooks
import { useEventSubscription } from "../../../hooks";

//css
import styles from "./Images.module.css";

const NFTIcon = (props) =>
{
    //functions
    const selectIcon = (nft, id) =>
    {
        if (id === undefined)
        {
            return nft?.image ?? "";
        }
        else
        {
            return nft.lazyLoadItem(id)?.data?.image ?? "";
        }
    };

    //state
    const [image, setImage] = useState(() => selectIcon(props.nft, props.nftID));

	//const state
	const [rounded] = useState(MLUtils.defaultBoolean(props.rounded, false));

    //handler
    const handleItemUpdate = (_data) =>
    {
        if (MLWeb3.checkEqualAddress(_data.detail.address, props.nft?.address))
        {
            setImage(selectIcon(props.nft, props.nftID));
        }
    };

    //effect
    useEffect(() =>
    {
        setImage(selectIcon(props.nft, props.nftID));
    }, [props.nft, props.nftID]);
    useEventSubscription(Events.nft.metaData, handleItemUpdate, [props.nft, props.nftID]);

    return (
        <Image
            className=
            {
                [
                    styles.nftIcon,
                    (rounded ? styles.rounded : ""),
                    props.className
                ].join(" ")
            }
            src={image}
        />
    );
};

export default NFTIcon;