//framework
import { DApp, MLWeb3, MLMultiCall, MLFormat, Web3Transaction } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "../modules/Module_MoonToken";

//contracts
import ABI_MoonToken from "../abi/MoonToken";

export class MoonToken
{
	////////////////////////////////////

	constructor(_address)
	{
		//init
		this.initialized = false;

		//values
		this.address = _address;
		this.percentFactor = 1000000;

		//data
		this.token = null;
		this.peggedToken = null;
		this.tokenAddress = "";
		this.peggedTokenAddress = "";
		this.minBuyPrice = MLWeb3.toBN(0);
		this.buyPriceMultiplicator = 0;
		this.buyPriceFee = 0;
		this.feeAddress = "";
		this.mintableSupply = MLWeb3.toBN(0);
		this.totalMinted = MLWeb3.toBN(0);
		this.emissionPerDay = MLWeb3.toBN(0);
		this.totalSupply = MLWeb3.toBN(0);
		this.burnedSupply = MLWeb3.toBN(0);
		this.circulatingSupply = MLWeb3.toBN(0);
		this.availableSupply = MLWeb3.toBN(0);
		this.burnedSupply = MLWeb3.toBN(0);
		this.totalBalance = MLWeb3.toBN(0);
		this.tokenValue = MLWeb3.toBN(0);
		this.tokenPrice = MLWeb3.toBN(0);
		this.wrappedBalance = MLWeb3.toBN(0);
		this.wrappedBalanceUSD = MLWeb3.toBN(0);
	}

	////////////////////////////////////

	debugErrorString(_text)
	{
		return `MoonToken failed at: ${_text}`;
	}

    getContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(ABI_MoonToken, this.address).methods;
    }

    makeMultiCall(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.address,
            ABI_MoonToken,
            _calls
        );
    }

    dispatchEvent(_name)
    {
        document.dispatchEvent(new CustomEvent(_name));
    }

	/////////////////////////
    // Init
    /////////////////////////

    async init()
    {
        if (this.initialized)
        {
            return;
        }
        await DApp.instance.batchCall(
            [this],
            (o) => o.makeRequest_init(),
            (o, r) => o.processRequest_init(r),
            false,
            "[MoonToken] init",
            "MoonToken: init"
        );
    }

    makeRequest_init()
    {
        return this.makeMultiCall(
        {
            percentFactor: { function: "PERCENT_FACTOR" },
            tokenAddress: { function: "moonToken" },
            peggedTokenAddress: { function: "peggedToken" },
            minBuyPrice: { function: "minBuyPrice" },
            feeAddress: { function: "feeAddress" },
            emissionPerDay: { function: "emissionPerDay" },
            buyPriceFee: { function: "buyPriceFee" },
            buyPriceMultiplicator: { function: "buyPriceMultiplicator" }
        });
    }

    async processRequest_init(_data)
    {
        this.percentFactor			= parseInt(_data.percentFactor);
		this.tokenAddress 		    = _data.tokenAddress;
		this.peggedTokenAddress 	= _data.peggedTokenAddress;
		this.minBuyPrice			= MLWeb3.toBN(_data.minBuyPrice);
		this.buyPriceMultiplicator	= parseFloat(_data.buyPriceMultiplicator) / this.percentFactor;
		this.buyPriceFee			= parseFloat(_data.buyPriceFee) / this.percentFactor;
		this.feeAddress				= _data.feeAddress;
		this.emissionPerDay			= MLWeb3.toBN(_data.emissionPerDay);

        //process
        this.token = DApp.instance.findToken(this.tokenAddress);
        this.peggedToken = DApp.instance.findToken(this.peggedTokenAddress);

        //comlete
        this.initialized = true;

        //event
        this.dispatchEvent(ModuleEvents.init);
    }

    /////////////////////////
    // Data
    /////////////////////////

    async reload_data()
    {
        await this.init()
		if (!this.initialized)
		{
			return;
		}
        await DApp.instance.batchCall(
            [this],
            (o) => o.makeRequest_data(),
            (o, r) => o.processRequest_data(r),
            false,
            "[MoonToken] data",
            "MoonToken: data"
        );
    }

    makeRequest_data()
    {
        return this.makeMultiCall(
        {
            totalSupply: { function: "totalSupply" },
            circulatingSupply: { function: "circulatingSupply" },
            availableSupply: { function: "availableSupply" },
            burnedSupply: { function: "burnedSupply" },
            mintableSupply: { function: "mintableSupply" },
            totalBalance: { function: "totalBalance" },
            wrappedBalance: { function: "wrappedBalance" },
            tokenValue: { function: "tokenValue" },
            tokenPrice: { function: "tokenPrice" },
            totalMinted: { function: "totalMinted" }
        });
    }

    async processRequest_data(_data)
    {
        this.totalSupply			= MLWeb3.toBN(_data.totalSupply);
		this.circulatingSupply 		= MLWeb3.toBN(_data.circulatingSupply);
		this.availableSupply 		= MLWeb3.toBN(_data.availableSupply);
		this.burnedSupply			= MLWeb3.toBN(_data.burnedSupply);
		this.totalBalance			= MLWeb3.toBN(_data.totalBalance);
		this.wrappedBalance			= MLWeb3.toBN(_data.wrappedBalance);
		this.tokenValue				= MLWeb3.toBN(_data.tokenValue);
		this.tokenPrice				= MLWeb3.toBN(_data.tokenPrice);
		this.mintableSupply			= MLWeb3.toBN(_data.mintableSupply);
		this.totalMinted			= MLWeb3.toBN(_data.totalMinted);

		//process
		this.wrappedBalanceUSD = await this.peggedToken.getPriceUSDForAmount(this.wrappedBalance);

        //handle price
        this.token.unitPriceUSD = this.totalBalance.mul(this.token.one).div(this.totalSupply);
		this.token.liquidityValue = this.totalBalance;
		this.token.liquidityAmount = this.totalSupply;
		this.token.lastPriceUpdate = new Date();
		this.token.initializedPrice = true;
		this.token.priceUpdated();
		DApp.instance.oracle.logUnitPrice(this.token, true);

        //event
        this.dispatchEvent(ModuleEvents.data);
    }

	/////////////////////////
    // Transactions
    /////////////////////////

	async checkApproved_Token()
	{
		return await this.token.checkApproved(this.address);
	}

	async checkApproved_Pegged()
	{
		return await this.peggedToken.checkApproved(this.address);
	}

	buy(_amount)
    {
        const con = this.getContract(true);
        return new Web3Transaction(
            con.buy(_amount),
            this.debugErrorString("buy"),
            `Buy ${MLFormat.smartFormatToken(_amount, this.token)} ${this.token.symbol}`
		);
    }

	sell(_amount)
    {
        const con = this.getContract(true);
        return new Web3Transaction(
            con.sell(_amount),
            this.debugErrorString("sell"),
            `Sell ${MLFormat.smartFormatToken(_amount, this.token)} ${this.token.symbol}`
		);
    }

	swapForLiquidity(_token)
    {
        const con = this.getContract(true);
        return new Web3Transaction(
            con.swapForLiquidity(_token.address),
            this.debugErrorString("swapForLiquidity"),
            `Swap for Liquidity`
		);
    }
}