//framework
import { DAppModule, MLUtils, useModule } from "@MoonLabsDev/dapp-core-lib";

//classes
import { MoonReferral } from "../classes/MoonReferral";

export const ModuleEvents =
{
    init: "moonReferral_init",
    userData: "moonReferral_userData"
};

export class Module_MoonReferral extends DAppModule
{
    static moduleName = "moon_referral";
    constructor()
    {
        super(m => m.referral);
        this.referral = null;
    }

    async onRefreshChainData(dApp)
    {
        if (this.referral === null)
        {
            return;
        }

        //data
        if (!this.referral.initialized)
        {
            await MLUtils.measureTime(`TM: MoonReferral => Init`, async () =>
            {
                await this.referral.reload_init();
            });
        }

        //userInfo
        if (dApp.account !== null)
        {
            await MLUtils.measureTime(`TM: MoonReferral => User`, async () =>
            {
                await this.referral.reload_userData();
            });
        }
    }

    async onLoad(dApp)
    {
        if (!Module_MoonReferral.availableOnChain(dApp.currentChain))
        {
            return;
        }

        const addr = dApp.currentChain?.modules?.moonReferral;
        dApp.log(false, `Loading MoonReferral [${addr}]`);
        this.referral = new MoonReferral(addr);
        this.setLoaded();
    }

    static availableOnChain(chain)
    {
        const addr = chain?.modules?.moonReferral;
        return (addr !== undefined
            && addr !== "");
    }
}

export const useMoonReferral = () => useModule(Module_MoonReferral);

const currentModule = new Module_MoonReferral();
export default currentModule;