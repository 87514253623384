//framework
import { DApp, MLMultiCall } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "..//modules/Module_FeeDistributor";

//contracts
import ABI_FeeDistributor from "../abi/FeeDistributor";

export class FeeDistributor
{
	////////////////////////////////////

	constructor(_address)
	{
        this.initialized = false;

		//values
		this.address = _address;

		//data
        this.totalShares = 0;
		this.fees = [];
	}

	////////////////////////////////////

	debugErrorString(_text)
	{
		return `FeeDistributor failed at: ${_text}`;
	}

    getContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(ABI_FeeDistributor, this.address).methods;
    }

    makeMultiCall(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.address,
            ABI_FeeDistributor,
            _calls
        );
    }

    dispatchEvent(_name)
    {
        document.dispatchEvent(new CustomEvent(_name));
    }

    /////////////////////////
    // Init
    /////////////////////////

    async reload_init()
    {
        if (this.initialized)
        {
            return;
        }

        await DApp.instance.batchCall(
            [this],
            (o) => o.makeRequest_init(),
            (o, r) => o.processRequest_init(r),
            false,
            "[FeeDistributor] init",
            "FeeDistributor: init"
        );
    }

    makeRequest_init()
    {
        return this.makeMultiCall(
        {
            totalShares: { function: "totalShares" },
            fees: { function: "getFees" },
        });
    }

    async processRequest_init(_data)
    {
        this.totalShares            = parseInt(_data.totalShares);
        this.fees		            = _data.fees;

        //process
        this.fees.forEach(f =>
        {
            f.percent = f.share / this.totalShares;
        });
        this.initialized = true;

        //event
        this.dispatchEvent(ModuleEvents.init);
    }
}