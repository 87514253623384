import { useState, useRef } from "react";

//styles
import styles from "./Inputs.module.css";

const Toggle = (props) =>
{
    //state
    const inputRef = useRef();
    const [checked, setChecked] = useState(() => props.checked);

    //handler
    const handleChange = (e) =>
    {
        setChecked(inputRef.current.checked);
        if (props.onChange)
        {
            props.onChange(e);
        }
    };
    const handleClick = () =>
    {
        inputRef.current.checked = !inputRef.current.checked;
        handleChange(inputRef.current.checked);
    };

    return (
        <div
            className=
            {
                [
                    styles.toggle,
                    (checked
                        ? styles.toggleEnabled
                        : styles.toggleDisabled),
                    props.className
                ].join(" ")
            }
            onClick={() => handleClick()}
        >            
            <input
                id={props.id}		
                ref={inputRef}	
                name={props.name}
                className={styles.toggleCheckBox}
                type="checkbox"
                checked={checked}
                onChange={(e) => handleChange(e)}
            />
            <div
                className=
                {
                    [
                        styles.toggleThumb,
                        (checked
                            ? styles.toggleThumbEnabled
                            : styles.toggleThumbDisabled)
                    ].join(" ")
                }
            />
        </div>
    );
};

export default Toggle;