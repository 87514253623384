import { arrowDownShort } from "../symbols";
import { menu } from "../symbols";
import { social_discord, social_telegram, social_twitter, social_github } from "../symbols";
import { transaction_confirm, transaction_failed, transaction_success, transaction_pending } from "../symbols";

const MLSymbols =
{
    arrowDownShort,
    menu,

    social_discord,
    social_github,    
    social_telegram,
    social_twitter,

    transaction_confirm,
    transaction_failed,
    transaction_success,
    transaction_pending
};

export default MLSymbols;