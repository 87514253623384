import { useState, useEffect } from "react";

//hooks
import { useMediaInfo, useTheme } from "../../../hooks";

//framework
import { MLUI } from "../../../utils";

//styles
import styles from "./Containers.module.css";

const Flex = (props) =>
{
    //context
    const curMediaInfo = useMediaInfo();
	const theme = useTheme();

    //select prop
    const responsiveProps = props.responsive;
    const selectProp = (_propID) =>
    {
        return MLUI.selectPropertyFromMediaQueryList(
            curMediaInfo.types,
            curMediaInfo.matches,
            responsiveProps,
            _propID
        );
    };

    //default style props
    const cur_gap = props.gap || "10px";

    //style props
    const [gap, setGap] = useState(() => cur_gap);
	const [direction, setDirection] = useState(() => props.direction);
	const [align, setAlign] = useState(() => props.align);
	const [justify, setJustify] = useState(() => props.justify);
	const [wrap, setWrap] = useState(() => props.wrap);

    //media query
    useEffect(() =>
    {
        setGap(selectProp("gap") || cur_gap);
		setDirection(selectProp("direction") || props.direction);
		setAlign(selectProp("align") || props.align);
		setJustify(selectProp("justify") || props.justify);
		setWrap(selectProp("wrap") || props.wrap);
    }, [JSON.stringify(curMediaInfo)]);

    return (
        <div
            className=
            {
                [
					theme?.getClassName("Flex") ?? "",
                    styles.flex,
                    props.className
                ].join(" ")
            }
            style=
            {
                {
                    ...props.style,
                    gap: gap,
					...(direction && { flexDirection: direction }),
					...(align && { flexDirection: align }),
					...(justify && { flexDirection: justify }),
					...(wrap && { flexDirection: wrap }),
                }
            }
        >
            {props.children}
        </div>
    );
};

export default Flex;