const translation =
{
    //general
	ok: "ok",
	confirm: "confirm",
    cancel: "cancel",
    dismiss: "dismiss",
    login: "login",
    logout: "logout",
	deposit: "deposit",
	withdraw: "withdraw",
	tryAnyway: "try anyway",

	//modal (transaction)
    modal_transaction_failed_title: "Failed",
    modal_transaction_failed_text: "The transaction failed with error: %error%",
    modal_transaction_confirm_title: "Confirm",
    modal_transaction_confirm_text: "Confirm transaction in your wallet",
    modal_transaction_pending_title: "Pending",
    modal_transaction_pending_text: "Waiting for transaction to be processed",
    modal_transaction_success_title: "Complete",
    modal_transaction_success_text: "Transaction successful",

    //modals
    modal_selectChain_title: "Select Network",
    modal_connectWallet_title: "Connect Wallet",
    modal_selectToken_title: "Select Token",
    modal_selectToken_filterPlaceholder: "Search Symbol or Contract",
    modal_selectNFT_title: "Select NFT",
    modal_selectNFT_filterPlaceholder: "Search Symbol or Name",
    modal_selectNFT_filterIDPlaceholder: "Search ID or Name",
	modal_transactionMightFail_title: "Transaction Error",
};

export default translation;