import { useState, useEffect } from "react";

//components
import
{
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Input,
    Button,
    Flex,
    Text,
    List
} from "@MoonLabsDev/dapp-core-lib";
import EscrowTradeInfo from "./EscrowTradeInfo";

//classes
import { TradeStatus } from "../../../../../dApp/classes/Escrow";

//module
import { useEscrow } from "../../../../modules";
import { ModuleEvents } from "../../../..//modules/Module_Escrow";

//hooks
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//style
import styles from "../Escrow.module.css";


const EscrowUserOverview = (props) =>
{
    //hook
    const dapp = useDApp();
    const escrow = useEscrow();

    //default props
    const default_status = props.status || TradeStatus.ONGOING;

    //state
    const [status, setStatus] = useState(default_status);
    const [trades, setTrades] = useState(escrow.getUserInfoTradeList(dapp.account, default_status));
    const [inputValue, setInputValue] = useState("");

    //handler
    const handleTradeUpdate = () =>
    {
        setTrades(escrow.getUserInfoTradeList(dapp.account, props.status || TradeStatus.ONGOING))
    };
    const handleCreateTrade = () =>
    {
        escrow.newTrade(inputValue).send();
    };
    const handleSelectTrade = (tradeID) =>
    {

    };

    //effect
    useEffect(() =>
    {
        setStatus(props.status || TradeStatus.ONGOING);
        handleTradeUpdate();
    }, [props.status]);
    useEventSubscription([ModuleEvents.userInfo, ModuleEvents.tradeInfo], handleTradeUpdate);

    return (
        <Card
            className={styles.offer}
        >
            <CardHeader>
                <Text>
                    {status === TradeStatus.ONGOING && <>Active</>}
                    {status === TradeStatus.CANCELLED && <>Cancelled</>}
                    {status === TradeStatus.COMPLETE && <>Completed</>}
                    {" "}Trades ( {trades?.length || 0} )
                </Text>
            </CardHeader>

            <CardBody>
                <List
                    className={styles.offer_list}
                    itemClassName={styles.offerItem_row}
                    onSelect={(v) => handleSelectTrade(v)}
                    items=
                    {trades.map((t) =>
                        {
                            return {
                                value: t,
                                content: (
                                    <EscrowTradeInfo
                                        tradeID={t}
                                    />
                                )
                            }
                        }
                    )}
                />
            </CardBody>

            {status === TradeStatus.ONGOING &&
                <CardFooter>
                    <Flex>
                        <Input
                            value={inputValue}
                            placeholder="Input trade partner"
                            onChange={(dummy, value) => setInputValue(value)}
                        />
                        <Button
                            onClick={() => handleCreateTrade()}
                        >
                            +
                        </Button>
                    </Flex>
                </CardFooter>
            }
        </Card>
    );
};

export default EscrowUserOverview;