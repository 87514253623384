import { useState, useEffect } from "react";

//components
import
{
    Card,
    CardHeader,
    CardBody,
    Text,
    List,
	Flex
} from "@MoonLabsDev/dapp-core-lib";
import BankAssetInfo from "./BankAssetInfo";

//framework
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//module
import { ModuleEvents } from "../../..//modules/Module_Bank";
import ModalBankTransfer from "./Modals/ModalBankTransfer";

//style
import styles from "./Bank.module.css";
import { useBank } from "../../../modules";

const BankUserAssets = () =>
{
    //hook
    const bank = useBank();

    //state
	const [withdrawToken, setWithdrawToken] = useState(() => false);
	const [show, setShow] = useState(() => false);
    const [assets, setAssets] = useState(() => bank.tokens);

    //handler
    const handleUserInfoUpdate = () =>
    {
		setAssets(bank.tokens);
    };
	const handleWithdraw = (_token) =>
	{
		setWithdrawToken(_token);
		setShow(true);
	};

    //effect
    useEventSubscription(ModuleEvents.userData, handleUserInfoUpdate);

    return (
        <Card
            className={styles.userAssets}
        >
            <CardHeader>
				<Flex>
					<Text>
						User Assets
					</Text>
				</Flex>
            </CardHeader>

            <CardBody>
				<ModalBankTransfer
					show={show}
					token={withdrawToken}
					onClose={() => setShow(false)}
				/>
                <List
                    className={styles.userAssets_list}
                    itemClassName={styles.userAssets_row}
                    items=
                    {assets.map((a) =>
                        {
                            return {
                                value: a.token,
                                content: (
                                    <BankAssetInfo
                                        token={a.token}
										onWithdraw={(t) => handleWithdraw(t)}
                                    />
                                )
                            }
                        }
                    )}
                />
            </CardBody>
        </Card>
    );
};

export default BankUserAssets;