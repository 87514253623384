import { useState } from "react";

//components
import { LineChart } from "@MoonLabsDev/dapp-core-lib";

//framework
import Events from "@MoonLabsDev/dapp-core-lib";
import { MLUtils, DataSeriesFillType } from "@MoonLabsDev/dapp-core-lib";

//hooks
import { useMoonAPI } from "../../../modules";
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

const LineChart_TVL = (props) =>
{
    //hooks
    const dApp = useDApp();
    const api = useMoonAPI();

    //state
    const [dataValues, setDataValues] = useState(() => []);
    const [dataKeys, setDataKeys] = useState(() => []);
    const [dataColors, setDataColors] = useState(() => []);

    //handler
    const handleGetData = async () =>
    {
        if (dataValues.length > 0)
        {
            return;
        }

        //get data
        const data = await api.protocol_getMetrics();
        const dKeys = [];
        const dCols = [];
        const dVals = [];
        for (let n = 0; n < data.length; n++)
        {
            const d = data[n];
            const c = dApp.findChain(d.chain);
            if (!c)
            {
                continue;
            }

            //handle data
            dKeys.push(c.name);
            dCols.push(c.color);
            for (let m = 0; m < d.data.length; m++)
            {
                const dv = d.data[m];
                const v = MLUtils.dataSeries_findOrInsert(
                    dVals,
                    "x",
                    MLUtils.roundDate(MLUtils.getDateTimeFromDB(dv.dateTime)).getTime());
                v[c.name] = parseInt(parseFloat(dv.TVL));
            }
        }

        //fill and normalize
        MLUtils.dataSeries_insertSteps(
            dVals,
            "x",
            1000 * 60 * 60 * 24 //1 day
        )
        MLUtils.dataSeries_fillMissing(
            dVals,
            dKeys,
            DataSeriesFillType.LINEAR,
            DataSeriesFillType.ZERO,
            DataSeriesFillType.FILL
        );
        MLUtils.dataSeries_normalize(
            dVals,
            dKeys,
            (v) => parseInt(parseFloat(v))
        );

        setDataValues(dVals);
        setDataColors(dCols);
        setDataKeys(dKeys);
    };

    //effects
    useEventSubscription(Events.dApp.reload, handleGetData);

    return (
        <LineChart
            width={600}
            height={200}
            showAxis={false}
            gridType={-1}
            colors={dataColors}
            data={dataValues}
            dataKeys={dataKeys}
            dot={false}
        />
    );
};

export default LineChart_TVL;