//framework
import { DApp, MLMultiCall, Web3Transaction } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "..//modules/Module_MoonReferral";

//contracts
import ABI_MoonReferral from "../abi/MoonReferral";

export class MoonReferral
{
	////////////////////////////////////

	constructor(_address)
	{
        this.initialized = false;
        this.initializedUser = false;

		//values
		this.address = _address;

		//data
        this.allowUserRegistration = false;
        this.percentFactor = 0;
		this.serviceFees = [];
        this.userReferralID = 0;
        this.userFees = [];
	}

	////////////////////////////////////

	debugErrorString(_text)
	{
		return `MoonReferral failed at: ${_text}`;
	}

    getContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(ABI_MoonReferral, this.address).methods;
    }

    makeMultiCall(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.address,
            ABI_MoonReferral,
            _calls
        );
    }

    dispatchEvent(_name)
    {
        document.dispatchEvent(new CustomEvent(_name));
    }

    /////////////////////////
    // Init
    /////////////////////////

    async reload_init()
    {
        if (this.initialized)
        {
            return;
        }

        await DApp.instance.batchCall(
            [this],
            (o) => o.makeRequest_init(),
            (o, r) => o.processRequest_init(r),
            false,
            "[MoonReferral] init",
            "MoonReferral: init"
        );
    }

    makeRequest_init()
    {
        return this.makeMultiCall(
        {
            percentFactor: { function: "PERCENT_FACTOR" },
            allowUserRegistration: { function: "allowUserRegistration" },
            serviceFees: { function: "getServiceFees" },
        });
    }

    async processRequest_init(_data)
    {
        this.percentFactor          = parseInt(_data.percentFactor);
        this.allowUserRegistration  = _data.allowUserRegistration;
        this.serviceFees		    = _data.serviceFees;

        //process
        this.serviceFees.forEach(f =>
        {
            f.maxFee = f.maxFee / this.percentFactor;
            f.defaultFee = f.defaultFee / this.percentFactor;
        });
        this.initialized = true;

        //event
        this.dispatchEvent(ModuleEvents.initialized);
    }

    /////////////////////////
    // User
    /////////////////////////

    async reload_userData()
    {
        if (!this.initialized)
        {
            await this.reload_init();
        }
        if (!this.initialized
            || DApp.instance.account === null)
        {
            return;
        }

        await DApp.instance.batchCall(
            [this],
            (o) => o.makeRequest_userData(),
            (o, r) => o.processRequest_userData(r),
            false,
            "[MoonReferral] user",
            "MoonReferral: user"
        );
    }

    makeRequest_userData()
    {
        return this.makeMultiCall(
        {
            userFees: { function: "getUserFees", parameters: [DApp.instance.account] },
            userReferralID : { function: "referralUserMap", parameters: [DApp.instance.account] }
        });
    }

    async processRequest_userData(_data)
    {
        this.userFees		= _data.userFees;
        this.userReferralID = parseInt(_data.userReferralID);

        //process
        this.userFees.forEach(f =>
        {
            f.fee = f.fee / this.percentFactor;
        });
        this.initializedUser = true;

        //event
        this.dispatchEvent(ModuleEvents.userData);
    }

	/////////////////////////
    // Transactions
    /////////////////////////

    register()
    {
        const con = this.getContract(true)
        return new Web3Transaction(
            con.registerForReferral(),
            this.debugErrorString("register"),
            `Register referral`
		);
    }

    changeReferralFee(_service, _fee)
    {
        const con = this.getContract(true)
        return new Web3Transaction(
            con.setUserReferralFee(
                _service,
                _fee
            ),
            this.debugErrorString("set fee"),
            `Set fee [${_service}]`
		);
    }
}