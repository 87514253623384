import { useState, useEffect } from "react";

//components
import { Text, Flex } from "../../";

//framework
import t from "../../../utils/MLTranslate";
import { MLUI, MLWeb3 } from "../../../utils";
import Events from "../../../core/Events";

//hooks
import { useEventSubscription } from "../../../hooks";

//symbols
import { approved, unapproved } from "../../../symbols";

//styles
import styles from "./Buttons.module.css";

const ApprovalButton = (props) =>
{
    //function
    const checkApproved = (_token, _nft, _nftID, _contract) =>
    {
        if (!!_token)
        {
            return _token.checkApproved(_contract);
        }
        else if (!!_nft)
        {
            return _nft.checkApprovedForAll(_contract);
        }
    };

    //state
    const [token, setToken] = useState(() => props.token || null);
    const [nft, setNFT] = useState(() => props.nft || null);
    const [nftID, setNFTID] = useState(() => props.nftID || 0);
    const [isApproved, setIsApproved] = useState(() => checkApproved(props.token, props.nft, props.nftID, props.contract));
    const [contract, setContract] = useState(() => props.contract || "");

    //handler
    const handleApprove = () =>
    {
        if (isApproved
            || !contract
            || contract === "")
        {
            return;
        }
        if (!!token)
        {
            token.approve(contract)?.send();
        }
        else if (!!nft)
        {
            nft.approveAll(contract, true)?.send();
        }
    };
    const handleApprovalUpdate = (_data) =>
    {
        if ((!!token
                && MLWeb3.checkEqualAddress(_data.detail.address, token.address))
            || (!!nft
                && MLWeb3.checkEqualAddress(_data.detail.address, nft.address)))
        {
            setIsApproved(checkApproved(token, nft, nftID, contract));
        }
    };

    //effects
    useEffect(() =>
    {
        setToken(props.token || null);
        setNFT(props.nft || null);
        setNFTID(props.nftID || 0);
        setContract(props.contract || "");
        setIsApproved(checkApproved(
            props.token,
            props.nft,
            props.nftID,
            props.contract));
    },
    [
        props.token,
        props.nft,
        props.nftID,
        props.contract
    ]);
    useEventSubscription(
        [Events.token.approval, Events.nft.approval],
        handleApprovalUpdate,
        [token, nft, nftID]);

    return (
        <>
            <div
                onClick={() => handleApprove()}
            >
                <Flex className={styles.approvalButton}>
                    {isApproved &&
                        <>
                            <Text
								color={1}
								weight={0}
								size={0}
								lineHeight={1}
							>
                                Approved
                            </Text>
                            {MLUI.imageOrSymbol(approved(), styles.approvalButton_icon)}
                        </>
                    }
                    {!isApproved &&
                        <>
                            <Text
								color={1}
								weight={0}
								size={0}
								lineHeight={1}
							>
                                Not Approved
                            </Text>
                            {MLUI.imageOrSymbol(unapproved(), styles.approvalButton_icon)}
                        </>
                    }
                </Flex>
            </div>
        </>
    );
};

export default ApprovalButton;