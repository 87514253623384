import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

//components
import
{
	Text,
    Flex,
    Grid,
	Button,
    PageHeader,
    PageContent,
    ErrorServiceNotAtChain
} from "@MoonLabsDev/dapp-core-lib";
import { SunflowerTraderListing, SunflowerTraderListingGroup } from "@MoonVault/dapp-widgets-lib";

//framework
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//module
import { useSunflowerTrader, ModuleEvents_SunflowerTrader } from "@MoonVault/dapp-widgets-lib";
import { ListingStatus, SFLResourceIDs } from "@MoonVault/dapp-widgets-lib";

const ListingOverview = (props) =>
{
	//context
	const trader = useSunflowerTrader();
	const navigate = useNavigate();

	//functions
	const filterAndSortListings = (_filter) =>
	{
		const list = trader.listings.filter(l =>
		{
			return (l.status === ListingStatus.ACTIVE
				&& (_filter === null
					|| l.resource === _filter));
		});

		if (_filter !== null)
		{
			list.sort((a, b) => a.pricePerUnit.cmp(b.pricePerUnit));
		}
		return list;
	};

	//state
	const [filter, setFilter] = useState(props.filter);
	const [listings, setListings] = useState(filterAndSortListings(filter));

	//handler
	const handleUpdate = () =>
	{
		setListings(filterAndSortListings(filter));
	};
	const handleBack = () =>
    {
        navigate(`${window.location.pathname}`);
    };

	//effects
	useEventSubscription([ModuleEvents_SunflowerTrader.data, ModuleEvents_SunflowerTrader.stats], handleUpdate);

	return (
		<Grid>
			<Flex>
				<Button onClick={() => handleBack()}>
					Back to Overview
				</Button>
			</Flex>
			<Flex>
				{listings.map(l =>
					<SunflowerTraderListing
						key={l.id}
						listingID={l.id}
					/>
				)}
			</Flex>
		</Grid>
	);
};

const ListingGroupOverview = () =>
{
	return (
		<Flex>
			{SFLResourceIDs.map(r =>
				<SunflowerTraderListingGroup
					key={r}
					resource={r}
				/>
			)}
		</Flex>
	);
};

export default () =>
{
	//context
	const trader = useSunflowerTrader();

	//state
	const [searchParams, setSearchParams] = useSearchParams();
	const [filter, setFilter] = useState(null);

	//effects
    useEffect(() =>
    {
        setFilter(parseInt(searchParams.get("resource")) || null);
    }, [searchParams.get("resource")]);

    return (
        <>
            <PageHeader
                title="Sunflower Listings"
            >
                Overview over active listings.
				<br />
				<br />
				Donation Wallet: <Text color={3}>0x383f1F48628D3414D295a1A6b79aF983890cfe54</Text>
            </PageHeader>

            <PageContent>
                <ErrorServiceNotAtChain checkSupportChainCallback={trader.availableOnChain}>
					{filter === null
						? <ListingGroupOverview />
						: <ListingOverview filter={filter} />
					}
                </ErrorServiceNotAtChain>
            </PageContent>
        </>
    )
};