import { useState, useEffect } from "react";

//components
import { LineChart } from "@MoonLabsDev/dapp-core-lib";

//framework
import Events from "@MoonLabsDev/dapp-core-lib";
import { MLWeb3, MLUtils, DataSeriesFillType } from "@MoonLabsDev/dapp-core-lib";

//hooks
import { useMoonAPI } from "../../../modules";
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

export const MoonTokenMetric =
{
    PEGGED_BALANCE: 1,
    CIRUCLATING_SUPPLY: 2,
    UNIT_PRICE: 4,

    ALL: (1 | 2 | 4)
};

const LineChart_MoonToken = (props) =>
{
    //hooks
    const api = useMoonAPI();

    //state
    const [dataValues, setDataValues] = useState(() => []);
    const [dataKeys, setDataKeys] = useState(() => []);
    const [dataColors, setDataColors] = useState(() => []);
    const [metrics, setMetrics] = useState(() => props.metrics || MoonTokenMetric.ALL);
    const [domainPerKey, setDomainPerKey] = useState(() => undefined);

    //handler
    const handleGetData = async () =>
    {
        if (dataValues.length > 0)
        {
            return;
        }

        //keys
        const allColors = ["#6236ff", "#1969ff", "#fea430"];
        const allKeys = ["PeggedBalance", "CirculatingSupply", "UnitPrice"];
        const dKeys = [];
        const dCols = [];
        if (metrics & MoonTokenMetric.PEGGED_BALANCE)
        {
            dKeys.push(allKeys[0]);
            dCols.push(allColors[0]);
        }
        if (metrics & MoonTokenMetric.CIRUCLATING_SUPPLY)
        {
            dKeys.push(allKeys[1]);
            dCols.push(allColors[1]);
        }
        if (metrics & MoonTokenMetric.UNIT_PRICE)
        {
            dKeys.push(allKeys[2]);
            dCols.push(allColors[2]);
        }
        setDataKeys(dKeys);
        setDataColors(dCols);

        //get data
        const data = await api.moonToken_getLiquidityData({ days: 180 });
        const dVals = [];
        const dDomains = [];
        for (let n = 0; n < data.data.length; n++)
        {
            const dv = data.data[n];
            const v = MLUtils.dataSeries_findOrInsert(
                dVals,
                "x",
                MLUtils.roundDate(MLUtils.getDateTimeFromDB(dv.dateTime)).getTime());
            if (metrics & MoonTokenMetric.PEGGED_BALANCE)
            {
                v[allKeys[0]] = MLWeb3.convertBN_Float(MLWeb3.toBN("0x" + dv.peggedBalance), 18);
            }
            if (metrics & MoonTokenMetric.CIRUCLATING_SUPPLY)
            {
                v[allKeys[1]] = MLWeb3.convertBN_Float(MLWeb3.toBN("0x" + dv.circulatingSupply), 18);
            }
            if (metrics & MoonTokenMetric.UNIT_PRICE)
            {
                v[allKeys[2]] = parseFloat(dv.unitPrice);
            }
        }

        //fill and normalize
        MLUtils.dataSeries_insertSteps(
            dVals,
            "x",
            1000 * 60 * 60 * 24 //1 day
        )
        MLUtils.dataSeries_fillMissing(
            dVals,
            dKeys,
            DataSeriesFillType.FILL,
            DataSeriesFillType.ZERO,
            DataSeriesFillType.FILL
        );
        MLUtils.dataSeries_normalize(
            dVals,
            [
                allKeys[0],
                allKeys[1]
            ],
            (v) => parseInt(parseFloat(v))
        );
        setDataValues(dVals);

        //domains
        if (metrics & MoonTokenMetric.PEGGED_BALANCE)
        {
            dDomains.push(
            {
                key: allKeys[0],
                domain:
                [
                    dVals[0][allKeys[0]],
                    dVals[dVals.length - 1][allKeys[0]]
                ]
            });
        }
        if (metrics & MoonTokenMetric.CIRUCLATING_SUPPLY)
        {
            dDomains.push(
            {
                key: allKeys[1],
                domain:
                [
                    dVals[0][allKeys[1]],
                    dVals[dVals.length - 1][allKeys[1]]
                ]
            });
        }
        if (metrics & MoonTokenMetric.UNIT_PRICE)
        {
            dDomains.push(
            {
                key: allKeys[2],
                domain:
                [
                    dVals[0][allKeys[2]],
                    dVals[dVals.length - 1][allKeys[2]]
                ]
            });
        }
        setDomainPerKey(dDomains);
    };

    //effects
    useEffect(() =>
    {
        setMetrics(props.metrics);
    }, [props.metrics]);
    useEventSubscription(Events.dApp.reload, handleGetData);

    return (
        <LineChart
            width={600}
            height={200}
            showAxis={false}
            gridType={-1}
            colors={dataColors}
            data={dataValues}
            dataKeys={dataKeys}
            dot={false}
            domainPerKey={domainPerKey}
        />
    );
};

export default LineChart_MoonToken;