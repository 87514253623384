//framework
import { MLFormat, MLUtils } from "../../../utils";

const FormatTokenAmount = ({value, token, smart, precision, shorten}) =>
{
    return (
        <>
            {token &&
                (MLUtils.defaultBoolean(smart, false)
                    ? MLFormat.smartFormatToken(
						value,
						token
					)
                    : MLFormat.formatToken(
						value,
						token,
						precision,
						shorten
					)
                )
            }
        </>
    );
};

export default FormatTokenAmount;