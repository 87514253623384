//framework
import { MLUtils } from "../../../utils";

//hooks
import { useDApp } from "../../../hooks";

const FormatFiatAmount = ({value, smart, precision, shorten}) =>
{
    //hooks
    const dApp = useDApp();

    return (
        <>
            {MLUtils.defaultBoolean(smart, false)
                ? dApp.smartFormatFiat(value)
                : dApp.formatFiat(
					value,
					precision,
					shorten)
            }
        </>
    );
};

export default FormatFiatAmount;