//components
import
{
	Flex,
	Text,
	ButtonMenu,
	TokenIcon,
	ValueTokenPrice
} from "../../";

//framework
import { useDApp } from "../../../hooks";

//styles
import styles from "./Buttons.module.css";

const TokenPriceButton = (props) =>
{
	//context
	const dApp = useDApp();

	return (
		<ButtonMenu
			menu={props.menu}
			hover={props.hover}
			className={props.className}
			style={props.style}
		>
			<Flex>
				<TokenIcon
					className={styles.tokenPrice_icon}
					token={(props.token ?? dApp.wrappedCoin)}
				/>
				<Text
					size={0}
					color={1}
				>
					<ValueTokenPrice
						token={props.token}
						shorten={true}
					/>
				</Text>
			</Flex>
		</ButtonMenu>
	);
};

export default TokenPriceButton;