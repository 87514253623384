import { useState, useEffect } from "react";

//components
import
{
    Text,
    Grid,
    Tooltip,
    TooltipPlacement,
    Flex
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLWeb3 } from "@MoonLabsDev/dapp-core-lib";
import Events from "@MoonLabsDev/dapp-core-lib";

//modules
import { useEscrow } from "../../../../modules";

//hooks
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//style
import styles from "../Escrow.module.css";


const EscrowOfferItemState = (props) =>
{
    //hooks
    const dApp = useDApp();
    const escrow = useEscrow();

    //item
    const [item, setItem] = useState(() => props.item);
    useEffect(() =>
    {
        setItem(props.item);
    }, [props.item]);

    //nft / token
    const [token, setToken] = useState(() => dApp.findToken(props.item?.contract));
    const [nft, setNFT] = useState(() => dApp.findNFT(props.item?.contract));
    const [nftItem, setNFTItem] = useState(() => nft?.findItem(props.item?.nftID) || null);
    useEffect(() =>
    {
        const state_NFT = dApp.findNFT(item?.contract);
        setToken(dApp.findToken(item?.contract));
        setNFT(state_NFT);
        setNFTItem(state_NFT?.findItem(item?.nftID) || null);
    }, [item?.contract, item?.nftID]);

    //balance
    const checkBalance = () =>
    {
        if (token)
        {
            return (token.userBalance.cmp(item.amount) >= 0);
        }
        else if (nftItem)
        {
            return (nftItem.userBalance.cmp(item.amount) >= 0);
        }
        return true;
    }
    const [balanceOK, setBalanceOK] = useState(() => checkBalance());
    const handleBalanceUpdate = (data) =>
    {
        if (item?.type === "Coin"
            || (token
                && !MLWeb3.checkEqualAddress(data.detail.address, token.address))
            || (nftItem
                && (!MLWeb3.checkEqualAddress(data.detail.address, nft.address)
                    || data.detail.id !== nftItem.id)))
        {
            return;
        }
        setBalanceOK(checkBalance());
    };
    const handleBalanceUpdateCoin = () =>
    {
        if (item?.type !== "Coin")
        {
            return;
        }
        setBalanceOK(checkBalance());
    };
    useEffect(() =>
    {
        setBalanceOK(checkBalance());
    }, [item?.amount, token, nftItem]);
    useEventSubscription(
        [Events.token.userData, Events.nft.userData],
        handleBalanceUpdate,
        [item, token, nft, nftItem]);
    useEventSubscription(Events.dApp.reload, handleBalanceUpdateCoin, [item]);

    //approval
    const checkApproval = () =>
    {
        if (token)
        {
            return token.checkApproved(escrow.address);
        }
        else if (nftItem)
        {
            return nft.checkApprovedForAll(escrow.address);
        }
        return true;
    }
    const [approvalOK, setApprovalOK] = useState(() => checkApproval());
    const handleApprovalUpdate = (data) =>
    {
        if ((token
                && !MLWeb3.checkEqualAddress(data.detail.address, token.address))
            || (nftItem
                && (!MLWeb3.checkEqualAddress(data.detail.address, nft.address))))
        {
            return;
        }
        setApprovalOK(checkApproval());
    };
    useEffect(() =>
    {
        setApprovalOK(checkApproval());
    }, [item?.amount, token, nftItem]);
    useEventSubscription(
        [Events.token.approval, Events.nft.approval],
        handleApprovalUpdate,
        [token, nft, nftItem]);

    return (
        <>
            {(approvalOK && balanceOK) &&
                <div className={[styles.itemState, styles.itemState_ok].join(" ")} />
            }
            {(!approvalOK || !balanceOK) &&
                <Tooltip
                    placement={TooltipPlacement.Top}
                    content=
                    {
                        <Grid>
                            <Flex>
                                <Text color={3}>
                                    Problem detected:
                                </Text>
                            </Flex>
                            <Flex>
                                <Text
                                    color={1}
                                    size={-1}
                                >
                                    <ul
                                        style={{ listStyle: "none" }}
                                    >
                                        {!balanceOK &&
                                            <li>
                                                - Insufficient Balance
                                            </li>
                                        }
                                        {!approvalOK &&
                                            <li>
                                                - Not approved
                                            </li>
                                        }
                                    </ul>
                                </Text>
                            </Flex>
                        </Grid>
                    }
                >
                    <div className={[styles.itemState, styles.itemState_error].join(" ")} />
                </Tooltip>
            }
        </>
    );
}

export default EscrowOfferItemState;