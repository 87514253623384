import React, { useState, useEffect, useContext } from "react";

//framework
import { MLUI } from "../utils";

//hooks
import { useMediaQuery } from "./useMediaQuery";

const MediaInfoContext = React.createContext();
export const useMediaInfo = () =>
{
    return useContext(MediaInfoContext);
};

export const MediaInfoProvider = ({ children }) =>
{
    //media type
    const mediaMatches = {};
    const mediaTypes = MLUI.getMediaQueryList();
    mediaTypes.forEach(t => mediaMatches[t] = useMediaQuery(t));
    const mediaInfoCur =
    {
        types: mediaTypes,
        matches: mediaMatches
    };

    //state & effect
    const [mediaInfo, setMediaInfo] = useState(() => mediaInfoCur);
    useEffect(() =>
    {
        setMediaInfo(mediaInfoCur);
    }, [JSON.stringify(mediaInfoCur)])

    return (
        <MediaInfoContext.Provider value={mediaInfo}>
            {children}
        </MediaInfoContext.Provider>
    );
};