import { useState } from "react";

//components
import
{
    Text,
    Flex,
    Grid,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Button
} from "@MoonLabsDev/dapp-core-lib";
import MoonReferralFeeInfo from "./MoonReferralFeeInfo";

//hooks
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useMoonReferral } from "../../../modules";
import { ModuleEvents } from "../../..//modules/Module_MoonReferral";

//styles
import styles from "./MoonReferral.module.css";


const MoonReferralInfo = () =>
{
    //context
    const referral = useMoonReferral();

    //state
    const [fees, setFees] = useState(() => referral.serviceFees);
    const [referralID, setReferralID] = useState(() => referral.userReferralID);

    //handler
    const handleUpdate = () =>
    {
        const feeData = [...referral.serviceFees];
        feeData.forEach(f =>
            f.userFee = (referral.userReferralID === 0
                ? -1
                : referral.userFees.find(u => u.service === f.service)?.fee || -1));

        setFees(feeData);
        setReferralID(referral.userReferralID);
    };
    const handleRegister = () =>
    {
        referral.register().send();
    };

    //effect
    useEventSubscription([ModuleEvents.init, ModuleEvents.userData], handleUpdate);

    return (
        <Card style={{ width: "300px" }}>
            <CardHeader>
                <Flex>
                    <Grid
                        gap="0px"
                        className={styles.header}
                    >
                        <Text size={1}>
                            Referral
                        </Text>
                        {referralID !== 0 &&
                            <Text size={-1} color={2}>
                                Referral ID: {referralID}
                            </Text>
                        }
                    </Grid>
                </Flex>
            </CardHeader>

            <CardBody>
                <Grid>
                    {fees.map(f =>
                        <MoonReferralFeeInfo fee={f} />
                    )}
                </Grid>
            </CardBody>

            {(referral.allowUserRegistration && referralID === 0) &&
                <CardFooter>
                    <Flex>
                        <Button onClick={() => handleRegister()}>
                            Register
                        </Button>
                    </Flex>
                </CardFooter>
            }
        </Card>
    );
}

export default MoonReferralInfo;