import { useState, useEffect } from "react";

//components
import
{
    Modal,
    Button,
    Grid,
    Flex,
    SelectTokenOrNFT,
    InputTokenAmount_Balance,
    ApprovalButton,
    ToolButton,
    ToolButtonType,
    InputNFTAmount_Balance
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLTranslate as t } from "@MoonLabsDev/dapp-core-lib";
import { MLFormat, MLWeb3 } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useEscrow } from "../../../../modules";

//hooks
import { useDApp } from "@MoonLabsDev/dapp-core-lib";

//classes
import { OfferItemType } from "../../../../../dApp/classes/Escrow";


const ModalEscrowEditItem = (props) =>
{
    //hooks
    const dApp = useDApp();
    const escrow = useEscrow();

    //functions
    const tryFindToken = (_item) =>
    {
        if (_item?.type === OfferItemType.ERC20)
        {
            return dApp.findToken(_item.contract);
        }
        return null;
    };
    const tryFindNFT = (_item) =>
    {
        if (_item?.type === OfferItemType.ERC721
            || _item?.type === OfferItemType.ERC1155)
        {
            return dApp.findNFT(_item.contract);
        }
        return null;
    };
    const tryGetAmount = (_item) =>
    {
        const t = tryFindToken(_item);
        if (t)
        {
            return MLFormat.formatToken(_item.amount, t, undefined, true);
        }

        const n = tryFindNFT(_item);
        if (n)
        {
            return MLFormat.formatNFT(_item.amount, n.findItem(_item.nftID), undefined, true);
        }

        if (_item?.type === "Coin")
        {
            return MLFormat.formatToken(_item.amount, dApp.wrappedCoin, undefined, true);
        }
        return "0";
    };

    //state
    const [show, setShow] = useState(() => props.show);
    const [item, setItem] = useState(() => props.item || null);
    const [editMode, setEditMode] = useState(() => props.editMode || false);
    const [token, setToken] = useState(() => tryFindToken(props.item));
    const [nft, setNFT] = useState(() => tryFindNFT(props.item));
    const [amount, setAmount] = useState(() => tryGetAmount(props.item));

    //functions
    const getItemSelectValue = () =>
    {
        let itemType = "";
        switch (item?.type)
        {
            case "Coin":
                itemType = "Coin";
                break;

            case OfferItemType.ERC20:
                itemType = "ERC20";
                break;

            case OfferItemType.ERC721:
                itemType = "ERC721";
                break;

            case OfferItemType.ERC1155:
                itemType = "ERC1155";
                break;

            default:
                return null;
        }
        return {
            type: itemType,
            contract: item.contract,
            nftID: item.nftID
        };
    };

    //handler
    const handleSelectItem = (value) =>
    {
        let itemType = "";
        switch (value.type)
        {
            case "Coin":
                itemType = "Coin";
                break;

            case "ERC20":
                itemType = OfferItemType.ERC20;
                break;

            case "ERC721":
                itemType = OfferItemType.ERC721;
                break;

            case "ERC1155":
                itemType = OfferItemType.ERC1155;
                break;
        }

        const sel =
        {
            type: itemType,
            contract: value.contract,
            nftID: value.nftID,
            amount: MLWeb3.toBN(0)
        };
        setItem(sel);
        setToken(tryFindToken(sel));
        setNFT(tryFindNFT(sel));
    };
    const handleClose = () =>
    {
        if (props.onClose)
        {
            props.onClose();
        }
        else
        {
            setShow(false);
        }
    };
    const handleSave = () =>
    {
        if (props.onSave)
        {
            props.onSave(item);
        }
        else
        {
            handleClose();
        }
    };
    const handleDelete = () =>
    {
        if (props.onDelete)
        {
            props.onDelete(item);
        }
        else
        {
            handleClose();
        }
    };
    const handleAmountChange = (e, _value) =>
    {
        setAmount(_value);
        if (token)
        {
            item.amount = MLWeb3.convertFloatString_TokenBN(_value, token);
        }
        else if (nft)
        {
            item.amount = MLWeb3.convertFloatString_NFTBN(_value, nft, item.nftID);
        }
        else
        {
            item.amount = MLWeb3.convertFloatString_TokenBN(_value, dApp.wrappedCoin);
        }
    };

    //effect
    useEffect(() =>
    {
        setShow(props.show);
    }, [props.show]);
    useEffect(() =>
    {
        setItem(props.item || null);
        setEditMode(props.editMode || false);
    }, [JSON.stringify(props.item), props.editMode]);
    useEffect(() =>
    {
        setToken(tryFindToken(item));
        setNFT(tryFindNFT(item));
        setAmount(tryGetAmount(item));
    }, [JSON.stringify(item)]);

    return (
        <Modal
            show={show}
            header={t("modal_escrowEditItem_title")}
            footer=
            {
                <Flex>
                    {(editMode && item?.type !== "Coin") &&
                        <ToolButton
                            type={ToolButtonType.Delete}
                            onClick={() => handleDelete()}
                        />
                    }
                    <Button onClick={() => handleClose()}>
                        {t("cancel")}
                    </Button>
                    <Button onClick={() => handleSave()}>
                        {t("OK")}
                    </Button>
                </Flex>
            }
            onClose={() => handleClose()}
        >
            <Grid>
                <SelectTokenOrNFT
                    value={getItemSelectValue()}
                    onSelect={(value) => handleSelectItem(value)}
                    readOnly={editMode}
                />

                {(token || item?.type === "Coin") &&
                    <InputTokenAmount_Balance
                        token={token}
                        value={amount}
                        onChange={(e, v) => handleAmountChange(e, v)}
                        showMax={false}
                    />
                }
                {nft &&
                    <InputNFTAmount_Balance
                        nft={nft}
                        nftID={item?.nftID}
                        value={amount}
                        onChange={(e, v) => handleAmountChange(e, v)}
                        showMax={false}
                    />
                }

                {(!!item && item?.type !== "Coin") &&
                    <ApprovalButton
                        token={token}
                        nft={nft}
                        nftID={item?.nftID}
                        contract={escrow.address}
					/>
                }
            </Grid>
        </Modal>
    );
};

export default ModalEscrowEditItem;