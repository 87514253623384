//framework
import { DAppModule, MLUtils, useModule } from "@MoonLabsDev/dapp-core-lib";

//classes
import { MoonSwap } from "../classes/MoonSwap";

export const ModuleEvents =
{
    initialized: "moonSwap_initialized",
    swapInfo: "moonSwap_swapInfo"
};

export class Module_MoonSwap extends DAppModule
{
    static moduleName = "moon_swap";
    constructor()
    {
        super(m => m.swap);
        this.swap = null;
    }

    async onRefreshChainData(dApp)
    {
        if (this.swap === null)
        {
            return;
        }

        //init
        if (!this.swap.initialized)
        {
            await MLUtils.measureTime(`TM: MoonSwap => Init`, async () =>
            {
                await this.swap.reload_init();
            });
        }
    }

    async onLoad(dApp)
    {
        if (!Module_MoonSwap.availableOnChain(dApp.currentChain))
        {
            return;
        }

        const addr = dApp.currentChain?.modules?.moonSwap;
        dApp.log(false, `Loading MoonSwap [${addr}]`);
        this.swap = new MoonSwap(addr);
        this.setLoaded();
    }

    static availableOnChain(chain)
    {
        const addr = chain?.modules?.moonSwap;
        return (addr !== undefined
            && addr !== "");
    }
}

export const useMoonSwap = () => useModule(Module_MoonSwap);

const currentModule = new Module_MoonSwap();
export default currentModule;