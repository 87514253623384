import DApp from "../core/DApp";

const initialState =
{
    currentLanguage: "en_US"
};

const Translator = (_label, _data) =>
{
    const state = initialState;

    const translate = (_label, _data) =>
    {
        //find language
        const lang = DApp.instance.config.labels[state.currentLanguage];
        if (lang === undefined)
        {
            //language not found
            return _label;
        }

        //find label
        const lbl = lang[_label] || _label;

        //expand variables
        if (!!_data
            && !!lbl?.match(new RegExp(/%\S+?%/, "gm")))
        {
            let lblVar = lbl;
            Object.keys(_data).forEach((key) =>
            {
                const placeholder = new RegExp(`%${key}%`, "g");
                lblVar = lblVar.replace(placeholder, _data[key].toString())
            });
            return lblVar;
        }

        return lbl;
    };

    return translate(_label, _data);
};

export default Translator;