import DApp from "./DApp";
import { MLWeb3 } from "../utils";

class Web3Transaction
{
	/////////////////////////
    // Create
    /////////////////////////

	constructor(
		_callPromise,
		_errorMsg,
		_transactionDescription,
		_coinValue = null
	)
	{
		this.callPromise = _callPromise;
		this.errorMsg = _errorMsg || "";
		this.transactionDescription = _transactionDescription;
		this.coinValue = _coinValue;
	}

	/////////////////////////
    // Web3
    /////////////////////////

	async directSend(_from, _gasPrice)
	{
		const data =
		{
			from: _from,
			...(this.coinValue !== undefined && { value: this.coinValue }),	//payable
			...(_gasPrice !== undefined && { gasPrice: _gasPrice })			//gas
		};

		return await this.callPromise.send(data);
	}

	async send()
	{
		return await DApp.instance.trySend(
            this.callPromise,
            DApp.instance.account,
            this.errorMsg,
            this.transactionDescription,
			this.coinValue
		);
	}

	async trySend()
	{
		//estimate gas and check for error
		const est = await this.estimate();
		if (est.gas === 0)
		{
			//open "transaction might fail" modal
			DApp.instance.reportTransactionMightFail(this, est.error.originalError.message);
			return;
		}

		//send
		return await this.send();
	}

	async estimate()
	{
		//init
		const data =
		{
			from: DApp.instance.account,
			...(this.coinValue !== undefined && { value: this.coinValue })
		};
		let gas = 0;
		let error = null;

		//estimate
		try
		{
			gas = await this.callPromise.estimateGas(data);
		}
		catch (e)
		{
			error = MLWeb3.getWeb3ErrorObject(e);
			console.error(e);
		}
		return {
			error,
			gas
		};
	}
}

export default Web3Transaction;