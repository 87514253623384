//dApp
import { configPage as config } from "@MoonLabsDev/dapp-core-lib";

//modules
import
{
	Module_MoonAPI,
	Module_FeeDistributor,
	Module_MoonReferral,
	Module_MoonToken,
	Module_MoonSwap,
	Module_Escrow,
	Module_Bank,
	Module_SunflowerTrader
} from "../modules";

const configPage =
{
	...config,

    title: "MoonVault",
    description: "Multi-Chain Crypto Service Provider",
    themeColor: "#F5F5F5",
	socials:
	{
		discord: "https://discord.com/invite/9S3gg5hAeG",
		telegram: "https://t.me/Moon_Vault",
		github: "https://github.com/MoonVault",
		gitbook: "https://docs.moon-vault.com/",
		twitter: "https://twitter.com/Moon_Vault_News"
	},
    modules:
    [
        Module_MoonAPI,
        Module_FeeDistributor,
        Module_MoonReferral,
        Module_MoonToken,
        Module_MoonSwap,
        Module_Escrow,
		Module_Bank,
		Module_SunflowerTrader
    ],
	format:
	{
		thousandsSeperator: ","
	},
    preconnect: [],
    fonts: [],
    pages: []
};

export default configPage;