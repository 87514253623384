import Web3 from "web3";

//components
import { Text } from "../components";

//framework
import { MLFormat, MLUtils, MLWeb3, MLMultiCall, MLUI } from "../utils";
import Web3Connection from "./Web3Connection";
import Events from "./Events";

//classes
import { Token, NFT, Router, Oracle } from "../classes";

//config
import { config } from "../../dApp/config";

export class DApp
{
    static instance = null;
    static getOrCreateInstance(_config, _modules, _web3User, _web3Data, _wallet, _toastCallback)
    {
        if (!DApp.instance)
        {
            DApp.instance = new DApp(_config, _modules, _toastCallback);
			DApp.instance.init(_web3User, _web3Data, _wallet);
        }
        return DApp.instance;
    }

    constructor(_config, _modulesToEnable, _toastCallback)
    {
        //init
		this.config = _config || config;
        this.chains = [];
        this.tokens = [];
        this.routers = [];
        this.nfts = [];
        this.oracle = new Oracle(this.config.debug.oracle);
        this.transactions = [];
        this.transactionCounter = 1;
        this.currentChain = null;
        this.stableCoin = null;
        this.wrappedCoin = null;
        this.coinSymbol = "";
        this.avgGasPrice = 0;
        this.gasPrice = MLWeb3.toBN(0);
        this.userCoinBalance = MLWeb3.toBN(0);
        this.userCoinBalanceUSD = MLWeb3.toBN(0);
        this.currentBlock = 0;
        this.refreshCount = 0;
		this.toastCallback = _toastCallback;
        this.dataVersion = (new Date()).getTime() / 1000;
        this.registeredModules = this.config?.page?.modules || [];
		this.modulesNamesToEnable = _modulesToEnable || this.registeredModules.map(m => m.constructor.moduleName);
		this.modules = [];
        this.initialized = false;
		this.showTx = null;
		this.showTxMightFail = null;

		//loader
		this.onLoad = null;
		this.loaded = new Promise((resolve, reject) => this.onLoaded = resolve);
    }

	/////////////////////////
    // Init
    /////////////////////////

    async init(_web3User, _web3Data, _wallet)
    {
		this.refreshModules();
		this.initFormat();
        return this.connect(_web3User, _web3Data, _wallet).then(() =>
        {
            this.initialized = true;

            const all = this.modules.map(m => Promise.resolve(m.init && m.init(this)));
            return Promise.all(all);
        })
        .then(() =>
        {
            //event
            document.dispatchEvent(new CustomEvent(Events.dApp.initialized));

            this.refreshData();
        });
    }

	initFormat()
	{
		MLFormat.config.thousandsSeperator = this.config.page.format.thousandsSeperator || MLFormat.config.thousandsSeperator;
	}

	refreshModules()
	{
		this.modules = this.registeredModules.filter(m => this.modulesNamesToEnable.includes(m.constructor.moduleName));
	}

    /////////////////////////
    // Refresh Data
    /////////////////////////

    async refreshData()
    {
        try
        {
            const web3Data = DApp.selectWeb3Connection(false);
            const web3User = DApp.selectWeb3Connection(true);
            if (web3Data !== null)
            {
                //gas price & current block
                this.gasPrice = MLWeb3.toBN(await web3Data.eth.getGasPrice());
                this.currentBlock = await web3Data.eth.getBlockNumber();

                //chain data
                await MLUtils.measureTime(`TM: AppRefresh #${this.refreshCount}`, async () =>
                {
                    await this.refreshChainData();
                });
            }
            else if (web3User !== null) //user selected unsupported chain
            {
                //gas price & current block
                this.gasPrice = MLWeb3.toBN(await web3User.eth.getGasPrice());
                this.currentBlock = await web3User.eth.getBlockNumber();
            }

            //complete
            this.avgGasPrice = parseFloat(Web3.utils.fromWei(this.gasPrice.toString(10), "gwei"));
            this.refreshCount += 1;

            //event
            document.dispatchEvent(new CustomEvent(Events.dApp.reload));
        }
        catch (e) {console.error(e)}

        //next call
        setTimeout(() => this.refreshData(), 10000);
    }

    async refreshChainData()
    {
		if (this.currentChain !== null)
		{
			//token prices
			await this.refreshData_tokens();

			//NFT data
			await this.refreshData_nfts();
		}

        await Promise.all(this.modules.map(m => Promise.resolve(m.onRefreshChainData && m.onRefreshChainData(this))))
    }

    async refreshData_tokens()
    {
        //ensure correct order for oracle
        this.tokens = Oracle.sortTokensByOracleType(this.tokens);

        //init all
        let tokensInit = [];
        await MLUtils.measureTime(`TM: Token => Init ${this.tokens.length}`, async () =>
        {
            tokensInit = await Token.batch_init(this.tokens);
        });

        //load price & pair info
        let tokensGetPrice = tokensInit;//tokensInit.filter(t => !this.isSpecialToken(t));
        await MLUtils.measureTime(`TM: Token => Data ${tokensGetPrice.length}`, async () =>
        {
            await Token.batch_data(tokensGetPrice);
        });
        await MLUtils.measureTime(`TM: Oracle => TokenPairs ${tokensGetPrice.length}`, async () =>
        {
            await Oracle.batch_loadPricePairs(tokensGetPrice);
            tokensGetPrice = this.tokens.filter(t => t.initialized);
        });
        await MLUtils.measureTime(`TM: Token => Price ${tokensGetPrice.length}`, async () =>
        {
            await Token.batch_reloadPrice(tokensGetPrice);
        });

        //load user data
        if (this.account !== null)
        {
            //token balance
            await MLUtils.measureTime(`TM: Token => UserData ${tokensGetPrice.length}`, async () =>
            {
                const tokensUserBalance = tokensInit;//.filter(t => this.isSpecialToken(t) || this.depositTokens.includes(t));
                await Token.batch_userData(tokensUserBalance);
            });

            //load approval
            await MLUtils.measureTime(`TM: Token => Approval ${tokensInit.length}`, async () =>
            {
                await Token.batch_approval(tokensInit);
            });

            //coin balance
            if (this.currentChain !== null)
            {
                const webUser = DApp.selectWeb3Connection(true);
                this.userCoinBalance = MLWeb3.toBN(await webUser.eth.getBalance(this.account));
                this.userCoinBalanceUSD = this.wrappedCoin.getPriceUSDForAmount(this.userCoinBalance);
            }
        }
    }

    async refreshData_nfts()
    {
		if (this.nfts.length === 0)
		{
			return;
		}

        //init all (lazy)
        let nftsInit = [];
        await MLUtils.measureTime(`TM: NFT => Init ${this.nfts.length}`, async () =>
        {
            nftsInit = await NFT.batch_init(this.nfts);
        });

        //load metadata
        await MLUtils.measureTime(`TM: NFT => Metadata ${nftsInit.length}`, async () =>
        {
            for (let n = 0; n < nftsInit.length; n++)
            {
                const nft = nftsInit[n];
                await nft.batch_metaData();
            }
        });

        //load user data
        if (this.account !== null)
        {
            //load approval
            await MLUtils.measureTime(`TM: NFT => Approval ${nftsInit.length}`, async () =>
            {
                await NFT.batch_approval(nftsInit);
            });

            //load balances
            await MLUtils.measureTime(`TM: NFT => UserData ${nftsInit.length}`, async () =>
            {
                await NFT.batch_userDataNFTs(nftsInit);
            });
        }
    }

    /////////////////////////
    // Load Data
    /////////////////////////

    async loadData()
    {
        await this.loadData_chains();
		if (this.currentChain !== null)
		{
        	await this.loadData_chainData();
		}
    }

    async loadData_chains()
    {
        //load data
        if (this.chains.length === 0)
        {
            this.log(false, `Loading chain data`);
            this.chains = await MLUtils.fetchJSON(`${this.config.page.dataPath ?? location.origin}/data/chains.json?v=${this.dataVersion}`);
			this.chains = this.chains.filter(c => !!this.config.chains.chains.find(cc => cc.id === c.id));
            this.currentChain = this.findChain(this.chainID);
            if (this.currentChain === null)
            {
                this.log(true, `Unsupported Chain [${this.chainID}]`);
				this.onLoaded();
                throw false;
            }
        }
    }

    async loadData_chainData()
    {
        //init router
        if (this.routers.length === 0)
        {
            this.log(false, `Loading routers`);
            const jsonRouters = await MLUtils.fetchJSON(`${this.config.page.dataPath ?? location.origin}/data/${this.currentChain.name}/routers.json?v=${this.dataVersion}`);
            for (let n = 0; n < jsonRouters.length; n++)
            {
                const r = jsonRouters[n];
                this.routers.push(new Router(r));
            }
        }

        //init tokens
        if (this.tokens.length === 0)
        {
            this.log(false, `Loading tokens`);
            const jsonTokens = await MLUtils.fetchJSON(`${this.config.page.dataPath ?? location.origin}/data/${this.currentChain.name}/tokens.json?v=${this.dataVersion}`);
            for (let n = 0; n < jsonTokens.length; n++)
            {
                const t = jsonTokens[n];
                this.tokens.push(new Token(t));
            }
            this.stableCoin = this.findToken(this.currentChain.stableCoin);
            this.wrappedCoin = this.findToken(this.currentChain.wrappedCoin);
            this.coinSymbol = this.wrappedCoin?.symbol?.substring(1);
        }

        //init NFTs
        if (this.nfts.length === 0)
        {
            this.log(false, `Loading NFTs`);
            try
            {
                const jsonNFTs = await MLUtils.fetchJSON(`${this.config.page.dataPath ?? location.origin}/data/${this.currentChain.name}/nfts.json?v=${this.dataVersion}`);
                for (let n = 0; n < jsonNFTs.length; n++)
                {
                    const t = jsonNFTs[n];
                    this.nfts.push(new NFT(t));
                }
            }
            catch (e) { console.error(e); }
        }

		//modules
        const all = this.modules.map(m =>
		{
			Promise.resolve(m.onLoad && m.onLoad(this));
			Promise.resolve(m.init && m.init(this));
		});
		const result = await Promise.all(all);
		this.onLoaded();
		return result;
    }

    /////////////////////////
    // Connect
    /////////////////////////

    async connect(_web3User, _web3Data, _wallet)
    {
        await this.tryConnect(_web3User, _web3Data, _wallet)
        .then(() =>
        {
            return this.loadData();
        })
        .catch((e) =>
        {
			if (e !== false)
			{
            	this.log(true, e);
			}
            setTimeout(() => this.connect(), 5000);
        });
    }

    async tryConnect(_web3User, _web3Data, _wallet)
    {
        //init
        Web3Connection.instance.initWeb3();
        Web3Connection.instance.tryDetectWallet();

        //connect
        if (!await Web3Connection.instance.tryConnect(_web3User, _web3Data))
        {
            throw false;
        }
        this.chainID = Web3Connection.instance.chainID;

        //connect wallet
        await Web3Connection.instance.connectWallet();

        //get account
        await Web3Connection.instance.getAccount(_wallet);
        this.account = Web3Connection.instance.account;

        return true;
    }

    /////////////////////////
    // format functions
    /////////////////////////

    formatFiat(_amount, _precision = 2, _shorten = true)
    {
        return MLFormat.formatFiat(
            MLWeb3.convertTokenBN_Float(
                _amount,
                this.stableCoin),
            _precision,
            _shorten);
    }

    smartFormatFiat(_amount)
    {
        return MLFormat.smartFormatFiat(
            MLWeb3.convertTokenBN_Float(
                _amount,
                this.stableCoin));
    }

    /////////////////////////
    // data functions
    /////////////////////////

	static makeToast(_content, _success)
	{
		if (!!DApp.instance?.toastCallback)
		{
			DApp.instance?.toastCallback(_content, _success);
		}
	}

	static getThemeClassName()
	{
		return DApp.instance?.config?.ui?.theme?.root || "";
	}

	isModuleRegistered(_moduleName)
    {
        return !!this.modules.find(m => m.constructor.moduleName === _moduleName);
    }

    getTokenList(_lpTokens = undefined)
    {
        return this.tokens.filter((t) =>
        {
            if (_lpTokens !== undefined
                && t.isLPToken() !== !!_lpTokens)
            {
                return false;
            }

            return true;
        });
    }

    findChain(_id)
	{
		return this.chains.find((c) => c.id === _id) || null;
	}

    findRouter(_idOrContract)
	{
		return this.routers.find((r) => r.id === _idOrContract || MLWeb3.checkEqualAddress(r.address, _idOrContract.toLowerCase())) || null;
	}

    findToken(_contractOrSymbol)
	{
		if (!_contractOrSymbol)
		{
			return null;
		}
		return this.tokens.find((t) => MLWeb3.checkEqualAddress(t.address, _contractOrSymbol) || MLWeb3.checkEqualAddress(t.symbol, _contractOrSymbol)) || null;
	}

    findNFT(_contract)
    {
        if (!_contract)
        {
            return null;
        }
        return this.nfts.find((n) => MLWeb3.checkEqualAddress(n.address, _contract)) || null;
    }

    findNFTItem(_contract, _id)
    {
        return this.findNFT(_contract)?.items?.find((i) => i.id === _id) || null;
    }

    findTransaction(_txHashOrId)
    {
        if (!_txHashOrId)
        {
            return null;
        }
        if (typeof(_txHashOrId) === "string")
        {
            _txHashOrId = _txHashOrId.toLowerCase();
        }
        return this.transactions.find((t) => t.hash.toLowerCase() === _txHashOrId || t.id === _txHashOrId) || null;
    }

    /////////////////////////
    // Web3
    /////////////////////////

    encodeABIParameter(_type, _value)
    {
        MLWeb3.encodeABIParameter(
            DApp.selectWeb3Connection(true),
            _type,
            _value);
    }

	static web3Override = null;
	static overrideWebConnection(_web3)
	{
		DApp.web3Override = _web3 || null;
	}

    static selectWeb3Connection(_user)
    {
		if (DApp.web3Override !== null)
		{
			return DApp.web3Override;
		}
        const i = Web3Connection.instance;
        if (!i.web3_user)
        {
            return i.web3_data;
        }
        return (_user || DApp.instance.config.web3.alwaysUseUserWeb3 ? i.web3_user : i.web3_data);
    }

    selectWeb3ConnectionID(_user)
    {
        const i = Web3Connection.instance;
        if (!i.web3_user)
        {
            return "data";
        }
        return (_user || this.config.web3.alwaysUseUserWeb3 ? "user" : "data");
    }

    makeMultiCall(_user)
	{
        return MLMultiCall.makeMultiCall(this.selectWeb3ConnectionID(_user));
    }

    async batchCall(_objects, _makeRequest, _processRequest, _user, _errorMessage = "", _topic = "")
    {
        if (_objects.length === 0)
        {
            return [];
        }

        //make calls
        const calls = [];
        _objects.forEach(o => calls.push(_makeRequest(o)));

        //make multicall
        const ret = await MLMultiCall.call(
            DApp.instance.makeMultiCall(_user),
            calls,
            _errorMessage,
            _topic);

        //process calls
        const processed = []
        for (let n = 0; n < _objects.length; n++)
        {
            processed.push(await _processRequest(_objects[n], ret[n]));
        }
        return processed;
    }

    async trySend(_callPromise, _from, _errorMsg, _transactionDescription, _coinValue)
	{
        if (this.pseudoAccount)
        {
            console.warn(`[PseudoAccount] ${_transactionDescription}`);
            return;
        }

        //create transaction
        const transID = this.transactionCounter++;
        const tx =
        {
            id: transID,
            hash: "",
            stage: 0,
            description: _transactionDescription || "",
            receipt: null,
            error: null,
            gasPrice: this.gasPrice
        };
        this.transactions.push(tx);

        //show modal
        this.showTx = tx;
        this.updateTransaction(tx, 0);

        //send
        console.log(`[Transaction] ${transID} started`);
        return await MLWeb3.trySend(
            _callPromise,
            _from,
            _errorMsg,
            undefined,
            (_txHash) =>
            {
                //transaction was confirmed from user and is send to blockchain
                console.log(`[Transaction] ${transID} pending`);
                const tx = this.findTransaction(transID);
                tx.hash = _txHash;
                this.updateTransaction(tx, 1);
            },
            (_receipt) =>
            {
                //a receipt is available to check the state
                console.log(`[Transaction] ${transID} receipt available`);
                const tx = this.findTransaction(transID);
                tx.receipt = _receipt;
                this.updateTransaction(tx, 2);
            },
            (_confirmations, _receipt) =>
            {
                //it was mined and confirmed
                console.log(`[Transaction] ${transID} mined`);
                const tx = this.findTransaction(transID);
                tx.receipt = _receipt;
                this.updateTransaction(tx, 3);
                DApp.makeToast(
                    <>
                        Transaction complete!
                        <br />
                        <Text size="-1">
                            {tx.description}
                        </Text>
                    </>,
					true
				);
            },
            (_error) =>
            {
                //error occured
                console.log(`[Transaction] ${transID} failed`);
                const tx = this.findTransaction(transID);
                tx.error = _error;
                this.updateTransaction(tx, -1);
                //this.makeToast(<>Transaction failed!<br /><Text size="-1">{tx.description}</Text></>, false)
            },
            this.gasPrice,
            _coinValue);
	}

	reportTransactionMightFail(_tx, _error)
	{
		this.showTxMightFail =
		{
			tx: _tx,
			error: _error
		};
		MLUtils.dispatchEvent(Events.dApp.transaction);
	}

    /////////////////////////
    // Utils
    /////////////////////////

    updateTransaction(_tx, _stage)
    {
        if (_stage === -1
            || _stage > _tx.stage)
        {
            _tx.stage = _stage;
            MLUtils.dispatchEvent(Events.dApp.transaction);
        }
    }

    log(_error, _text)
    {
        if (_error)
        {
            console.error(`[dApp] ${_text}`);
        }
        else
        {
            console.log(`[dApp] ${_text}`);
        }
    }
}
export default DApp