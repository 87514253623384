import { useState } from "react";
import { usePopper } from "react-popper";

//styles
import styles from "./Tooltip.module.css";

export const TooltipPlacement =
{
    TopStart: "top-start",
    Top: "top",
    TopEnd: "top-end",

    BottomStart: "bottom-start",
    Bottom: "bottom",
    BottomEnd: "bottom-end",

    LeftStart: "left-start",
    Left: "left",
    LeftEnd: "left-end",

    RightStart: "right-start",
    Right: "right",
    RightEnd: "right-end",
};

const Tooltip = (props) =>
{
    //show
    const [show, setShow] = useState(() => false);
    const handleMouseEvent = (enter) =>
    {
        setShow(enter);
    };

    //popper
    const [popperOwnerRef, setPopperOwnerRef] = useState(null);
	const [popperRef, setPopperRef] = useState(null);
    const popperInfo = usePopper(popperOwnerRef, popperRef,
    {
        placement: props.placement || TooltipPlacement.Top,
        modifiers:
        [
            {
                name: "offset",
                options:
                {
                    offset: [0, 10]
                }
            }
        ]
	});

    return (
        <div
            ref={setPopperOwnerRef}
            onMouseEnter={() => handleMouseEvent(true)}
            onMouseLeave={() => handleMouseEvent(false)}
            onTouchStart={() => handleMouseEvent(true)}
        >
            {props.children}

            {show &&
                <div
                    ref={setPopperRef}
                    className={styles.tooltip}
                    style={popperInfo.styles.popper}
                    {...popperInfo.attributes.popper}
                >
                    {props.content}
                </div>
            }
        </div>
    );
};

export default Tooltip;