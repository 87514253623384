import React, { useState } from "react";

//components
import
{
	Grid,
	Flex,
	Text,
	Card,
	CardHeader,
	CardBody,
	NFTIcon,
	TokenIcon,
	ValueNFTAmount,
	ValueTokenAmount,
	ValueNFTName
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { ModuleEvents } from "../../..//modules/Module_SunflowerTrader";
import { useSunflowerTrader } from "../../../modules";

//styles
import styles from "./SunflowerTrader.module.css";


const ListingStat = (props) =>
{
	return (
		<Flex
			style={{ justifyContent: "space-between"}}
		>
			<Text>
				{props.title}
			</Text>
			<Flex>
				{props.children}
			</Flex>
		</Flex>
	);
}

const SunflowerTraderListing = (props) =>
{
	//context
	const trader = useSunflowerTrader();

	//state
	const [id, setID] = useState(props.listingID);
	const [listing, setListing] = useState({...trader.findListing(id)});

	//handler
	const handleUpdate = (_data) =>
	{
		if (_data.detail.id !== id)
		{
			return;
		}

		setListing({...trader.findListing(id)})
	}

	//effects
	useEventSubscription(ModuleEvents.listing, handleUpdate, [id]);

	return (
		<Card
			style=
			{
				{
					width: "250px"
				}
			}
		>
			<CardHeader>
				<Grid gap="0px">
					<Text align="center">
						Farm #{listing.farmID}
					</Text>
					<Text
						align="center"
						size={-1}
						color={2}
					>
						Listing #{listing.id}
					</Text>
				</Grid>
			</CardHeader>
			<CardBody>
				<Text size={-1}>
					<Grid>
						<ListingStat
							title="Item:"
						>
							<ValueNFTName
								nft={trader.nft}
								nftID={listing.resource}
							/>
							<NFTIcon
								nft={trader.nft}
								nftID={listing.resource}
								className={styles.nftIcon}
							/>
						</ListingStat>

						<ListingStat
							title="Amount:"
						>
							<ValueNFTAmount
								nft={trader.nft}
								nftID={listing.resource}
								value={listing.amount}
								smart={true}
							/>
						</ListingStat>

						<ListingStat
							title="Price:"
						>
							<ValueTokenAmount
								token={trader.token}
								value={listing.priceWithTax}
								smart={true}
								load={true}
							/>
							<TokenIcon
								token={trader.token}
								className={styles.sflIcon}
							/>
						</ListingStat>

						<ListingStat
							title="Price / Unit (*):"
						>
							<ValueTokenAmount
								token={trader.token}
								value={listing.pricePerUnitWithTax}
								smart={true}
								load={true}
							/>
							<TokenIcon
								token={trader.token}
								className={styles.sflIcon}
							/>
						</ListingStat>

						<ListingStat
							title="Price / Unit:"
						>
							<ValueTokenAmount
								token={trader.token}
								value={listing.pricePerUnit}
								smart={true}
								load={true}
							/>
							<TokenIcon
								token={trader.token}
								className={styles.sflIcon}
							/>
						</ListingStat>
					</Grid>
				</Text>
			</CardBody>
		</Card>
	);
};

export default SunflowerTraderListing;