import { useState, useEffect } from "react";

//components
import { Flex } from "../../";
import ListItem from "./ListItem";

//hooks
import { useMediaInfo, useTheme } from "../../../hooks";

//framework
import { MLUI } from "../../../utils";

//styles
import styles from "./Containers.module.css";

const List = (props) =>
{
    //context
    const curMediaInfo = useMediaInfo();
	const theme = useTheme();

    //select prop
    const responsiveProps = props.responsive;
    const selectProp = (_propID) =>
    {
        return MLUI.selectPropertyFromMediaQueryList(
            curMediaInfo.types,
            curMediaInfo.matches,
            responsiveProps,
            _propID
        );
    };

    //default style props
    const cur_cols = props.cols || 1;

    //style props
    const [cols, setCols] = useState(() => cur_cols);

    //handler
    const handleSelectItem = (value) =>
    {
        if (props.onSelect)
        {
            props.onSelect(value);
        }
    };

    //media query
    useEffect(() =>
    {
        setCols(selectProp("cols") || cur_cols);
    }, [JSON.stringify(curMediaInfo)]);

    return (
        <Flex
            cols={cols}
            gap="0px"
            className=
            {
                [
					theme?.getClassName("List") ?? "",
                    styles.list,
                    props.className
                ].join(" ")
            }
        >
            {props.items.map((item, index) => (
                <ListItem
                    key={`item_${index}`}
                    className={props.itemClassName}
                    value={item.value}
                    index={index}
                    selectable={props.onSelect !== undefined}
                    onSelect={(v) => handleSelectItem(v)}
                >
                    {item.content}
                </ListItem>
            ))}
        </Flex>
    );
};

export default List;