import { useState, useRef } from "react";
import { usePopper } from "react-popper";

//components
import Button from "./Button";
import ButtonMenuItem from "./ButtonMenuItem";

//framework
import { useEventSubscription } from "../../../hooks";

//styles
import styles from "./Buttons.module.css";

const ButtonMenu = (props) =>
{
    //state
    const [showSubMenu, setShowSubMenu] = useState(() => false);

    //popper
    const popperOwnerRef = useRef(null);
	const [popperRef, setPopperRef] = useState(null);
    const popperInfo = usePopper(popperOwnerRef.current, popperRef,
    {
        placement: "bottom-end"
    });

    //handlers
    const handleDocumentClick = (event) =>
    {
        if (popperOwnerRef.current.contains(event.target))
        {
            return;
        }
        setShowSubMenu(false);
    };
	const handleMouseEvent = (_enter) =>
	{
		if (props.menu
			&& props.menu.length > 1
			&& !!props.hover)
		{
			setShowSubMenu(_enter);
		}
	};
    const handleMenuClick = () =>
    {
		if (props.menu
			&& props.menu.length > 1
			&& !props.hover)
		{
        	setShowSubMenu(!showSubMenu);
		}
    };

    //effects
    useEventSubscription("mousedown", handleDocumentClick);

	const standaloneMenu = (props.menu && props.menu.length === 1 ? props.menu[0] : null);
    return (
        <div
			ref={popperOwnerRef}
			onClick={() => handleMenuClick()}
			onMouseEnter={() => handleMouseEvent(true)}
            onMouseLeave={() => handleMouseEvent(false)}
            onTouchStart={() => handleMouseEvent(true)}
		>
			<Button
				href={(standaloneMenu !== null ? standaloneMenu.link : undefined)}
				nav={(standaloneMenu !== null ? !standaloneMenu.extern : false)}
				buttonStyle={props.buttonStyle}
				target="_blank"
				style={props.style}
				className={props.className}
			>
				{props.children}
			</Button>
            {props.menu && props.menu.length > 0 && showSubMenu &&
                <div
                    ref={setPopperRef}
                    className={styles.buttonMenu_subMenu}
                    style={popperInfo.styles.popper}
                    {...popperInfo.attributes.popper}
                >
                    {
                        props.menu.map((m) => (
                            <ButtonMenuItem
                                selectOption={() => setShowSubMenu(false)}
                                key={`item_${props.menu.indexOf(m)}`}
                                menuItem={m}
                            />
                    ))}
                </div>
            }
        </div>
    );
};

export default ButtonMenu;