//components
import { PageHeader} from "@MoonLabsDev/dapp-core-lib";

export default () =>
{
    return (
        <PageHeader
            title={"Coming Soon!"}
        >
            Please be patient, our developers are building in the background to build you the protocol you deserve!
        </PageHeader>
    );
}