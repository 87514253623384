import { useState } from "react";

//components
import { Image } from "../../";

//framework
import DApp from "../../../core/DApp";
import { MLUtils } from "../../../utils";

//css
import styles from "./Images.module.css";

const TokenIcon = (props) =>
{
	//const state
	const [rounded] = useState(MLUtils.defaultBoolean(props.rounded, false));

    if (props.token?.isLPToken())
    {
        //LP
        const token0 = DApp.instance.findToken(props.token.token0);
        const token1 = DApp.instance.findToken(props.token.token1);
        return (
            <div
                className=
                {
                    [
                        styles.tokenIcon_lp,
                        props.className
                    ].join(" ")
                }
                onClick={props.onClick}
                style={props.style}
            >
                <Image
                    className=
                    {
                        [
                            styles.tokenIcon_token0,
                            (rounded ? styles.rounded : "")
                        ].join(" ")
                    }
                    src={token0.icon}
                />
                <Image
                    className=
                    {
                        [
                            styles.tokenIcon_token1,
                            (rounded ? styles.rounded : "")
                        ].join(" ")
                    }
                    src={token1.icon}
                />
            </div>
        );
    }
    else
    {
        //single asset
        return (
            <Image
                className=
                {
                    [
                        styles.tokenIcon_single,
                        (rounded ? styles.rounded : ""),
                        props.className
                    ].join(" ")
                }
                src={props.token?.icon}
                onClick={props.onClick}
                style={props.style}
            />
        );
    }
};

export default TokenIcon;