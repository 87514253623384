import { useState } from "react";

//components
import
{
	Button,
	Flex,
	Text
} from "../../";

//framework
import { gas } from "../../../symbols";
import { MLUI, MLFormat } from "../../../utils";

//framework
import { useDApp, useEventSubscription } from "../../../hooks";
import Events from "../../../core/Events";

//styles
import styles from "./Buttons.module.css";

const GasButton = (props) =>
{
	//context
	const dApp = useDApp();

	//state
	const [gasPrice, setGasPrice] = useState(() => dApp.avgGasPrice);

	//effects
	useEventSubscription(Events.dApp.reload, () => setGasPrice(dApp.avgGasPrice));

	const priceLow = (dApp.currentChain?.gas?.low ?? 0);
	const priceHigh = (dApp.currentChain?.gas?.high ?? 0);
	return (
		<Button
			href={dApp.currentChain?.explorer?.gasTracker || ""}
			target="_blank"
		>
			<Flex>
				{MLUI.imageOrSymbol(
					gas(),
					[
						styles.gas_icon,
						styles.gasPrice,
						(gasPrice < priceLow
							? styles.low
							: (gasPrice > priceHigh && priceHigh !== priceLow
								? styles.high
								: styles.medium
							)
						),
						props.className
					].join(" ")
				)}
				<Text size={-1}>
					{gasPrice === 0 &&
						<>???</>
					}
					{gasPrice !== 0 &&
						<>
							{MLFormat.formatFloat(gasPrice, 1, true)}
							{" "}GWEI
						</>
					}
				</Text>
			</Flex>
		</Button>
	);
};

export default GasButton;