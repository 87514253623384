import { useState } from "react";
import { osName, osVersion, browserName, browserVersion } from "react-device-detect";

//components
import
{
    Text,
    Flex,
    Grid,
    Emblem,
    EmblemColor,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    PageHeader,
    PageContent
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { Events } from "@MoonLabsDev/dapp-core-lib";
import { useDApp, useWeb3Connection, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

const makeInfo = (_title, _text) =>
{
    return (
        <>
            <div style={{ textAlign: "left" }}>
                {_title}
            </div>
            <div style={{ textAlign: "right" }}>
                {_text}
            </div>
        </>
    );
};

const MakeEmblems = ({walletProvider}) =>
{
    //context
    const dApp = useDApp();

    //functions
    const getChainName = () =>
    {
        return (dApp.currentChain === null
            ? "??? Chain"
            : dApp.currentChain.name);
    };

    //state
    const [chainName, setChainName] = useState(() => getChainName());

    //effect
    useEventSubscription(Events.dApp.initialized, () => setChainName(getChainName()));

    return (
        <Flex>
            <Emblem color={EmblemColor.blue}>
                {`${browserName} ${browserVersion}`}
            </Emblem>
            <Emblem color={EmblemColor.green}>
                {`${osName} ${osVersion}`}
            </Emblem>
            <Emblem color={EmblemColor.violet}>
                {navigator.cookieEnabled ? "" : "no"} Cookies
            </Emblem>
            <Emblem color={EmblemColor.red}>
                {walletProvider}
            </Emblem>
            <Emblem color={EmblemColor.yellow}>
                {chainName}
            </Emblem>
        </Flex>
    );
};

export default () =>
{
    //context
    const web3 = useWeb3Connection();

    //state
    const [walletProvider, setWalletProvider] = useState(() => web3.walletProvider ?? "No Wallet");

    //effect
    useEventSubscription(Events.web3.update, () => setWalletProvider(web3.walletProvider ?? "No Wallet"));

    return (
        <>
            <PageHeader
                title="Debug Info"
            >
                Shows info about your device / browser / operating system, which is useful to give you support.
            </PageHeader>

            <PageContent>
                <Flex style={{ width: "100%" }}>
                    <Card style={{ width: "300px" }}>
                        <CardHeader style={{ textAlign: "center" }}>
                            <Text
                                color={3}
                                size={1}
                            >
                                Browser Info
                            </Text>
                        </CardHeader>
                        <CardBody style={{ minHeight: "200px" }}>
                            <Grid cols={2}>
                                {makeInfo("Window:", <>{window.innerWidth} / {window.innerHeight}</>)}
                                {makeInfo("Screen:", <>{screen.width} / {screen.height}</>)}
                            </Grid>
                        </CardBody>
                        <CardFooter>
                            <MakeEmblems walletProvider={walletProvider} />
                        </CardFooter>
                    </Card>
                </Flex>
            </PageContent>
        </>
    );
}