import { useState, useEffect } from "react";

//components
import
{
    Modal,
    Button,
    Grid,
    Flex,
    Text,
    Input
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLTranslate as t } from "@MoonLabsDev/dapp-core-lib";
import { MLFormat, MLUtils } from "@MoonLabsDev/dapp-core-lib";

//styles
import styles from "./MoonSwap.module.css";

const SlippageQuickSelect = ({slippage, setSlippage}) =>
{
    return (
        <Button
            className={styles.settings_slippageQuick}
            onClick={() => setSlippage((slippage * 100).toString())}
        >
            <Text size={-1}>
                {MLFormat.formatPercent(slippage)}
            </Text>
        </Button>
    );
};

const ModalSwapSettings = (props) =>
{
    //state
    const [show, setShow] = useState(() => props.show);
    const [slippage, setSlippage] = useState(() => props.slippage);

    //handler
    const handleClose = () =>
    {
        if (props.onClose)
        {
            props.onClose();
        }
        else
        {
            setShow(false);
        }
    };
    const handleSave = () =>
    {
        MLUtils.setStorage(false, "MoonSwap_slippage", Math.max(Math.min(parseFloat(slippage) / 100, 1), 0));
        handleClose();
    };

    //effect
    useEffect(() =>
    {
        setShow(props.show);
    }, [props.show]);
    useEffect(() =>
    {
        setSlippage(props.slippage);
    }, [props.slippage]);

    return (
        <Modal
            show={show}
            header={t("modal_swapSettings_title")}
            footer=
            {
                <Flex>
                    <Button onClick={() => handleClose()}>
                        {t("cancel")}
                    </Button>
                    <Button onClick={() => handleSave()}>
                        {t("OK")}
                    </Button>
                </Flex>
            }
            onClose={() => handleClose()}
        >
            <Grid>
                <Text>
                    Slippage %:
                </Text>
                <Input
                    value={slippage}
                    placeholder={"Slippage"}
                    type="number"
                    min={0}
                    max={100}
                    step={0.1}
                    onChange={(e, v) => setSlippage(v)}
                />
                <Grid cols={3}>
                    <SlippageQuickSelect
                        slippage={0.001}
                        setSlippage={setSlippage}
                    />
                    <SlippageQuickSelect
                        slippage={0.005}
                        setSlippage={setSlippage}
                    />
                    <SlippageQuickSelect
                        slippage={0.01}
                        setSlippage={setSlippage}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ModalSwapSettings;