import { useEffect, useState } from "react";
import { AreaChart as RechartAreaChart, Area, YAxis, CartesianGrid, Tooltip } from "recharts";

//hooks
import { useMediaInfo } from "../../../hooks";

//framework
import { MLUI } from "../../../utils";

//misc
import CustomTooltip from "./CustomTooltip";
import { Chart_defaultColors } from "./config";
import { generateXAxis, fillData, getFillColor, generateChartGradients } from "./utils";
import { Chart_testData_Area, Chart_testDataKeys_Area } from "./tests";

const AreaChart = (props) =>
{
    //context
    const curMediaInfo = useMediaInfo();

    //select prop
    const responsiveProps = props.responsive;
    const selectProp = (_propID) =>
    {
        return MLUI.selectPropertyFromMediaQueryList(
            curMediaInfo.types,
            curMediaInfo.matches,
            responsiveProps,
            _propID
        );
    };

    //default style props & cur values
    const cur_width = props.width || 300;
    const cur_height = props.height || 300;
    const cur_showTooltip = (props.showTooltip !== undefined ? props.showTooltip : true);
    const cur_gridType = props.gridType || 0;
    const cur_axisType = (props.axisType !== undefined ? props.axisType : "number");
    const cur_showAxis = (props.showAxis !== undefined ? props.showAxis : true);
    const cur_colors = props.colors || Chart_defaultColors;
    const cur_data = props.data || Chart_testData_Area;
    const cur_dataKeys = props.dataKeys || Chart_testDataKeys_Area;
    const cur_gradients = props.gradients || [];
    const cur_stroke = props.stroke || 2;

    //style props
    const [width, setWidth] = useState(() => cur_width);
    const [height, setHeight] = useState(() => cur_height);
    const [showTooltip, setShowTooltip] = useState(() => cur_showTooltip);
    const [colors, setColors] = useState(() => cur_colors);
    const [data, setData] = useState(() => fillData(cur_axisType, cur_data));
    const [dataKeys, setDataKeys] = useState(() => cur_dataKeys);
    const [axisType, setAxisType] = useState(() => cur_axisType);
    const [showAxis, setShowAxis] = useState(() => cur_showAxis);
    const [gradients, setGradients] = useState(() => cur_gradients);
    const [stroke, setStroke] = useState(() => cur_stroke);

    //media query
    useEffect(() =>
    {
        setWidth(selectProp("width") || cur_width);
        setHeight(selectProp("height") || cur_height);
    }, [JSON.stringify(curMediaInfo)]);

    //color & data
    useEffect(() =>
    {
        setColors(cur_colors);
    }, [JSON.stringify(cur_colors)]);
    useEffect(() =>
    {
        setData(fillData(cur_axisType, cur_data));
        setAxisType(cur_axisType);
    }, [JSON.stringify(cur_data), JSON.stringify(cur_axisType)]);
    useEffect(() =>
    {
        setDataKeys(cur_dataKeys);
    }, [JSON.stringify(cur_dataKeys)]);
    useEffect(() =>
    {
        setGradients(cur_gradients);
    }, [JSON.stringify(cur_gradients)]);

    return (
        <RechartAreaChart
            width={width}
            height={height}
            data={data}
        >
            {cur_gridType >= 0 &&
                (cur_gridType === 0
                    ? <CartesianGrid />
                    : <CartesianGrid strokeDasharray={cur_gridType} />
                )
            }
            {showTooltip && <Tooltip />}
            {showAxis &&
                <>
                    {generateXAxis(axisType)}
                    <YAxis />
                </>
            }
            {generateChartGradients(gradients)}

            {dataKeys.map((key, index) => (
                <Area
                    key={`area-${index}`}
                    dataKey={key}
                    stackId="1"
                    strokeWidth={stroke}
                    stroke={colors[index % colors.length]}
                    type="monotone"
                    fillOpacity={1}
                    fill={getFillColor(key, index, gradients, colors)}
                />
            ))}
        </RechartAreaChart>
    );
};
export default AreaChart;