//styles
import styles from "./Card.module.css";

export const CardRibbonType = 
{
    Normal: "",
    SlantUp: "slant-up",
    SlantDown: "slant-down",
    Up: "up",
    Down: "down",
    Check: "check",
    Rounded: "rounded"
};

const CardRibbon = (props) =>
{
    //get type
    const types =
    [
        CardRibbonType.Normal, 
        CardRibbonType.SlantUp, 
        CardRibbonType.SlantDown, 
        CardRibbonType.Up, 
        CardRibbonType.Down, 
        CardRibbonType.Check,
        CardRibbonType.Rounded
    ];
    const typeStyles =
    [
        "",
        styles.cardRibbonContent_slantUp,
        styles.cardRibbonContent_slantDown,
        styles.cardRibbonContent_up,
        styles.cardRibbonContent_down,
        styles.cardRibbonContent_check,
        styles.cardRibbonContent_round
    ];
    const currentTypeIdx = types.indexOf(props.type || "");
    const currentTypeStyle = typeStyles[(currentTypeIdx === -1 ? 0 : currentTypeIdx)];

    //style    
    const style = {...props.style};
    if (props.color !== undefined)
    {
        style.background = `${props.color} linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(1, 1, 1, 0.25) 100%)`;
    }

    return (
        <div
            className={[styles.cardRibbon, props.className].join(" ")}>
            <div
                className={[styles.cardRibbonContent, currentTypeStyle].join(" ")}
                style={style}
            >
                {props.children}
            </div>
        </div>
    );
};

export default CardRibbon;