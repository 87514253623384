import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

//components
import
{
    Flex,
    Grid,
    PageHeader,
    PageContent,
    SelectBox,
    ErrorServiceNotAtChain
} from "@MoonLabsDev/dapp-core-lib";
import { EscrowTrade, EscrowUserOverview } from "@MoonVault/dapp-widgets-lib";

//classes
import { TradeStatus } from "@MoonVault/dapp-widgets-lib";

//module
import { useEscrow } from "@MoonVault/dapp-widgets-lib";

export default () =>
{
	//contexxt
	const escrow = useEscrow();

    //state
    const [searchParams] = useSearchParams();
    const [trade, setTrade] = useState(() => parseInt(searchParams.get("trade")) || null);
    const [showActive, setShowActive] = useState(() => true);

    //handler
    const handleShowModeSelected = (value) =>
    {
        setShowActive(value);
    };

    //effects
    useEffect(() =>
    {
        setTrade(parseInt(searchParams.get("trade")) || null);
    }, [searchParams.get("trade")]);

    return (
        <>
            <PageHeader
                title="Escrow"
            >
                An escrow is a service where 2 people can trade with each other and exchange goods.
                <br />
                Escrows significantly reduce the risk of getting scammed.
                <br />
                Contact us to get your token or NFT collection whitelisted.
            </PageHeader>

            <PageContent>
                <ErrorServiceNotAtChain checkSupportChainCallback={escrow.availableOnChain}>
                    <Flex>
                        {trade === null &&
                            <Grid>
                                <SelectBox
                                    options=
                                    {
                                        [
                                            {
                                                value: true,
                                                text: "Show active"
                                            },
                                            {
                                                value: false,
                                                text: "Show history"
                                            }
                                        ]
                                    }
                                    value={showActive}
                                    onSelect={(v) => handleShowModeSelected(v)}
                                />

                                {showActive &&
                                    <Flex>
                                        <EscrowUserOverview status={TradeStatus.ONGOING} />
                                    </Flex>
                                }
                                {!showActive &&
                                    <Flex>
                                        <EscrowUserOverview status={TradeStatus.COMPLETE} />
                                        <EscrowUserOverview status={TradeStatus.CANCELLED} />
                                    </Flex>
                                }
                            </Grid>
                        }

                        {trade !== null &&
                            <EscrowTrade tradeID={trade} />
                        }
                    </Flex>
                </ErrorServiceNotAtChain>
            </PageContent>
        </>
    )
};