//framework
import { DAppModule, MLUtils, useModule } from "@MoonLabsDev/dapp-core-lib";

//classes
import { SunflowerTrader } from "../classes/SunflowerTrader";

export const ModuleEvents =
{
    data: "sflTrader_data",
    listing: "sflTrader_listing",
	stats: "sflTrader_stats",
};

export class Module_SunflowerTrader extends DAppModule
{
    static moduleName = "sunflower_trader";
    constructor()
    {
        super(m => m.sflTrader);
        this.sflTrader = null;
    }

    async onRefreshChainData(dApp)
    {
        if (this.sflTrader === null)
        {
            return;
        }

        //data
        await MLUtils.measureTime(`TM: SunflowerTrader => Data`, async () =>
        {
            await this.sflTrader.batch_data();
        });

        //listings
        await MLUtils.measureTime(`TM: SunflowerTrader => Listings ${this.sflTrader.listings.length}`, async () =>
        {
            await this.sflTrader.batch_listing();
        });
    }

    async onLoad(dApp)
    {
        if (!Module_SunflowerTrader.availableOnChain(dApp.currentChain))
        {
            return;
        }

        const addr = dApp.currentChain?.modules?.sflTrader;
        dApp.log(false, `Loading SunflowerTrader [${addr}]`);
        this.sflTrader = new SunflowerTrader(addr);
        this.setLoaded();
    }

    static availableOnChain(chain)
    {
        const addr = chain?.modules?.sflTrader;
        return (addr !== undefined
            && addr !== "");
    }
}

export const useSunflowerTrader = () => useModule(Module_SunflowerTrader);

const currentModule = new Module_SunflowerTrader();
export default currentModule;