import { useState, useEffect } from "react";

//components
import { ValueLoad, FormatNFTAmount } from "../../../";

//framework
import { MLWeb3 } from "../../../../utils";
import Events from "../../../../core/Events";

//hooks
import { useEventSubscription } from "../../../../hooks";

const ValueNFTAmount = (props) =>
{
    //state
    const [nft, setNFT] = useState(() => props.nft || null);
    const [nftID, setNFTID] = useState(() => props.nftID);
    const [additionalLoad, setAdditionalLoad] = useState(() => (props.additionalLoad !== undefined ? props.additionalLoad : true));
    const [smart, setSmart] = useState(() => props.smart || false);
    const [value, setValue] = useState(() => props.value || MLWeb3.toBN(0));
    const [shorten, setShorter] = useState(() => props.shorten || false);
    const [precision, setPrecision] = useState(() => (isNaN(props.precision) ? 8 : props.precision));
    const [load, setLoad] = useState(() => props.load);
    const [item, setItem] = useState(() => nft?.findItem(nftID) ?? null);

    //handler
    const handleNFTItemUpdate = (data) =>
    {
        if (!MLWeb3.checkEqualAddress(data.detail.address, nft.address)
            || data.detail.id !== nftID)
        {
            return;
        }
        setItem(nft?.findItem(nftID) ?? null);
    };

    //effect
    useEffect(() =>
    {
        setNFT(props.nft?.lazyLoad() || null);
        setNFTID(props.nft.lazyLoadItem(props.nftID));
        setValue(props.value || MLWeb3.toBN(0));
    }, [props.nft, props.nftID, props.value]);
    useEffect(() =>
    {
        setAdditionalLoad(props.additionalLoad !== undefined ? props.additionalLoad : true);
    }, [props.additionalLoad]);
    useEventSubscription(Events.nft.metaData, handleNFTItemUpdate, [nft, nftID]);

    if (load)
    {
        return (
            <ValueLoad
                loaded={item?.initialized && additionalLoad}
            >
                <FormatNFTAmount
                    nft={nft}
                    nftID={nftID}
                    value={value}
                    smart={smart}
                    shorten={shorten}
                    precision={precision}
                />
            </ValueLoad>
        );
    }
    return (
        <FormatNFTAmount
            nft={nft}
            nftID={nftID}
            value={value}
            smart={smart}
            shorten={shorten}
            precision={precision}
        />
    );
};

export default ValueNFTAmount;