import { useState } from "react";

//components
import
{
	Text,
	Button,
	Flex,
	Grid,
	NFTIcon,
	InputBNAmount
} from "../../";

//framework
import { MLFormat, MLUI, MLWeb3, MLUtils } from "../../../utils";

//symbols
import { wallet } from "../../../symbols";

//styles
import styles from "./Inputs.module.css";

const InputNFTAmount = (props) =>
{
	//const state
	const [nftItem] = useState(props.nft?.findItem(props.nftID));
    const [availableText] = useState(props.availableText || "");
    const [showAvailable] = useState(MLUtils.defaultBoolean(props.showAvailable, false));
    const [showInWallet] = useState(MLUtils.defaultBoolean(props.showInWallet, false));
    const [showMax] = useState(MLUtils.defaultBoolean(props.showMax, true));
    const [showIcon] = useState(MLUtils.defaultBoolean(props.showIcon, true));

    //handler
    const handleClickAvailable = () =>
    {
        if (props.onChange)
        {
            props.onChange(
				null,
					MLWeb3.convertNFTBN_FloatString(
					props.available,
					props.nft,
					props.nftID,
					true
				)
			);
        }
    };

    return (
        <div
            className=
            {
                [
                    styles.inputAmount,
                    styles.inputNFTAmount,
                    props.className
                ].join(" ")
            }
        >
            <Grid
                gap="0px"
                className={styles.inputAmountContainer}
            >
                {showAvailable &&
                    <Flex className={styles.inputAmount_header}>
                        {showIcon &&
							<Flex className={styles.inputAmount_headerIconWrapper}>
								<NFTIcon
									className={styles.inputAmount_headerIcon}
									nft={props.nft}
									nftID={props.nftID}
								/>
							</Flex>
                        }
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClickAvailable()}
                        >
                            <Flex>
                                <Text
									color={1}
									weight={0}
									size={-1}
									lineHeight={1}
								>
                                    {availableText !== "" ? `${availableText}: ` : ""}
                                    {nftItem?.initialized &&
                                        <>
                                            {MLFormat.formatNFT(
                                                props.available,
                                                nftItem
                                            )}
                                        </>
                                    }
                                </Text>
                                {MLUI.imageOrSymbol(wallet(), styles.inputAmount_iconBalance)}
                            </Flex>
                        </div>
                    </Flex>
                }

                <Flex
                    gap="0px"
                    className=
                    {
                        [
                            styles.inputAmount_content,
                            (showInWallet ? "": styles.noFooter),
                            (showAvailable ? "": styles.noHeader)
                        ].join(" ")
                    }
                >
                    <InputBNAmount
                        id={props.id}
                        name={props.name}
                        rawStyle={true}
                        value={props.value}
                        decimals={nftItem?.data?.decimals || 0}
                        placeholder="Input NFT amount"
                        max={props.max}
                        step={props.step}
                        readOnly={props.readOnly}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                    />

                    {showMax &&
                        <div className={styles.inputAmount_buttons}>
                            <Button
                                onClick={() => handleClickAvailable()}
                            >
                                max
                            </Button>
                        </div>
                    }
                </Flex>
            </Grid>
        </div>
    );
};

export default InputNFTAmount;