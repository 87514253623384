//framework
import { MLWeb3, MLMultiCall } from "../utils";
import DApp from "../core/DApp";

//contracts
import ABI_Router_UniswapV2 from "../abi/Router_UniswapV2";
import ABI_Factory_UniswapV2 from "../abi/Factory_UniswapV2";
import ABI_Router_UniswapV3 from "../abi/Router_UniswapV3";
import ABI_Factory_UniswapV3 from "../abi/Factory_UniswapV3";

//classes
import { Token } from "./Token";

export class Router
{
	constructor(_data)
	{
        //init
        this.initialized = false;

		//base values
        this.id = _data.id;
		this.address = _data.contract;
        this.v3 = !!_data.v3;
        this.linkSwap = _data.linkSwap;
        this.linkAddLiquidity = _data.linkAddLiquidity;
        this.linkRemoveLiquidity = _data.linkRemoveLiquidity;
        this.factoryAddress = "";
		this.data = _data.data || null;

        //runtime data
        this.pairs = [];
	}

	debugErrorString(_text)
	{
		return `Router [${this.id}] failed at: ${_text}`;
	}

    getContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(
            (this.v3 ? ABI_Router_UniswapV3 : ABI_Router_UniswapV2),
            this.address).methods;
    }

    makeMultiCall(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.address,
            (this.v3 ? ABI_Router_UniswapV3 : ABI_Router_UniswapV2),
            _calls
        );
    }

    getFactoryContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(
            (this.v3 ? ABI_Factory_UniswapV3 : ABI_Factory_UniswapV2),
            this.factoryAddress).methods;
    }

    makeMultiCallFactory(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.factoryAddress,
            (this.v3 ? ABI_Factory_UniswapV3 : ABI_Factory_UniswapV2),
            _calls
        );
    }

    /////////////////////////
    // Init
    /////////////////////////

    static async batch_init(_routers)
    {
        const filtered = _routers.filter(r => !r.initialized && r.address !== "");
        await DApp.instance.batchCall(
            filtered,
            (o) => o.makeRequest_init(),
            (o, r) => o.processRequest_init(r),
            false,
            "[Router] batch init",
            "Router: init"
        );

        return _routers.filter(r => r.initialized);
    }

    makeRequest_init()
    {
        return this.makeMultiCall(
        {
            factoryAddress: { function: "factory" }
        });
    }

    async processRequest_init(_data)
    {
        this.factoryAddress = _data.factoryAddress;

        //complete
        this.initialized = true;
    }

    /////////////////////////
    // Find pair
    /////////////////////////

    static async batch_findPair(_pairs)
    {
        //load pairs
        return await DApp.instance.batchCall(
            _pairs,
            (o) => o.router.makeRequest_findPair(o.token0, o.token1),
            (o, r) => o.router.processRequest_findPair(r.pair, o.token0, o.token1),
            false,
            "[Router] batch findPair",
            "Router: findPair"
        );
    }

    makeRequest_findPair(_t0, _t1)
    {
        if (this.v3)
        {
            return this.makeMultiCallFactory(
            {
                pair:
                {
                    function: "getPool",
                    parameters:
                    [
                        _t0.address,
                        _t1.address,
                        parseInt(_t0.v3PoolFee * 10000)
                    ]
                }
            });
        }
        else
        {
            return this.makeMultiCallFactory(
            {
                pair: { function: "getPair", parameters: [_t0.address, _t1.address] }
            });
        }
    }

    processRequest_findPair(_pair, _t0, _t1)
    {
        const t0 = (MLWeb3.compareAddress(_t0.address, _t1.address) === -1 ? _t0 : _t1);
        const t1 = (MLWeb3.compareAddress(_t0.address, _t1.address) === -1 ? _t1 : _t0);

        if (MLWeb3.toBN(_pair).isZero())
        {
            console.error(this.debugErrorString(`findOrCreatePair: no pair found [${_t0.symbol}-${_t1.symbol}]`))
            return null
        }
        let pairToken = DApp.instance.findToken(_pair);
        if (pairToken === null)
        {
            pairToken = new Token(
            {
                contract: _pair,
                router: this.id,
                initPair: true,
                oracleType: "LP",
                ...(this.v3 && { v3PoolFee: _t0.v3PoolFee })
            });
            DApp.instance.tokens.push(pairToken);
        }
        this.pairs.push(
        {
            token0: t0,
            token1: t1,
            pairToken: pairToken
        });

        return pairToken;
    }

    /////////////////////////
    // helper
    /////////////////////////

    lookupPair(_token0, _token1)
    {
        const t0 = (MLWeb3.compareAddress(_token0.address, _token1.address) === -1 ? _token0 : _token1);
        const t1 = (MLWeb3.compareAddress(_token0.address, _token1.address) === -1 ? _token1 : _token0);

        //find
        const pair = this.pairs.find((p) => MLWeb3.checkEqualAddress(p.token0.address, t0.address) && MLWeb3.checkEqualAddress(p.token1.address, t1.address));
        return (pair?.pairToken || null);
    }
}