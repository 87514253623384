import { useState, useEffect } from "react";

//components
import
{
    Modal,
    Button,
    Grid,
    Flex
} from "@MoonLabsDev/dapp-core-lib";
import InputTokenAmount_BankBalance from "../Helper/InputTokenAmount_BankBalance";

//framework
import { MLTranslate as t } from "@MoonLabsDev/dapp-core-lib";
import { MLWeb3, useDApp } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useBank } from "../../../../modules";

const ModalBankTransfer = (props) =>
{
    //hooks
    const dApp = useDApp();
    const bank = useBank();

    //state
    const [show, setShow] = useState(() => props.show);
    const [token, setToken] = useState(() => props.token || null);
    const [amount, setAmount] = useState(() => "");

    //handler
    const handleClose = () =>
    {
        if (props.onClose)
        {
            props.onClose();
        }
        else
        {
            setShow(false);
        }
    };
    const handleWithdraw = () =>
    {
        handleClose();
		const amt = MLWeb3.convertFloatString_TokenBN(amount, (token || dApp.wrappedCoin));
		bank.transfer(
			token,
			null,
			null,
			amt
		).send();
    };
    const handleValueChange = (_value) =>
    {
        setAmount(_value);
    };

    //effect
    useEffect(() =>
    {
        setShow(props.show);
    }, [props.show]);
	useEffect(() =>
    {
        setToken(props.token);
    }, [props.token]);

    return (
        <Modal
            show={show}
            header={t("modal_bankTransfer_title")}
            footer=
            {
                <Flex>
                    <Button onClick={() => handleClose()}>
                        {t("cancel")}
                    </Button>
					<Button
						onClick={() => handleWithdraw()}
					>
						{t("withdraw")}
					</Button>
                </Flex>
            }
            onClose={() => handleClose()}
        >
            <Grid>
				<InputTokenAmount_BankBalance
					token={token}
					value={amount}
					showInWallet={true}
					showMax={false}
					onChange={(e, v) => handleValueChange(v)}
					onSelect={(t) => handleTokenSelect(t)}
				/>
            </Grid>
        </Modal>
    );
};

export default ModalBankTransfer;