//framework
import { MLUI } from "../../../utils";
import { useTheme } from "../../../hooks";

//styles
import styles from "./Text.module.css";

const sizes =
[
	{ id: -1, class: styles.sizeSmall },
	{ id: 0, class: styles.sizeNormal },
	{ id: 1, class: styles.sizeBig },
	{ id: 2, class: styles.sizeVeryBig }
];

const weights =
[
	{ id: -1, class: styles.weightLight },
	{ id: 0, class: styles.weightNormal },
	{ id: 1, class: styles.weightBold }
];

const colors =
[
	{ id: 0, class: styles.colorDisabled },
	{ id: 1, class: styles.colorPrimary },
	{ id: 2, class: styles.colorSecondary },
	{ id: 3, class: styles.colorAccent },
];

const lineHeights =
[
	{ id: 0, class: styles.lineNone },
	{ id: 1, class: styles.lineNormal }
];

const Text = (props) =>
{
	const theme = useTheme();

    return (
        <span
			className=
			{
				[
					theme?.getClassName("Text") ?? "",
					props.className,
					MLUI.findClassByID(sizes, props.size),
					MLUI.findClassByID(weights, props.weight),
					MLUI.findClassByID(colors, props.color),
					MLUI.findClassByID(lineHeights, props.lineHeight),
				].join(" ")
			}
			style=
			{
				{
					...props.style,
					...(props.align && { textAlign: props.align})
				}
			}
		>
            {props.children}
        </span>
    );
};

export default Text;