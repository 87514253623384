import { useState } from "react";

//framework
import Events from "./Events";

//hooks
import { useEventSubscription, useDApp } from "../hooks";

//modals
import ModalTransaction from "../components/extended/Modals/ModalTransaction";
import ModalTransactionMightFail from "../components/extended/Modals/ModalTransactionMightFail";

const DAppModals = () =>
{
	//context
	const dApp = useDApp();

    //state
    const [show_transaction, setShow_transaction] = useState(() => null);
	const [show_txMightFail, setShow_txMightFail] = useState(() => null);

    //handler
    const handleTxUpdate = () =>
    {
        setShow_transaction(dApp.showTx || null);
    };
    const handleTxClose = () =>
    {
        setShow_transaction(null);
        dApp.showTx = null;
    };
	const handleTxMightFailUpdate = () =>
    {
        setShow_txMightFail(dApp.showTxMightFail || null);
    };
    const handleTxMightFailClose = () =>
    {
        setShow_txMightFail(null);
        dApp.showTxMightFail = null;
    };

    //effects
    useEventSubscription(Events.dApp.transaction, handleTxUpdate);
	useEventSubscription(Events.dApp.transaction, handleTxMightFailUpdate);

    return (
		<>
			<ModalTransaction
				show={show_transaction !== null}
				onClose={() => handleTxClose()}
			/>
			<ModalTransactionMightFail
				show={show_txMightFail !== null}
				onClose={() => handleTxMightFailClose()}
			/>
		</>
    );
};

export default DAppModals;
