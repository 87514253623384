import { useState, useEffect } from "react";

//components
import
{
    Modal,
    Button,
    Grid,
    Flex,
    InputTokenAmount_Balance,
    ApprovalButton,
	CheckBalanceApproveButton
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLTranslate as t } from "@MoonLabsDev/dapp-core-lib";
import { MLWeb3, useDApp } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useBank } from "../../../../modules";

const ModalBankDeposit = (props) =>
{
    //hooks
    const dApp = useDApp();
    const bank = useBank();

    //state
    const [show, setShow] = useState(() => props.show);
    const [token, setToken] = useState(() => null);
    const [amount, setAmount] = useState(() => "");

    //handler
    const handleClose = () =>
    {
        if (props.onClose)
        {
            props.onClose();
        }
        else
        {
            setShow(false);
        }
    };
    const handleDeposit = () =>
    {
        handleClose();
		const amt = MLWeb3.convertFloatString_TokenBN(amount, (token || dApp.wrappedCoin));
		if (token === null)
		{
			bank.depositCoinFor(
				amt,
				null
			).send();
		}
		else
		{
			bank.depositFor(
				token,
				amt,
				null
			).send();
		}
    };
    const handleTokenSelect = (_token) =>
    {
        setToken(_token);
    };
    const handleValueChange = (_value) =>
    {
        setAmount(_value);
    };

    //effect
    useEffect(() =>
    {
        setShow(props.show);
    }, [props.show]);

    return (
        <Modal
            show={show}
            header={t("modal_bankDeposit_title")}
            footer=
            {
                <Flex>
                    <Button onClick={() => handleClose()}>
                        {t("cancel")}
                    </Button>
					<CheckBalanceApproveButton
						token={token}
						approveFor={bank.address}
						amount={MLWeb3.convertFloatString_TokenBN(amount, (token || dApp.wrappedCoin))}
						onClick={() => handleDeposit()}
					>
						{t("deposit")}
					</CheckBalanceApproveButton>
                </Flex>
            }
            onClose={() => handleClose()}
        >
            <Grid>
				<InputTokenAmount_Balance
					token={token}
					value={amount}
					allowTokenSelection={true}
					showInWallet={true}
					showMax={false}
					onChange={(e, v) => handleValueChange(v)}
					onSelect={(t) => handleTokenSelect(t)}
				/>

                {!!token &&
                    <ApprovalButton
                        token={token}
                        contract={bank.address}
					/>
                }
            </Grid>
        </Modal>
    );
};

export default ModalBankDeposit;