import { useState, useEffect } from "react";

//components
import { Modal, Text, Button, Grid } from "../../";

//framework
import t from "../../../utils/MLTranslate";
import { MLSymbols, MLUI, MLWeb3 } from "../../../utils";
import Events from "../../../core/Events";

//hooks
import { useDApp, useEventSubscription } from "../../../hooks";

//styles
import styles from "./Modals.module.css";

const ModalTransaction = (props) =>
{
    //hooks
    const dApp = useDApp();

    //functions
    const handleErrorData = (_error) =>
    {
        let autoClose = false
        switch (_error.code)
        {
            case 4001:
                //user denied transaction
                autoClose = true
                break

            default:
                //do nothing

        }

		const errObj = MLWeb3.getWeb3ErrorObject(_error);
        return {
            message: _error.message,
            reason: _error.data?.reason,
			text: (!!errObj ? errObj.message : _error.message),
            autoClose
        }
    };

    //state
    const [show, setShow] = useState(() => props.show);
    const [title, setTitle] = useState(() => "");
    const [text, setText] = useState(() => <></>);
    const [description, setDescription] = useState(() => <></>);
    const [icon, setIcon] = useState(() => <></>);

    //handler
    const handleClose = () =>
    {
        if (props.onClose)
        {
            props.onClose();
        }
        else
        {
            setShow(false);
        }
    };
    const handleTxUpdate = () =>
    {
        const tx = dApp.showTx;
        if (tx === null)
        {
            return;
        }
        setDescription(tx?.description || "");
        switch (tx.stage)
        {
            case -1:
                const err = handleErrorData(tx.error);
                setTitle(t("modal_transaction_failed_title"));
                setText(t("modal_transaction_failed_text", { error: err.text}));
                setIcon(MLUI.imageOrSymbol(MLSymbols.transaction_failed(),
                    [
                        styles.transaction_icon,
                        styles.transaction_iconFailed
                    ].join(" ")));
                if (err.autoClose)
                {
                    handleClose();
                }
                return;

            case 0:
                setTitle(t("modal_transaction_confirm_title"));
                setText(t("modal_transaction_confirm_text"));
                setIcon(MLUI.imageOrSymbol(MLSymbols.transaction_confirm(),
                    [
                        styles.transaction_icon,
                        styles.transaction_iconConfirm
                    ].join(" ")));
                return;

            case 1:
            case 2:
                setTitle(t("modal_transaction_pending_title"));
                setText(t("modal_transaction_pending_text"));
                setIcon(MLUI.imageOrSymbol(MLSymbols.transaction_pending(),
                    [
                        styles.transaction_icon,
                        styles.transaction_iconPending
                    ].join(" ")));
                return;

            case 3:
                setTitle(t("modal_transaction_success_title"));
                setText(t("modal_transaction_success_text"));
                setIcon(MLUI.imageOrSymbol(MLSymbols.transaction_success(),
                    [
                        styles.transaction_icon,
                        styles.transaction_iconSuccess
                    ].join(" ")));
        }
    };

    //effect
    useEffect(() =>
    {
        setShow(props.show);
    }, [props.show]);
    useEventSubscription(Events.dApp.transaction, handleTxUpdate);

    return (
        <Modal
            show={show}
            header=
            {
                <Text size="1">
                    {title}
                </Text>
            }
            footer=
            {
                <Button onClick={() => handleClose()}>
                    {t("dismiss")}
                </Button>
            }
            classNameOverlay={styles.priorityOverlay}
            onClose={() => handleClose()}
        >
            <Grid className={styles.transaction_content}>
                {icon}
                <Text color={1}>
                    {text}
                </Text>
                <Text
                    color={2}
                    size={-1}
                >
                    {description}
                </Text>
            </Grid>
        </Modal>
    );
};

export default ModalTransaction;