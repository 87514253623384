//components
import
{
    PageHeader,
    PageContent,
    Grid,
    Flex,
    ErrorServiceNotAtChain,
	InfoModuleLoading
} from "@MoonLabsDev/dapp-core-lib";
import
{
    MoonSwapTradeInfo,
    MoonSwapTradeWindow
} from "@MoonVault/dapp-widgets-lib";

const MoonSwapContent = () =>
{
    return (
        <Flex style={{ width: "100%"}}>
            <Grid
                style=
                {
                    {
                        alignItems: "start"
                    }
                }
                responsive=
                {
                    {
                        lg:
                        {
                            gap: "50px",
                            cols: 2
                        }
                    }
                }
            >
                <MoonSwapTradeWindow />
                <MoonSwapTradeInfo
                    groupByRouter={true}
                />
            </Grid>
        </Flex>
    );
};

export default () =>
{
	return (
        <>
            <PageHeader
                title=
                {
                    <Flex>
                        Moon Swap
                    </Flex>
                }
            >
                Find the cheapest swap across multiple decentralized exchanges
            </PageHeader>

            <PageContent>
                <ErrorServiceNotAtChain module="moon_swap">
					<InfoModuleLoading module="moon_swap">
						<MoonSwapContent />
					</InfoModuleLoading>
                </ErrorServiceNotAtChain>
            </PageContent>
        </>
    );
}