//framework
import { MLWeb3, MLMultiCall } from "../utils";
import DApp from "../core/DApp";
import Web3Transaction from "../core/Web3Transaction";
import Events from "../core/Events";

//contracts
import ABI_ERC20 from "../abi/ERC20";
import ABI_ERC20_UniswapV2_LP from "../abi/ERC20_UniswapV2_LP";

export class Token
{
    ////////////////////////////////////

	static txCallbacks =
	{
		approve: null
	};

	constructor(_data)
	{
        this.initialized = false;
		this.initializedData = false;
        this.initializedPrice = false;
        this.initPair = (_data.initPair || false);

		//base values
		this.address = _data.contract || "";
        this.symbol = _data.symbol || "";
        this.icon = _data.icon;
        this.linkSwap = _data.linkSwap || "";
        this.router = (_data.router || "");
        this.decimals = (_data.decimals === undefined ? 18 : parseInt(_data.decimals));
        this.v3PoolFee = _data.v3PoolFee || 0;
        this.one = MLWeb3.toBN(10).pow(MLWeb3.toBN(this.decimals));
        this.token0 = (_data.token0 || null);
		this.token1 = (_data.token1 || null);
        this.oracleType = (_data.oracleType || "");
        this.oracleParameter = (_data.oracleParameter || "");
		this.data = _data.data || null;

        //info
        this.unitPriceUSD = MLWeb3.toBN(0);
        this.liquidityValue = MLWeb3.toBN(0);
        this.liquidityAmount = MLWeb3.toBN(0);
        this.token0Reserve = MLWeb3.toBN(0);
        this.token1Reserve = MLWeb3.toBN(0);
        this.totalSupply = MLWeb3.toBN(0);
        this.lastPriceUpdate = null;
        this.approvalList = [];
		this.deadBalance = MLWeb3.toBN(0);
		this.zeroBalance = MLWeb3.toBN(0);
		this.burnedSupply = MLWeb3.toBN(0);
		this.circulatingSupply = MLWeb3.toBN(0);
		this.marketCapUSD = MLWeb3.toBN(0);
		this.burnedUSD = MLWeb3.toBN(0);

        //user info
        this.userBalance = MLWeb3.toBN(0);
        this.userBalanceUSD = MLWeb3.toBN(0);
	}

    ////////////////////////////////////

	debugErrorString(_text)
	{
		return `Token [${this.symbol}] failed at: ${_text}`;
	}

	static setTxCallback(_name, _cb)
	{
		Token.txCallbacks[_name] = _cb;
	}

    getTokenABI()
    {
        return (this.isLPToken()
            ? ABI_ERC20_UniswapV2_LP
            : ABI_ERC20);
    }

    getContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(
            this.getTokenABI(),
            this.address).methods;
    }

    makeMultiCall(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.address,
            this.getTokenABI(),
            _calls
        );
    }

    dispatchEvent(_name)
    {
        document.dispatchEvent(new CustomEvent(_name,
        {
            detail:
            {
                address: this.address
            }
        }));
    }

    /////////////////////////
    // Init
    /////////////////////////

    static async batch_init(_tokens)
    {
        const filtered = _tokens.filter(t => !t.initialized && t.address !== "");
        await DApp.instance.batchCall(
            filtered,
            (o) => o.makeRequest_init(),
            (o, r) => o.processRequest_init(r),
            false,
            "[Token] batch init",
            "Token: init"
        );

        return _tokens.filter(t => t.initialized);
    }

    makeRequest_init()
    {
        return this.makeMultiCall(
        {
            //Token or LP
			name: { function: "name" },
			decimals: { function: "decimals" },
			totalSupply: { function: "totalSupply" },
			...(this.symbol === "" &&
				{
					symbol: { function: "symbol" }
				}
			),

            //LP
            ...(this.initPair &&
                {
                    token0: { function: "token0" },
                    token1: { function: "token1" }
                }
            ),

            //LP v2
            ...(this.isLPToken() &&
                {
                    reserves: { function: "getReserves" },
                    totalSupply: { function: "totalSupply" }
                }
            )
        });
    }

    async processRequest_init(_data)
    {
        this.name = _data.name;
        this.decimals = parseInt(_data.decimals);
		this.totalSupply = MLWeb3.toBN(_data.totalSupply);
        if (this.symbol === "")
        {
            this.symbol = _data.symbol;
        }
        if (this.initPair)
        {
            this.token0 = _data.token0;
            this.token1 = _data.token1;
        }
        if (this.isLPToken())
        {
            this.totalSupply = MLWeb3.toBN(_data.totalSupply);
            this.token0Reserve = MLWeb3.toBN(_data.reserves[0]);
            this.token1Reserve = MLWeb3.toBN(_data.reserves[1]);
        }

        //process
        this.one = MLWeb3.toBN(10).pow(MLWeb3.toBN(this.decimals));

        //complete
        this.initialized = true;
        this.dispatchEvent(Events.token.init);
    }

    /////////////////////////
    // pair info
    /////////////////////////

    static async batch_data(_tokens)
    {
        //get valid tokens
        const filtered = await Token.batch_init(_tokens);
        await DApp.instance.batchCall(
            filtered,
            (o) => o.makeRequest_data(),
            (o, r) => o.processRequest_data(r),
            false,
            "[Token] batch data",
            "Token: data"
        );
    }

    makeRequest_data()
    {
        return this.makeMultiCall(
        {
			totalSupply: { function: "totalSupply" },
			zeroBalance: { function: "balanceOf", parameters: [MLWeb3.getZeroAddress()] },
			deadBalance: { function: "balanceOf", parameters: [MLWeb3.getDeadAddress()] },

			//LP v2
			...(this.isLPToken() &&
				{
					reserves: { function: "getReserves" }
				}
			)
        });
    }

    async processRequest_data(_data)
    {
        this.totalSupply = MLWeb3.toBN(_data.totalSupply);
		this.deadBalance = MLWeb3.toBN(_data.deadBalance);
		this.zeroBalance = MLWeb3.toBN(_data.zeroBalance);
		if (this.isLPToken())
		{
        	this.token0Reserve = MLWeb3.toBN(_data.reserves[0]);
        	this.token1Reserve = MLWeb3.toBN(_data.reserves[1]);
		}

		//process
		this.burnedSupply = this.deadBalance.add(this.zeroBalance);
		this.circulatingSupply = this.totalSupply.sub(this.burnedSupply);
		this.calcTokenStats();

        //complete
		this.initializedData = true;
        this.dispatchEvent(Events.token.data);
    }

    /////////////////////////
    // user data
    /////////////////////////

    static async batch_userData(_tokens)
    {
        if (DApp.instance.account === null)
        {
            return;
        }

        //get valid tokens
        const initToken = await Token.batch_init(_tokens);
        await DApp.instance.batchCall(
            initToken,
            (o) => o.makeRequest_userData(),
            (o, r) => o.processRequest_userData(r),
            true,
            "[Token] batch userInfo",
            "Token: userInfo"
        );
    }

    makeRequest_userData()
    {
        return this.makeMultiCall(
        {
            userBalance: { function: "balanceOf", parameters: [DApp.instance.account] }
        });
    }

    async processRequest_userData(_data)
    {
        this.userBalance = MLWeb3.toBN(_data.userBalance);

        //process
        this.userBalanceUSD = this.getPriceUSDForAmount(this.userBalance);

        //event
        this.dispatchEvent(Events.token.userData);
    }

    /////////////////////////
    // Approval
    /////////////////////////

    static async batch_approval(_tokens)
    {
        if (DApp.instance.account === null)
        {
            return;
        }

        //make special approval list
        const filtered = [];
        for (let n = 0; n < _tokens.length; n++)
        {
            const token = _tokens[n];
            for (let m = 0; m < token.approvalList.length; m++)
            {
                const approval = token.approvalList[m];
                if (!approval.load
                    || approval.approved)
                {
                    continue;
                }
                filtered.push(
                {
                    ...approval,
                    token: token
                });
            }
        }

        //batch call
        await DApp.instance.batchCall(
            filtered,
            (o) => o.token.makeRequest_approval(o),
            (o, r) => o.token.processRequest_approval(o, r),
            false,
            "[Token] batch approval",
            "Token: approval"
        );
    }

    makeRequest_approval(_approval)
    {
        return this.makeMultiCall(
        {
            allowance: { function: "allowance", parameters: [DApp.instance.account, _approval.for]}
        });
    }

    async processRequest_approval(_approval, _data)
    {
        const approval = this.findAppoved(_approval.for);
        approval.approved = !MLWeb3.toBN(_data.allowance).isZero();

        //event
        this.dispatchEvent(Events.token.approval);
    }

    /////////////////////////
    // reload price
    /////////////////////////

    static async batch_reloadPrice(_tokens)
    {
        for (let n = 0; n < _tokens.length; n++)
        {
            await DApp.instance.oracle.reloadPriceData(_tokens[n]);
        }
    }

    /////////////////////////
    // helper
    /////////////////////////

    async addToWallet()
	{
		try
		{
			const tokenName = (this.token1 !== null ? "LP Token" : this.name);
			let tokenIcon = (this.icon || "");
			if (tokenIcon.length > 1
				&& tokenIcon[0] === "/")
			{
				tokenIcon = window.location.origin + "/" + tokenIcon;
			}
			await window.ethereum.request(
			{
				method: "wallet_watchAsset",
				params:
				{
					type: "ERC20",
					options:
					{
						address: this.address,
						symbol: (this.symbol.length <= 11 ? this.symbol : tokenName),
						decimals: (this.decimals < 0 ? 18 : this.decimals),
						image: tokenIcon
					}
				},
			})
		}
        catch(e) { }
	}

	getPriceUSD()
	{
		return this.unitPriceUSD;
	}

    getPriceUSDForAmount(_amount)
    {
        if (this.liquidityAmount.isZero())
        {
            return MLWeb3.toBN(0);
        }
        return this.liquidityValue.mul(_amount).div(this.liquidityAmount);
    }

	getPriceInTokenForAmount(_token, _amount)
	{
		if (_token.address === this.address)
		{
			return _amount;
		}

		//calc
		const amountUSD = this.getPriceUSDForAmount(_amount);
		const tokenAmount = _token.liquidityAmount.mul(amountUSD).div(_token.liquidityValue);

		return tokenAmount;
	}

	getLPTokenRatio(_token, _amount)
	{
		//init
		const ret =
		{
			price: MLWeb3.toBN(0),
			priceToken: null,
			ratio: 0
		};

		//check
		if (!this.isLPToken()
			|| (this.token0.address !== _token.address
				&& this.token1.address !== _token.address))
		{
			return ret;
		}

		//get values
		const isT0 = (this.token0.address === _token.address);
		let tA;
		let tB;
		let resA;
		let resB;
		if (isT0)
		{
			tA = this.token0;
			tB = this.token1;
			resA = this.token0Reserve;
			resB = this.token1Reserve;
		}
		else
		{
			tA = this.token1;
			tB = this.token0;
			resA = this.token1Reserve;
			resB = this.token0Reserve;
		}

		//calc
		ret.ratio = MLWeb3.getPercent(resA, resB);
		ret.price = resB.mul(_amount).div(resA);
		ret.priceToken = tB;

		return ret;
	}

	calcTokenStats()
	{
		this.marketCapUSD = this.getPriceUSDForAmount(this.circulatingSupply);
		this.burnedUSD = this.getPriceUSDForAmount(this.burnedSupply);
	}

	getPriceShare(_amount, _percent)
	{
		const pf = MLWeb3.toBN(DApp.instance.percentFactor);
		const percentMul = MLWeb3.toBN(parseInt(_percent * DApp.instance.percentFactor));
		const share = _amount.mul(percentMul).div(pf);
		return share;
	}

    getAddressSortString()
    {
        if (this.isLPToken())
        {
            if (MLWeb3.compareAddress(this.token0, this.token1) < 1)
            {
                return (this.token0.toLowerCase() + "-" + this.token1.toLowerCase());
            }
            return (this.token1.toLowerCase() + "-" + this.token0.toLowerCase());
        }
        return this.address.toLowerCase();
    }

    getSymbolSortString()
    {
        if (this.isLPToken())
        {
            const t0 = DApp.instance.findToken(this.token0)?.symbol;
            const t1 = DApp.instance.findToken(this.token1)?.symbol;
            if (LWeb3.compareAddress(t0, t1) < 1)
            {
                return (t0.toLowerCase() + "-" + t1.toLowerCase());
            }
            return (t1.toLowerCase() + "-" + t0.toLowerCase());
        }
        return this.symbol.toLowerCase();
    }

    isLPToken()
    {
        return (this.initPair
            || (!!this.token0
                && !!this.token1));
    }

    isStable()
    {
        return (this.oracleType === "Stable");
    }

    hasStable()
    {
        if (this.isLPToken())
        {
            const t0 = DApp.instance.findToken(this.token0);
            const t1 = DApp.instance.findToken(this.token1);
            return (t0.isStable()
                || t1.isStable());
        }

        return false;
    }

    getFullName()
    {
        if (this.isLPToken())
        {
            const t0 = DApp.instance.findToken(this.token0);
            const t1 = DApp.instance.findToken(this.token1);
            return `${t0?.symbol || "???"}-${t1?.symbol || "???"}`;
        }

        return this.symbol;
    }

    priceUpdated()
	{
		this.calcTokenStats();

		//event
        this.dispatchEvent(Events.token.priceInfo);
	}

    findAppoved(_approveFor)
    {
        return this.approvalList.find((a) => MLWeb3.checkEqualAddress(a.for, _approveFor)) || null;
    }

    findOrCreateApproved(_approveFor)
    {
        if (!_approveFor
            || _approveFor === "")
        {
            return null;
        }
        let approval = this.findAppoved(_approveFor);
        if (!approval)
        {
            approval =
            {
                for: _approveFor,
                load: true,
                approved: false
            };
            this.approvalList.push(approval);
        }
        return approval;
    }

    checkApproved(_approveFor)
    {
        return !!this.findOrCreateApproved(_approveFor)?.approved;
    }

    /////////////////////////
    // Transactions
    /////////////////////////

    approve(_approveFor)
    {
		if (!!Token.txCallbacks.approve)
		{
			Token.txCallbacks.approve(
				this.address,
				_approveFor
			);
			return;
		}
        const con = this.getContract(true);
        return new Web3Transaction(
            con.approve(
                _approveFor,
                MLWeb3.getBNMax(256)),
            this.debugErrorString("approve"),
            `Approve ${this.symbol}`
		);
    }

    async balanceOf(_address)
    {
        return MLWeb3.toBN(await MLWeb3.tryCall(
            this.getContract(false).balanceOf(_address),
            "balanceOf"
		));
    }

    ////////////////////////////////////
}