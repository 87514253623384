//framework
import { MLUI } from "../../../utils";
import { useDApp, useTheme } from "../../../hooks";

//styles
import styles from "./Link.module.css";

const colors =
[
	{ id: 0, class: styles.colorDisabled },
	{ id: 1, class: styles.colorPrimary },
	{ id: 2, class: styles.colorSecondary }
];

const Link = (props) =>
{
	//context
	const dApp = useDApp();
	const theme = useTheme();

	//generate className
    const cn =
	[
		theme?.getClassName("Link") ?? "",
		props.className,
		styles.link,
		MLUI.findClassByID(colors, parseInt(props.color || 1)),
		(!!props.disabled ? styles.disabled : "")
	].join(" ");

	//render
    if (props.href === undefined)
    {
		//button
        return(
            <button
				className={cn}
				onClick={props.onClick}
				title={props.title}
				style={props.style}
			>
                {props.children}
            </button>
        );
    }
	else if (props.nav === true)
	{
		//nav link
		return (
			<span
				className={cn}
				style={props.style}
			>
				<dApp.config.ui.navLinkComponent
					href={props.href}
				>
					{props.children}
				</dApp.config.ui.navLinkComponent>
			</span>
		);
	}
    else
    {
		//normal link
        return (
            <a
				className={cn}
				href={props.href}
				target={props.target}
				title={props.title}
				style={props.style}
			>
                {props.children}
            </a>
        );
    }
};

export default Link;