import { useState, useEffect } from "react";

//components
import InputNFTAmount from "../InputNFTAmount";

//framework
import { MLWeb3 } from "../../../../utils";
import Events from "../../../../core/Events";
import { useEventSubscription, useDApp } from "../../../../hooks";

const InputNFTAmount_Balance = (props) =>
{
    //hooks
    const dApp = useDApp();

    //default props
    const default_balance = props.nft?.findItem(props.nftID)?.userBalance || MLWeb3.toBN(0);

    //state
    const [value, setValue] = useState(() => props.value || null);
    const [nft, setNFT] = useState(() => props.nft || null);
    const [nftID, setNFTID] = useState(() => props.nftID || 0);
    const [available, setAvailable] = useState(default_balance);

    //handler
    const handleBalanceUpdate = (data) =>
    {
        if (!MLWeb3.checkEqualAddress(data.detail.address, nft.address)
            || data.detail.id !== nftID)
        {
            return;
        }

        setAvailable(nft?.findItem(nftID)?.userBalance || MLWeb3.toBN(0));
    };
    const handleChange = (e, _value) =>
    {
        if (props.onChange)
        {
            props.onChange(e, _value);
        }
        else
        {
            setValue(_value);
        }
    };
    const handleBlur = (e, _value) =>
    {
        if (props.onBlur)
        {
            props.onBlur(e, _value);
        }
    };

    //effects
    useEffect(() =>
    {
        setValue(props.value || null);
        setNFT(props.nft || null);
        setNFTID(props.nftID || null);
        setAvailable(default_balance);
    },
    [
        props.value,
        props.nft?.address,
        props.nftID
    ]);
    useEventSubscription(Events.nft.userData, handleBalanceUpdate, [nft, nftID]);

    return (
        <InputNFTAmount
            nft={nft}
            nftID={nftID}
            value={value}
            showAvailable={true}
            available={available}
            availableText={"Balance"}
            showMax={props.showMax}
            onChange={(e, v) => handleChange(e, v)}
            onBlur={(e, v) => handleBlur(e, v)}
        />
    );
};

export default InputNFTAmount_Balance;