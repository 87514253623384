import { useState, useEffect } from "react";

//components
import
{
    Text,
    Grid,
    ValueLoad,
    ToolButton,
    ToolButtonType,
    Flex
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLTranslate as t } from "@MoonLabsDev/dapp-core-lib";
import { MLWeb3, MLFormat } from "@MoonLabsDev/dapp-core-lib";

//modules
import EscrowTradeState from "./EscrowTradeState";
import { ModuleEvents } from "../../../..//modules/Module_Escrow";
import { useEscrow } from "../../../../modules";

//classes
import { TradeStatus } from "../../../../../dApp/classes/Escrow";

//hooks
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//styles
import styles from "../Escrow.module.css";


const generateOfferOwnerString = (_owner) =>
{
    if (MLWeb3.checkEqualAddress(_owner, useDApp().account))
    {
        return "You";
    }
    else
    {
        return MLFormat.formatAddress(_owner, true);
    }
}

const EscrowTradeInfo = (props) =>
{
    //hooks
    const escrow = useEscrow();

    //vars
    const trade = escrow.findTrade(props.tradeID);

    //state
    const [tradeID, setTradeID] = useState(escrow.lazyLoadTrade(props.tradeID));
    const [tradeInitialized, setTradeInitialized] = useState(trade?.initialized || false);
    const [owner1, setOwner1] = useState(trade?.offer1?.owner);
    const [owner2, setOwner2] = useState(trade?.offer2?.owner);
    const [tradeStatus, setTradeStatus] = useState(() => trade?.status);

    //handler
    const handleTradeUpdate = () =>
    {
        setOwner1(trade?.offer1?.owner);
        setOwner2(trade?.offer2?.owner);
        setTradeInitialized(trade?.initialized || false);
        setTradeStatus(trade?.status);
    };
    const handleCancelTrade = (e) =>
    {
        e.stopPropagation();
        escrow.cancelTrade(tradeID).send();
    };

    //effect
    useEffect(() =>
    {
        setTradeID(escrow.lazyLoadTrade(props.tradeID));
    }, [props.tradeID]);
    useEffect(() =>
    {
        handleTradeUpdate();
    }, [tradeID]);
    useEventSubscription(ModuleEvents.tradeInfo, handleTradeUpdate, [trade]);

    //values
    return (
        <Flex
            className=
            {
                [
                    (tradeStatus === TradeStatus.ONGOING ? "" : styles.tradeInfo_history)
                ].join(" ")
            }
            style={{ width: "100%" }}
        >
            <Grid
                gap="0px"
            >
                <Text>
                    #{tradeID}
                </Text>
                <Text size={-1}>
                    <ValueLoad
                        loaded={tradeInitialized}
                    >
                        {generateOfferOwnerString(owner1)}
                        {" <=> "}
                        {generateOfferOwnerString(owner2)}
                    </ValueLoad>
                </Text>
            </Grid>

            {tradeStatus === TradeStatus.ONGOING &&
                <Flex
                    style={{ marginLeft: "auto" }}
                >
                    <EscrowTradeState
                        trade={trade}
                    />
                    <ToolButton
                        type={ToolButtonType.Delete}
                        onClick={(e) => handleCancelTrade(e)}
                    />
                </Flex>
            }
        </Flex>
    );
};

export default EscrowTradeInfo;