import { useState } from "react";

//components
import
{
    PageHeader,
    PageContent,
    Grid,
    Flex,
	Button,
	ErrorServiceNotAtChain,
} from "@MoonLabsDev/dapp-core-lib";

//modules
import { ModalBankDeposit, BankUserAssets } from "@MoonVault/dapp-widgets-lib";
import { Module_Bank, useBank } from "@MoonVault/dapp-widgets-lib";

export default () =>
{
	//context
	const bank = useBank();

	//state
	const [show, setShow] = useState(() => false);

    return (
        <>
            <PageHeader
                title=
                {
                    <Flex>
                        Bank
                    </Flex>
                }
            >
                Here you can stash your funds or withdraw your payouts from vaults or referral.
            </PageHeader>

            <PageContent>
				<ErrorServiceNotAtChain checkSupportChainCallback={bank.availableOnChain}>
					<ModalBankDeposit
						show={show}
						onClose={() => setShow(false)}
					/>

					<Flex>
						<Grid>
							<Button
								onClick={() => setShow(true)}
							>
								Deposit
							</Button>

							<BankUserAssets />
						</Grid>
					</Flex>
				</ErrorServiceNotAtChain>
            </PageContent>
        </>
    );
}