//framework
import { MLUtils, MLWeb3 } from "../utils";
import Events from "./Events";
import {DApp} from "./DApp";

class Web3Connection
{
    static instance = null;
    static getOrCreateInstance()
    {
        return Web3Connection.instance || new Web3Connection();
    }

    constructor()
    {
        //init
        this.walletInstalled = false;
        this.account = null;
        this.pseudoAccount = false;
        this.web3_user = null;
        this.web3_data = null;
        this.chainID = -1;
        this.walletProvider = null;
        Web3Connection.instance = this;
    }

    initWeb3()
    {
        //init web3
        if (window.ethereum)
        {
            this.walletInstalled = true;

            //add event listener [account change]
            window.ethereum.on("accountsChanged", () =>
            {
                window.location.reload();
            });

            //add event listener [chain change]
            window.ethereum.on("chainChanged", () =>
            {
                window.location.reload();
            });

            //add event listener [disconnect]
            window.ethereum.on("disconnect", () =>
            {
                window.location.reload();
            });
        }
    }

    async tryConnect(_web3User, _web3Data)
    {
        this.log(false, `Try to connect Web3...`);

		//try connect web3 user & web3 data
        await this.tryConnectUser(_web3User);
        await this.tryConnectData(_web3Data);

        //event
        document.dispatchEvent(new CustomEvent(Events.web3.connect));

        this.log(false, `Web3${this.web3_data !== null ? "" : " not"} connected`);
        return (this.web3_data !== null);
    }

	async tryConnectUser(_web3User)
	{
		//try connect web3 user
		if (_web3User !== undefined)
		{
			//override connect
			if (_web3User !== null)
			{
				//connect
				this.web3_user = _web3User;
				const c = MLWeb3.createConnection("user");
				c.web3 = this.web3_user;
				this.log(false, "Web3 [user] was manually connected");
			}
		}
		else if (!MLUtils.getStorage_bool(false, "web3_disconnected", false)
            && MLUtils.getStorage(false, "web3_provider", "MetaMask") !== "none")
        {
			//normal connect
            try
            {
                await MLWeb3.selectProvider(MLUtils.getStorage(false, "web3_provider", "MetaMask"));
                this.web3_user = await MLWeb3.createAndConnect("user", -1);
            }
            catch (_e) { }
        }

        //select chain
        this.chainID = (this.web3_user === null
            ? MLUtils.getStorage_int(false, "web3_selectedChain", DApp.instance.config.chains?.defaultChain || 0)
            : await this.web3_user.eth.net.getId());
	}

	async tryConnectData(_web3Data)
	{
		//try connect web data
        try
        {
			if (_web3Data !== undefined)
			{
				//override connect
				if (_web3Data === null)
				{
					//dont connect
					throw false;
				}

				this.web3_data = _web3Data;
				const c = MLWeb3.createConnection("data");
				c.web3 = this.web3_data;
				c.chain = await c.web3.eth.net.getId();
				this.log(false, "Web3 [data] was manually connected");
			}
			else
			{
				//normal connect
				this.web3_data = await MLWeb3.createAndConnect("data", this.chainID);
			}
        }
        catch (_e)
        {
            //try fallback to user connection
            this.log(true, "Web3 [data] failed to connect");
            this.web3_data = this.web3_user;
        }

		//select chain
		if (this.web3_user === null)
		{
			this.chainID = await this.web3_data.eth.net.getId();
		}
	}

    async selectProvider(_providerID)
    {
        MLUtils.setStorage(false, "web3_provider", _providerID);
        try
		{
			await MLWeb3.selectProvider(_providerID);
            if (this.walletInstalled)
            {
                let success = false;
                if (this.account !== null)
                {
                    success = await this.reconnectWallet();
                }
                else
                {
                    MLUtils.setStorage(false, "web3_disconnected", false);
                    success = await this.connectWallet();
                }
                if (success)
                {
                    window.location.reload();
                }
            }

		}
		catch (e) { }
    }

    tryDetectWallet()
    {
        this.walletProvider = null;
        for (let n = 0; n < MLWeb3.config.wallets.length; n++)
        {
            const w = MLWeb3.config.wallets[n];
            if (w.detect !== undefined
                && w.detect())
            {
                this.walletProvider = w.name;
                this.log(false, `Detected ${this.walletProvider}`);
                break;
            }
        }
    }

    async connectWallet()
    {
        if (MLUtils.getStorage_bool(false, "web3_disconnected", false))
        {
            return;
        }
        this.log(false, `Try to connect Wallet...`);

        try
        {
            //logged in
            await window.ethereum.request({ method: "eth_requestAccounts" });
            MLUtils.setStorage(false, "web3_disconnected", false);
        }
        catch (e)
        {
            //user rejected connection, so let him logged out
            MLUtils.setStorage(false, "web3_disconnected", true);
            return false;
        }

        //event
        document.dispatchEvent(new CustomEvent(Events.web3.connectWallet));

        this.log(false, `Wallet connected`);
        return true;
    }

    async reconnectWallet()
    {
        try
        {
            if (MLUtils.getStorage(false, "web3_provider", "MetaMask") !== "WalletConnect")
            {
                //login
                await window.ethereum.request({ method: "wallet_requestPermissions", params: [{ eth_accounts: {} }]})
                MLUtils.setStorage(false, "web3_disconnected", false);
            }
            else
            {
                await LWeb3.closeProvider();
                window.location.reload();
                return true;
            }
        }
        catch (e)
        {
            return false;
        }

        window.location.reload();
        return true;
    }

    disconnectWallet()
    {
        MLUtils.setStorage(false, "web3_disconnected", true);
		window.location.reload();
    }

    async getAccount(_wallet)
    {
        //pseudo account
        this.pseudoAccount = false;
        const searchParams = new URLSearchParams(window.location.search);
		if (!!_wallet)
        {
            this.account = _wallet;
            this.pseudoAccount = true;
			this.log(false, `Emulating Wallet: [${this.account}]`);

            //event
            document.dispatchEvent(new CustomEvent(Events.web3.getAccount));
            return this.account;
        }

        //user account
        if (this.web3_user !== null)
        {
            const accounts = await this.web3_user.eth.getAccounts();
            if (accounts.length > 0)
            {
                this.account = accounts[0];

                //event
                document.dispatchEvent(new CustomEvent(Events.web3.getAccount));
                return this.account;
            }
        }

        //event
        document.dispatchEvent(new CustomEvent(Events.web3.getAccount));
        return null;
    }

    async selectChain(_chainID)
    {
        MLUtils.setStorage(false, 'web3_selectedChain', _chainID);
		if (this.walletInstalled)
		{
			await MLWeb3.switchChain(_chainID);
		}
		else
		{
			window.location.reload();
		}
    }

    log(_error, _text)
    {
        if (_error)
        {
            console.error(`[Web3] ${_text}`);
        }
        else
        {
            console.log(`[Web3] ${_text}`);
        }
    }
}

export default Web3Connection;