import { useState } from "react";
import { usePopper } from "react-popper";
import { NavLink } from "react-router-dom";

//framework
import { MLTranslate as t, MLUI } from "@MoonLabsDev/dapp-core-lib";

//styles
import styles from "./Menu.module.css";

const MenuItem = ({ menuItem, depth, hideParentMenu }) =>
{
    //show
    const [showSubMenu, setShowSubMenu] = useState(false);
    const handleMouseEvent = (enter) =>
    {
        setShowSubMenu(enter);
    };
    const hideMenu = () =>
    {
        if (hideParentMenu !== undefined)
        {
            hideParentMenu();
        }
        else
        {
            setShowSubMenu(false);
        }
    };

    //popper
    const [popperOwnerRef, setPopperOwnerRef] = useState(null);
	const [popperRef, setPopperRef] = useState(null);
    const popperInfo = usePopper(popperOwnerRef, popperRef,
    {
        placement: (depth === 0 ? "bottom-start" : "right-start")
	});

    //generate child menu
    const childMenu = menuItem.children?.map((m) => (
        <MenuItem
            key={`item_${depth}_${menuItem.children.indexOf(m)}`}
            menuItem={m}
            depth={depth + 1}
            hideParentMenu={hideMenu}
        />
    ));

    //generate link and text
    const noLinkMode = (MLUI.isTouchDevice() && menuItem.children && menuItem.children.length > 0);
    const itemText = <>{menuItem.text ? menuItem.text : t(menuItem.label)}</>;
    const itemIcon = <>{menuItem.icon ? <div className={styles.mobileMenu}>{menuItem.icon}</div> : ""}</>
    let itemLink = <a>{itemText}{itemIcon}</a>;
    if (menuItem.link !== undefined
        && !noLinkMode)
    {
		if (!!menuItem.extern)
		{
			itemLink = (
				<a
					href={menuItem.link}
					target="_blank"
					onClick={() => hideMenu()}
				>
					{itemText}{itemIcon}
				</a>
			);
		}
		else
		{
			itemLink = (
				<NavLink
					to={menuItem.link}
					onClick={() => hideMenu()}
				>
					{itemText}{itemIcon}
				</NavLink>
			);
		}
    }

    return (
        <div
            ref={setPopperOwnerRef}
            onMouseEnter={() => handleMouseEvent(true)}
            onMouseLeave={() => handleMouseEvent(false)}
            onTouchStart={() => handleMouseEvent(true)}
        >
            <div className={styles.item}>
                {itemLink}
            </div>
            {childMenu && showSubMenu &&
                <div
                    ref={setPopperRef}
                    className={styles.subMenu}
                    style={popperInfo.styles.popper}
                    {...popperInfo.attributes.popper}
                >
                    {childMenu}
                </div>
            }
        </div>
    );
};

export default MenuItem;
