//dApp
import DApp from "../../framework/core/DApp";

const configPage =
{
	socials:
	{
		discord: "",
		telegram: "",
		github: "",
		gitbook: "",
		twitter: ""
	},
    dApp: DApp,
	dataPath: null,
	format:
	{
		thousandsSeperator: ","
	}
};

export default configPage;