import { useRef } from "react";

//styles
import styles from "./Inputs.module.css";

const Input = (props) =>
{
    //state
    const inputRef = useRef();

    //handler
    const handleChange = (e) =>
    {
        if (props.onChange)
        {
            props.onChange(e, inputRef.current.value);
        }
    };
    const handleBlur = (e) =>
    {
        if (props.onBlur)
        {
            props.onBlur(e, inputRef.current.value);
        }
    };

    return (
        <input
            id={props.id}
            ref={inputRef}
            name={props.name}
            className={[styles.input, props.className].join(" ")}
            type={props.type || "text"}
            placeholder={props.placeholder}
            value={props.value ?? ""}
            min={props.min}
            max={props.max}
            inputMode={props.inputmode}
            pattern={props.pattern}
            title={props.title}
            step={props.step}
            readOnly={props.readOnly}
            onChange={(e) => handleChange(e)}
            onBlur={(_e) => handleBlur(_e)}
        />
    );
};

export default Input;