import { useState } from "react";

//components
import { Grid, Flex, Text, Image } from "../..";

//framework
import { MLWeb3 } from "../../../utils";
import Events from "../../../core/Events";
import { DAppModule, getModule } from "../../../core";

//hooks
import { useDApp, useEventSubscription, useWeb3Connection } from "../../../hooks";

//styles
import styles from "./Errors.module.css";

export const ErrorServiceNotAtChain = ({checkSupportChainCallback, module, children}) =>
{
    //context
    const dApp = useDApp();
    const web3 = useWeb3Connection();

    //functions
    const getAvailableChains = () =>
    {
		let cb = checkSupportChainCallback || null;
		if (module instanceof DAppModule)
		{
			cb = module.constructor.availableOnChain;
		}
		else if (typeof module === "string")
		{
			cb = getModule(module).constructor.availableOnChain;
		}

		if (cb === null)
		{
			return [];
		}
        return dApp.chains.filter(c => cb(c));
    };

    //state
    const [availableChains, setAvailableChains] = useState(() => getAvailableChains());
	const [error] = useState(!availableChains.find(c => c.id === dApp.currentChain.id));

    //effect
    useEventSubscription(Events.dApp.initialized, () => setAvailableChains(getAvailableChains()));

    return (
		<>
			{error &&
				<Grid>
					<Flex>
						<Text size={1}>
							Service not available
						</Text>
					</Flex>
					<Flex>
						<Text size={0} style={{ textAlign: "center" }}>
							The Service is not available at this Blockchain.
							<br />
							{availableChains.length === 0 &&
								<>
									Please select another Blockchain.
								</>
							}
							{availableChains.length > 0 &&
								<>
									Please connect to one of the following Blockchains:
									<br />
									<br />
									<Flex>
										{availableChains.map(c =>
											{
												const chain = MLWeb3.findChainConfig(c.id);
												return (
													<Image
														key={c.id}
														src={chain.networkIcon}
														onClick={() => web3.selectChain(c.id)}
														className={styles.notOnChain_icon}
													/>
												);
											}
										)}
									</Flex>
								</>
							}
						</Text>
					</Flex>
				</Grid>
			}
			{!error && children}
		</>
    );
};