import { useState, useEffect } from "react";

//components
import { Flex } from "../../";

//styles
import styles from "./Containers.module.css";

const ListItem = (props) =>
{
    //style props
    const [index, setIndex] = useState(() => props.index);
    const [value, setValue] = useState(() => props.value);

    //handler
    const handleSelectItem = (e) =>
    {
        e.stopPropagation();
        if (props.onSelect)
        {
            props.onSelect(value);
        }
    };

    //effects
    useEffect(() =>
    {
        setIndex(props.index);
        setValue(props.value);
    }, [props.index, props.value]);

    return (
        <div
            onClick={(e) => handleSelectItem(e)}
        >
            <Flex
                className=
                {
                    [
                        styles.listItem,
                        (index % 2 == 0 ? styles.listItem_even : styles.listItem_odd),
                        props.className
                    ].join(" ")
                }
                style=
                {
                    {
                        ...props.style,
                        ...(props.selectable && { cursor: "pointer" })
                    }
                }
            >
                {props.children}
            </Flex>
        </div>
    );
};

export default ListItem;