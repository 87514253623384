import { useState, useEffect } from "react";

//components
import
{
    Text,
    Button,
    Grid,
    Flex,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    InputTokenAmount_Balance,
    FormatPercent,
    ToolButton,
    ToolButtonType,
    CheckBalanceApproveButton
} from "@MoonLabsDev/dapp-core-lib";
import ModalSwapSettings from "./ModalSwapSettings";

//framework
import { MLWeb3, MLUtils, MLUI } from "@MoonLabsDev/dapp-core-lib";
import { arrowDown } from "@MoonLabsDev/dapp-core-lib";

//modules
import { ModuleEvents, useMoonSwap } from "../../../modules/Module_MoonSwap";

//hooks
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//styles
import styles from "./MoonSwap.module.css";

const MoonSwapTradeWindow = () =>
{
    //context
    const dApp = useDApp();
    const swap = useMoonSwap();

    //functions
    const getSetting_slippage = () =>
    {
        return Math.max(Math.min(MLUtils.getStorage_float(false, "MoonSwap_slippage", 0.001), 1), 0);
    };

    //state
    const [from, setFrom] = useState(() => null);
    const [to, setTo] = useState(() => dApp.stableCoin);
    const [amountIn, setAmountIn] = useState(() => "1");
    const [bestAmountOut, setBestAmountOut] = useState(() => "");
    const [slippage, setSlippage] = useState(() => getSetting_slippage());
    const [swapFee, setSwapFee] = useState(() => swap.fee);
    const [showSettings, setShowSettings] = useState(() => false);

    //handler
    const handleCloseSettings = () =>
    {
        setShowSettings(false);
        setSlippage(getSetting_slippage());
    };
    const handleFromTokenSelect = (_token) =>
    {
        setFrom(_token);
    };
    const handleSwitchFromTo = () =>
    {
        setFrom(to);
        setTo(from);
    };
    const handleToTokenSelect = (_token) =>
    {
        setTo(_token);
    };
    const handleFromValueChange = (_value) =>
    {
        setAmountIn(_value);
    };
    const handleSwap = () =>
    {
		if (!swap.swapInfo?.length)
		{
			return;
		}

        //swap
        const fromToken = (from || dApp.wrappedCoin);
        swap.swap(
            from,
            to,
            MLWeb3.convertFloatString_TokenBN(amountIn, fromToken),
            slippage,
			dApp.currentChain.referralID || 0
        )?.trySend();
    };
    const handleUpdateSwapInfo = () =>
    {
        let val = "";
        if (!!swap.swapInfo
            && swap.swapInfo.length > 0)
        {
            const toToken = (to || dApp.wrappedCoin);
            val = MLWeb3.convertTokenBN_FloatString(
                swap.swapInfo[0].amountOut,
                toToken,
                true);
        }
        setBestAmountOut(val);
    };

    //effects
    useEffect(() =>
    {
        const fromToken = (from || dApp.wrappedCoin);
        if (amountIn !== "")
        {
            swap.getSwapInfo(
                (fromToken?.address),
                (to?.address || dApp.wrappedCoin?.address),
                MLWeb3.convertFloatString_TokenBN(amountIn, fromToken)
            );
        }
        handleUpdateSwapInfo();
    }, [from, to, amountIn]);
    useEffect(() =>
    {
        const refreshData = async() =>
        {
            //swapInfo
            await MLUtils.measureTime(`TM: MoonSwap => Info`, async () =>
            {
                await swap.reload_swapInfo();
            });
        }
        const interval = setInterval(refreshData, 2500);
        return () => clearInterval(interval);
    }, []);
    useEventSubscription(ModuleEvents.initialized, () => setSwapFee(swap.fee));
    useEventSubscription(ModuleEvents.swapInfo, handleUpdateSwapInfo, [to]);

    return (
        <Card>
            <CardHeader>
                <Flex>
                    <Text size={1}>
                        Swap
                    </Text>
                </Flex>
            </CardHeader>
            <CardBody>
                <Grid>
                    <InputTokenAmount_Balance
                        token={from}
                        value={amountIn}
                        allowTokenSelection={true}
                        showInWallet={true}
                        onChange={(e, v) => handleFromValueChange(v)}
                        onSelect={(t) => handleFromTokenSelect(t)}
                    />
                    <Flex>
                        <Button
                            className={styles.button_switch}
                            onClick={() => handleSwitchFromTo()}
                        >
                            {MLUI.imageOrSymbol(arrowDown(), styles.button_switchIcon)}
                        </Button>
                    </Flex>
                    <InputTokenAmount_Balance
                        token={to}
                        value={bestAmountOut}
                        showMax={false}
                        placeHolder={"Waiting for swap info"}
                        allowTokenSelection={true}
                        readOnly={true}
                        showInWallet={true}
                        onSelect={(t) => handleToTokenSelect(t)}
                    />

                    <Flex>
                        <Text size={-1} color={2}>
                            Slippage:{" "}
                            <FormatPercent value={slippage} />
                        </Text>
                        <Text size={-1} color={2}>
                            Swap Fee:{" "}
                            <FormatPercent
                                value={swapFee}
								precision={4}
                            />
                        </Text>
                    </Flex>
                </Grid>
            </CardBody>
            <CardFooter>
                <Flex>
                    <CheckBalanceApproveButton
                        style=
                        {
                            {
                                flexGrow: 1
                            }
                        }
                        token={from}
                        amount={MLWeb3.convertFloatString_TokenBN(amountIn, (from || dApp.wrappedCoin))}
                        approveFor={swap.address}
                        onClick={() => handleSwap()}
                    >
                        Swap
                    </CheckBalanceApproveButton>

                    <ToolButton
                        type={ToolButtonType.Settings}
                        onClick={() => setShowSettings(true)}
                    />
                    <ModalSwapSettings
                        show={showSettings}
                        slippage={(slippage * 100).toString()}
                        onClose={() => handleCloseSettings()}
                    />
                </Flex>
            </CardFooter>
        </Card>
    );
};

export default MoonSwapTradeWindow;