//components
import
{
    PageHeader,
    PageContent,
    Grid,
    Flex,
} from "@MoonLabsDev/dapp-core-lib";
import
{
    LineChart_TVL,
    LineChart_MoonToken,
    MoonTokenMetric,
	PieChart_Fees
} from "@MoonVault/dapp-widgets-lib";

//framework
import { Events } from "@MoonLabsDev/dapp-core-lib";
import { MLUtils, DataSeriesFillType } from "@MoonLabsDev/dapp-core-lib";

//hooks
import { useMoonAPI } from "@MoonVault/dapp-widgets-lib";
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";


export default () =>
{
    //hooks
    const api = useMoonAPI();

    return (
        <>
            <PageHeader
                title=
                {
                    <Flex>
                        Protocol
                    </Flex>
                }
            >
                Key metrics of our Protocol, to measure our profitability.
            </PageHeader>

            <PageContent>
                <Grid style={{ width: "100%" }}>
                    <LineChart_TVL />

                    <LineChart_MoonToken
                        metrics={MoonTokenMetric.ALL}
                    />

                    <PieChart_Fees />
                </Grid>
            </PageContent>
        </>
    );
}