
//components
import
{
    Text,
    Grid,
    Image,
    PageContent,
    Card,
    CardHeader,
    CardBody,
    Flex,
	SocialButton,
	SocialPlatform
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { configPage } from "../dApp/config";

//hooks
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//stlyes
import styles from "src/pages/Pages.module.css";

const OurValue = (props) =>
{
    return (
        <Card
            style=
            {
                {
                    width: "300px",
                    height: "400px"
                }
            }
        >
            <CardHeader>
                <Flex>
                    <Text size={1}>
                        {props.title}
                    </Text>
                </Flex>
            </CardHeader>
            <CardBody className={props.className}>
                <Text size={0}>
                    {props.children}
                </Text>
            </CardBody>
        </Card>
    );
};

const OurValues = (props) =>
{
    return (
        <Grid>
            <Flex>
                <Text size={1}>
                    Our Values
                </Text>
            </Flex>

            <Flex>
                <OurValue
                    title={"Accessibility"}
                    className={styles.home_values_accessibility}
                >
                    With helpful UI features and guides, we support new &amp; advanced investors to make well informed investments.
                </OurValue>

                <OurValue
                    title={"Transparency"}
                    className={styles.home_values_transparency}
                >
                    We don't just copy our competition, but strive to add features never seen before in crypto
                </OurValue>

                <OurValue
                    title={"Security"}
                    className={styles.home_values_security}
                >
                    Our Products are audited for security and we completed a KYC at Rugdoc.
                </OurValue>
            </Flex>
        </Grid>
    );
};

const Product = (props) =>
{
    return (
        <Card
            style=
            {
                {
                    width: "300px",
                    height: "400px"
                }
            }
        >
            <CardHeader>
                <Flex>
                    <Text size={1}>
                        {props.title}
                    </Text>
                </Flex>
            </CardHeader>
            <CardBody className={props.className}>
                {props.children}
            </CardBody>
        </Card>
    );
};

const Products= () =>
{
    return (
        <Grid>
            <Flex>
                <Text size={1}>
                    Our Products
                </Text>
            </Flex>

            <Flex>
                <Product
                    title={"Vaults"}
                    className={styles.home_product_vaults}
                >
                    Our vaults automatically reinvest for you, thereby increasing your yield significantly.
                </Product>

                <Product
                    title={"Token"}
                    className={styles.home_product_token}
                >
                    A unique token that can't loose in value. All protocol profits go to our token holders!
                </Product>

                <Product
                    title={"Escrow"}
                    className={styles.home_product_escrow}
                >
                    P2P trading of NFTs and tokens, without the risk of getting scammed!
                </Product>
            </Flex>
        </Grid>
    );
};

const Header = () =>
{
    return (
        <Grid
            style=
            {
                {
                    justifyItems: "center",
                    textAlign: "center"
                }
            }
        >
            <Text
                size={2}
                color={3}
            >
                Welcome to Moon Vault
            </Text>
            <Text size={1}>
                Multi-Chain Yield Aggregator and Crypto Service Hub
            </Text>
            <Flex gap="20px">
				<SocialButton social={SocialPlatform.Twitter} />
				<SocialButton social={SocialPlatform.Telegram} />
				<SocialButton social={SocialPlatform.Discord} />
				<SocialButton social={SocialPlatform.Gitbook} />
				<SocialButton social={SocialPlatform.Github} />
            </Flex>

            <Image
                src="https://app.moon-vault.com/logo.gif"
                style=
                {
                    {
                        minWidth: "300px",
                        maxWidth: "95vw",
                        maxHeight: "400px"
                    }
                }
            />
        </Grid>
    );
};

export default () =>
{
    return (
		<>
			<PageContent>
				<Grid
					gap={"25px"}
					style=
					{
						{
							width: "100%"
						}
					}
				>
					<Header />
					{configPage.devMode &&
						<>
							<Products />
							<OurValues />
						</>
					}
				</Grid>
			</PageContent>
		</>
    );
}