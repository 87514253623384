//framework
import { MLFormat, MLUtils } from "../../../utils";

//styles
import styles from "./Formats.module.css";

const FormatPercent = ({value, colorize, showSign, precision}) =>
{
    return (
        <span
            className=
			{
				(value === 0 || !MLUtils.defaultBoolean(colorize, false)
					? ""
					: (value < 0
						? styles.percent_negative
						: styles.percent_positive
				))
			}
        >
            {MLFormat.formatPercent(
				value,
				MLUtils.defaultBoolean(showSign, false),
				MLUtils.defaultNumber(precision, 2)
			)}
        </span>
    );
};

export default FormatPercent;