import { useTheme } from "../../../hooks";

//styles
import styles from "./Card.module.css";

const Card = (props) =>
{
	const theme = useTheme();

    return (
        <div
            className=
			{
				[
					theme?.getClassName("Card") ?? "",
					styles.card,
					props.className
				].join(" ")
			}
            style={props.style}
        >
            {props.ribbon}
            {props.children}
        </div>
    );
};

export default Card;