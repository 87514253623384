import { useState, useEffect } from "react";

//components
import { ValueLoad, FormatFiatAmount } from "../../../";

//framework
import { MLWeb3 } from "../../../../utils";
import Events from "../../../../core/Events";

//hooks
import { useDApp, useEventSubscription } from "../../../../hooks";

const ValueTokenFiatAmount = (props) =>
{
    //hooks
    const dApp = useDApp();

    //state
    const [token, setToken] = useState(() => props.token || null);
    const [smart, setSmart] = useState(() => props.smart || false);
    const [value, setValue] = useState(() => props.value);
    const [valueUSD, setValueUSD] = useState(() => (props.token ? props.token : dApp.wrappedCoin)?.getPriceUSDForAmount(props.value))
    const [shorten, setShorter] = useState(() => props.shorten || false);
    const [precision, setPrecision] = useState(() => (isNaN(props.precision) ? 8 : props.precision));
    const [load, setLoad] = useState(() => props.load);

    //handler
    const handlePriceUpdate = (data) =>
    {
        const t = (token ? token : dApp.wrappedCoin);
        if (!MLWeb3.checkEqualAddress(data.detail.address, t?.address))
        {
            return;
        }
        setValueUSD(t?.getPriceUSDForAmount(value));
    };

    //effect
    useEffect(() =>
    {
        setToken(props.token || null);
        setValue(props.value);
		setValueUSD((props.token ? props.token : dApp.wrappedCoin)?.getPriceUSDForAmount(props.value));
    }, [props.token, props.value]);
    useEventSubscription(Events.token.priceInfo, handlePriceUpdate, [token, value]);

	return (
		<ValueLoad
			loaded={!load || (token ? token : dApp.wrappedCoin)?.initializedPrice}
		>
			<FormatFiatAmount
				value={valueUSD}
				smart={smart}
				shorten={shorten}
				precision={precision}
			/>
		</ValueLoad>
	);
};

export default ValueTokenFiatAmount;