//components
import
{
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardRibbon,
    PageHeader,
    PageContent,
    Grid
} from "@MoonLabsDev/dapp-core-lib";

const makeCard = (_key, _head, _body, _foot, _ribbon, _ribbonColor) =>
{
    return (
        <Card
            key={_key}
            ribbon=
            {_ribbon !== undefined &&
                <CardRibbon
                    type={_ribbon}
                    color={_ribbonColor}
                >
                    X
                </CardRibbon>
            }
            style=
            {
                {
                    width: "100%",
                }
            }
        >
            {_head !== undefined &&
                <CardHeader>
                    {_head}
                </CardHeader>
            }
            {_body !== undefined &&
                <CardBody
                    style={ { height: "200px" } }
                >
                    {_body}
                </CardBody>
            }
            {_foot !== undefined &&
                <CardFooter>
                    {_foot}
                </CardFooter>
            }
        </Card>
    );
};

const makeCardTest = () =>
{
    const ribbonTypes = ["", "check", "down", "up", "slant-down", "slant-up"];
    const ribbonColors = ["rgb(197, 40, 62)", "#ffae00", "#2ca7d8"]

    return (
        <Grid
            gap={"25px"}
            responsive=
            {
                {
                    sm: { cols: 2 },
                    md: { cols: 3 },
                    ld: { cols: 4 },
                    xl: { cols: 6 }
                }
            }
            style=
            {
                {
                    width: "100%",
                    maxWidth: "95vw"
                }
            }
        >
            {ribbonTypes.map((r) =>
            {
                return makeCard(
                    r,
                    "header",
                    "body",
                    "footer",
                    r,
                    ribbonColors[ribbonTypes.indexOf(r) % ribbonColors.length]);
            })}
        </Grid>
    );

};

export default () =>
{
    return (
        <>
            <PageHeader title="Cards" />

            <PageContent>
                {makeCardTest()}
            </PageContent>
        </>
    );
};