import { useState, useEffect } from "react";

//components
import { ValueTokenAmount } from "../../../";

//framework
import { MLWeb3 } from "../../../../utils";
import Events from "../../../../core/Events";

//hooks
import { useEventSubscription } from "../../../../hooks";

const ValueTokenBalance = (props) =>
{
    //state
    const [token, setToken] = useState(() => props.token || null);
    const [smart, setSmart] = useState(() => props.smart || false);
    const [value, setValue] = useState(() => props.token.userBalance);
    const [shorten, setShorter] = useState(() => props.shorten || false);
    const [precision, setPrecision] = useState(() => (isNaN(props.precision) ? 8 : props.precision));
    const [load, setLoad] = useState(() => props.load);

    //handler
    const handleTokenUpdate = (data) =>
    {
        if (!MLWeb3.checkEqualAddress(data.detail.address, token.address))
        {
            return;
        }
        setValue(token.userBalance);
    };

    //effect
    useEffect(() =>
    {
        setToken(props.token || null);
        setValue(props.token.userBalance);
    }, [props.token]);
    useEventSubscription(Events.token.userData, handleTokenUpdate, [token]);

    return (
        <ValueTokenAmount
            token={token}
            value={value}
            smart={smart}
            shorten={shorten}
            load={load}
            additionalLoad={token?.initializedUser}
        />
    );
};

export default ValueTokenBalance;