import { useState, useEffect } from "react";

//components
import { InputTokenAmount } from "@MoonLabsDev/dapp-core-lib";

//framework
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useBank } from "../../../../modules";

const InputTokenAmount_BankBalance = (props) =>
{
    //hooks
	const bank = useBank();

    //state
    const [value, setValue] = useState(() => props.value || null);
    const [token, setToken] = useState(() => props.token || null);
    const [available, setAvailable] = useState(bank.getBalance(token));

    //handler
    const handleBalanceUpdate = (data) =>
    {
        setAvailable(bank.getBalance(token));
    };
    const handleChange = (e, _value) =>
    {
        if (props.onChange)
        {
            props.onChange(e, _value);
        }
        else
        {
            setValue(_value);
        }
    };
    const handleBlur = (e, _value) =>
    {
        if (props.onBlur)
        {
            props.onBlur(e, _value);
        }
    };

    //effects
    useEffect(() =>
    {
        setValue(props.value || null);
        setToken(props.token || null);
        setAvailable(bank.getBalance(token));
    },
    [
        props.value,
        props.token?.address
    ]);
    useEventSubscription(ModuleEvents.userData, handleBalanceUpdate, [token]);

    return (
        <InputTokenAmount
            token={token}
            value={value}
            showAvailable={true}
            available={available}
            availableText={"Balance"}
            showMax={props.showMax}
            showInWallet={props.showInWallet}
            allowTokenSelection={props.allowTokenSelection}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            onChange={(e, v) => handleChange(e, v)}
            onSelect={props.onSelect}
            onBlur={(e, v) => handleBlur(e, v)}
        />
    );
};

export default InputTokenAmount_BankBalance;