import { useState } from "react";

//components
import
{
	Grid,
	Flex,
	Text,
	Button,
	Card,
	CardHeader,
	CardBody,
	NFTIcon,
	TokenIcon,
	ValueTokenAmount,
	ValueNFTName,
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { ModuleEvents } from "../../..//modules/Module_SunflowerTrader";
import { useSunflowerTrader } from "../../../modules";

//styles
import styles from "./SunflowerTrader.module.css";

const ListingStat = (props) =>
{
	return (
		<Flex
			style={{ justifyContent: "space-between"}}
		>
			<Text>
				{props.title}
			</Text>
			<Flex>
				{props.children}
			</Flex>
		</Flex>
	);
}

const SunflowerTraderListingGroup = (props) =>
{
	//context
	const trader = useSunflowerTrader();

	//state
	const [resource, setResource] = useState(props.resource);
	const [group, setGroup] = useState({...trader.findListingGroup(resource)});

	//handler
	const handleUpdate = (_data) =>
	{
		setGroup({...trader.findListingGroup(resource)})
	}

	//handler
	const handleShowList = () =>
    {
        props?.onShowList();
    };

	//effects
	useEventSubscription(ModuleEvents.stats, handleUpdate);

	if (!group?.count_active)
	{
		return null;
	}
	return (
		<Card
			style=
			{
				{
					width: "250px"
				}
			}
		>
			<CardHeader>
				<Grid gap="0px">
					<Text align="center">
						<ValueNFTName
							nft={trader.nft}
							nftID={resource}
						/>
					</Text>
					<Text
						align="center"
						size={-1}
						color={2}
					>
						#{resource}
					</Text>
				</Grid>
			</CardHeader>
			<CardBody>
				<Text size={-1}>
					<Grid>
						<ListingStat
							title="Item:"
						>
							<ValueNFTName
								nft={trader.nft}
								nftID={resource}
							/>
							<NFTIcon
								nft={trader.nft}
								nftID={resource}
								className={styles.nftIcon}
							/>
						</ListingStat>

						<ListingStat
							title="Active:"
						>
							<Text>
								{group.count_active}
							</Text>
						</ListingStat>

						<ListingStat
							title="Sold:"
						>
							<Text>
								{group.count_purchased}
							</Text>
						</ListingStat>

						<ListingStat
							title="Sold (24h):"
						>
							<Text>
								{group.count_purchased24h}
							</Text>
						</ListingStat>

						<ListingStat
							title="Min Price:"
						>
							<ValueTokenAmount
								token={trader.token}
								value={group.priceMin}
								smart={true}
								load={true}
							/>
							<TokenIcon
								token={trader.token}
								className={styles.sflIcon}
							/>
						</ListingStat>

						<ListingStat
							title="Avg Price (24h):"
						>
							<ValueTokenAmount
								token={trader.token}
								value={group.priceAvg24h}
								smart={true}
								load={true}
							/>
							<TokenIcon
								token={trader.token}
								className={styles.sflIcon}
							/>
						</ListingStat>

						<ListingStat
							title="Last Price:"
						>
							<ValueTokenAmount
								token={trader.token}
								value={group.priceLast}
								smart={true}
								load={true}
							/>
							<TokenIcon
								token={trader.token}
								className={styles.sflIcon}
							/>
						</ListingStat>

						<Button onClick={() => handleShowList()}>
							Show Listings
						</Button>
					</Grid>
				</Text>
			</CardBody>
		</Card>
	);
};

export default SunflowerTraderListingGroup;