import { useState } from "react";

//components
import { PieChart } from "@MoonLabsDev/dapp-core-lib";

//hooks
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { ModuleEvents } from "../../..//modules/Module_FeeDistributor";
import { useFeeDistributor } from "../../../modules";

const PieChart_Fees = (props) =>
{
    //hooks
    const feeDistributor = useFeeDistributor();

    //state
    const [dataValues, setDataValues] = useState(() => []);
    const [dataColors, setDataColors] = useState(() => []);

    //handler
    const handleGetData = async () =>
    {
        if (dataValues.length > 0)
        {
            return;
        }

        //get data
        const data = feeDistributor.fees;
        const dCols = [];
        const dVals = [];
        for (let n = 0; n < data.length; n++)
        {
            const d = data[n];
            if (!d.percent)
            {
                continue;
            }

            //handle data
            //dCols.push(c.color);
            dVals.push(
            {
                name: d.name,
                value: parseFloat((d.percent * 100).toFixed(2))
            });
        }

        setDataValues(dVals);
        setDataColors(dCols);
    };

    //effects
    useEventSubscription(ModuleEvents.init, handleGetData);

    return (
        <PieChart
            width={600}
            height={200}
            data={dataValues}
        />
    );
};

export default PieChart_Fees;