export const Events =
{
    web3:
    {
        connect: "web3_connect",
        connectWallet: "web3_connectWallet",
        getAccount: "web3_getAccount",
        update: "web3_update"
    },
    dApp:
    {
        initialized: "dApp_initialized",
        reload: "dApp_reload",
        transaction: "dApp_transaction"
    },
    token:
    {
        init: "token_init",
        data: "token_data",
        userData: "token_userData",
        approval: "token_approval",
        priceInfo: "token_priceInfo"
    },
    nft:
    {
        tokenURI: "nft_tokenURI",
        metaData: "nft_metaData",
        userData: "nft_userData",
        approval: "nft_approval"
    }
};

export default Events;