import { useState, useEffect, useRef } from "react";

//components{}
import
{
    Text,
    Grid,
    Flex,
    Button,
    Modal,
    List,
    TokenIcon,
    ConditionalContainer,
    ValueTokenBalance,
    Input
} from "../../";

//framework
import t from "../../../utils/MLTranslate";

//hooks
import { useDApp } from "../../../hooks";

//styles
import styles from "./Modals.module.css";

const TokenQuickSelect = (props) =>
{
    //context
    const dApp = useDApp();

    return (
        <Button
            className={styles.selectToken_quickSelectButton}
            onClick={() => props.onSelect(props.token)}
        >
            <TokenIcon
                className={styles.selectToken_iconMini}
                token={props.token || dApp.wrappedCoin}
            />
            <Text size={-1}>
                {(!!props.token ? props.token.symbol : dApp.coinSymbol)}
            </Text>
        </Button>
    );
};

const ModalSelectToken = (props) =>
{
    //context
    const dApp = useDApp();

    //state
    const [show, setShow] = useState(() => props.show);
    const [tokenList, setTokenList] = useState(() => props.tokenList || []);
    const [showInWallet, setShowInWallet] = useState(() => (props.showInWallet !== undefined ? props.showInWallet : true));
    const [showBalance, setShowBalance] = useState(() => (props.showBalance !== undefined ? props.showBalance : true));
    const [filterString, setFilterString] = useState(() => "");
    const [filterTokenList, setFilterTokenList] = useState(() => props.tokenList || []);

    //functions
    const filterTokens = (list, filter) =>
    {
        filter = filter.toUpperCase();
        return list.filter((t) =>
        {
            return (filter === ""
                || t.symbol.toUpperCase().indexOf(filter) !== -1
                || t.address.toUpperCase() === filter);
        });
    };
    const applyFilter = () =>
    {
        setFilterTokenList(filterTokens(tokenList, filterString));
    };

    //handler
    const handleFilterChanged = (data) =>
    {
        setFilterString(data.target.value);
    };
    const handleShowInWallet = (e, token) =>
    {
        e.stopPropagation();
        token.addToWallet();
    };
    const handleSelect = (token) =>
    {
        if (props.onSelect)
        {
            props.onSelect(token);
        }
        else
        {
            handleClose();
        }
    };
    const handleClose = () =>
    {
        if (props.onClose)
        {
            props.onClose();
        }
        else
        {
            setShow(false);
        }
    };

    //effect
    useEffect(() =>
    {
        applyFilter();
    }, [filterString]);
    useEffect(() =>
    {
        setShow(props.show);
        setFilterString("");
        setFilterTokenList(filterTokens(tokenList, ""));
    }, [props.show]);
    useEffect(() =>
    {
        setTokenList(props.tokenList);
        setFilterTokenList(filterTokens(props.tokenList, filterString));
    }, [JSON.stringify(props.tokenList)]);

    return (
        <Modal
            show={show}
            allowBig={true}
            header={t("modal_selectToken_title")}
            footer=
            {
                <Button onClick={() => handleClose()}>
                    {t("cancel")}
                </Button>
            }
            onClose={() => handleClose()}
        >
            <Input
                className={styles.selectToken_filterString}
                value={filterString}
                onChange={handleFilterChanged}
                placeholder={t("modal_selectToken_filterPlaceholder")}
            />

            {(filterTokenList.includes(dApp.wrappedCoin) || filterTokenList.filter(t => t.oracleType === "Stable").length > 0) &&
                <Flex className={styles.selectToken_quickSelect}>
                    {filterTokenList.includes(dApp.wrappedCoin) &&
                        <>
                            <TokenQuickSelect
                                token={null}
                                onSelect={(v) => handleSelect(v)}
                            />
                            <TokenQuickSelect
                                token={dApp.wrappedCoin}
                                onSelect={(v) => handleSelect(v)}
                            />
                        </>
                    }
                    {filterTokenList.filter(t => t.oracleType === "Stable").map(t =>
                        <TokenQuickSelect
                            key={t.address}
                            token={t}
                            onSelect={(v) => handleSelect(v)}
                        />
                    )}
                </Flex>
            }

            <List
                className={styles.selectToken_list}
                itemClassName={styles.selectToken_row}
                onSelect={(v) => handleSelect(v)}
                items=
                {filterTokenList.map((token) =>
                    {
                        return {
                            value: token,
                            content: (
                                <>
                                    <TokenIcon
                                        className={styles.selectToken_icon}
                                        token={token}
                                    />
                                    <Grid gap="0px">
                                        <Text>
                                            {token.getFullName()}
                                        </Text>
                                        <ConditionalContainer visible={token.isLPToken()}>
                                            <Text size={-1}>
                                                {token.router}
                                            </Text>
                                        </ConditionalContainer>
                                    </Grid>
                                    {showInWallet &&
                                        <Button
                                            className={styles.selectToken_buttonShowInWallet}
                                            onClick={(e) => handleShowInWallet(e, token)}
                                        >
                                            +
                                        </Button>
                                    }
                                    {showBalance &&
                                        <Text
                                            size={-1}
                                            style=
                                            {
                                                {
                                                    marginLeft: "auto"
                                                }
                                            }
                                        >
                                            <ValueTokenBalance
                                                token={token}
                                                shorten={true}
                                                precision={4}
                                            />
                                        </Text>
                                    }
                                </>
                            )
                        };
                    }
                )}
            />
        </Modal>
    );
};

export default ModalSelectToken;