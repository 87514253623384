import { config } from "@MoonLabsDev/dapp-sdk-lib";
import { configDebug, configWeb3 } from "@MoonLabsDev/dapp-core-lib";
import configPage from "./page";
import configMenu from "./menu";
import configUI from "./ui";
import configChains from "./chains";
import configLabels from "./labels";


export default
{
	...config,

	debug: configDebug,
	web3: configWeb3,
	menu: configMenu,
	chains: configChains,
	labels: configLabels,

	page: configPage,
	ui: configUI
};