import { configDebug, configWeb3, configLabels } from "@MoonLabsDev/dapp-core-lib";
import configPage from "./page";
import configMenu from "./menu";
import configUI from "./ui";
import configChains from "./chains";

const config =
{
	debug: configDebug,
	web3: configWeb3,
	ui: configUI,
	menu: configMenu,
	chains: configChains,
	page: configPage,
	labels: configLabels
}

export default config;