import { useState, useEffect } from "react";

//config
import { useDApp } from "./useDApp";

export const useMediaQuery = (_query, _queryIsName = true) =>
{
	//context
	const dApp = useDApp();

    //match
    const [matches, setMatches] = useState(false);

    //make query string
    const matchQueryStr = (_queryIsName
        ? `(min-width: ${dApp.config.ui.mediaQuery[_query]}px)`
        : _query);

    useEffect(() =>
    {
        const media = window.matchMedia(matchQueryStr);
        if (media.matches !== matches)
        {
            setMatches(media.matches);
        }
        const listener = () =>
        {
            setMatches(media.matches);
        };
        media.addEventListener("change", listener);
        return () => media.removeEventListener("change", listener);
    }, [matches, matchQueryStr]);

    return matches;
};