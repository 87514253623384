import { NavLink } from "react-router-dom";

//components
import { Image } from "@MoonLabsDev/dapp-core-lib";
import MenuItem from "./MenuItem";
import { MenuStyle } from "./types";

//framework
import { menu as Icon_Menu } from "@MoonLabsDev/dapp-core-lib";
import { useDApp } from "@MoonLabsDev/dapp-core-lib";

//styles
import styles from "./Menu.module.css";

//config
const configPage =
{
	logo: "",
}

const Menu = ({ menu, menuStyle }) =>
{
	//context
	const dApp = useDApp();

    //generate menu
    let menuContent = <></>;
    let menuClass = "";
    switch (menuStyle)
    {
        case MenuStyle.Minimal:
            //minimal menu just with "..."
            menuClass = styles.menuMode_minimal;
            menuContent = (
                <MenuItem
                    menuItem=
                    {
                        {
                            icon: Icon_Menu(),
                            children: menu
                        }
                    }
                    depth={0}
                />
            );
            break;

        case MenuStyle.App:
            //app menu with icons
            menuClass = styles.menuMode_app;
            menuContent = (
                <>
                    {menu.map((m) => (
                        <MenuItem
                            key={`item_root_${menu.indexOf(m)}`}
                            menuItem={m}
                            depth={0}
                        />
                    ))}
                </>
            );
            break;

        default:
            //normal menu
            menuClass = styles.menuMode_normal;
            menuContent = (
                <>
                    {menu.map((m) => (
                        <MenuItem
                            key={`item_root_${menu.indexOf(m)}`}
                            menuItem={m}
                            depth={0}
                        />
                    ))}
                </>
            );
            break;
    }

    //normal menu
    return (
        <div
            className=
            {
                [
                    styles.menuContainer,
                    (menuStyle === MenuStyle.App ? styles.menuMode_app : styles.menuMode_desktop)
                ].join(" ")
            }
        >
            {menuStyle !== MenuStyle.App && dApp.config.page.logo && dApp.config.page.logo !== "" &&
                <NavLink to="/">
                    <Image
                        src={dApp.config.page.logo}
                        className={styles.menuLogo}
                    />
                </NavLink>
            }
            <nav className={[styles.menu, menuClass].join(" ")}>
                {menuContent}
            </nav>
        </div>
    );
};

export default Menu;