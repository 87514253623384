import React, { useState, useEffect, useContext } from "react";

//framework
import {DApp} from "../core";

const DAppContext = React.createContext();
export const useDApp = () =>
{
    return useContext(DAppContext);
};

export const DAppProvider = ({ config, children, modules, web3User, web3Data, wallet, toastCallback}) =>
{
    //state
    const [dApp] = useState(() => DApp.getOrCreateInstance(config, modules, web3User, web3Data, wallet, toastCallback));
	const [loaded, setLoaded] = useState(() => false);

	//effects
	useEffect(() =>
	{
        dApp.loaded.then(() => setLoaded(true));
    }, []);

    return (
        <DAppContext.Provider value={dApp}>
            {loaded ? children : null}
        </DAppContext.Provider>
    );
};