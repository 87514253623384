import { useState, useEffect } from "react";

//components
import
{
    Text,
    Grid,
    ToolButton,
    ToolButtonType,
    Flex,
	TokenIcon
} from "@MoonLabsDev/dapp-core-lib";
import ValueTokenBankBalance from "./Helper/ValueTokenBankBalance";

//framework
import { useDApp } from "@MoonLabsDev/dapp-core-lib";

//styles
import styles from "./Bank.module.css";

const BankAssetInfo = (props) =>
{
    //hooks
	const dApp = useDApp();

    //state
    const [token, setToken] = useState(() => props.token || null);

    //effect
    useEffect(() =>
    {
        setToken(props.token || null);
    }, [props.token]);

    //values
    return (
        <Flex
            style={{ width: "100%" }}
        >
			<TokenIcon
				className={styles.assetInfo_icon}
				token={token ?? dApp.wrappedCoin}
			/>
            <Grid
                gap="0px"
            >
                <Text>
                    {token?.symbol ?? dApp.coinSymbol}
                </Text>
				<Text size={-1}>
					<ValueTokenBankBalance
						token={token}
						smart={true}
						load={true}
					/>
				</Text>
            </Grid>

			 <Flex
				style={{ marginLeft: "auto" }}
			>
				<ToolButton
					type={ToolButtonType.Wallet}
					onClick={() => props.onWithdraw(token)}
				/>
			</Flex>
        </Flex>
    );
};

export default BankAssetInfo;