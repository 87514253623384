import { useState } from "react";

//css
import styles from "./Values.module.css";

const ValueLoad = (props) =>
{
	if (props.loaded
		&& props.className === undefined)
	{
		//loaded
		return <>{props.children}</>;
	}
	else
	{
		//load
		return (
			<div
				className=
				{
					[
						(props.loaded ? "" : styles.loading),
						props.className
					].join(" ")
				}
			>
				{(props.loaded
					? props.children
					: (props.loadText ?? "???")
				)}
			</div>
		);
	}
};

export default ValueLoad;